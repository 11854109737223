import { GddModelDefine } from '../gdd'

export const gddPineNeedleScale: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddPineNeedleScale',
  name: 'Pine Needle Scale',
  fullName: 'Pine Needle Scale (Chionaspis pinifoliae) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Egg laying has not begun.',
      range: {
        min: 0,
        max: 250,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult scales may be present.',
        },
        {
          name: 'Eggs',
          value: 'Eggs are not yet present.',
        },
      ],
    },
    {
      name: 'First Generation Egg Hatching',
      range: {
        min: 251,
        max: 400,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to hatch.',
        },
        {
          name: 'Crawlers',
          value: 'Crawlers have emerged from eggs.',
        },
      ],
    },
    {
      name: 'First Generation Hyaline Stage',
      range: {
        min: 401,
        max: 500,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to hatch.',
        },
        {
          name: 'Hyaline Stage',
          value: 'Hyaline Stage scales have emerged from eggs and can be targeted with control.',
        },
      ],
    },
    {
      name: 'Second Generation Egg laying has not begun.',
      range: {
        min: 501,
        max: 1250,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult scales may be present.',
        },
        {
          name: 'Eggs',
          value: 'Eggs are not yet present.',
        },
      ],
    },
    {
      name: 'Second Generation Egg Hatching',
      range: {
        min: 1251,
        max: 1350,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to hatch.',
        },
        {
          name: 'Crawlers',
          value: 'Second generation Crawlers have emerged from eggs.',
        },
      ],
    },
    {
      name: 'Second Generation Hyaline Stage',
      range: {
        min: 1351,
        max: 1500,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to hatch.',
        },
        {
          name: 'Hyaline Stage',
          value: 'Hyaline Stage scales have emerged from eggs and can be targeted with control.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pine Needle Scale Degree Day Model is used to predict life-stage developments of the Pine Needle Scale (Chionaspis pinifoliae), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of hyaline stage which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-pine-needle-scale-growing-degree-day-model/',
  citations: [
    'Fondren, K. and D.G. McCullough. 2002. Biology and management of pine needle scale. MSU Extension bulletin E-2812. 4 p.',
  ],
}
