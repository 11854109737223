import { GddModelDefine } from '../gdd'

export const gddPineTreeLappetMoth: GddModelDefine = {
  lowerThreshold: 45,
  upperThreshold: 86,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPineTreeLappetMoth',
  name: 'Pine Tree Lappet Moth',
  fullName: 'Pine Tree Lappet Moth (Dendrolimus pini) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 661,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have not begun flight.',
        },
      ],
    },
    {
      name: 'First Flight Adults from Overwintered Larvae (Colder Regions)',
      range: {
        min: 662,
        max: 1034,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have begun flight.',
        },
      ],
    },
    {
      name: 'First Flight - Adults from Overwintered Larvae (All regions)',
      range: {
        min: 1035,
        max: 1588,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have begun flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'First Egghatch- 1st Generation',
      range: {
        min: 1589,
        max: 1752,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are in flight and egg-laying is in peak.',
        },
        {
          name: 'Larvae',
          value: 'First generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'Peak Flight (Most regions)',
      range: {
        min: 1753,
        max: 2114,
      },
      infos: [
        {
          name: 'Adults',
          value:
            'Adults are in peak flight in most regions and egg-laying has begun. End of flight in colder regions.',
        },
      ],
    },
    {
      name: 'Early-Mid Larval Instars seek Hibernacula',
      range: {
        min: 2115,
        max: 2470,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Early-mid larval instars seek hibernacula.',
        },
      ],
    },
    {
      name: 'End of Adult Flight (Most Regions)',
      range: {
        min: 2471,
        max: 2669,
      },
      infos: [
        {
          name: 'Adults',
          value: 'End of adult flight in most regions.',
        },
      ],
    },
    {
      name: 'Mid-Late Larval Instars seek Hibernacula',
      range: {
        min: 2670,
        max: 4074,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Mid-late larval instars seek hibernacula.',
        },
      ],
    },
    {
      name: '2nd Generation Adult Emergence',
      range: {
        min: 4075,
        max: 4598,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have emerged (only in warm regions).',
        },
      ],
    },
    {
      name: 'First Egg Hatch from 2nd Generation Adults',
      range: {
        min: 4599,
        max: 4700,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have layed eggs (only in warm regions).',
        },
        {
          name: 'Larvae',
          value: 'Larvae from second generation adults have begun to emerge.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pine Tree Lappet Moth Degree Day Model is used to predict development of life-cycle stages of Pine Tree Lappet Moth (Dendrolimus pini), in order to improve pest management decisions.',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-pine-tree-lappet-moth-growing-degree-day-model/',
  citations: [
    'Hardin, J.A. and A. Suazo. 2012. New pest response guidelines: Dendrolimus pine moths. USDA-APHIS-PPQ-CPHST',
  ],
}
