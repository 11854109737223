import { GddModelDefine } from '../gdd'

export const gddTobaccoBudworm: GddModelDefine = {
  lowerThreshold: 54.7,
  upperThreshold: 91.9,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddTobaccoBudworm',
  name: 'Tobacco Budworm',
  fullName: 'Tobacco Budworm (Helicoverpa virescens) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adults / Eggs  Observed',
      range: {
        min: 0,
        max: 72,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have been observed and eggs are present.',
        },
      ],
    },
    {
      name: 'Small Larvae',
      range: {
        min: 73,
        max: 220,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Eggs have hatched and 1-3rd instar larvae are present.',
        },
      ],
    },
    {
      name: 'Large Larvae',
      range: {
        min: 221,
        max: 437,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Eggs have hatched and 4-5th instar larvae are present.',
        },
      ],
    },
    {
      name: 'Pupation',
      range: {
        min: 438,
        max: 760,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Pupation has begun.',
        },
      ],
    },
    {
      name: 'Next Generation Adults - Pre-Egglaying',
      range: {
        min: 761,
        max: 872,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged, eggs have not yet been laid..',
        },
      ],
    },
    {
      name: 'Next Generation Adults - Egglaying',
      range: {
        min: 873,
        max: 1000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged, eggs have been laid..',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Tobacco Budworm Degree Day Model is used to predict development of life-cycle stages of Tobacco Budworm (Helicoverpa virescens), in order to improve pest management decisions',
    `For best results, a biofix date should be set when adults or eggs are first observed in the field. The growing degree day model can then be used to predict emergence of next generation of adults. In order to predict emergence at the beginning of the year, the 'Tobacco Budworm Emergence' Model should be used.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-tobacco-budworm-growing-degree-day-model/',
  citations: [
    'Hartstack, A. W., Jr., J. P. Hollingsworth, R. L. Ridgeway, and J. D. Lopez. 1976. MOTHZV-2: A computer simulation of Heliothis zea and virescens population dynamics. User manual. 1976. U.S.D.A. ARS-S-127.',
  ],
}
