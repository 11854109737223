import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { Router } from '@angular/router'
import { StatusBar } from '@capacitor/status-bar'
import {
  AlertController,
  IonContent,
  LoadingController,
  NavController,
} from '@ionic/angular'
import 'firebase/auth'
import 'firebase/database'
import { Subscription } from 'rxjs'
import { Analytics } from '../../services/analytics'
import { StartupService } from '../../services/startup.service'
import { StoreService } from '../../services/store.service'
import { Tools } from '../../services/tools'
import { UserActivity } from '../../services/user-activity.service'
import { Me } from '../../services/me'
import { PageService } from '../../utility/page.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  showConnecting = true
  showLoginForm = false
  connectingMessage = 'Connecting...'
  email = ''
  password = ''
  onKeyboardShow?: Subscription

  @ViewChild('loginFormContainer', { static: false }) set content(content: ElementRef) {
    if (content) {
      setTimeout(() => {
        this.renderer.addClass(content.nativeElement, 'slide-in')
      })
    }
  }

  @ViewChild('content', { static: true }) private viewContent!: IonContent

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private userActivity: UserActivity,
    private store: StoreService,
    private zone: NgZone,
    private renderer: Renderer2,
    private firebaseAuth: AngularFireAuth,
    private startup: StartupService,
    private page: PageService,
  ) {
    const { startupComplete } = page.getState<LoginRouteData>(false)
    if (startupComplete != null) {
      this.showConnecting = !startupComplete
      this.showLoginForm = startupComplete
    }
  }

  ngOnInit() {
    void this.connect()
  }

  async ionViewWillEnter() {
    await this.startup.waitForComplete()

    if (Tools.isNative()) {
      void StatusBar.setBackgroundColor({ color: '#f1f1f1' })

      // // HACK: Fix Ionic bug where keyboard covers input on unscrollable page.
      // this.onKeyboardShow = this.keyboard.onKeyboardShow()
      //   .subscribe(() => {
      //     const top = document.activeElement?.getBoundingClientRect().top
      //     void this.viewContent.scrollToPoint(0, top, 500)
      //   })
    }
  }

  ionViewWillLeave() {
    if (this.onKeyboardShow) {
      this.onKeyboardShow.unsubscribe()
      this.onKeyboardShow = undefined
    }
  }

  async connect() {
    this.connectingMessage = 'Connecting...'

    // Make sure config data is loaded before anything else happens.
    try {
      await this.startup.waitForComplete()
    } catch (err) {
      console.error(err.message)
      const alert = await this.alertCtrl.create({
        header: 'The server could not be reached. Sorry for the inconvenience.',
        buttons: [
          {
            text: 'Retry',
            handler: () => {
              void this.connect()
            },
          },
        ],
        backdropDismiss: false,
      })
      await alert.present()
      throw err
    }

    if (Tools.isAppUpdateRequired()) {
      await Tools.showAppUpdateAlert()
      return
    }

    //
    // Automatic Login
    //
    try {
      await Me.autoLogin()
    } catch (err) {
      this.presentLoginForm()
      return
    }

    await this.loginSuccess()
  }

  presentLoginForm() {
    this.zone.run(() => {
      this.showConnecting = false
      this.showLoginForm = true
    })
  }

  async handleLoginFormSubmit() {
    // Error check.
    let errorMsg
    if (!this.email) {
      errorMsg = 'Email is required.'
    } else if (!this.password) {
      errorMsg = 'Password is required.'
    }

    if (errorMsg) {
      const alert = await this.alertCtrl.create({
        header: errorMsg,
        buttons: ['Close'],
        backdropDismiss: false,
      })
      await alert.present()
      return
    }

    const loading = await this.loadingCtrl.create({ message: 'Logging in...' })
    await loading.present()

    try {
      await Me.login({
        email: this.email,
        password: this.password,
      })
    } catch (err) {
      await loading.dismiss()
      console.error(err.message)
      const alert = await this.alertCtrl.create({
        header: 'Error',
        message: err.message,
        buttons: ['Close'],
        backdropDismiss: false,
      })
      await alert.present()
      throw err
    }

    Analytics.login('email')
    await this.loginSuccess()

    await loading.dismiss()
  }

  async loginSuccess() {
    void this.userActivity.addActivity('login')
    await this.navCtrl.navigateRoot('/home', {
      animated: true,
      animationDirection: 'forward',
    })
  }

  async handleSignupClick() {
    await this.navCtrl.navigateForward('/signup')
  }

  async handleForgotPasswordClick() {
    await this.navCtrl.navigateForward('/forgot-password')
  }

}

export type LoginRouteData = {
  state: {
    startupComplete?: boolean
  }
}
