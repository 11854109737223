import { GddModelDefine } from '../gdd'

export const gddNantucketPineTipMoth: GddModelDefine = {
  lowerThreshold: 5.5,
  upperThreshold: 37.35,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddNantucketPineTipMoth',
  name: 'Nantucket Pine Tip Moth',
  fullName: 'Nantucket Pine Tip Moth (Rhyacionia frustrana) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'First Adult Trap',
      range: {
        min: 0,
        max: 575,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults are present but have not reached peak flight',
        },
      ],
    },
    {
      name: 'Peak Flight',
      range: {
        min: 576,
        max: 1262,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are peak flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Larvae/ Treatment Timing',
      range: {
        min: 1262,
        max: 1462,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and can be targeted for treatment.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Nantucket Pine Tip Moth Degree Day Model is used to predict development of life-cycle stages of the Nantucket Pine Tip Moth (Rhyacionia frustrana), in order to improve pest management decisions',
    'For best results, a biofix date should be set adult moths are first observed or trapped. The growing degree day model can then be used to predict emergence of larvae that can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-nantucket-pine-tip-moth-growing-degree-day-model/',
  citations: [
    `Malinoski, M. K., and T. D. Paine. 1988. A degree-day model to predict Nantucket pine tip moth, Rhyacionia frustrana (Comstock)(Lepidoptera: Tortricidae), flights in Southern California. Environ. Entomol. 17: 75-79.`,
  ],
}
