// Deployed dev environment.
export const environment = {
  isProd: false,
  isDeployed: true,
  firebase: {
    apiKey: 'AIzaSyCqdRklhBm7OKBRWxLmh1aDJR8u1hrwGw4',
    authDomain: 'pest-prophet-dev.firebaseapp.com',
    projectId: 'pest-prophet-dev',
    storageBucket: 'pest-prophet-dev.appspot.com',
    appId: '1:1051898310365:web:e7caffe34ea25d6f0f3534',
  },
  googleMapsApiKey: 'AIzaSyCqdRklhBm7OKBRWxLmh1aDJR8u1hrwGw4',
  apiBaseUrl: 'https://dev.api.pestprophet.com',
}
