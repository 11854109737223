import { GddModelDefine } from '../gdd'

export const gddCabbageLooper: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 90,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCabbageLooper',
  name: 'Cabbage Looper',
  fullName: 'Cabbage Looper (Trichoplusia ni) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adult Moth Activity / Eggs',
      range: {
        min: 0,
        max: 98,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'First Generation Larvae',
      range: {
        min: 99,
        max: 518,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Peak of first generation larvae.`,
        },
      ],
    },
    {
      name: 'First Generation Pupae',
      range: {
        min: 519,
        max: 792,
      },
      infos: [
        {
          name: 'Pupae',
          value: `Peak of first generation pupae.`,
        },
      ],
    },
    {
      name: 'First Generation Adult Moths',
      range: {
        min: 793,
        max: 890,
      },
      infos: [
        {
          name: 'Adults',
          value: `First generation adult moths present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Cabbage Looper Growing Degree Day Model is used to predict life-stage developments of the Cabbage Looper (Trichoplusia ni) in order to improve pest management decisions.`,
    `For best results, traps should be placed early in the season.The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Toba, H. H., A. N. Kishaba, R. Pangaldan and P. V. Vail 1973. Temperature and the development of the cabbage looper. Ann. Entomol. Soc. Amer. 66: 965-974.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-cabbage-looper-growing-degree-day-model',
}
