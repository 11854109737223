import { tomCastAsparagusStemphyliumLeafSpot } from './tom-cast-asparagus-stemphylium-leaf-spot'
import { tomCastCarrotAlternariaLeafBlight } from './tom-cast-carrot-alternaria-leaf-blight'
import { tomCastPistachioAlternariaLateBlight } from './tom-cast-pistachio-alternaria-late-blight'
import { tomCastPotatoEarlyBlight } from './tom-cast-potato-early-blight'
import { tomCastTomatoAnthracnose } from './tom-cast-tomato-anthracnose'
import { tomCastTomatoBlackmold } from './tom-cast-tomato-blackmold'
import { tomCastTomatoEarlyBlight } from './tom-cast-tomato-early-blight'
import { tomCastTomatoSeptoriaLeafBlight } from './tom-cast-tomato-septoria-leaf-blight'

export * from './tom-cast'

export const tomCastModelDefines = {
  tomCastPotatoEarlyBlight,
  tomCastCarrotAlternariaLeafBlight,
  tomCastPistachioAlternariaLateBlight,
  tomCastAsparagusStemphyliumLeafSpot,
  tomCastTomatoEarlyBlight,
  tomCastTomatoAnthracnose,
  tomCastTomatoBlackmold,
  tomCastTomatoSeptoriaLeafBlight,
} as const
