import { GddModelDefine } from '../gdd'

export const gddSweetpotatoWhitefly: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 90,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddSweetpotatoWhitefly',
  name: 'Sweetpotato Whitefly',
  fullName: 'Sweetpotato Whitefly (Bemisia tabaci) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 569,
      },
      infos: [
        {
          name: 'Eggs',
          value: `Most whiteflies are in the egg stage.`,
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 570,
        max: 1139,
      },
      infos: [
        {
          name: 'Adults',
          value: `Most whiteflies are adults. Biofix should be reset for next generation`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Sweetpotato Whitefly Growing Degree Day Model is used to predict life-stage developments of the Sweetpotato Whitefly (Bemisia tabaci) in order to improve pest management decisions.`,
  ],
  citations: [
    `Zalom, F.G., E.T. Natwick, and N.C. Toscano. 1985. Temperature regulation of Bemisia tabaci (Homoptera: Aleyrodidae) populations in Imperial Valley Cotton. J. Econ. Entomol. 78: 61-64.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-sweetpotato-whitefly-growing-degree-day-model',
}
