import { MeasurementUnits } from '@prisma/client'

export const measurementUnitsText: {
  [K in MeasurementUnits]: string
} = {
  metric: 'Metric (°C, cm.)',
  imperial: 'Imperial (°F, in.)',
}

export const modelUnitsTypes = [
  'temperature',
  'index',
  'dsv',
  'efi',
  'probability',
] as const
export type ModelUnits = typeof modelUnitsTypes[number]

export class ModelUnitsName {

  readonly metric: string
  readonly imperial: string

  constructor(args: {
    metric: string
    imperial: string
  }) {
    this.metric = args.metric
    this.imperial = args.imperial
  }

  static universal(universal: string) {
    return new ModelUnitsName({
      metric: universal,
      imperial: universal,
    })
  }

}

