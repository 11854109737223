import { GddModelDefine } from '../gdd'

export const gddSunflowerStemWeevil: GddModelDefine = {
  lowerThreshold: 5,
  upperThreshold: 32,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddSunflowerStemWeevil',
  name: 'Sunflower Stem Weevil',
  fullName: 'Sunflower Stem Weevil (Cylindrocopturus adspersus) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Emergence',
      range: {
        min: 0,
        max: 420,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Overwintered adults have not emerged.',
        },
      ],
    },
    {
      name: 'Initial Emergence',
      range: {
        min: 421,
        max: 535,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Overwintered adults have started to emerge.',
        },
      ],
    },
    {
      name: '10% Emergence',
      range: {
        min: 536,
        max: 670,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Overwintered adults have reached 10% emergence.',
        },
      ],
    },
    {
      name: '50% Emergence',
      range: {
        min: 671,
        max: 865,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Overwintered adults have reached 50% emergence.',
        },
      ],
    },
    {
      name: '90% Emergence',
      range: {
        min: 866,
        max: 900,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Overwintered adults have reached 90% emergence.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Sunflower Stem Weevil Degree Day Model is used to predict development of life-cycle stages of the Sunflower Stem Weevil (Cylindrocopturus adspersus), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict adult emergence.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-sunflower-stem-weevil-growing-degree-day-model/',
  citations: [
    `Charlet, L. D. 1987. Emergence of the sunflower stem weevil, Cylindrocopturus adspersus (Coleoptera: Curculionidae), relative to calendar date and degree-days in the northern great plains. J. Kan. Ent. Soc. 60:426-432.`,
  ],
}
