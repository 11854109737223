import { GddModelDefine } from '../gdd'

export const gddSpruceBudworm: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddSpruceBudworm',
  name: 'Spruce Budworm',
  fullName: 'Spruce Budworm (Choristoneura fumiferana) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Larvae have not emerged',
      range: {
        min: 0,
        max: 90,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Larvae',
      range: {
        min: 91,
        max: 250,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged and have begun to feed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Spruce Budworm Degree Day Model is used to predict life-stage developments of the Spruce Budworm (Argyrotaenia pinatubana), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by insecticedes.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-spruce-budworm-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
