import { gddGrape } from './gdd-grape'

export const gddGrapeSyrah = gddGrape.variety('Syrah', {
  lowerThreshold: undefined,
  upperThreshold: 48.88,
  cutoffMethod: 'horizontal',

  type: 'gddGrapeSyrah',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Dormancy - Budbreak',
      range: {
        min: 0,
        max: 113,
      },
      lowerThreshold: 7.3,
      infos: [
        {
          name: 'Info',
          value: `Vines are starting to become active leading to budbreak.`,
        },
      ],
    },
    {
      name: 'Budbreak - Bloom',
      range: {
        min: 114,
        max: 485,
      },
      lowerThreshold: 8.7,
      infos: [
        {
          name: 'Info',
          value: `Buds break new leaves followed by shoot growth and then flowering.`,
        },
      ],
    },
    {
      name: 'Bloom - Veraison',
      range: {
        min: 486,
        max: 1180,
      },
      lowerThreshold: 10,
      infos: [
        {
          name: 'Info',
          value: `Flowers bloom, followed by berry formation and setting.`,
        },
      ],
    },
    {
      name: 'Veraison - Harvest',
      range: {
        min: 1181,
      },
      lowerThreshold: 10,
      infos: [
        {
          name: 'Info',
          value: `Berry softening and coloring begins, followed by full ripening and harvest.`,
        },
      ],
    },
  ],
  notes: [
    `Growing Degree Day phenology models represent an estimate for an average crop. Predicted dates for budbreak, bloom, and veraison should be interpreted as the mean of a range of possible dates.`,
  ],
  description: [
    `The Grape Growing Degree Day Crop Model is used to predict phenological stages of the grape crop as it develops through the season. For the best results, the biofix should be adjusted at observed bud break and bloom time to better predict later crop stages.`,
  ],
  citations: [
    `Zapata et al. "Predicting Key Phenological Stages for 17 Grapevine Cultivars (Vitis vinifera L.)". American Journal of Enology and Viticulture. Vol. 68: 60-72. January 2017`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-grape-growing-degree-day-crop-model',
})
