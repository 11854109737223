import { GddModelDefine } from '../gdd'

export const gddBrownMarmoratedStinkBug: GddModelDefine = {
  lowerThreshold: 54,
  upperThreshold: 92,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddBrownMarmoratedStinkBug',
  name: 'Brown Marmorated Stink Bug',
  fullName: 'Brown Marmorated Stink Bug (Halyomorpha halys) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 86,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults may be present but have not reached reproductive maturity.',
        },
      ],
    },
    {
      name: 'First Adults begin Reproductive Maturation',
      range: {
        min: 86,
        max: 360,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have begun reproductive maturation.',
        },
      ],
    },
    {
      name: 'First Active Spring Adults',
      range: {
        min: 361,
        max: 566,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are present and active.',
        },
      ],
    },
    {
      name: 'First Egg-Laying',
      range: {
        min: 567,
        max: 700,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are present and have begun egg-laying.',
        },
      ],
    },
    {
      name: 'First Nymphs',
      range: {
        min: 701,
        max: 1608,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'First nymphs have emerged from eggs.',
        },
      ],
    },
    {
      name: 'First Summer Generation Adults',
      range: {
        min: 1609,
        max: 1734,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First summer generation of adults are active.',
        },
        {
          name: 'Nymphs',
          value: 'First generation nymphs are also present.',
        },
      ],
    },
    {
      name: 'Peak 3rd Instar Nymphs',
      range: {
        min: 1735,
        max: 2022,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults are active.',
        },
        {
          name: 'Nymphs',
          value: 'Peak 3rd instar nymphs.',
        },
      ],
    },
    {
      name: 'First Egg Hatch second generation',
      range: {
        min: 2023,
        max: 2213,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Summer generation of adults are active.',
        },
        {
          name: 'Eggs',
          value: 'First egg hatch of second generation.',
        },
      ],
    },
    {
      name: 'Peak Summer Generation Adults',
      range: {
        min: 2214,
        max: 2905,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Summer generation of adults are in peak activity.',
        },
        {
          name: 'Nymphs',
          value: 'Final summer generatino nymphs.',
        },
      ],
    },
    {
      name: 'First Second Generation Adults',
      range: {
        min: 2906,
        max: 3000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First second generation of adults have begun.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Brown Marmorated Stink Bug Degree Day Model is used to predict development of life-cycle stages of the Brown Marmorated Stink Bug (Halyomorpha halys), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict peak spring catch.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-brown-marmorated-stink-bug-growing-degree-day-model/',
  citations: [`Nielsen and Hamilton. Env Ent 2008 37:346-354`],
}
