import { GddModelDefine } from '../gdd'

export const gddRedheadedPineSawfly: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddRedheadedPineSawfly',
  name: 'Redheaded Pine Sawfly',
  fullName: 'Redheaded Pine Sawfly (Neodiprion lecontei) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Larvae Present',
      range: {
        min: 0,
        max: 400,
      },
      infos: [
        {
          name: 'Adults',
          value: ' Adult flies may be present.',
        },
        {
          name: 'Larvae',
          value: ' Larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'Larvae Present',
      range: {
        min: 401,
        max: 600,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and can be targeted for control',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Redheaded Pine Sawfly Degree Day Model is used to predict life-stage developments of the Redheaded Pine Sawfly (Neodiprion lecontei ), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by insecticide treatments.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-redheaded-pine-sawfly-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
