import { assertExists } from '../../utils/assert.utils'
import { Model,
  ModelDayCreateInput, WeatherDayDecrypted } from '../../utils/entity.utils'
import { MathUtils } from '../../utils/math.utils'
import { ModelUnitsName } from '../../utils/units.utils'
import {
  ModelDefineBase,
  ModelGroupDefineBase,
  ModelStageBase,
} from '../model-base-types'

export class WhiteMold {

  static calcModelDays(
    model: Model,
    define: WhiteMoldModelDefine,
    weatherDaysSorted: WeatherDayDecrypted[],
  ): ModelDayCreateInput[] {
    const daily = weatherDaysSorted.map(weatherDay => {
      let maxTemperature = 0
      let maxWindSpeed = 0
      let maxHumidity = 0
      for (const hour of weatherDay.hours) {
        maxTemperature = Math.max(maxTemperature, hour.temperature)
        maxWindSpeed = Math.max(maxWindSpeed, hour.windSpeed ?? 0)
        maxHumidity = Math.max(maxHumidity, hour.humidity ?? 50)
      }
      return {
        maxTemperature,
        maxWindSpeed,
        maxHumidity,
      }
    })

    const movingAverages = {
      maxTemperature: MathUtils.movingAverageByKey(daily, 'maxTemperature', 30),
      maxWindSpeed: MathUtils.movingAverageByKey(daily, 'maxWindSpeed', 30),
      maxHumidity: MathUtils.movingAverageByKey(daily, 'maxHumidity', 30),
    }

    return weatherDaysSorted.map((weatherDay, dayIndex) => ({
      date: weatherDay.date,
      isForecast: weatherDay.hours.some(x => x.isForecast),
      value: calcDayProbability(model, define, movingAverages, dayIndex),
      modelId: model.id,
    }))
  }

}

function calcDayProbability(
  model: Model,
  define: WhiteMoldModelDefine,
  movingAverages: WhiteMoldMovingAverages,
  dayIndex: number,
): number {
  const logit = calcLogit(model, define, movingAverages, dayIndex)
  const eLogit = Math.E * logit
  return (1 + eLogit) !== 0
    ? eLogit / (1 + eLogit)
    : 0
}

function calcLogit(
  model: Model,
  define: WhiteMoldModelDefine,
  movingAverages: WhiteMoldMovingAverages,
  dayIndex: number,
): number {
  // Irrigated
  if (define.irrigated) {
    assertExists(model.rowSpacing, 'Unable to calculate WhiteMold model irrigated logit: row spacing is missing.')
    const row = getRowValue(model.rowSpacing)
    return -2.38 * row + 0.65 * movingAverages.maxTemperature[dayIndex] + 0.38 * movingAverages.maxHumidity[dayIndex] - 52.65
  }

  // Non-irrigated
  return -0.47 * movingAverages.maxTemperature[dayIndex] - 1.01 * movingAverages.maxWindSpeed[dayIndex] + 16.65
}

function getRowValue(rowSpacing: number): 0 | 1 {
  const diffA = Math.abs(rowSpacing - 0.38)
  const diffB = Math.abs(rowSpacing - 0.76)
  return diffA < diffB
    ? 0
    : 1
}

type WhiteMoldMovingAverages = {
  maxTemperature: number[]
  maxWindSpeed: number[]
  maxHumidity: number[]
}

export const whiteMoldModelCommon: Pick<WhiteMoldModelDefine, 'group'> = {
  group: 'whiteMold',
}

export type WhiteMoldModelDefine = ModelDefineBase<WhiteMoldModelGroupDefine, WhiteMoldModelStage> & {
  irrigated: boolean
}

export type WhiteMoldModelStage = ModelStageBase

export const whiteMoldModelGroupDefine: WhiteMoldModelGroupDefine = {
  name: 'whiteMold',
  currentStageHeader: 'Current Probability',
  stagesHeader: 'Stages',
  modelUnits: 'probability',
  modelUnitsName: ModelUnitsName.universal('Probability'),
  requiresBiofixDate: false,
  requiresBiofixStage: false,
}

export type WhiteMoldModelGroupDefine = ModelGroupDefineBase<'whiteMold'>
