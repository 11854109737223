import { GddModelDefine } from '../gdd'

export const gddAlderLeafminer: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddAlderLeafminer',
  name: 'Alder Leafminer GDD',
  fullName: 'Alder Leafminer (Caloptilia alnivorella) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 189,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Overwintered pupae are in leaf mines and adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence from Overwintered Pupae',
      range: {
        min: 190,
        max: 250,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from overwintered pupae have begun to emerge.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Alder Leafminer Degree Day Model is used to predict development of life-cycle stages of Alder Leafminer (Caloptilia alnivorella), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered pupae.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-alder-leafminer-growing-degree-day-model/',
  citations: [
    `Herms, Daniel. Using Degree-Days and Plant Pathology to Predict Pest Activity. Tactics and Tools for IPM. Chapter 11. 49-59.`,
  ],
}
