import { GddModelDefine } from '../gdd'

export const gddMintRootBorer: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddMintRootBorer',
  name: 'Mint Root Borer',
  fullName: 'Mint Root Borer (Pyrausta fumalis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 430,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult Moths have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Moth Catch',
      range: {
        min: 431,
        max: 910,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adult moths have emerged.',
        },
      ],
    },
    {
      name: 'Peak Moth Catch',
      range: {
        min: 911,
        max: 1130,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths have emerged but have not yet reached peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak Egg-Laying',
      range: {
        min: 1131,
        max: 1280,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths have emerged.',
        },
        {
          name: 'Eggs',
          value: 'Peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak Egg Hatch',
      range: {
        min: 1281,
        max: 1350,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths have emerged.',
        },
        {
          name: 'Eggs',
          value: 'Peak egg hatch.',
        },
      ],
    },
    {
      name: '90% Moth Catch',
      range: {
        min: 1351,
        max: 1850,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths have emerged but have not yet reached peak egg-laying.',
        },
        {
          name: 'Eggs',
          value: 'Most eggs have hatched.',
        },
      ],
    },
    {
      name: '5% Form Hibernaculum',
      range: {
        min: 1851,
        max: 2150,
      },
      infos: [
        {
          name: 'Pre-Pupae',
          value: '5% of larvae have entered pre-pupal hibernaculum form.',
        },
      ],
    },
    {
      name: '50% Form Hibernaculum',
      range: {
        min: 2151,
        max: 2300,
      },
      infos: [
        {
          name: 'Pre-Pupae',
          value: '50% of larvae have entered pre-pupal hibernaculum form.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Mint Root Borer Degree Day Model is used to predict development of the Mint Root Borer (Pyrausta fumalis), in order to improve pest management decisions',
    'For best results, a biofix date should be set to April 1st for the Northern Hemisphere. The emergence of adult moths can then be predicted.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-mint-root-borer-growing-degree-day-model/',
  citations: ['Berry et al. 2000. Mint Root Borer Growing Degree Day model'],
}
