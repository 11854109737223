import { GddModelDefine } from '../gdd'

export const gddJapaneseFlowerThrips: GddModelDefine = {
  lowerThreshold: 52,
  upperThreshold: 100,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddJapaneseFlowerThrips',
  name: 'Japanese Flower Thrips',
  fullName: 'Japanese Flower Thrips (Thrips setosus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'No Activity',
    range: {
      min: 0,
      max: 68,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintered adult thrips have not yet emerged. Recommended to set biofix to Jan. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'Overwintering Adults Emerge',
    range: {
      min: 69,
      max: 250,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintering adults begin to emerge, feed, and lay eggs.`,
    }],
  }, {
    name: 'Peak Egg Laying (First Generation)',
    range: {
      min: 251,
      max: 413,
    },
    infos: [{
      name: 'Eggs',
      value: `Peak of egg laying.`,
    }],
  }, {
    name: 'First Adult Activity (First Generation)',
    range: {
      min: 414,
      max: 845,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (First Generation)',
    range: {
      min: 846,
      max: 1440,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Second Generation)',
    range: {
      min: 1441,
      max: 2035,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Third Generation)',
    range: {
      min: 2036,
      max: 2630,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Fourth Generation)',
    range: {
      min: 2631,
      max: 3225,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Fifth Generation)',
    range: {
      min: 3226,
      max: 3820,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Sixth Generation)',
    range: {
      min: 3821,
      max: 4415,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Seventh Generation)',
    range: {
      min: 4416,
      max: 5010,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Eighth Generation)',
    range: {
      min: 5011,
      max: 5605,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Ninth Generation)',
    range: {
      min: 5606,
      max: 6000,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Japanese Flower Thrips Growing Degree Day Model is used to predict life-stage developments of the Japanese Flower Thrips (Thrips setosus) in order to improve pest management decisions.`,
    `For best results, the biofix should be readjusted when adults are observed in order to predict the peak of egg hatch of the following generation.`,
  ],
  citations: [
    `Murai, T. 2001. Life history study of Thrips setosus. Entomol. Experim. et Applicata. 100:245-251.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-japanese-flower-thrips-growing-degree-day-model',
}
