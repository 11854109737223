import { GddModelDefine } from '../gdd'

export const gddYellowNutsedge: GddModelDefine = {
  lowerThreshold: 53,
  upperThreshold: 78,
  cutoffMethod: 'intermediate',
  group: 'gdd',
  type: 'gddYellowNutsedge',
  name: 'Yellow Nutsedge',
  fullName: 'Yellow Nutsedge (Cyperus esculentus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Tubers not yet Emerged',
      range: {
        min: 0,
        max: 204,
      },
      infos: [
        {
          name: 'Tubers',
          value: 'Tubers have not yet emerged.',
        },
      ],
    },
    {
      name: 'Tubers Emerged',
      range: {
        min: 205,
        max: 400,
      },
      infos: [
        {
          name: 'Tubers',
          value: 'Tubers have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Yellow Nutsedge Degree Day Model is used to predict tuber emrgence of Yellow Nutsedge (Cyperus esculentus) based on the weather in a given season.',
    'For best results, a biofix date should be set to date of lowest air temperature, or planting date, whichever is later. The growing degree day model can then be used to predict tuber emergence.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-yellow-nutsedge-growing-degree-day-model/',
  citations: [
    `Wilen, C.A., J.S. Holt and W.B. McCloskey. 1996. Predicting yellow nutsedge (Cyperus esculentus) emergence using degree-day models. Weed Science. 44: 821-829.`,
  ],
}
