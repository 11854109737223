import {
  EventEmitter,
} from '@angular/core'
import { Api } from './api'
import { Tools } from './tools'

export class Config {

  // Client Version
  // If this is lower than the server's minimum client version (returned in /config)
  // the app should force the user to update.
  static readonly clientVersion = 6

  private static _minClientVersion: number
  private static _onRefresh = new EventEmitter<void>()
  private static _hasLoaded = false

  static readonly emails = {
    sales: 'sales@pestprophet.com',
    support: 'support@pestprophet.com',
  }

  static async hasLoaded() {
    await new Promise<void>(resolve => {
      if (this._hasLoaded) {
        resolve()
      }
    })
  }

  static get minClientVersion() {
    return this._minClientVersion
  }

  static get stripePublicKey() {
    return Tools.isProd()
      ? 'pk_live_gIWhae9QNMkBnZLN90CGYwrA'
      : 'pk_test_N5v8AJCYKjDRCYfGQIGSxJkv'
  }

  static get onRefresh() {
    return this._onRefresh
  }

  static get locationsPurchaseLimit() {
    switch (Tools.getPlatform()) {
      case 'ios':
        return 5
      case 'android':
        return 4
      default:
        return 100
    }
  }

  static async refresh(options?: { retries?: number }) {
    const { retries } = options ?? {}

    const config = await Api.get('/config/', { retries })
    this._minClientVersion = config.minClientVersion

    this.onRefresh.emit()

    this._hasLoaded = true
  }

}
