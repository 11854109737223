import { Type } from '@sinclair/typebox'
import {
  TypeBasic,
  TypeBody,
  TypeData,
  TypeParam,
  TypeQuery,
  TypeResponse,
} from '../utils/typebox.utils'
import { apiTags } from './api-tags'

export const apiSchemaInternal = {

  '/_ah': {
    '/ios-notification': {
      post: {
        body: TypeBody.IosNotification({
          additionalProperties: false,
        }),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/android-notification': {
      post: {
        body: TypeBody.AndroidNotification({
          additionalProperties: false,
        }),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },
  },

  '/admin': {
    '/refresh-weather-days-for-user': {
      post: {
        body: Type.Object({
          userId: TypeBasic.Integer(),
        }),
        response: {
          200: TypeResponse.Message(),
        },
      },
    },

    '/refresh-weather-days-for-location': {
      post: {
        body: Type.Object({
          locationId: TypeBasic.Integer(),
        }),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/refresh-all-weather-days': {
      post: {
        response: {
          200: TypeResponse.Message(),
        },
      },
    },

    '/user-info': {
      get: {
        query: Type.Object({
          userId: TypeBasic.Integer(),
        }),
        response: {
          200: Type.Object({
            maxLocations: TypeBasic.Integer(),
          }),
        },
      },
    },
  },

  '/config': {
    '/': {
      get: {
        response: {
          200: Type.Object({
            minClientVersion: TypeBasic.Number(),
          }, {
            additionalProperties: false,
          }),
        },
      },
    },
  },

  '/cron': {
    '/force-update-weather-days': {
      get: {
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/update-weather-days': {
      get: {
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/send-email-reports': {
      get: {
        query: Type.Object({
          emailReportType: TypeData.EmailReportType(),
        }),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/update-user-metrics': {
      get: {
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/report-api-usage': {
      get: {
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/retry-failed-weather-updates': {
      get: {
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },
  },

  '/email': {
    '/unsubscribe': {
      get: {
        query: Type.Object({
          email: TypeBasic.Email(),
          token: TypeBasic.String(),
          emailType: TypeData.EmailReportType(),
        }),
        response: {
          200: TypeResponse.Html(),
        },
      },
    },
  },

  '/locations': {
    '/': {
      post: {
        summary: 'Create location',
        description: 'Create a new location, optionally specifying a list of models to add to the location',
        tags: [apiTags.location],
        body: TypeBody.LocationCreate({
          additionalProperties: false,
        }),
        response: {
          200: TypeResponse.Location(),
        },
      },

      get: {
        summary: 'Get all locations',
        tags: [apiTags.location],
        query: Type.Intersect([
          TypeQuery.LocationInclude(),
          TypeData.ModelDayDateRange(),
        ]),
        response: {
          200: TypeBasic.Array(TypeResponse.Location()),
        },
      },
    },

    '/:locationId': {
      get: {
        summary: 'Get location',
        tags: [apiTags.location],
        params: TypeParam.LocationId(),
        query: Type.Intersect([
          TypeQuery.LocationInclude(),
          TypeData.ModelDayDateRange(),
        ]),
        response: {
          200: TypeResponse.Location(),
        },
      },

      patch: {
        summary: 'Update location',
        tags: [apiTags.location],
        params: TypeParam.LocationId(),
        body: TypeBody.LocationSettings({
          additionalProperties: false,
        }),
        response: {
          200: TypeResponse.Ok(),
        },
      },

      delete: {
        summary: 'Delete location',
        tags: [apiTags.location],
        params: TypeParam.LocationId(),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/:locationId/models': {
      post: {
        summary: 'Add model to location',
        tags: [apiTags.locationModel],
        params: TypeParam.LocationId(),
        body: TypeBody.ModelCreate({
          additionalProperties: false,
        }),
        response: {
          200: TypeData.Model(),
        },
      },

      get: {
        summary: 'Get all models in location',
        tags: [apiTags.locationModel],
        params: TypeParam.LocationId(),
        query: Type.Intersect([
          TypeQuery.ModelInclude(),
          TypeData.ModelDayDateRange(),
        ]),
        response: {
          200: TypeBasic.Array(TypeResponse.Model()),
        },
      },

      delete: {
        summary: 'Remove all models from location',
        tags: [apiTags.locationModel],
        params: TypeParam.LocationId(),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/:locationId/models/:modelId': {
      get: {
        summary: 'Get model in location',
        tags: [apiTags.locationModel],
        params: Type.Object({
          locationId: TypeBasic.Integer(),
          modelId: TypeBasic.Integer(),
        }),
        query: Type.Intersect([
          TypeQuery.ModelInclude(),
          TypeData.ModelDayDateRange(),
        ]),
        response: {
          200: TypeResponse.Model(),
        },
      },

      patch: {
        summary: 'Update model in location',
        tags: [apiTags.locationModel],
        params: Type.Object({
          locationId: TypeBasic.Integer(),
          modelId: TypeBasic.Integer(),
        }),
        body: TypeBody.ModelSettings({
          additionalProperties: false,
        }),
        response: {
          200: TypeResponse.Ok(),
        },
      },

      delete: {
        summary: 'Remove model from location',
        tags: [apiTags.locationModel],
        params: Type.Object({
          locationId: TypeBasic.Integer(),
          modelId: TypeBasic.Integer(),
        }),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/:locationId/weather-days': {
      get: {
        summary: 'Get weather days in location',
        tags: [apiTags.locationWeatherDay],
        params: TypeParam.LocationId(),
        query: TypeData.ModelDayDateRange(),
        response: {
          200: TypeBasic.Array(TypeData.WeatherDay()),
        },
      },

      patch: {
        summary: 'Update weather days in location',
        tags: [apiTags.locationWeatherDay],
        params: TypeParam.LocationId(),
        body: Type.Array(
          TypeBody.WeatherDayCreate({
            additionalProperties: false,
          }),
        ),
        response: {
          200: TypeResponse.Ok(),
        },
      },

      delete: {
        summary: 'Delete weather days in location',
        tags: [apiTags.locationWeatherDay],
        params: TypeParam.LocationId(),
        query: TypeData.ModelDayDateRange(),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/:locationId/retry-weather-update': {
      post: {
        summary: 'Retry weather update for the location',
        tags: [apiTags.location],
        params: TypeParam.LocationId(),
        response: {
          200: TypeResponse.Message(),
        },
      },
    },

    '/:locationId/start-auto-weather-update': {
      post: {
        params: TypeParam.LocationId(),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },
  },

  '/iap': {
    '/validator': {
      post: {
        body: TypeBody.IapValidation(),
        response: {
          200: TypeResponse.IapValidation(),
        },
      },
    },
  },

  '/stripe': {
    '/customer': {
      post: {
        body: TypeBody.StripeSourceTokenId({
          additionalProperties: false,
        }),
        response: {
          200: TypeData.User(),
        },
      },
    },

    '/source': {
      put: {
        body: TypeBody.StripeSourceTokenId({
          additionalProperties: false,
        }),
        response: {
          200: TypeResponse.Ok(),
        },
      },

      delete: {
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/source/card': {
      get: {
        response: {
          200: TypeResponse.StripeSourceCardInfo(),
        },
      },
    },

    '/change-subscription': {
      put: {
        body: Type.Object({
          subscriptionType: TypeData.StripeSubscriptionType(),
          maxLocations: TypeBasic.Integer({ minimum: 1 }),
          couponId: Type.Optional(TypeBasic.String()),
        }, {
          additionalProperties: false,
        }),
        response: {
          200: TypeResponse.StripeSubscription(),
        },
      },
    },

    '/cancel-subscription': {
      patch: {
        response: {
          200: TypeResponse.StripeSubscription(),
        },
      },
    },

    '/undo-cancel-subscription': {
      patch: {
        response: {
          200: TypeResponse.StripeSubscription(),
        },
      },
    },

    '/webhook-event': {
      post: {
        response: {
          200: TypeResponse.Message(),
        },
      },
    },
  },

  '/users': {
    '/me': {
      post: {
        summary: 'Create user',
        tags: [apiTags.user],
        body: TypeBody.UserCreate({
          additionalProperties: false,
        }),
        response: {
          200: TypeData.User(),
        },
      },

      get: {
        summary: 'Get my user data',
        tags: [apiTags.user],
        response: {
          200: TypeData.User(),
        },
      },

      patch: {
        summary: 'Update my user data',
        tags: [apiTags.user],
        body: TypeBody.UserSettings({
          additionalProperties: false,
        }),
        response: {
          200: TypeResponse.Ok(),
        },
      },

      delete: {
        summary: 'Delete my user data',
        tags: [apiTags.user],
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/me/activity': {
      post: {
        summary: 'Add user activity',
        tags: [apiTags.user],
        body: Type.Object({
          type: TypeData.UserActivityType(),
          platforms: TypeBasic.Array(TypeBasic.String()),
        }, {
          additionalProperties: false,
        }),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },

    '/me/api-key': {
      post: {
        summary: 'Replace my API key',
        tags: [apiTags.user],
        response: {
          200: Type.Object({
            apiKey: TypeBasic.String(),
          }),
        },
      },

      get: {
        summary: 'Get my account API key',
        tags: [apiTags.user],
        response: {
          200: Type.Object({
            apiKey: TypeBasic.Nullable(TypeBasic.String()),
          }),
        },
      },
    },

    '/me/enable-encryption': {
      post: {
        summary: 'Enable encryption for my account',
        tags: [apiTags.user],
        response: {
          200: Type.Object({
            apiKey: TypeBasic.String(),
          }),
        },
      },
    },

    '/me/disable-encryption': {
      post: {
        summary: 'Disable encryption for my account',
        tags: [apiTags.user],
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },
  },

}
