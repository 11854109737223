import { GddModelDefine } from '../gdd'

export const gddPotatoTuberworm: GddModelDefine = {
  lowerThreshold: 58.2,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPotatoTuberworm',
  name: 'Potato Tuberworm',
  fullName: 'Potato Tuberworm (Phthorimaea operculella) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'First Generation Adults Observed',
      range: {
        min: 0,
        max: 496,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have been observed and eggs are present.',
        },
      ],
    },
    {
      name: 'Next Generation Adult Emergence - Pre-Egglaying',
      range: {
        min: 497,
        max: 561,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged but have not yet begun egg-laying.',
        },
      ],
    },
    {
      name: 'Next Generation Adult Emergence - Egglaying',
      range: {
        min: 562,
        max: 650,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged and have begun egg-laying.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Potato Tuberworm Degree Day Model is used to predict development of life-cycle stages of Potato Tuberworm (Phthorimaea operculella), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adults or eggs are first observed in the field. The growing degree day model can then be used to predict emergence of next generation of adults. The biofix should be re-set for each subsequent generation.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-potato-tuberworm-growing-degree-day-model/',
  citations: [
    'Cardona C., and E. R. Oatman. 1975. Biology and physical ecology of Apanteles subandinus Blanchard (Hymenoptera: Braconidae) with notes on temperature responses of Apanteles scutellaris (Muesebeck) and its host, the potato tuberworm. Hilgardia 43: 1-51.',
  ],
}
