import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire/compat'
import {
  AngularFireAuth,
  AngularFireAuthModule,
} from '@angular/fire/compat/auth'
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx'
import { Market } from '@ionic-native/market/ngx'
import {
  IonicModule,
  IonicRouteStrategy,
} from '@ionic/angular'
import { ForgotPasswordPageModule } from './pages/forgot-password/forgot-password.module'
import { HomePageModule } from './pages/home/home.module'
import { LoginPageModule } from './pages/login/login.module'
import { PrivacyPolicyPageModule } from './pages/privacy-policy/privacy-policy.module'
import { SignupPageModule } from './pages/signup/signup.module'
import { TermsAndConditionsPageModule } from './pages/terms-and-conditions/terms-and-conditions.module'
import { environment } from '../environments/environment'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'

@NgModule({
    declarations: [AppComponent],
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireAuthGuardModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        LoginPageModule,
        SignupPageModule,
        ForgotPasswordPageModule,
        PrivacyPolicyPageModule,
        TermsAndConditionsPageModule,
        HomePageModule,
    ],
    providers: [
        Market,
        InAppPurchase2,
        AngularFireAuth,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
