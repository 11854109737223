import { tomCastModelCommon, TomCastModelDefine } from './tom-cast'

export const tomCastTomatoBlackmold: TomCastModelDefine = {
  ...tomCastModelCommon,
  type: 'tomCastTomatoBlackmold',
  name: 'TomCast Tomato Blackmold',
  fullName: 'TomCast Tomato Blackmold',
  stages: [
    {
      name: 'Treatment Not Recommended',
      range: {
        min: 0,
        max: 11,
      },
      infos: [
        {
          name: 'Tomato Blackmold',
          value: `Risk of infection is low and treatment is not yet recommended.`,
        },
      ],
    },
    {
      name: 'Treatment Recommended - Susceptible Varieties',
      range: {
        min: 12,
        max: 17,
      },
      infos: [
        {
          name: 'Tomato Blackmold',
          value: `Risk of infection is high  for susceptible varieties and preventative treatment is recommended.`,
        },
      ],
    },
    {
      name: 'Treatment Recommended - Resistant Varieties',
      range: {
        min: 18,
      },
      infos: [
        {
          name: 'Tomato Blackmold',
          value: `Risk of infection is high  for all varieties and preventative treatment is recommended.`,
        },
      ],
    },
  ],
  notes: [
    'Plant disease risk models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'Tomato Blackmold is caused by the fungus Alternaria alternata, and it can be very difficult to control once the disease has been established. Preventative spray treatments are recommend to prevent infection.',
    'The Tomato Blackmold risk model, is a modified verstion of Tomcast, and is used to determine interval length between spray treatments. The model calculates daily DSV (Disease Severity Value) based on temperature and leaf wetness duration. When 12-18 DSVs have accumulated, treatment is recommended.',
    'Model should be first used after July 1 (in the Northern Hemisphere), or when fruit turns color. Treatment should continue until 7-10 days before harvest.',
    'The model should be reset after each spray, in order to time the subsequent treatment.',
  ],
  citations: [
    'Pitblado, R. E. 1992. The development and implementation of TOM-CAST: A weather-timed fungicide spray program for field tomatoes. Ministry of Agriculture and Food, Ontario, Canada.',
    'Madden L., Pennypacker, S. P., and McNab, A. A. 1978. FAST, a forecast system for Alternaria solani on tomato. Phytopathology 68:1354-1358.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-tomato-blackmold-risk-model/',
}
