import {
  Component,
  NgZone,
  OnInit,
  ViewContainerRef,
} from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { App } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import {
  StatusBar,
  Style,
} from '@capacitor/status-bar'
import { Market } from '@ionic-native/market/ngx'
import {
  AlertController,
  NavController,
} from '@ionic/angular'
import { setAutoFreeze } from 'immer'
import { Analytics } from './services/analytics'
import { Api } from './services/api'
import { ComponentCreatorService } from './services/component-creator.service'
import { Config } from './services/config'
import { DebugService } from './services/debug.service'
import { Google } from './services/google'
import { Me } from './services/me'
import { StartupService } from './services/startup.service'
import { Tools } from './services/tools'
import { UserActivity } from './services/user-activity.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private debug: DebugService,
    private componentCreator: ComponentCreatorService,
    private viewContainerRef: ViewContainerRef,
    private userActivity: UserActivity,
    private startup: StartupService,
    private market: Market,
    private alertCtrl: AlertController,
    private firebaseAuth: AngularFireAuth,
    private navController: NavController,
    private zone: NgZone,
  ) {
    void App.addListener('appUrlOpen', ({ url }) => {
      this.zone.run(() => {
        // const domain = 'pestprophet.com'
        // const pathArray = url.split(domain)
        console.log('appUrlOpen', url)
      })
    })
  }

  async ngOnInit() {
    setAutoFreeze(false)

    await Google.init()
    await Tools.init(this.alertCtrl, this.market)
    await Api.init()
    await Me.init(this.firebaseAuth)

    if (Tools.isDev()) {
      this.debug.init()
    }

    this.componentCreator.init(this.viewContainerRef)

    Analytics.init()

    if (Tools.isNative()) {
      void StatusBar.setStyle({ style: Style.Default })
    }

    await SplashScreen.hide()

    if (Tools.isDesktop()) {
      // Show phone image.
      const phoneRef = document.querySelector('.phone') as HTMLImageElement
      phoneRef.style.display = 'inline-block'

      // Wait for the phone image to be rendered...
      await new Promise<void>(resolve => {
        const intervalId = setInterval(() => {
          const phoneRefSize = phoneRef.getBoundingClientRect()
          if (phoneRefSize.width > 0) {
            clearInterval(intervalId)
            resolve()
          }
        })
      })

      const updateAppSize = () => {
        const appRef = document.querySelector('ion-app') as HTMLElement
        appRef.style.position = 'absolute'
        appRef.style.top = '48.6%'
        appRef.style.left = '50%'
        appRef.style.transform = 'translateX(-50%) translateY(-50%)'

        const phoneRefSize = phoneRef.getBoundingClientRect()
        let width = Math.round(phoneRefSize.width * 0.7159)
        let height = Math.round(phoneRefSize.height * 0.7232)
        if (width % 2 !== 0) {
          width += 1
        }
        if (height % 2 !== 0) {
          height += 1
        }

        appRef.style.width = `${width}px`
        appRef.style.height = `${height}px`
      }
      updateAppSize()
      window.addEventListener('resize', updateAppSize)
    }

    await App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        void this.userActivity.addActivity('resume')
        void Me.refresh()
      }
    })

    Config.onRefresh.subscribe(() => {
      if (Tools.isAppUpdateRequired()) {
        void Tools.showAppUpdateAlert()
      }
    })

    await Config.refresh({ retries: 100 })

    this.startup.setComplete()
  }

}
