<ion-card
  *ngIf="!isHidden()"
  [ngStyle]="rootStyle"
>
  <ion-card-header>
    <div layout="row" layout-align="start center">
      <ion-icon class="lock-icon-outline" name="lock-closed"></ion-icon>
      <div>{{ title }}</div>
    </div>
  </ion-card-header>
  <ion-card-content>
    <div layout="column" layout-align="start stretch">
      <div class="content">
        <ng-content></ng-content>
      </div>
      <div layout="row" layout-align="space-around start">
        <ion-button
          size="small"
          (click)="handleHideClick()"
        >
          Hide
        </ion-button>
        <ion-button
          color="secondary"
          size="small"
          (click)="handleUpgradePlanClick()"
        >
          Upgrade
        </ion-button>
      </div>
    </div>
  </ion-card-content>
</ion-card>
