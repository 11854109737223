import { DateFormat } from '@prisma/client'

export const dateFormatsText: {
  [K in DateFormat]: string
} = {
  usa: 'USA (m/d/yy)',
  europe: 'Europe (d/m/yy)',
}

export const emailReportTypes = [
  'dailyReport',
  'weeklyReport',
  'monthlyReport',
] as const
export type EmailReportType = typeof emailReportTypes[number]

export const emailTypes = [
  ...emailReportTypes,
  'newsletter',
] as const
export type EmailType = typeof emailTypes[number]

export function parseBool(boolStr: string | null | undefined): boolean {
  switch (boolStr?.toLowerCase()) {
    case 'true':
    case 't':
      return true
    default:
      return false
  }
}
