import { GddModelDefine } from '../gdd'

export const gddLentil: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddLentil',
  name: 'Lentil',
  fullName: 'Lentil Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Planting',
      range: {
        min: 0,
        max: 280,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Leaves have not yet emerged.',
        },
        {
          name: 'Shoot Break',
          value: 'Shoot has not broken through soil.',
        },
      ],
    },
    {
      name: 'Shoot Break through soil.',
      range: {
        min: 281,
        max: 340,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has broken through the soil.',
        },
      ],
    },
    {
      name: 'First True Leaves',
      range: {
        min: 341,
        max: 440,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'First true leaves have emerged.',
        },
      ],
    },
    {
      name: 'Fourth True Leaves',
      range: {
        min: 441,
        max: 1590,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Fourth true leaves have emerged.',
        },
      ],
    },
    {
      name: 'Flower buds just visible.',
      range: {
        min: 1591,
        max: 1910,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'Flower buds just visible (inside auxiliary buds).',
        },
      ],
    },
    {
      name: '10% of flowers visible.',
      range: {
        min: 1911,
        max: 2220,
      },
      infos: [
        {
          name: 'Flowers',
          value: '10% of flowers are visible. 13 to 14 nodes.',
        },
      ],
    },
    {
      name: '10% of pods solid to the touch.',
      range: {
        min: 2221,
        max: 2600,
      },
      infos: [
        {
          name: 'Pods',
          value: '10% of pods are solid to the touch.',
        },
      ],
    },
    {
      name: '1/3 of Pods yellow-brown.',
      range: {
        min: 2601,
        max: 2880,
      },
      infos: [
        {
          name: 'Pods',
          value: 'Lower 1/3 of pods yellow-brown and rattle when shaken, Upper pods still green.',
        },
      ],
    },
    {
      name: 'Harvest',
      range: {
        min: 3061,
        max: 3300,
      },
      infos: [
        {
          name: 'Harvest',
          value:
            'Thresh when seed tests 18% moisture or lower. Over dry (8-10%) are hard and difficult to bite. 14% for safe storage. ',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Lentil Degree Day Model is used to predict harvest time of lentil based on the weather in a given season.',
    'For best results, a biofix date should be set at planting time. The growing degree day model can then be used to predict harvest time.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-lentil-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Stu Brandt, Scott, SK 1995-97 and Perry Miller, Swift Current, SK 1995-98`,
  ],
}
