import { GddModelDefine } from '../gdd'

export const gddCerealLeafBeetle: GddModelDefine = {
  lowerThreshold: 7,
  upperThreshold: 30,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCerealLeafBeetle',
  name: 'Cereal Leaf Beetle',
  fullName: 'Cereal Leaf Beetle (Oulema melanopus) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Adult Beetle Activity',
      range: {
        min: 0,
        max: 105,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult beetles present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'First Generation Larvae',
      range: {
        min: 106,
        max: 272,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Peak of first generation larvae.`,
        },
      ],
    },
    {
      name: 'First Generation Pupae',
      range: {
        min: 273,
        max: 554,
      },
      infos: [
        {
          name: 'Pupae',
          value: `Peak of first generation pupae.`,
        },
      ],
    },
    {
      name: 'First Generation Adult Beetles',
      range: {
        min: 555,
        max: 659,
      },
      infos: [
        {
          name: 'Adults',
          value: `First generation adult beetles present.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Cereal Leaf Beetle Growing Degree Day Model is used to predict life-stage developments of the Cereal Beetle (Oulema melanopus) in order to improve pest management decisions.`,
    `For best results, the biofix should be adjusted to represent the first date that adult beetles have been observed consistently.`,
  ],
  citations: [
    `Guppy, J. C., and D. G. Harcourt. 1978. Effects of temperature on development of the immature stages of the cereal leaf beetle, Oulema melanopus (Coleoptera: Chrysomelidae). Can. Ent. 110: 257-263.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-cereal-leaf-beetle-growing-degree-day-model',
}
