import { GddModelDefine } from '../gdd'

export const gddHopsDownyMildew: GddModelDefine = {
  lowerThreshold: 43,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddHopsDownyMildew',
  name: 'Hops Downy Mildew',
  fullName: 'Hops Downy Mildew (Humulus lupulus) Basal Spike Emergence Growing Degree',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence',
      range: {
        min: 0,
        max: 92,
      },
      infos: [
        {
          name: 'Probability of Emergence',
          value: `Less than 1%`,
        },
        {
          name: 'Action',
          value: `None.`,
        },
      ],
    },
    {
      name: 'Low Risk of Emergence',
      range: {
        min: 93,
        max: 102,
      },
      infos: [
        {
          name: 'Probability of Emergence',
          value: `1% - 25%`,
        },
        {
          name: 'Action',
          value: `Begin scouting in highly susceptible varieties.`,
        },
      ],
    },
    {
      name: 'Low / Medium Risk of Emergence',
      range: {
        min: 103,
        max: 117,
      },
      infos: [
        {
          name: 'Probability of Emergence',
          value: `26% - 50%`,
        },
        {
          name: 'Action',
          value: `Begin spraying in highly susceptible varieties. Begin scouting in moderately susceptible varieties.`,
        },
      ],
    },
    {
      name: 'Medium / High Risk of Emergence',
      range: {
        min: 118,
        max: 143,
      },
      infos: [
        {
          name: 'Probability of Emergence',
          value: `51% - 75%`,
        },
        {
          name: 'Action',
          value: `Begin spraying in moderately susceptible varieties. Begin scouting in low susceptibility varieties.`,
        },
      ],
    },
    {
      name: 'High Risk of Emergence',
      range: {
        min: 144,
        max: 542,
      },
      infos: [
        {
          name: 'Probability of Emergence',
          value: `76% - 100%`,
        },
        {
          name: 'Action',
          value: `Begin spraying in low susceptibility varieties.`,
        },
      ],
    },
  ],
  notes: [
    `Probability of emergence values are estimates and will vary between varieties and will also depend on the level of crown infection that occurred the previous season.`,
  ],
  description: [
    `The pathogen that causes downy mildew in hops, Pseudoperonospora humuli, survives and overwinters in infected crowns. This model can be used to predict when the first basal spikes will emerge and when preventative fungicide sprays should begin.`,
  ],
  citations: [
    `Gent, D. H., Ocamb, C. M., and Farnsworth, J. L. 2010. Forecasting and management of hop downy mildew. Plant Dis. 94:425-431.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-downy-mildew-basal-spike-emergence-model-on-hops',
}
