import { GddModelDefine } from '../gdd'

export const gddFendersBlueButterfly: GddModelDefine = {
  lowerThreshold: 41,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddFendersBlueButterfly',
  name: 'Fenders Blue Buttefly',
  fullName: 'Fenders Blue Butterfly (Kincades Lupine) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 678,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have not begun flight.',
        },
      ],
    },
    {
      name: 'First Flight Adults from Overwintered Larvae',
      range: {
        min: 679,
        max: 877,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have begun flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - Adults from Overwintered Larvae',
      range: {
        min: 878,
        max: 1000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are in peak flight and egg-laying has begun.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Fenders Blue Buttefly Day Model is used to predict development of life-cycle stages of Fenders Blue Butterfly (Kincades lupine), in order to improve pest management decisions',
    'For best results, a biofix date should be set for February 15th for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-fenders-blue-butterfly-growing-degree-day-model/',
  citations: [
    `Len Coop, OSU IPPC Feb. 2012, data coordinated by Greg Fitzpatrick, Fitzpatrick Ecological Consulting LLC`,
  ],
}
