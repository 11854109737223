import { GddModelDefine } from '../gdd'

export const gddOakSpiderMite: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddOakSpiderMite',
  name: 'Oak Spider Mite GDD',
  fullName: 'Oak Spider Mite (Oligonychus bicolor) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Nymph or Larvae Emergence',
      range: {
        min: 0,
        max: 802,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have not yet emerged from overwintered eggs.',
        },
      ],
    },
    {
      name: 'First Generation Larvae Emergence',
      range: {
        min: 803,
        max: 1266,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have begun to emerge.',
        },
      ],
    },
    {
      name: 'End of First Generation Larvae Emergence',
      range: {
        min: 1267,
        max: 1400,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have all emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Oak Spider Mite Degree Day Model is used to predict development of life-cycle stages of Oak Spider Mite (Oligonychus bicolor), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted for treatment with insecticides.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-oak-spider-mite-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
