import { GddModelDefine } from '../gdd'

export const gddAppleScab: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 110,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddAppleScab',
  name: 'Apple Scab',
  fullName: 'Apple Scab (Venturia inaequalis) Ascospore Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Bud-break',
      range: {
        min: 0,
        max: 200,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: '0% Ascospore Maturation.',
        },
      ],
    },
    {
      name: '7% Ascospore Maturation',
      range: {
        min: 201,
        max: 300,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: '7% Ascospore Maturation.',
        },
      ],
    },
    {
      name: '21% Ascospore Maturation',
      range: {
        min: 301,
        max: 500,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: '21% Ascospore Maturation.',
        },
      ],
    },
    {
      name: '60% Ascospore Maturation',
      range: {
        min: 501,
        max: 750,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: '60% Ascospore Maturation.',
        },
      ],
    },
    {
      name: '95% Ascospore Maturation',
      range: {
        min: 751,
        max: 900,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: '95% Ascospore Maturation.',
        },
      ],
    },
    {
      name: '100% Ascospore Maturation',
      range: {
        min: 901,
        max: 1000,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: '95% Ascospore Maturation.',
        },
      ],
    },
  ],
  notes: [
    'Disease maturation based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Apple Scab Degree Day Model is used to predict the ascopore maturation of the Apple Scab fungus, in order to improve pest management decisions.',
    'For best results, a biofix date should be set at bud-break. The growing degree day model can then be used to predict ascospore maturation.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-apple-scab-growing-degree-day-model/',
  citations: [`D. M. Gadoury, R. C. Seem, A. Stensvand, and S. P. Falk. 1995.`],
}
