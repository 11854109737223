import { App } from '@capacitor/app'
import {
  Device,
  DeviceInfo,
} from '@capacitor/device'
import { Market } from '@ionic-native/market/ngx'
import { AlertController } from '@ionic/angular'
import { PaymentPlatform } from '@pest-prophet/shared'
import { environment } from '../../environments/environment'
import { Config } from './config'

export class Tools {

  private static showingAppUpdateAlert = false
  private static deviceInfo: DeviceInfo
  private static alertCtrl: AlertController
  private static market: Market

  static async init(
    alertCtrl: AlertController,
    market: Market,
  ) {
    this.alertCtrl = alertCtrl
    this.market = market
    this.deviceInfo = await Device.getInfo()
  }

  static isDeployed() {
    return environment.isDeployed
  }

  static isDev() {
    return !environment.isProd
  }

  static isProd() {
    return environment.isProd
  }

  static getPlatform() {
    return this.deviceInfo.platform
  }

  static isBrowser() {
    return this.getPlatform() === 'web'
  }

  static isNative() {
    return (
      this.getPlatform() === 'ios'
      || this.getPlatform() === 'android'
    )
  }

  static isDesktop() {
    return (
      this.deviceInfo.operatingSystem === 'windows'
      || this.deviceInfo.operatingSystem === 'mac'
    )
  }

  static async showAppUpdateAlert() {
    if (this.showingAppUpdateAlert) {
      return
    }

    this.showingAppUpdateAlert = true

    const alert = await this.alertCtrl.create({
      header: 'App Update Required',
      buttons: [
        {
          text: this.isBrowser()
            ? 'Refresh'
            : 'Update',
          handler: () => {
            const update = async () => {
              this.showingAppUpdateAlert = false
              if (this.getPlatform() === 'android') {
                const appInfo = await App.getInfo()
                const packageName = appInfo.version
                void this.market.open(packageName)
              } else if (this.getPlatform() === 'ios') {
                void this.market.open('https://itunes.apple.com/us/app/pest-prophet/id1302595322?mt=8')
              } else if (this.isBrowser()) {
                // eslint-disable-next-line no-restricted-globals
                location.reload()
              }
            }
            void update()
          },
        },
      ],
      backdropDismiss: false,
    })

    await alert.present()
  }

  static isAppUpdateRequired() {
    return (Config.clientVersion < Config.minClientVersion)
  }

  static get packageId() {
    return 'com.farmczar.pestprophet'
  }

  static get currentPaymentPlatform(): PaymentPlatform {
    switch (this.getPlatform()) {
      case 'ios':
        return 'iosAppstore'
      case 'android':
        return 'androidPlaystore'
      case 'web':
        return 'stripe'
    }

    throw new Error('Unknown payment platform!')
  }

  static get paymentPlatformText() {
    switch (this.currentPaymentPlatform) {
      case 'iosAppstore':
        return 'iOS'
      case 'androidPlaystore':
        return 'Android'
      case 'stripe':
        return 'Stripe'
    }

    throw new Error('Unknown payment platform!')
  }

  static isDarkMode() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
  }

  static getDefaultTextColor() {
    return getComputedStyle(document.body)
      .getPropertyValue('--ion-text-color')
  }

}
