import { GddModelDefine } from '../gdd'

export const gddWesternFlowerThrips: GddModelDefine = {
  lowerThreshold: 45,
  upperThreshold: 104,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddWesternFlowerThrips',
  name: 'Western Flower Thrips',
  fullName: 'Western Flower Thrips (Frankliniella occidentalis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'No Activity',
    range: {
      min: 0,
      max: 20,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintered adult beetles have not yet emerged. Recommended to set biofix to Jan. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'Overwintering Adults Emerge',
    range: {
      min: 21,
      max: 338,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintering adults begin to emerge, feed, and lay eggs.`,
    }],
  }, {
    name: 'Peak Egg Hatch (First Generation)',
    range: {
      min: 339,
      max: 675,
    },
    infos: [{
      name: 'Eggs',
      value: `Peak of eggs hatching.`,
    }],
  }, {
    name: 'Peak Adult Activity (First Generation)',
    range: {
      min: 676,
      max: 1013,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Egg Hatch (Second Generation)',
    range: {
      min: 1014,
      max: 1350,
    },
    infos: [{
      name: 'Eggs',
      value: `Peak of eggs hatching.`,
    }],
  }, {
    name: 'Peak Adult Activity (Second Generation)',
    range: {
      min: 1351,
      max: 2025,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Third Generation)',
    range: {
      min: 2026,
      max: 2700,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Fourth Generation)',
    range: {
      min: 2701,
      max: 3375,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Fifth Generation)',
    range: {
      min: 3376,
      max: 4050,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Sixth Generation)',
    range: {
      min: 4051,
      max: 4725,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Seventh Generation)',
    range: {
      min: 4726,
      max: 5400,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Eighth Generation)',
    range: {
      min: 5401,
      max: 6075,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }, {
    name: 'Peak Adult Activity (Ninth Generation)',
    range: {
      min: 6076,
      max: 7000,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult activity.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Western Flower Thrips Growing Degree Day Model is used to predict life-stage developments of the Western Flower Thrips (Frankliniella occidentalis) in order to improve pest management decisions.`,
    `For best results, the biofix should be re-adjusted when adults are observed to predict the peak of egg hatch of the following generation.`,
  ],
  citations: [
    `McDonald, Bale and Walters 1998. Effect of temperature on development of the western flower thrips, F. o. Eur. J. Entomol. 95:301-306.`,
    `Stacey and Fellowes. 2002. Temperature and the development rates of thrips: Evidence for a constraint on local adaptation? Eur. J. Entomol. 99:399-404.`,
    `M.-C. Nielsen, D.A.J. Teulon, R. B. Chapman, R. C. Butler, G. M. Drayton, and H. Philipse. 2010. Comparison of Life History Parameters of Two Frankliniella occidentalis (Thysanoptera: Thripidae) Strains in New Zealand Environ. Entomol. 39(2): 303-311.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-western-flower-thrips-growing-degree-day-model',
}
