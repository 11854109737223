import { GddModelDefine } from '../gdd'

export const gddPistachioShellHardening: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddPistachioShellHardening',
  name: 'Pistachio Shell Hardening',
  fullName: 'Pistachio Shell Hardening Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: '75% Bloom',
      range: {
        min: 0,
        max: 1197,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'Biofix should be set to 75% Bloom.',
        },
      ],
    },
    {
      name: 'Pistachio Shell Hardened',
      range: {
        min: 1198,
        max: 2200,
      },
      infos: [
        {
          name: 'Shell',
          value: 'Pistachio shell has hardened.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pistachio Shell Hardening Degree Day Model is used to predict hardening of Pistachio shells based on the weather in a given season.',
    'For best results, a biofix date should be set at 75% Bloom. The growing degree day model can then be used to predict shell hardening.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-pistachio-shell-hardening-growing-degree-day-model/',
  citations: [
    'Purcell, M., and S. C. Welter. 1991. Effect of Calocoris norvegicus (Hemiptera: Miridae) on pistachio yields. J. Econ. Entomol. 84: 114-119.',
  ],
}
