import { GddModelDefine } from '../gdd'

export const gddTomatoFruitworm: GddModelDefine = {
  lowerThreshold: 12.6,
  upperThreshold: 33.3,
  cutoffMethod: 'intermediate',

  group: 'gdd',
  type: 'gddTomatoFruitworm',
  name: 'Tomato Fruitworm',
  fullName: 'Tomato Fruitworm (Helicoverpa zea) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Adult Moth Activity Pre Egg Laying',
      range: {
        min: 0,
        max: 63,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has not yet begun.`,
        },
      ],
    },
    {
      name: 'Adult Moth Activity and Egg Laying',
      range: {
        min: 64,
        max: 103,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'First Generation Small Larvae',
      range: {
        min: 104,
        max: 185,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Peak of first generation small first, second, and third instar larvae.`,
        },
      ],
    },
    {
      name: 'First Generation Large Larvae',
      range: {
        min: 186,
        max: 306,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Peak of first generation large fourth and fifth instar larvae.`,
        },
      ],
    },
    {
      name: 'First Generation Pupae',
      range: {
        min: 307,
        max: 486,
      },
      infos: [
        {
          name: 'Pupae',
          value: `Peak of first generation pupae.`,
        },
      ],
    },
    {
      name: 'First Generation Adult Moths Pre Egg Laying',
      range: {
        min: 487,
        max: 599,
      },
      infos: [
        {
          name: 'Adults',
          value: `First generation adult moths present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has not yet begun.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Tomato Fruitworm Growing Degree Day Model is used to predict life-stage developments of the Tomato Fruitworm (Helicoverpa zea) in order to improve pest management decisions.`,
    `For best results, traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Hartstack, A. W., Jr., J. P. Hollingsworth, R. L. Ridgeway, and J. D. Lopez. 1976. MOTHZV-2: A computer simulation of Heliothis zea and virescens population dynamics. User manual. 1976. U.S.D.A. ARS-S-127.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-corn-earworm-growing-degree-day-model',
}
