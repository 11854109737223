import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { UserActivityType } from '@pest-prophet/shared'
import { Api } from './api'
import { Tools } from './tools'
import { Me } from './me'

@Injectable({
  providedIn: 'root',
})
export class UserActivity {

  constructor(
    private platform: Platform,
  ) {}

  get isEnabled() {
    return (!Tools.isDev() && Me.getFirebaseId() && Me.getUserData().analyticsEnabled)
  }

  async addActivity(type: UserActivityType) {
    if (!this.isEnabled) {
      return
    }

    await Api.post('/users/me/activity', {
      body: {
        type,
        platforms: this.platform.platforms(),
      },
    })
  }

}
