export class MathUtils {

  static clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(max, value))
  }

  static fahrenheightToCelsius(temp: number): number {
    return (temp - 32) / 1.8
  }

  static celsiusToFahrenheight(temp: number): number {
    return temp * 1.8 + 32
  }

  static roundToNearest(value: number, nearest: number, direction: 'up' | 'down' | 'none' = 'none'): number {
    switch (direction) {
      case 'up':
        return Math.ceil(value / nearest) * nearest
      case 'down':
        return Math.floor(value / nearest) * nearest
      case 'none':
        return Math.round(value / nearest) * nearest
    }
  }

  /**
   * Check if value is in the provided range (inclusive)
   */
  static inRange(value: number, range: Range): boolean {
    const min = range.min ?? Number.NEGATIVE_INFINITY
    const max = range.max ?? Number.POSITIVE_INFINITY

    return value >= min && value <= max
  }

  static movingAverage(values: number[], windowSize: number): number[] {
    let result: number[] = []

    let windowSum = 0
    for (let i = 0; i < values.length; i++) {
      const oldestValue = values[i - windowSize] ?? 0
      windowSum = windowSum - oldestValue + values[i]

      const windowAverage = i >= (windowSize - 1)
        ? windowSum / windowSize
        : windowSum / (i + 1)

      result.push(windowAverage)
    }

    return result
  }

  static movingAverageByKey<
    TObject extends object,
    TObjects extends TObject[],
    TKey extends keyof TObjects[number],
    TValue extends number & TObjects[number][TKey], // Constrain to number arrays.
  >(
    objects: TObjects,
    key: TKey,
    windowSize: number,
  ): TValue[] {
    let result: number[] = []

    let windowSum = 0
    for (let i = 0; i < objects.length; i++) {
      const oldestObj = objects[i - windowSize] as TObject | undefined
      const oldestValue = (oldestObj?.[key] ?? 0) as TValue
      const newestValue = objects[i][key] as TValue
      windowSum = windowSum - oldestValue + newestValue

      const windowAverage = i >= (windowSize - 1)
        ? windowSum / windowSize
        : windowSum / (i + 1)

      result.push(windowAverage)
    }

    return result as TValue[]
  }

  static maxByKey<
    TObjects extends TObject[],
    TKey extends keyof TObject,
    TValue extends number & TObjects[number][TKey], // Constrain to number arrays.
    TObject = TObjects extends Array<infer T> ? T : never,
  >(
    objects: TObjects,
    key: TKey,
    defaultValue = Number.NEGATIVE_INFINITY,
  ): TValue {
    let max = Number.NEGATIVE_INFINITY
    for (const obj of objects) {
      const value = (obj[key] ?? defaultValue) as number
      max = Math.max(value, max)
    }
    return max as TValue
  }

}

export type Range = {
  min?: number
  max?: number
}
