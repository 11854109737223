import { GddModelDefine } from '../gdd'

export const gddAsianLonghornedBeetleEmergence: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 95,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddAsianLonghornedBeetleEmergence',
  name: 'Asian Longhorned Beetle Emergence',
  fullName: 'Asian Longhorned Beetle (Anoplophora glabripennis) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'No Activity',
    range: {
      min: 0,
      max: 104,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintered larvae have not yet emerged. Recommended to set biofix to Jan. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'Overwintered Larvae Begin Pupation',
    range: {
      min: 105,
      max: 794,
    },
    infos: [{
      name: 'Larvae',
      value: `Overwintered larvae begin pupation period.`,
    }],
  }, {
    name: 'Beginning Adult Emergence',
    range: {
      min: 795,
      max: 989,
    },
    infos: [{
      name: 'Adults',
      value: `Approximately 1% of adult beetles have emerged.`,
    }],
  }, {
    name: '10% Adult Emergence',
    range: {
      min: 990,
      max: 1419,
    },
    infos: [{
      name: 'Adults',
      value: `Approximately 10% of adult beetles have emerged.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }, {
    name: 'First Egg Hatch',
    range: {
      min: 1420,
      max: 1589,
    },
    infos: [{
      name: 'Adults',
      value: `Approximately 25%-50% of adult beetles have emerged.`,
    }, {
      name: 'Eggs',
      value: `Egg Hatch has begun.`,
    }],
  }, {
    name: '50% Adult Emergence',
    range: {
      min: 1590,
      max: 2159,
    },
    infos: [{
      name: 'Adults',
      value: `Approximately 50% of adult beetles have emerged.`,
    }, {
      name: 'Eggs',
      value: `Egg Hatch has begun.`,
    }],
  }, {
    name: '75% Adult Emergence',
    range: {
      min: 2160,
      max: 2499,
    },
    infos: [{
      name: 'Adults',
      value: `Approximately 75% of adult beetles have emerged.`,
    }, {
      name: 'Eggs',
      value: `Egg Hatch has begun.`,
    }],
  }, {
    name: 'First Fifth Instar Larvae',
    range: {
      min: 2500,
      max: 2729,
    },
    infos: [{
      name: 'Adults',
      value: `Approximately 75%-95% of adult beetles have emerged.`,
    }, {
      name: 'Larvae',
      value: `First fifth instar larvae have emerged.`,
    }],
  }, {
    name: '95% Adult Emergence',
    range: {
      min: 2730,
      max: 2999,
    },
    infos: [{
      name: 'Adults',
      value: `Approximately 95% of adult beetles have emerged.`,
    }, {
      name: 'Larvae',
      value: `Fifth instar larvae have emerged.`,
    }],
  }, {
    name: '99% Adult Emergence',
    range: {
      min: 3000,
      max: 4099,
    },
    infos: [{
      name: 'Adults',
      value: `Approximately 99% of adult beetles have emerged.`,
    }, {
      name: 'Larvae',
      value: `Fifth instar larvae have emerged.`,
    }],
  }, {
    name: 'First Eighth Instar Larvae',
    range: {
      min: 4100,
      max: 5000,
    },
    infos: [{
      name: 'Adults',
      value: `All adult beetles have emerged.`,
    }, {
      name: 'Larvae',
      value: `First eighth instar larvae have emerged.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Asian Longhorned Beetle Emergence Growing Degree Day Model is used to predict emergence of Asian Longhorned Beetle (Anoplophora glabripennis) in order to improve pest management decisions. Traps should be placed before first sign of adult beetle activity.`,
  ],
  citations: [
    `This model was developed and tested in Oregon by Len Coop at Integrated Plant Protection Center, Oregon State University with the following primary references:`,
    `Smith, M.T., P.C. Tobin, J. Bancroft, G. Li, and R. Gao. 2004. Dispersal and Spatiotemporal dynamics of asian longhorned beetle (Coleoptera: Cerambycidae) in China. Environ. Entomol. 33:435-442.`,
    `Keena, M.A. and P.M. Moore. 2010. Effects of temperature on Anoplophora glabripennis (Col.: Cerambycidae) larvae and pupae. Environ. Entomol. 39:1323-1335.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-asian-longhorned-beetle-growing-degree-day-model',
}
