import { NgModule } from '@angular/core'
import { EnterBlurDirective } from './directives/enter-blur.directive'
import { EnterFocusNextDirective } from './directives/enter-focus-next.directive'

@NgModule({
  declarations: [
    EnterBlurDirective,
    EnterFocusNextDirective,
  ],
  exports: [
    EnterBlurDirective,
    EnterFocusNextDirective,
  ],
})
export class PestProphetCommonModule {}
