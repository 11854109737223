import { GddModelDefine } from '../gdd'

export const gddGypsyMoth: GddModelDefine = {
  lowerThreshold: undefined,
  upperThreshold: undefined,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddGypsyMoth',
  name: 'Gypsy Moth',
  fullName: 'Gypsy Moth (Lymantria Dispar) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Egg Not Yet Hatched',
      range: {
        min: 0,
        max: 508,
      },
      lowerThreshold: 37.4,
      upperThreshold: 120,
      infos: [
        {
          name: 'Eggs',
          value: `Eggs have not yet hatched. Recommended to set biofix to Jan. 1 for Northern Hemisphere.`,
        },
      ],
    },
    {
      name: 'Egg Hatch',
      range: {
        min: 509,
        max: 803,
      },
      lowerThreshold: 45.8,
      upperThreshold: 106,
      infos: [
        {
          name: 'Larvae',
          value: `First larvae have emerged.`,
        },
      ],
    },
    {
      name: 'First Instar Larvae',
      range: {
        min: 804,
        max: 865,
      },
      lowerThreshold: 45.8,
      upperThreshold: 106,
      infos: [
        {
          name: 'Larvae',
          value: `Peak of first instar larvae.`,
        },
      ],
    },
    {
      name: 'Second Instar Larvae',
      range: {
        min: 866,
        max: 1038,
      },
      lowerThreshold: 45.8,
      upperThreshold: 106,
      infos: [
        {
          name: 'Larvae',
          value: `Peak of second instar larvae.`,
        },
      ],
    },
    {
      name: 'Third Instar Larvae',
      range: {
        min: 1039,
        max: 1236,
      },
      lowerThreshold: 45.8,
      upperThreshold: 106,
      infos: [
        {
          name: 'Larvae',
          value: `Peak of third instar larvae.`,
        },
      ],
    },
    {
      name: 'Fourth Instar Larvae',
      range: {
        min: 1237,
        max: 1365,
      },
      lowerThreshold: 45.8,
      upperThreshold: 106,
      infos: [
        {
          name: 'Larvae',
          value: `Peak of fourth instar larvae.`,
        },
      ],
    },
    {
      name: 'Fifth and Sixth Instar Larvae',
      range: {
        min: 1366,
        max: 1481,
      },
      lowerThreshold: 45.8,
      upperThreshold: 106,
      infos: [
        {
          name: 'Larvae',
          value: `Peak of fifth and sixth instar larvae.`,
        },
      ],
    },
    {
      name: 'Beginning Pupation',
      range: {
        min: 1482,
        max: 1566,
      },
      lowerThreshold: 45.8,
      upperThreshold: 106,
      infos: [
        {
          name: 'Pupae',
          value: `Beginning of pupation period.`,
        },
      ],
    },
    {
      name: 'Peak Pupation',
      range: {
        min: 1567,
        max: 1695,
      },
      lowerThreshold: 45.8,
      upperThreshold: 106,
      infos: [
        {
          name: 'Pupae',
          value: `Peak of pupation period.`,
        },
      ],
    },
    {
      name: 'Adult Emergence',
      range: {
        min: 1696,
        max: 2000,
      },
      lowerThreshold: 45.8,
      upperThreshold: 106,
      infos: [
        {
          name: 'Adult',
          value: `Adult moths begin emergence.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Gypsy Moth Growing Degree Day Model is used to predict life-stage developments of the Gypsy Moth (Lymantria dispar) in order to improve pest management decisions.`,
    `To use for predicting egg hatch, a default starting biofix of Jan. 1 is recommended for the Northern Hemisphere. For best results in later lifecycle stages, the biofix should be readjusted when lifecycle stages are observed.`,
  ],
  citations: [
    `Johnson, P. C., D. P. Mason, S. L. Radke, and K. T. Tracewski. 1983. Gypsy moth, Lymantria dispar (L.) (Lepidoptera: Lymantriidae), egg eclosion: degree-day accumulations. Environ. Entomol. 12: 929-932.`,
    `Carter, M. R., F. W. Ravlin, and M. L. McManus. 1992. Effect of defoliation on gypsy moth phenology and capture of male moths in pheromone-baited traps. Environ. Entomol. 21: 1308-1318.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-gypsy-moth-growing-degree-day-model',
}
