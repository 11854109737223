<ion-content #content>
  <div class="logo" layout="row" layout-align="center center">
    <img src="../../../assets/img/pest-prophet-logo-1024.png" width="275" alt="pest prophet logo" />
  </div>

  <div
    *ngIf="showConnecting"
    class="connecting"
    layout="column" layout-align="start center"
  >
    <div class="connecting-text">
      {{ connectingMessage }}
    </div>
    <ion-spinner></ion-spinner>
  </div>

  <div
    *ngIf="showLoginForm"
    class="login-form-container"
    #loginFormContainer
  >
    <form (submit)="handleLoginFormSubmit()">
      <ion-list>
        <ion-item>
          <ion-input
            type="email"
            name="email"
            label="Email"
            label-placement="stacked"
            mode="ios"
            placeholder="Enter email..."
            [(ngModel)]="email"
            maxlength="256"
            required
            fcEnterFocusNext
          ></ion-input>
        </ion-item>

        <ion-item>
          <ion-input
            type="password"
            name="password"
            label="Password"
            label-placement="stacked"
            mode="ios"
            placeholder="Enter password..."
            [(ngModel)]="password"
            maxlength="256"
            aria-labelledby="passwordLabel"
            required
            fcEnterBlur
          ></ion-input>
        </ion-item>
      </ion-list>

      <div class="ph-40 mt-20" layout="row" layout-align="space-between start">
        <div layout="column">
          <ion-button type="submit">Login</ion-button>
        </div>

        <div layout="column" class="text-right">
          <ion-button type="button" color="secondary" (click)="handleSignupClick()">Sign Up</ion-button>
        </div>
      </div>
    </form>

    <div class="pv-20"></div>

    <div layout="column" layout-align="start center">
      <a class="forgot-password" (click)="handleForgotPasswordClick()">Forgot password?</a>
    </div>
  </div>
</ion-content>
