import { GddModelDefine } from '../gdd'

export const gddMetaseiulusOccidentalis: GddModelDefine = {
  lowerThreshold: 48,
  upperThreshold: 89.6,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddMetaseiulusOccidentalis',
  name: 'Metaseiulus Occidentalis',
  fullName: 'Metaseiulus Occidentalis Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 430,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged observed eggs or nymphs.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 431,
        max: 500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have emerged and begun laying next generation eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Metaseiulus Occidentalis Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Metaseiulus Occidentalis, in order to improve pest management practices.',
    'For best results, a biofix date should be set when Metaseiulus Occidentalis are first observed or introduced to field. The growing degree day model can then be used to predict emergence of adults and next generation eggs.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-metaseiulus-occidentalis-growing-degree-day-model/',
  citations: [
    'Tanigoshi, L. K., S. C. Hoyt, R. W. Browne, and J. A. Logan. 1975. Influence of temperature on population increase of Metaseiulus occidentalis (Acarina: Phytoseiidae). Ann. Ent. Soc. Amer. 68: 979-986.',
  ],
}
