import { GddModelDefine } from '../gdd'

export const gddBoxwoodMite: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddBoxwoodMite',
  name: 'Boxwood Mite',
  fullName: 'Boxwood Mite (Eurytetranychus buxi) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence',
      range: {
        min: 0,
        max: 245,
      },
      infos: [
        {
          name: 'Adults and Nymphs',
          value: 'Adults and nymphs from overwintered eggs have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Emergence from Overwintered Eggs',
      range: {
        min: 246,
        max: 600,
      },
      infos: [
        {
          name: 'Adults and Nymphs',
          value: 'First adults and nymphs from overwintered eggs have begun to emerge.',
        },
      ],
    },
    {
      name: 'End of Emergence',
      range: {
        min: 601,
        max: 700,
      },
      infos: [
        {
          name: 'Adults and Nymphs',
          value: 'Adults and Nymphs have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Boxwood Mite Growing Degree Day Model is used to predict development of life-cycle stages of Boxwood Mite (Eurytetranychus buxi), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults and nymphs from overwintered eggs which can be targeted for treatment.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-boxwood-mite-growing-degree-day-model/',
  citations: [
    'Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.',
    'Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.',
  ],
}
