import { GddModelDefine } from '../gdd'

export const gddWoollyElmAphid: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddWoollyElmAphid',
  name: 'Woolly Elm Aphid GDD',
  fullName: 'Wooly Elm Aphid (Erisoma americanum) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Nymph Emergence',
      range: {
        min: 0,
        max: 121,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs from overwintered eggs have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Nymph Emergence',
      range: {
        min: 122,
        max: 246,
      },
      infos: [
        {
          name: 'Nymphs',
          value:
            'Nymphs from overwintered eggs have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Nymph Emergence',
      range: {
        min: 247,
        max: 400,
      },
      infos: [
        {
          name: 'Nymph',
          value:
            'Nymphs from overwintered eggs have fully emerged and treatment window has closed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Woolly Elm Aphid Growing Degree Day Model is used to predict development of life-cycle stages of Wolly Elm Aphid (Erisoma americanum), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of nymphs from overwintered eggs, which can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-woolly-elm-aphid-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
