import { GddModelDefine } from '../gdd'

export const gddPrivetRustMite: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPrivetRustMite',
  name: 'Privet Rust Mite GDD',
  fullName: 'Privet Rust Mite (Aculus ligustri) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence',
      range: {
        min: 0,
        max: 298,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Emergence of Overwintered Adults',
      range: {
        min: 299,
        max: 802,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have begun to emerge.',
        },
      ],
    },
    {
      name: 'End of Overwintered Adult Emergence',
      range: {
        min: 803,
        max: 1266,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have fully emerged.',
        },
      ],
    },
    {
      name: 'First Emergence of Next Generation Adults',
      range: {
        min: 1267,
        max: 1515,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First next generation adults have begun to emerge.',
        },
      ],
    },
    {
      name: 'End of Next Generation Adult Emergence',
      range: {
        min: 1516,
        max: 1600,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next Generation adults have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Privet Rust Mite Growing Degree Day Model is used to predict development of life-cycle stages of Privet Rust Mite (Aculus ligustri), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of overwintered adults which can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-privet-rust-mite-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
