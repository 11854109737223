import { GddModelDefine } from '../gdd'

export const gddJackPineBudworm: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddJackPineBudworm',
  name: 'Jack Pine Budworm',
  fullName: 'Jack Pine Budworm (Choristoneura pinus) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Larvae Overwintering',
      range: {
        min: 0,
        max: 300,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have not emerged.',
        },
      ],
    },
    {
      name: 'Young Larvae Feeding',
      range: {
        min: 301,
        max: 350,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are beginning to emerge and feed.',
        },
      ],
    },
    {
      name: 'Intermediate Larvae Feeding',
      range: {
        min: 351,
        max: 650,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding.',
        },
      ],
    },
    {
      name: 'Large Larvae Feeding - Defoliation',
      range: {
        min: 651,
        max: 700,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Large larvae are present and feeding.',
        },
      ],
    },
    {
      name: 'Pupation',
      range: {
        min: 701,
        max: 800,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have begun to pupate.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Jack Pine Budworm Degree Day Model is used to predict life-stage developments of the Jack Pine Budworm (Choristoneura pinus), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by traps.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-jack-pine-budworm-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
