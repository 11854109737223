import { GddModelDefine } from '../gdd'

export const gddElmLeafBeetle: GddModelDefine = {
  lowerThreshold: 51.8,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddElmLeafBeetle',
  name: 'Elm Leaf Beetle Emergence',
  fullName: 'Elm Leaf Beetle (Pyrrhalta luteola) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'No Adult Activity',
    range: {
      min: 0,
      max: 360,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintered adult beetles may have emerged but have not yet begun egg laying. Recommended to set biofix to Mar. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'Begin Egg Monitoring',
    range: {
      min: 361,
      max: 510,
    },
    infos: [{
      name: 'Adults',
      value: `First emergence of adult beetles predicted.`,
    }, {
      name: 'Eggs',
      value: `Egg monitoring recommended.`,
    }],
  }, {
    name: 'Peak Egg Density',
    range: {
      min: 511,
      max: 700,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult beetle emergence.`,
    }, {
      name: 'Eggs',
      value: `Egg density peak.`,
    }],
  }, {
    name: 'Peak Small Larvae',
    range: {
      min: 701,
      max: 1565,
    },
    infos: [{
      name: 'Larvae',
      value: `Peak of first and second instar larvae. Optimum timing fo trunk spray or bark band application.`,
    }],
  }, {
    name: 'Begin Egg Monitoring Second Generation',
    range: {
      min: 1566,
      max: 1715,
    },
    infos: [{
      name: 'Adults',
      value: `First emergence of second generation adult beetles predicted.`,
    }, {
      name: 'Eggs',
      value: `Egg monitoring recommended.`,
    }],
  }, {
    name: 'Peak Egg Density Second Generation',
    range: {
      min: 1716,
      max: 2000,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of second generation adult beetle emergence.`,
    }, {
      name: 'Eggs',
      value: `Egg density peak.`,
    }],
  }, {
    name: 'Peak Small Larvae Second Generation',
    range: {
      min: 2001,
      max: 3000,
    },
    infos: [{
      name: 'Larvae',
      value: `Peak of second generation first and second instar larvae. Optimum timing for trunk spray or bark band application.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Elm Leaf Beetle Emergence Growing Degree Day Model is used to predict emergence of Elm Leaf Beetle (Pyrrhalta luteola) in order to improve pest management decisions. It can be used to predict optimum timing for insecticde use. For best results, the biofix should be readjusted after each observed lifecycle event.`,
  ],
  citations: [
    `Elm leaf beetle. IN: Pests of Landscape Trees and Shrubs: An Integrated Pest Management Guide. University of California Statewide IPM project. Div. Agr. Publ. #3359.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-elm-leaf-beetle-growing-degree-day-model',
}
