import { GddModelDefine } from '../gdd'

export const gddSourCherry: GddModelDefine = {
  lowerThreshold: 4,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddSourCherry',
  name: 'Sour Cherry Crop Stage',
  fullName: 'Sour Cherry (Montmorency Variety) Crop Stage Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Side Green',
      range: {
        min: 0,
        max: 24,
      },
      infos: [
        {
          name: 'Crop Stage',
          value: 'Side Green',
        },
      ],
    },
    {
      name: 'Green Tip',
      range: {
        min: 25,
        max: 44,
      },
      infos: [
        {
          name: 'Crop Stage',
          value: 'Green Tip',
        },
      ],
    },
    {
      name: 'Tight Cluster',
      range: {
        min: 45,
        max: 60,
      },
      infos: [
        {
          name: 'Crop Stage',
          value: 'Tight Cluster',
        },
      ],
    },
    {
      name: 'Open Cluster',
      range: {
        min: 61,
        max: 78,
      },
      infos: [
        {
          name: 'Crop Stage',
          value: 'Open Cluster',
        },
      ],
    },
    {
      name: 'First White',
      range: {
        min: 79,
        max: 98,
      },
      infos: [
        {
          name: 'Crop Stage',
          value: 'First White',
        },
      ],
    },
    {
      name: 'First Bloom',
      range: {
        min: 99,
        max: 123,
      },
      infos: [
        {
          name: 'Crop Stage',
          value: 'First Bloom',
        },
      ],
    },
    {
      name: 'Full Bloom',
      range: {
        min: 124,
        max: 181,
      },
      infos: [
        {
          name: 'Crop Stage',
          value: 'Full Bloom',
        },
      ],
    },
    {
      name: 'Petal Fall',
      range: {
        min: 182,
        max: 220,
      },
      infos: [
        {
          name: 'Crop Stage',
          value: 'Petal Fall',
        },
      ],
    },
  ],
  notes: [
    'Crop stages based Growing Degree Day models are estimations. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Sour Cherry crop stage Growing Degree Day model is used to predict crop-stage developments of the Sour Cherries, in order to improve management decisions',
    'For best results, a biofix should be set when side green stage is first observed.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-sour-cherry-growing-degree-day-model/',
  citations: [
    "Zavalloni, C., Andresen, J., Flore, J.A. . Phenological Models of Crop Bud Stages and Fruit Growth of 'Montmorency' Sour Cherry Based on Growing Degree-day Accumulation. J. Amer. Hort. Sci.131(5) 601-607, 2006",
  ],
}
