import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { UpgradeCardModule } from '../../components/upgrade-card/upgrade-card.module'
import { PestProphetCommonModule } from '../../pest-prophet-common.module'
import { HomePage } from './home.page'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UpgradeCardModule,
    RouterModule.forChild([
      {
        path: '',
        component: HomePage,
      },
    ]),
    PestProphetCommonModule,
  ],
  declarations: [HomePage],
})
export class HomePageModule {}
