import { GddModelDefine } from '../gdd'

export const gddCitrusThrips: GddModelDefine = {
  lowerThreshold: 58.2,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCitrusThrips',
  name: 'Citrus Thrips',
  fullName: 'Citrus Thrips (Scirtothrips citri) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'First Generation',
      range: {
        min: 0,
        max: 125,
      },
      infos: [
        {
          name: 'Adults',
          value: `Set biofix when first generation thrips are observed.`,
        },
      ],
    },
    {
      name: 'Second Generation Emergence',
      range: {
        min: 126,
        max: 281,
      },
      infos: [
        {
          name: 'Adults',
          value: `Second generation has begun to emerge. Optimum time for insecticide treatment.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Citrus Thrips Growing Degree Day Model is used to predict second generation emergence of Citrus Thrips (Scirtothrips citri) in order to improve pest management timing decisions. The biofix should be set when the first generation is observed in order to optimize insecticide treatment timing for the second generation.`,
  ],
  citations: [
    `Grout, T.G., J.G. Morse, N.V. O'Connell, D.L. Flaherty, P.B. Goodell, M.W. Freeman, and R.L. Coviello. 1986. Citrus thrips (Thysanoptera: Thripidae) phenology and sampling in the San Joaquin Valley. J. Econ. Entomol. 79:1516-1523.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-citrus-thrips-growing-degree-day-model',
}
