import { Component } from '@angular/core'
import { StatusBar } from '@capacitor/status-bar'
import { AlertController } from '@ionic/angular'
import { Analytics } from '../../services/analytics'
import { Tools } from '../../services/tools'
import { Me } from '../../services/me'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage {

  inputs = {
    email: '',
  }

  emailSent = false

  constructor(
    private alertCtrl: AlertController,
  ) {}

  ionViewWillEnter() {
    if (Tools.isNative()) {
      void StatusBar.setBackgroundColor({ color: '#ffffff' })
    }
  }

  ionViewDidEnter() {
    Analytics.screenView('Forgot Password')
  }

  async handleSendClick() {
    if (!this.inputs.email) {
      const alert = await this.alertCtrl.create({
        header: 'Email is required.',
        buttons: ['Close'],
        backdropDismiss: false,
      })
      await alert.present()
      return
    }

    this.emailSent = true

    try {
      await Me.forgotPassword({
        email: this.inputs.email,
      })
    } catch (err) {
      console.error(err.message)
      this.emailSent = false
      const alert = await this.alertCtrl.create({
        header: 'Error',
        message: err.message,
        buttons: ['Close'],
        backdropDismiss: false,
      })
      await alert.present()
      throw err
    }

    Analytics.event({ category: 'forgot_password', action: 'send_email' })

    const alert = await this.alertCtrl.create({
      header: 'Sent!',
      message: 'An email has been sent with instructions to reset your password.',
      buttons: ['Close'],
      backdropDismiss: false,
    })
    await alert.present()
  }

}
