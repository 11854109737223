import {
  gddModelGroupDefine,
  gddModelDefines,
} from './gdd'
import {
  melCastModelDefines,
  melCastModelGroupDefine,
} from './mel-cast'
import {
  powderyMildewModelGroupDefine,
  powderyMildewModelDefines,
} from './powdery-mildew'
import {
  tomCastModelGroupDefine,
  tomCastModelDefines,
} from './tom-cast'
import {
  whiteMoldModelDefines,
  whiteMoldModelGroupDefine,
} from './white-mold'

export * from './gdd'
export * from './mel-cast'
export * from './powdery-mildew'
export * from './tom-cast'
export * from './white-mold'
export * from './model-full-types'

export const allModelDefines = {
  ...powderyMildewModelDefines,
  ...gddModelDefines,
  ...tomCastModelDefines,
  ...melCastModelDefines,
  ...whiteMoldModelDefines,
} as const

export const modelGroupDefines = {
  powderyMildew: powderyMildewModelGroupDefine,
  gdd: gddModelGroupDefine,
  tomCast: tomCastModelGroupDefine,
  melCast: melCastModelGroupDefine,
  whiteMold: whiteMoldModelGroupDefine,
} as const
