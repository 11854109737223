import { GddModelDefine } from '../gdd'

export const gddAphytisMelinus: GddModelDefine = {
  lowerThreshold: 49.5,
  upperThreshold: 86,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddAphytisMelinus',
  name: 'Aphytis Melinus',
  fullName: 'Aphytis Melinus Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 407,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged observed eggs or nymphs.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 408,
        max: 500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have emerged and begun laying next generation eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Aphytis Melinus Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Aphytis Melinus, in order to improve pest management practices.',
    'For best results, a biofix date should be set when Aphytis Melinus eggs are first observed or introduced to field. The growing degree day model can then be used to predict emergence of adults and next generation eggs.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-aphytis-melinus-growing-degree-day-model/',
  citations: [
    'Yu, D. S., and R. F. Luck. 1988. Temperature-dependent size and development of California red scale (Homoptera: Diaspididae) and its effect on host availability for the ectoparasitoid, Aphytis melinus DeBach (Hymenoptera: Aphelinidae). Environ. Entomol. 17: 154-161.',
  ],
}
