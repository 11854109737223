import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import {
  RouterModule,
  Routes,
} from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { PageInfoComponentModule } from '../../components/page-info/page-info.module'
import { PestProphetCommonModule } from '../../pest-prophet-common.module'
import { ForgotPasswordPage } from './forgot-password.page'

const routes: Routes = [
  {
    path: '',
    component: ForgotPasswordPage,
  },
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    PestProphetCommonModule,
    PageInfoComponentModule,
  ],
  declarations: [ForgotPasswordPage],
})
export class ForgotPasswordPageModule {}
