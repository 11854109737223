import {
  Directive,
  HostListener,
} from '@angular/core'
// import { TextInput } from 'ionic-angular'

@Directive({
  selector: '[fcEnterFocusNext]',
})
export class EnterFocusNextDirective {

  // constructor(
  //   private inputRef: IonText,
  // ) {}

  @HostListener('keydown', ['$event'])
  handleInputChange(e: KeyboardEvent) {
    // if (!Tools.isMobile()) {
    //     return
    // }
    //
    // const code = e.keyCode || e.which
    // if (code === 13) {
    //     e.preventDefault()
    //     this.inputRef.focusNext()
    // }
  }

}
