import { GddModelDefine } from '../gdd'

export const gddEuropeanElmScale: GddModelDefine = {
  lowerThreshold: 51.8,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddEuropeanElmScale',
  name: 'European Elm Scale',
  fullName: 'European Elm Scale (Gossyparia spuria) Emergence Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Pre-Emergence of Adults',
      range: {
        min: 0,
        max: 542,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult scales have not yet emerged. Recommended to set biofix to Mar. 1 for Northern Hemisphere.`,
        },
      ],
    },
    {
      name: 'Adult Female Scale Peak',
      range: {
        min: 543,
        max: 800,
      },
      infos: [
        {
          name: 'Adult Females',
          value: `Adult females have emerged.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The European Elm Scale Growing Degree Day Model is used to predict the spring emergence of the European Elm Scale (Gossyparia spuria) in order to prepare for setting traps or beginning a spray program.`,
  ],
  citations: [
    `Dreistadt, S. H. and K.S. Hagen. 1994. European elm scale (Homoptera: Eriococcidae) abundance and parasitism in northern California. Pan-Pacific Entomologist 70:240-252.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-european-elm-scale-emergence-growing-degree-day-model',
}
