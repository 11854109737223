import { GddModelDefine } from '../gdd'

export const gddJapaneseBeetleEmergence: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 100,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddJapaneseBeetleEmergence',
  name: 'Japanese Beetle Emergence',
  fullName: 'Japanese Beetle (Popilia japonica) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'No Adult Activity',
    range: {
      min: 0,
      max: 920,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintered adult beetles have not yet emerged. Recommended to set biofix to Jan. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'First Adult Trapping (Pre-Emergence)',
    range: {
      min: 921,
      max: 970,
    },
    infos: [{
      name: 'Adults',
      value: `Adult beetles will soon begin to emergence. Placing traps is recommended.`,
    }],
  }, {
    name: 'First Adult Emergence',
    range: {
      min: 971,
      max: 1600,
    },
    infos: [{
      name: 'Adults',
      value: `First emergence of adult beetles predicted.`,
    }],
  }, {
    name: 'Peak Adult Emergence',
    range: {
      min: 1601,
      max: 2150,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult beetle emergence.`,
    }],
  }, {
    name: 'End Adult Activity',
    range: {
      min: 2151,
      max: 2790,
    },
    infos: [{
      name: 'Adults',
      value: `Approximate end of main period of adult beetle activity`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Japanese Beetle Emergence Growing Degree Day Model is used to predict emergence of the Japanese Beetle (Popilia japonica) in order to improve pest management decisions. Traps should be placed before first sign of adult beetle activity.`,
  ],
  citations: [
    `Magarey et al. 2007. NAPPFAST: An Internet System for the Weather-Based Mapping of Plant Pathogen. Plant Disease. April 2007, Volume 91, Number 4 Pages 336-345.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-japanese-beetle-growing-degree-day-model',
}
