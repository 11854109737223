import { GddModelDefine } from '../gdd'

export const gddEasternSpruceGallAdelgid: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddEasternSpruceGallAdelgid',
  name: 'Eastern Spruce Gall Adelgid',
  fullName: 'Eastern Spruce Gall Adelgid (Adelges abietis) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Adults Present',
      range: {
        min: 0,
        max: 25,
      },
      infos: [
        {
          name: 'Adults',
          value: 'No adult adelgids present.',
        },
      ],
    },
    {
      name: 'First Adults Active',
      range: {
        min: 26,
        max: 100,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult have emerged and may be targeted for control',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
      ],
    },
    {
      name: 'First Generation Larvae',
      range: {
        min: 101,
        max: 250,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae and present and feeding.',
        },
      ],
    },
    {
      name: 'First Galls Apparent',
      range: {
        min: 251,
        max: 310,
      },
      infos: [
        {
          name: 'Galls',
          value: 'Galls caused by adelgids should be visible.',
        },
      ],
    },
    {
      name: 'Next Generation Adults Not Present',
      range: {
        min: 311,
        max: 1500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'No adult adelgids present.',
        },
      ],
    },
    {
      name: 'Second Adults Active',
      range: {
        min: 1501,
        max: 1600,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult have emerged and may be targeted for control',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Eastern Spruce Gall Adelgid Degree Day Model is used to predict life-stage developments of the Eastern Spruce Gall Adelgid (Adelges abietis), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict egg hatch and emergence of adults which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-eastern-spruce-gall-adelgid-midge-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
