import { Injectable } from '@angular/core'
import {
  AppStoreReceipt,
  PlayStoreReceipt,
} from '@awesome-cordova-plugins/in-app-purchase-2'
import {
  IAPError,
  IAPProduct,
  InAppPurchase2,
} from '@awesome-cordova-plugins/in-app-purchase-2/ngx'
import {
  IapSubscriptionProductId,
  iapSubscriptionTypes,
  IapValidationAndroidBody,
  IapValidationBody,
  IapValidationIosBody,
  SubscriptionUtils,
  assertExhaustive,
} from '@pest-prophet/shared'
import { environment } from '../../environments/environment'
import { Api } from './api'
import { Tools } from './tools'
import { Me } from './me'

@Injectable({
  providedIn: 'root',
})
export class StoreService {

  initialized = false
  products: { [productId: string]: IAPProduct } = {}
  productsArray: IAPProduct[] = []
  ownedProducts: { [productId: string]: IAPProduct } = {}
  ownedProductsArray: IAPProduct[] = []
  private onPurchaseComplete?: () => void
  private onPurchaseFailed?: (message: string) => void
  onConnectionFailed?: (message: string) => void

  registeredProducts = 0
  loadedProducts = 0

  constructor(
    private iap2: InAppPurchase2,
  ) {}

  async init() {
    if (this.initialized) {
      return
    }

    console.log('Initializing store...')

    this.iap2.error((err: IAPError) => {
      console.log(`IAP Error: ${err.message}`)

      if (err.message.toLowerCase().includes('cannot connect')) {
        if (this.onConnectionFailed) {
          this.onConnectionFailed(err.message)
        }
      }
    })

    this.iap2.verbosity = this.iap2.DEBUG
    if (Tools.isDev()) {
      console.log('~~~~~ IAP Complete Transactions ~~~~~')
      this.iap2.autoFinishTransactions = true
    }

    this.iap2.validator = async (product: string | IAPProduct, callback: Function) => {
      if (!Me.getFirebaseId()) {
        throw new Error('IAP unable to validate: user is not logged in.')
      }

      if (typeof product === 'string' || product.transaction == null) {
        return
      }

      console.log(`IAP Validate: ${product.id}`)

      try {
        const result = await Api.post('/iap/validator', {
          body: this.buildValidatorBody(product, product.transaction),
        })

        const success = result.ok || Tools.isDev()

        if (success) {
          console.log(`IAP Validate Success: ${product.id}`)
          product.finish()
          callback(true, product)
        } else if ('data' in result) {
          console.log(`IAP Validate Failed: ${result.error.message}`)
          if (result.data.code === 6778001 || result.data.code === 6778003) {
            product.finish()
          }
          callback(false, result.error.message)
        } else {
          callback(false, 'Unknown Error')
        }
      } catch (err) {
        console.error(err)
        callback(false, `Unexpected error: ${err.message}`)
      }
    }

    this.registerProducts()

    await this.refresh()

    console.log('Store initialized')

    this.initialized = true
  }

  buildValidatorBody(product: IAPProduct, transaction: AppStoreReceipt | PlayStoreReceipt): IapValidationBody {
    switch (transaction.type) {
      case 'ios-appstore':
        return this.buildIapTransactionIos(product, transaction)
      case 'android-playstore':
        return this.buildIapTransactionAndroid(product, transaction)
      default:
        return assertExhaustive(transaction)
    }
  }

  buildIapTransactionIos(product: IAPProduct, transaction: AppStoreReceipt): IapValidationIosBody {
    return {
      platform: 'iosAppstore',
      request: {
        appStoreReceipt: transaction.appStoreReceipt,
      },
    }
  }

  buildIapTransactionAndroid(product: IAPProduct, transaction: PlayStoreReceipt): IapValidationAndroidBody {
    return {
      platform: 'androidPlaystore',
      request: {
        productId: product.id as IapSubscriptionProductId,
        purchaseToken: transaction.purchaseToken,
      },
    }
  }

  private registerProducts() {
    // Register products.
    iapSubscriptionTypes
      .map(type => SubscriptionUtils.getSubscriptionDefineByType(type, environment))
      .forEach(define => {
        const productId = SubscriptionUtils.getProductId(define)
        this.iap2.register({
          id: productId,
          alias: define.name,
          type: this.iap2.PAID_SUBSCRIPTION,
        })
        this.setupProductEvents(productId)
        this.registeredProducts += 1
      })

    // Register Android test products on dev.
    if (Tools.isDev() && Tools.getPlatform() === 'android') {
      iapAndroidTestProductIds
        .forEach(productId => {
          this.iap2.register({
            id: productId,
            alias: productId,
            type: this.iap2.CONSUMABLE,
          })
          this.setupProductEvents(productId)
          this.registeredProducts += 1
        })
    }
  }

  clear() {
    this.clearOwnedProducts()
  }

  async refresh() {
    // The first refresh call to iap2 doesn't fire a refresh-completed event... because iap2 is fucking retarded.
    if (!this.initialized) {
      this.iap2.refresh()

      await new Promise<void>(resolve => {
        setInterval(() => {
          if (this.loadedProducts === this.registeredProducts) {
            resolve()
          }
        })
      })
    } else {
      try {
        await new Promise<void>((resolve, reject) => {
          this.iap2.once('refresh-completed', resolve as any)
          this.iap2.once('refresh-failed', reject as any)
          this.iap2.refresh()
        })
      } catch (err) {
        console.log('IAP Refresh Failed')
        console.log(err)
        return
      }
    }

    console.log('IAP Refresh Completed')
  }

  private setupProductEvents(productId: string) {
    this.iap2.when(productId)

      .loaded((product: IAPProduct) => {
        this.addProduct(product)
        this.loadedProducts += 1
      })

      .updated((product: IAPProduct) => {
        if (product.owned) {
          this.addOwnedProduct(product)
        } else {
          this.removeOwnedProduct(product)
        }
      })

      .approved((product: IAPProduct) => {
        console.log(`IAP Approved: ${product.id}`)

        const result = product.verify()

        result.success(() => {
          console.log(`IAP Verify Success: ${product.id}`)
          product.finish()
        })

        result.expired(() => {
          console.log(`IAP Verify Expired: ${product.id}`)
          product.finish()
        })

        result.error((err: IAPError) => {
          console.log(`IAP Verify Error: ${product.id} - ${err.message}`)
          if (
            err.code === this.iap2.ERR_PAYMENT_EXPIRED
            || err.code === this.iap2.ERR_PAYMENT_CANCELLED
          ) {
            product.finish()
          }
        })
      })

      .verified((product: IAPProduct) => {
        console.log(`IAP Verified: ${product.id}`)
        product.finish()
      })

      .finished((product: IAPProduct) => {
        console.log(`IAP Finished: ${product.id}`)
        if (this.onPurchaseComplete) {
          this.onPurchaseComplete()
        }
      })

      .owned((product: IAPProduct) => {
        console.log(`IAP Owned: ${product.id}`)
      })

      .expired((product: IAPProduct) => {
        console.log(`IAP Expired: ${product.id}`)

        product.finish()
      })

      .cancelled((product: IAPProduct) => {
        console.log(`IAP Canceled: ${product.id} Canceled`)

        if (this.onPurchaseFailed) {
          this.onPurchaseFailed('Purchase was canceled.')
        }
      })

      .unverified((product: IAPProduct) => {
        console.log(`IAP Unverified: ${product.id} Unverified`)

        if (this.onPurchaseFailed) {
          this.onPurchaseFailed('Failed to verify purchase.')
        }
      })

      .error((err: IAPError) => {
        console.log(`IAP Error ${productId}: ${err.message}`)

        if (this.onPurchaseFailed) {
          this.onPurchaseFailed(err.message)
        }
      })
  }

  async purchase(productId: string) {
    console.log(`IAP Purchasing ${productId}...`)

    await new Promise<void>((resolve, reject) => {
      this.onPurchaseComplete = () => {
        this.onPurchaseComplete = undefined
        resolve()
      }

      this.onPurchaseFailed = message => {
        this.onPurchaseFailed = undefined
        reject(message)
      }

      const product = this.products[productId]
      if (!product) {
        throw new Error(`Product "${productId}" doesn't exist!`)
      } else if (!product.valid) {
        throw new Error(`Product "${productId}" exists but is not valid!`)
      }

      // Android needs the currently subscribed productId (if we're currently subscribed). This
      // will let the user change subscriptions properly.
      let additionalData: any
      if (Tools.getPlatform() === 'android' && Me.getSubscriptionData()) {
        additionalData = {
          oldPurchasedSkus: [Me.getSubscriptionProductId()],
        }
      }

      // This just puts the order in. We have to listen for 'approved' and 'verified' callbacks for
      // true confirmation that the purchase succeeded.
      const order = this.iap2.order(product, additionalData)
      console.log('IAP Order', order)

      // eslint-disable-next-line
      order.then(() => {
        console.log(`Purchase placed.`)
      })
      order.error((err: IAPError) => {
        console.log(`Purchase failed.`)
        reject(new Error(`Purchase error: ${err.message}`))
      })
    })

    await Me.refresh()
  }

  //
  // Helpers
  //

  private rebuildProductsArray() {
    this.productsArray = Object.keys(this.products).map(key => {
      return this.products[key]
    })
  }

  private addProduct(product: IAPProduct) {
    this.products[product.id] = product
    this.rebuildProductsArray()
  }

  private rebuildOwnedProductsArray() {
    this.ownedProductsArray = Object.keys(this.ownedProducts).map(productId => {
      return this.ownedProducts[productId]
    })
  }

  private addOwnedProduct(product: IAPProduct) {
    this.ownedProducts[product.id] = product
    this.rebuildOwnedProductsArray()
  }

  private removeOwnedProduct(product: IAPProduct) {
    delete this.ownedProducts[product.id]
    this.rebuildOwnedProductsArray()
  }

  private clearOwnedProducts() {
    this.ownedProducts = {}
    this.ownedProductsArray = []
  }

}

export const iapAndroidTestProductIds = [
  'android.test.purchased',
  'android.test.canceled',
  'android.test.item_unavailable',
] as const
