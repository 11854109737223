import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import {
  RouterModule,
  Routes,
} from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { PestProphetCommonModule } from '../../pest-prophet-common.module'
import { PrivacyPolicyPage } from './privacy-policy.page'

const routes: Routes = [
  {
    path: '',
    component: PrivacyPolicyPage,
  },
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    PestProphetCommonModule,
  ],
  declarations: [PrivacyPolicyPage],
})
export class PrivacyPolicyPageModule {}
