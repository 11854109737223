import { GddModelDefine } from '../gdd'

export const gddObliquebandedLeafrollerHazelnut: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 90,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddObliquebandedLeafrollerHazelnut',
  name: 'Obliquebanded Leafroller GDD (Hazelnut)',
  fullName:
    'Obliquebanded Leafroller (Choristoneura rosaceana) Growing Degree Day Model on Hazelnut',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence from Overwintering',
      range: {
        min: 0,
        max: 80,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered larvae have not emerged.',
        },
      ],
    },
    {
      name: '50% Overwintered Larvae Emergence',
      range: {
        min: 81,
        max: 245,
      },
      infos: [
        {
          name: 'Larvae',
          value: '50% of larvae out of overwintering diapause.',
        },
      ],
    },
    {
      name: 'Peak Larval Molt - 75% Overwintered Larvae Emergence',
      range: {
        min: 246,
        max: 450,
      },
      infos: [
        {
          name: 'Larvae',
          value: '75% of larvae out of overwintering diapause.',
        },
      ],
    },
    {
      name: '5% Catch in Pheromone Traps',
      range: {
        min: 451,
        max: 515,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Some adults from overwintered larvae are active.',
        },
        {
          name: 'Larvae',
          value: 'Over 75% of larvae out of overwintering diapause.',
        },
      ],
    },
    {
      name: 'Peak Pupal Population',
      range: {
        min: 516,
        max: 535,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Peak pupal population from overwintered larvae.',
        },
        {
          name: 'Adults',
          value: 'Some adults from overwintered larvae are active.',
        },
      ],
    },
    {
      name: 'First Egg-laying - 1st Generation',
      range: {
        min: 536,
        max: 655,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active and have begun laying eggs.',
        },
      ],
    },
    {
      name: '50% Catch in Pheromone Traps',
      range: {
        min: 656,
        max: 735,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active and have begun laying eggs.',
        },
      ],
    },
    {
      name: 'Peak Egg-laying - 1st Generation',
      range: {
        min: 736,
        max: 935,
      },
      infos: [
        {
          name: 'Adults',
          value: '80% catch in pheromone traps. Peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak Egg Hatch - 1st Generation',
      range: {
        min: 936,
        max: 1230,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Peak egg hatch for 1st generation.',
        },
      ],
    },
    {
      name: 'Peak Small Larvae - 1st Generation',
      range: {
        min: 1231,
        max: 1590,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Peak third stage (small) larvae for 1st generation.',
        },
      ],
    },
    {
      name: 'Peak Large Larvae - 1st Generation',
      range: {
        min: 1591,
        max: 1690,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Peak fifth stage (large) larvae for 1st generation.',
        },
      ],
    },
    {
      name: 'First Catch in Pheromone Traps - 1st Generation',
      range: {
        min: 1691,
        max: 1775,
      },
      infos: [
        {
          name: 'Adults',
          value: '1st generation adults have emerged and are active.',
        },
      ],
    },
    {
      name: 'First Egg-laying- 2nd Generation',
      range: {
        min: 1776,
        max: 1800,
      },
      infos: [
        {
          name: 'Adults',
          value:
            '1st generation adultsare active and have begun laying eggs, 25% catch in pheromone traps',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Obliquebanded Leafroller Degree Day Model is used to predict development of life-cycle stages of Obliquebanded Leafroller (Choristoneura rosaceana) on hazelnuts, in order to improve pest management decisions',
    'For best results, a biofix date should be set for March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-obliquebanded-leafroller-growing-degree-day-model/',
  citations: [
    'Gangavalli & AliNiazee, M. T. 1985. Temperature requirements for development of the obliquebanded leafroller, Choristoneura rosaceana (Lepidoptera: Tortricidae). Environ. Entomol. 14:17-19.',
  ],
}
