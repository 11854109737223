import {
  Loader,
  google,
} from 'google-maps'

export class Google {

  private static _google: google

  static async init() {
    const loader = new Loader('AIzaSyD0ZAy23ptX_eLn6_Vm_lvSTmoCT6cDMyA', {
      version: 'quarterly',
      libraries: ['places'],
    })
    this._google = await loader.load()
  }

  static get Map() {
    return this._google.maps.Map
  }

}
