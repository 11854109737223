import { GddModelDefine } from '../gdd'

export const gddSpottedWingDrosophilia: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 88,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddSpottedWingDrosophilia',
  name: 'Spotted Wing Drosophilia',
  fullName: 'Spotted Wing Drosophila (Drosophila suzukii) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adults Overwintering',
      range: {
        min: 0,
        max: 260,
      },
      infos: [
        {
          name: 'Info',
          value: `Most adult flies remain dormant at this stage.`,
        },
      ],
    },
    {
      name: 'First Egg Laying by Overwintered Females',
      range: {
        min: 261,
        max: 509,
      },
      infos: [
        {
          name: 'Info',
          value: `Most adult flies have emerged and females have begun to lay eggs.`,
        },
      ],
    },
    {
      name: 'First Adult Emergence (First Generation)',
      range: {
        min: 510,
        max: 564,
      },
      infos: [
        {
          name: 'Info',
          value: `First generation of adults have begun to emerge. Overwintered adult females at peak egg laying.`,
        },
      ],
    },
    {
      name: 'First Egg Laying (First Generation)',
      range: {
        min: 565,
        max: 754,
      },
      infos: [
        {
          name: 'Info',
          value: `First generation adult females have begun egg laying.`,
        },
      ],
    },
    {
      name: 'Peak Adult Emergence (First Generation)',
      range: {
        min: 755,
        max: 994,
      },
      infos: [
        {
          name: 'Info',
          value: `First generation adults have reached peak emergence.`,
        },
      ],
    },
    {
      name: 'Peak Egg Laying (First Generation)',
      range: {
        min: 995,
        max: 1248,
      },
      infos: [
        {
          name: 'Info',
          value: `First generation adult females have reached peak egg laying.`,
        },
      ],
    },
    {
      name: 'Peak Adult Emergence (Second Generation)',
      range: {
        min: 1249,
        max: 1488,
      },
      infos: [
        {
          name: 'Info',
          value: `Second generation adults have reached peak emergence.`,
        },
      ],
    },
    {
      name: 'Peak Egg Laying (Second Generation)',
      range: {
        min: 1489,
        max: 1742,
      },
      infos: [
        {
          name: 'Info',
          value: `Second generation adult females have reached peak egg laying.`,
        },
      ],
    },
    {
      name: 'Peak Adult Emergence (Third Generation)',
      range: {
        min: 1743,
        max: 1982,
      },
      infos: [
        {
          name: 'Info',
          value: `Third generation adults have reached peak emergence. Generations may begin overlapping.`,
        },
      ],
    },
    {
      name: 'Peak Egg Laying (Third Generation)',
      range: {
        min: 1983,
        max: 2236,
      },
      infos: [
        {
          name: 'Info',
          value: `Third generation adult females have reached peak egg laying.`,
        },
      ],
    },
    {
      name: 'Peak Adult Emergence (Fourth Generation)',
      range: {
        min: 2237,
        max: 2476,
      },
      infos: [
        {
          name: 'Info',
          value: `Fourth generation adults have reached peak emergence. Generations may overlap.`,
        },
      ],
    },
    {
      name: 'Peak Egg Laying (Fourth Generation)',
      range: {
        min: 2477,
        max: 2730,
      },
      infos: [
        {
          name: 'Info',
          value: `Fourth generation adult females have reached peak egg laying.`,
        },
      ],
    },
    {
      name: 'Peak Adult Emergence (Fifth Generation)',
      range: {
        min: 2731,
        max: 2970,
      },
      infos: [
        {
          name: 'Info',
          value: `Fifth generation adults have reached peak emergence. Generations may overlap.`,
        },
      ],
    },
    {
      name: 'Peak Egg Laying (Fifth Generation)',
      range: {
        min: 2971,
        max: 3224,
      },
      infos: [
        {
          name: 'Info',
          value: `Fifth generation adult females have reached peak egg laying.`,
        },
      ],
    },
    {
      name: 'Peak Adult Emergence (Sixth Generation)',
      range: {
        min: 3225,
        max: 3464,
      },
      infos: [
        {
          name: 'Info',
          value: `Sixth generation adults have reached peak emergence. Generations may overlap.`,
        },
      ],
    },
    {
      name: 'Peak Egg Laying (Sixth Generation)',
      range: {
        min: 3465,
        max: 3718,
      },
      infos: [
        {
          name: 'Info',
          value: `Sixth generation adult females have reached peak egg laying.`,
        },
      ],
    },
    {
      name: 'Peak Adult Emergence (Seventh Generation)',
      range: {
        min: 3719,
      },
      infos: [
        {
          name: 'Info',
          value: `Seventh generation adults have reached peak emergence. Generations may overlap.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Spotted Wing Drosophila Growing Degree Day Model is used to track lifecycle stages of Spotted Wing Drosophila (Drosophila suzukii) for various crops. It may be used to help determine when to put out traps or when to treat with insecticides. Later in the season, different generations of Spotted Wing Drosophila may overlap, making the model less practical for use.`,
  ],
  citations: [
    `This model was developed and tested in Oregon by Len Coop and Amy Dreves at Integrated Plant Protection Center, Oregon State University as a hybridized version of the following models:`,
    `Kanzawa, T. 1939 Studies on Drosophila suzukii Mats.`,
    `Sakai, M. and Sato, R. 1996. Bionomics of Drosophila pulchrella (Diptera: Drosphilidae) Fukushima Fruit Tree Exper. Sta`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-spotted-wing-drosophila-growing-degree-day-model',
}
