import { GddModelDefine } from '../gdd'

export const gddWildOats: GddModelDefine = {
  lowerThreshold: 41,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddWildOats',
  name: 'Wild Oats',
  fullName: 'Wild Oats (Avena fatua) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Plant Emergence',
      range: {
        min: 0,
        max: 64,
      },
      infos: [
        {
          name: 'Coleoptyle',
          value: 'Coleoptyle has just broken through soil.',
        },
      ],
    },
    {
      name: 'Leaf 1',
      range: {
        min: 65,
        max: 156,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'First leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Leaf 2',
      range: {
        min: 157,
        max: 250,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Second leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Leaf 3',
      range: {
        min: 251,
        max: 352,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Third leaf has unfolded.',
        },
      ],
    },

    {
      name: 'Leaf 4',
      range: {
        min: 353,
        max: 453,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Fourth leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Leaf 5',
      range: {
        min: 454,
        max: 567,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Fifth leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Leaf 6',
      range: {
        min: 567,
        max: 689,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Sixth leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Leaf 7',
      range: {
        min: 690,
        max: 824,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Seventh leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Leaf 8',
      range: {
        min: 825,
        max: 970,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Eighth leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Flag Leaf',
      range: {
        min: 971,
        max: 1134,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Flag leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Flag Leaf Extension',
      range: {
        min: 1135,
        max: 1320,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Flag leaf has begun extension.',
        },
      ],
    },
    {
      name: 'Enlargement of Boot',
      range: {
        min: 1321,
        max: 1585,
      },
      infos: [
        {
          name: 'Boot',
          value: 'Boot has begun enlargement.',
        },
      ],
    },
    {
      name: 'Spike Emergence',
      range: {
        min: 1586,
        max: 2158,
      },
      infos: [
        {
          name: 'Spike',
          value: 'Spike has emerged.',
        },
      ],
    },
    {
      name: 'Elongation of Culm',
      range: {
        min: 2159,
        max: 2300,
      },
      infos: [
        {
          name: 'Culm',
          value: 'Culm has begun elongation.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Wild Oats Degree Day Model is used to predict harvest time and other growth stages of Wild Oats (Avena fatua) based on the weather in a given season.',
    'For best results, a biofix date should be set when plant is first emerged/ observed. For best results the biofix should be reset when later stages have been observed, in order to more accurately predict the next stage.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-wild-oats-growing-degree-day-model/',
  citations: [
    `Cudney, D. W., L. S. Jordan, C. J. Corbett, and W. E. Bendixen. 1989. Developmental rates of wild oats (Avena fatua) and Wheat (Triticum aestivum). Weed Science 37: 521-524.`,
  ],
}
