import { GddModelDefine } from '../gdd'

export const gddSilverYMoth: GddModelDefine = {
  lowerThreshold: 48,
  upperThreshold: 95,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddSilverYMoth',
  name: 'Silver Y Moth',
  fullName: 'Silver Y Moth (Autographa gamma) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence from Overwintering',
      range: {
        min: 0,
        max: 360,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have not emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Overwintering Generation',
      range: {
        min: 361,
        max: 582,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from overwintered larvae have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - Overwintering Generation',
      range: {
        min: 583,
        max: 780,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have emerged and are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Egg Hatch - First Generation',
      range: {
        min: 781,
        max: 1360,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - First Generation',
      range: {
        min: 1361,
        max: 1583,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from next generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - First Generation',
      range: {
        min: 1584,
        max: 1780,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from first generation larvae have emerged and are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Egg Hatch - Second Generation',
      range: {
        min: 1781,
        max: 2583,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Second generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - Second Generation',
      range: {
        min: 2584,
        max: 2780,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from second generation larvae have emerged and are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Egg Hatch - Third Generation',
      range: {
        min: 2781,
        max: 3781,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Third generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - Third Generation',
      range: {
        min: 3782,
        max: 4781,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from third generation larvae have emerged and are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - Fourth Generation',
      range: {
        min: 4782,
        max: 5000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from fourth generation larvae have emerged and are in peak flight.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Silver Y Moth Degree Day Model is used to predict development of life-cycle stages of Silver Y Moth (Autographa gamma), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults which can be targeted by pheromone traps.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-silver-y-moth-growing-degree-day-model/',
  citations: [
    'Honěk, A., V. Jarošík, Z. Martinková, and I. Novák. 2002. Food induced variation of thermal constants of development and growth of Autographa gamma (Lepidoptera: Noctuidae) larvae. European Journal of Entomology. 99:241-252',
  ],
}
