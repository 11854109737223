import { GddModelDefine } from '../gdd'

export const gddWalnutBlisterMite: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddWalnutBlisterMite',
  name: 'Walnut Blister Mite GDD',
  fullName: 'Walnut Blister Mite (Eriophyes erinea) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 363,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence',
      range: {
        min: 364,
        max: 707,
      },
      infos: [
        {
          name: 'Adults',
          value:
            'Adults from overwintered eggs have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Adult Emergence',
      range: {
        min: 708,
        max: 800,
      },
      infos: [
        {
          name: 'Adult',
          value:
            'Adults from overwintered eggs have fully emerged and treatment window has closed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Walnut Blister Mite Growing Degree Day Model is used to predict development of life-cycle stages of Walnut Blister Mite (Eriophyes erinea), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults and nymphs from overwintered eggs.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-walnut-blister-mite-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
