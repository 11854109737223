import { GddModelDefine } from '../gdd'

export const gddWalnutHuskFly: GddModelDefine = {
  lowerThreshold: 41,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddWalnutHuskFly',
  name: 'Walnut Husk Fly',
  fullName: 'Walnut Husk Fly (Rhagoletis completa) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adults Have Not Yet Emerged',
      range: {
        min: 0,
        max: 1890,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged from overwintered pupae.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Overwintering Generation',
      range: {
        min: 1891,
        max: 2219,
      },
      infos: [
        {
          name: 'Adults',
          value:
            'Adults from overwintered pupae have emerged but have not yet reached peak egg-laying.',
        },
      ],
    },
    {
      name: 'Mature Females - Overwintering Generation',
      range: {
        min: 2220,
        max: 2480,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adult females from overwintered pupae have reached maturity.',
        },
      ],
    },
    {
      name: 'First Oviposition / Egg-laying - Overwintering Generation',
      range: {
        min: 2481,
        max: 2700,
      },
      infos: [
        {
          name: 'Adults',
          value:
            'First adult females from overwintered pupae have reached maturity and have begun laying eggs.',
        },
      ],
    },
    {
      name: 'First Larvae  / Egg Hatch',
      range: {
        min: 2701,
        max: 2831,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have begun to emerge.',
        },
      ],
    },
    {
      name: '25% Adult Emergence',
      range: {
        min: 2832,
        max: 3152,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have reached 25% emergence.',
        },
      ],
    },
    {
      name: '50% Adult Emergence',
      range: {
        min: 3153,
        max: 3461,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have reached 50% emergence.',
        },
      ],
    },
    {
      name: 'First Larvae Exit Husks',
      range: {
        min: 3462,
        max: 3956,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have begun to exit husks.',
        },
      ],
    },
    {
      name: '100% Adult Emergence',
      range: {
        min: 3956,
        max: 4200,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have reached 100% emergence.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Walnut Husk Fly Degree Day Model is used to predict development of life-cycle stages of Walnut Husk Fly (Rhagoletis completa), in order to improve pest management decisions',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered pupae as well as following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-walnut-husk-fly-growing-degree-day-model/',
  citations: [
    'Kusana, A. & M. T. AliNiazee, 1997. A thermal summation model for the phenology of Rhagoletis completa  (Diptera: Tephritidae).  J. Entomol. Soc. Brit. Columbia 94: 13-18.',
  ],
}
