import { GddModelDefine } from '../gdd'

export const gddLygusBugSafflower: GddModelDefine = {
  lowerThreshold: 52,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddLygusBugSafflower',
  name: 'Lygus Bug (Safflower)',
  fullName: 'Lygus Bug (Lygus hesperus) on Safflower Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 668,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Nymphs',
      range: {
        min: 669,
        max: 800,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs have emerged from eggs. Optimum time for treatment',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Lygus Bug Degree Day Model is used to predict development of life-cycle stages of the Lygus Bug (Lygus hesperus), in order to improve pest management decisions',
    'For best results, a biofix date should be set to April 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of next generation of nymphs, which can be targeted for insecticide treatment.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-lygus-bug-growing-degree-day-model/',
  citations: [
    'Sevacherian, V., V. M. Stern, and A. J. Mueller. 1977. Heat accumulation for timing Lygus control measures in a safflower-cotton complex. J. Econ. Entomol. 70: 399-402.',
  ],
}
