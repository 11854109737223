import { GddModelDefine } from '../gdd'

export const gddJohnsongrass: GddModelDefine = {
  lowerThreshold: 15,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddJohnsongrass',
  name: 'Johnsongrass',
  fullName: 'Johnsongrass (Sorghum halepense) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Rhizome Sprout Not Yet Emerged',
      range: {
        min: 0,
        max: 190,
      },
      infos: [
        {
          name: 'Rhizome',
          value: 'Rhizome sprout has not yet emerged from soil.',
        },
      ],
    },
    {
      name: '25% Rhizome Sprout Emergence',
      range: {
        min: 191,
        max: 350,
      },
      infos: [
        {
          name: 'Rhizome',
          value: '25% Rhizome sprout has emergence from soil.',
        },
      ],
    },
    {
      name: '50% Rhizome Sprout Emergence',
      range: {
        min: 351,
        max: 580,
      },
      infos: [
        {
          name: 'Rhizome',
          value: '50% Rhizome sprout has emergence from soil.',
        },
      ],
    },
    {
      name: '75% Rhizome Sprout Emergence',
      range: {
        min: 581,
        max: 650,
      },
      infos: [
        {
          name: 'Rhizome',
          value: '75% Rhizome sprout has emergence from soil.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Johnsongrass Degree Day Model is used to predict rhizome sprout emergence of Johnsongrass (Sorghum halepense) based on the weather in a given season.',
    'For best results, a biofix date should be set to Jan. 1st for the Northern Hemisphere. The growing degree day model can then be used to predict rhizome sprout emergence.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-johnsongrass-growing-degree-day-model/',
  citations: [
    `Satorre, E.H., C.M. Ghersa, and A.M. Pataro. 1985. Prediction of Sorghum halepense (L.) Pers. rhizome sprout emergence in relation to air temperature. Weed Research 25:103-109.`,
  ],
}
