import axios, {
  AxiosInstance,
} from 'axios'
import {
  isAxiosApiError,
  ApiFullTypedPaths,
  ApiSchemaFullTypedPaths,
  RequestMethod,
  apiHeaders,
  ApiRequestOptions,
  RouteResponse,
  apiVersionPaths,
} from '@pest-prophet/shared'
import axiosRetry from 'axios-retry'
import qs from 'qs'
import { environment } from '../../environments/environment'
import { Me } from './me'

const apiVersion = 'internal' as const
type TApiVersion = typeof apiVersion

export class Api {

  private static client: AxiosInstance

  static init() {
    this.client = axios.create({
      baseURL: this.getBaseUrl(),
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
      paramsSerializer: qs.stringify,
    })
    axiosRetry(this.client, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: error => {
        return error.response == null || error.code === 'ERR_NETWORK'
      },
    })
  }

  static getBaseUrl() {
    const versionPath = apiVersionPaths[apiVersion]
    return `${environment.apiBaseUrl}${versionPath}`
  }

  static async request<
    TFullTypedPath extends ApiFullTypedPaths,
    TMethod extends keyof ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath] & RequestMethod,
    TResponseData extends RouteResponse<TApiVersion, TFullTypedPath, TMethod> = RouteResponse<TApiVersion, TFullTypedPath, TMethod>,
  >(
    path: TFullTypedPath,
    method: TMethod,
    ...options: ApiRequestOptions<TApiVersion, TFullTypedPath, TMethod>
  ): Promise<TResponseData> {
    const { body, query, retries } = (options[0] ?? {}) as {
      body?: unknown
      query?: unknown
      retries?: number
    }

    try {
      const { data } = await this.client.request({
        url: path as string,
        method,
        data: body,
        params: query,
        headers: {
          [apiHeaders.firebaseIdToken]: await Me.getFirebaseIdToken(),
        },
        ...(
          retries != null
            ? { 'axios-retry': { retries } }
            : {}
        ),
      })
      return data
    } catch (err) {
      if (isAxiosApiError(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors[0] ?? err
      }

      throw err
    }
  }

  static get<
    TFullTypedPath extends ApiFullTypedPaths,
    TMethod extends keyof ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath] & 'get',
  >(
    path: TFullTypedPath,
    ...options: ApiRequestOptions<TApiVersion, TFullTypedPath, TMethod>
  ) {
    return this.request<TFullTypedPath, TMethod>(path, 'get' as TMethod, ...options)
  }

  static post<
    TFullTypedPath extends ApiFullTypedPaths,
    TMethod extends keyof ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath] & 'post',
  >(
    path: TFullTypedPath,
    ...options: ApiRequestOptions<TApiVersion, TFullTypedPath, TMethod>
  ) {
    return this.request(path, 'post' as TMethod, ...options)
  }

  static put<
    TFullTypedPath extends ApiFullTypedPaths,
    TMethod extends keyof ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath] & 'put',
  >(
    path: TFullTypedPath,
    ...options: ApiRequestOptions<TApiVersion, TFullTypedPath, TMethod>
  ) {
    return this.request(path, 'put' as TMethod, ...options)
  }

  static patch<
    TFullTypedPath extends ApiFullTypedPaths,
    TMethod extends keyof ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath] & 'patch',
  >(
    path: TFullTypedPath,
    ...options: ApiRequestOptions<TApiVersion, TFullTypedPath, TMethod>
  ) {
    return this.request(path, 'patch' as TMethod, ...options)
  }

  static delete<
    TFullTypedPath extends ApiFullTypedPaths,
    TMethod extends keyof ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath] & 'delete',
  >(
    path: TFullTypedPath,
    ...options: ApiRequestOptions<TApiVersion, TFullTypedPath, TMethod>
  ) {
    return this.request(path, 'delete' as TMethod, ...options)
  }

}
