import {
  Component,
  Input,
  OnInit,
} from '@angular/core'
import { NavController } from '@ionic/angular'
import { LocalStorageService } from '../../services/local-storage.service'
import { Utility } from '../../utility/utility'
import { Tools } from '../../services/tools'
import { Me } from '../../services/me'

@Component({
  selector: 'upgrade-card',
  templateUrl: './upgrade-card.component.html',
  styleUrls: ['./upgrade-card.component.scss'],
})
export class UpgradeCardComponent implements OnInit {

  @Input() cardId!: string
  @Input() title!: string

  isFading = false
  isManuallyHidden = false

  constructor(
    private localStorage: LocalStorageService,
    private navCtrl: NavController,
  ) {}

  async ngOnInit() {
    if (!this.cardId) {
      throw new Error('A cardId attribute is required for upgrade cards!')
    }

    await this.refreshIsManuallyHidden()
  }

  isHidden() {
    return this.isManuallyHidden || Me.isSubscribed()
  }

  async refreshIsManuallyHidden() {
    this.isManuallyHidden = await this.localStorage.isUpgradeCardHidden(this.cardId)
  }

  get fadeTimeMS() {
    return 500
  }

  get rootStyle() {
    return {
      transition: `opacity ${this.fadeTimeMS}ms`,
      opacity: (this.isFading) ? '0' : '1',
    }
  }

  async handleHideClick() {
    if (this.isManuallyHidden) {
      throw new Error('Upgrade card is already hidden!')
    }

    if (this.isFading) {
      return
    }

    this.isFading = true
    await Utility.wait(this.fadeTimeMS)
    void this.localStorage.setUpgradeCardHidden(this.cardId, true)
    this.isManuallyHidden = true
  }

  async handleUpgradePlanClick() {
    if (Tools.currentPaymentPlatform === 'stripe') {
      await this.navCtrl.navigateForward('/account-settings/subscription-stripe/change')
    } else {
      await this.navCtrl.navigateForward('/account-settings/subscription-iap/change')
    }
  }

}
