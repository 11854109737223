import { GddModelDefine } from '../gdd'

export const gddOnionMaggot: GddModelDefine = {
  lowerThreshold: 4.4,
  upperThreshold: 50,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddOnionMaggot',
  name: 'Onion Maggot',
  fullName: 'Onion Maggot(Delia antiqua) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 50,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 51,
        max: 338,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged from eggs.',
        },
      ],
    },
    {
      name: 'Pupae',
      range: {
        min: 339,
        max: 643,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Pupation has begun.',
        },
        {
          name: 'Adults',
          value: 'Adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'Adults Pre-Egglaying',
      range: {
        min: 644,
        max: 746,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged but have not yet layed eggs, planting is now recommended.',
        },
      ],
    },
    {
      name: 'Adults Egglaying',
      range: {
        min: 747,
        max: 797,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emergedand have started to lay eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Onion Maggot Degree Day Model is used to predict development of life-cycle stages of the Onion Maggot (Delia (Hylemya) antiqua), in order to improve pest management decisions',
    'For best results, a biofix date should be set when soil is tilled, under the assumption egg-laying adults may be present. The growing degree day model can then be used to predict emergence of next generation of adults. Planting should be delayed until this stage is reached to avoid damage by feeding larvae.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-onion-maggot-growing-degree-day-model/',
  citations: [
    `Liu, H. J., F. L. McEwen and G. Ritcey. 1982. Forecasting events in the life cycle of the onion maggot, Hylema antiqua (Diptera: Anthomyiidae): Application to control schemes. Environ. Entomol. 11: 751-755.`,
  ],
}
