import { GddModelDefine } from '../gdd'

export const gddAsiaticRiceBorer: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 100,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddAsiaticRiceBorer',
  name: 'Asiatic Rice Borer',
  fullName: 'Asiatic Rice Borer (Chilo suppressalis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 135,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered larvae are beginning to emerge.',
        },
      ],
    },
    {
      name: 'First Overwintered Larvae Pupate',
      range: {
        min: 136,
        max: 360,
      },
      infos: [
        {
          name: 'Larve',
          value: 'Larvae have begun pupation.',
        },
      ],
    },
    {
      name: 'First Adult Trap Catch',
      range: {
        min: 361,
        max: 648,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are in flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak Adult Trap Catch',
      range: {
        min: 649,
        max: 1813,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are in peak flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'First F1 Adult Trap Catch',
      range: {
        min: 1814,
        max: 2101,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adult moths are in flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak F1 Adult Trap Catch',
      range: {
        min: 2102,
        max: 3265,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adult moths are in peak flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'First F2 Adult Trap Catch',
      range: {
        min: 3266,
        max: 3553,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adult moths are in flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak F2 Adult Trap Catch',
      range: {
        min: 3554,
        max: 4718,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adult moths are in peak flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'First F3 Adult Trap Catch',
      range: {
        min: 4719,
        max: 5006,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adult moths are in flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak F1 Adult Trap Catch',
      range: {
        min: 5007,
        max: 6170,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adult moths are in peak flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'First F4 Adult Trap Catch',
      range: {
        min: 6171,
        max: 6458,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fourth generation adult moths are in flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak F4 Adult Trap Catch',
      range: {
        min: 6459,
        max: 7000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fourth generation adult moths are in peak flight and egg-laying has begun.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Asiatic Rice Borer Degree Day Model is used to predict development of life-cycle stages of the Asiatic Rice Borer (Chilo suppressalis), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence and peak flight of adult moths from overwintered larvae and the following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-asiatic-rice-borer-growing-degree-day-model/',
  citations: [
    `Tsumuki, H, T. Take, K. Kanehisa, T. Saito, and Y.I. Chu. 1994. Effect of temperature on the development and voltinism of the rice stem borer, Chilo suppressalis (Lepidoptera: Pyralidae) in Taiwan. Eur. J. Entomol. 91: 477-479.`,
  ],
}
