import { GddModelDefine } from '../gdd'

export const gddPlumCurculio: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddPlumCurculio',
  name: 'Plum Curculio',
  fullName: 'Plum Curculio (Conotrachelus nenuphar) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adults Ovipositing',
      range: {
        min: 0,
        max: 308,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are in oviposition stage and may be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End of Oviposition',
      range: {
        min: 309,
        max: 500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are no longer in oviposition stage and treatment is no longer necessary',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Plum Curuclio Degree Day Model is used to predict life-stage developments of the Plum Curculio (Conotrachelus nenuphar), in order to improve pest management decisions.',
    'For best results, the biofix date should be set to the date of petal fall, which should coincide with the first treatment. The growing degree day model can then be used to predict timing when adult oviposition has ended and treatment is no longer necessary.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-plum-curculio-growing-degree-day-model/',
  citations: [
    'Baskerville, G.L. and Emin, P. 1969. Rapid Estimation of Heat Accumulation from Maximum and Minimum Temperatures. Ecology. 50: 514-517.',
    'Reissig, W.H., Nyrop, J.P., and Straub, R. 1998. Oviposition model for timing insecticide sprays against plum curculio (Coleoptera: Curculionidae) in New York State. Environ. Entomol. 27: 1053-1061.',
  ],
}
