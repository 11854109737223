import {
  powderyMildewModelCommon,
  PowderyMildewModelDefine,
} from './powdery-mildew'

export const powderyMildewGrape: PowderyMildewModelDefine = {
  ...powderyMildewModelCommon,
  lowerTempRiskThreshold: 20,
  upperTempRiskThreshold: 30,
  excessiveTempThreshold: 35,
  consecutiveRiskHoursRequired: 6,
  type: 'powderyMildewGrape',
  name: 'Grape Powdery Mildew',
  fullName: 'Gubler-Thomas Grape Powdery Mildew Risk Index Model',
  stages: [
    {
      name: 'Low',
      range: {
        min: 0,
        max: 30,
      },
      sprayLevel: 'low',
      infos: [
        {
          name: 'Biologicals',
          value: `10 Days *`,
        },
        {
          name: 'Sulfur Dust',
          value: `14 Days`,
        },
        {
          name: 'Micronized Sulfur',
          value: `18 Days`,
        },
        {
          name: 'Chemical Fungicides',
          value: `21 Days (or label max) **`,
        },
      ],
    },
    {
      name: 'Moderate',
      range: {
        min: 40,
        max: 50,
      },
      sprayLevel: 'medium',
      infos: [
        {
          name: 'Biologicals',
          value: `7 Days *`,
        },
        {
          name: 'Sulfur Dust',
          value: `10 Days`,
        },
        {
          name: 'Micronized Sulfur',
          value: `14 Days`,
        },
        {
          name: 'Chemical Fungicides',
          value: `17 Days (or label max) **`,
        },
      ],
    },
    {
      name: 'High',
      range: {
        min: 60,
        max: 100,
      },
      sprayLevel: 'high',
      infos: [
        {
          name: 'Biologicals',
          value: `Use not recommended *`,
        },
        {
          name: 'Sulfur Dust',
          value: `7 Days`,
        },
        {
          name: 'Micronized Sulfur',
          value: `10 Days`,
        },
        {
          name: 'Chemical Fungicides',
          value: `14 Days (or label min) **`,
        },
      ],
    },
  ],
  notes: [
    `* Biologicals may include Bacillus pumilis, Bacillus subtilis or system acquired resistance products.`,
    `** Recommended intervals for chemical fungicides refer to fungicides in the following classes: DMI (Demthylation-inhibitors), Strobilurins or Quinolines. For other fungicide classes consult label for recommended label interval.`,
  ],
  description: [
    `This model calculates a daily (0-100) risk index for Grape Powdery Mildew (Uncinula necator) infection based on optimum temperatures for reproduction at the conidial stage. Recommended use is to start program at bud-break and use the risk index to adjust intervals for preventative sprays.`,
    `Rotating sprays with different active ingredients is recommended to reduce risk of resistance. Always consult product label for necessary environmental conditions before spraying, as well as proper handling/safety precautions.`,
  ],
  citations: [
    `Thomas, C. S., Gubler, W. D., and Leavitt, G. 1994. Field testing of a powdery mildew disease forecast model on grapes in California. Phytopathology 84:1070 (abstr.).`,
    `Weber E., Gubler, D., and Derr, A. Powdery mildew controlled with fewer fungicide applications. Practical Winery & Vineyard, January/February 1996.`,
  ],
  moreInfoUrl: 'http://www.ipm.ucdavis.edu/DISEASE/DATABASE/grapepowderymildew.html',
}
