import { GddModelDefine } from '../gdd'

export const gddSevenspottedLadyBeetle: GddModelDefine = {
  lowerThreshold: 53.8,
  upperThreshold: 86,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddSevenspottedLadyBeetle',
  name: 'Sevenspotted Lady Beetle',
  fullName: 'Sevenspotted Lady Beetle (Coccinella septempunctata) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 60,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have been laid but have not yet hatched.',
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 61,
        max: 265,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Eggs have begun to hatch and larvae have emerged.',
        },
      ],
    },
    {
      name: 'Pupae',
      range: {
        min: 266,
        max: 357,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Larvae have entered pupae stage.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 358,
        max: 417,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Sevenspotted Lady Beetle Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Sevenspotted Lady Beetle (Coccinella septempunctata) in order to improve pest management practices.',
    'For best results, a biofix date should be set to when first eggs/ adults are observed or released. The growing degree day model can then be used to predict emergence of larvae, pupae and adults.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-sevenspotted-lady-beetle-growing-degree-day-model/',
  citations: [
    'Obrycki, J. J. and M. J. Tauber. 1981. Phenology of three Coccinellid species: thermal requirements for development. Ann. Ent. Soc. Amer. 74: 31-36.',
  ],
}
