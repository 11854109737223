import { GddModelDefine } from '../gdd'

export const gddMustardBAlba: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddMustardBAlba',
  name: 'Mustard (B. Alba)',
  fullName: 'Mustard (B. Alba) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Seed Planted',
      range: {
        min: 0,
        max: 260,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has not broken through soil.',
        },
      ],
    },
    {
      name: 'Shoot Break through soil.',
      range: {
        min: 261,
        max: 320,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has broken through the soil.',
        },
      ],
    },
    {
      name: 'First true leaf unfolds.',
      range: {
        min: 321,
        max: 350,
      },
      infos: [
        {
          name: 'First leaf.',
          value: 'First true leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Rosette Stage.',
      range: {
        min: 351,
        max: 900,
      },
      infos: [
        {
          name: 'Rosette.',
          value: 'Rosette stage with cotelydon and 2nd true leaves.',
        },
      ],
    },
    {
      name: 'First Inter-Node Detectable',
      range: {
        min: 901,
        max: 1480,
      },
      infos: [
        {
          name: 'Node',
          value: 'First visible inter-node has become detectable.',
        },
      ],
    },
    {
      name: 'Flower bud just visible (green bud).',
      range: {
        min: 1481,
        max: 1710,
      },
      infos: [
        {
          name: 'Flower bud',
          value: 'Green flower bud is just visible.',
        },
      ],
    },
    {
      name: 'First flower bud yellow.',
      range: {
        min: 1711,
        max: 1770,
      },
      infos: [
        {
          name: 'Flower bud',
          value: 'First flower bud is yellow but still closed.',
        },
      ],
    },
    {
      name: '10% Flowers Open / 10% Bloom.',
      range: {
        min: 1771,
        max: 2060,
      },
      infos: [
        {
          name: 'Flowers',
          value: '10% of flower buds open or 10% bloom.',
        },
      ],
    },
    {
      name: '10% of pods reach final size.',
      range: {
        min: 2061,
        max: 2350,
      },
      infos: [
        {
          name: 'Pods',
          value: '10% of pods have reached final size.',
        },
      ],
    },
    {
      name: '10% of seeds changed color.',
      range: {
        min: 2351,
        max: 2530,
      },
      infos: [
        {
          name: 'Seeds',
          value: '10% of seeds have changed color (any brown detectable).',
        },
      ],
    },
    {
      name: '25% Seed Moisture.',
      range: {
        min: 2531,
        max: 2670,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Seeds are 25% moisture and firm.',
        },
      ],
    },
    {
      name: 'Harvest',
      range: {
        min: 2671,
        max: 3000,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Thresh when seeds are 12% moisture or lower.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Mustard Degree Day Model is used to predict harvest time and other growth stages of mustard based on the weather in a given season.',
    'For best results, a biofix date should be set at planting. The growing degree day model can then be used to predict harvest time. For best results the biofix should be reset when later stages have been observed, in order to more accurately predict the next stage.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-mustard-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Stu Brandt, Scott, SK 1993-97 and Perry Miller, Swift Current, SK 1995-98`,
  ],
}
