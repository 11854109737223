import { WeatherHour } from '../../utils'
import { ModelUnitsName } from '../../utils/units.utils'
import { MathUtils } from '../../utils/math.utils'
import {
  Model,
  ModelDayCreateInput,
  WeatherDayDecrypted,
} from '../../utils/entity.utils'
import {
  ModelDefineBase,
  ModelGroupDefineBase,
  ModelStageBase,
} from '../model-base-types'

export class PowderyMildew {

  static calcModelDays(model: Model, define: PowderyMildewModelDefine, weatherDaysSorted: WeatherDayDecrypted[]): ModelDayCreateInput[] {
    const modelDays: ModelDayCreateInput[] = []
    let accumulatedIndex = 0

    for (const weatherDay of weatherDaysSorted) {
      accumulatedIndex += calcDayIndex(define, weatherDay)
      accumulatedIndex = MathUtils.clamp(accumulatedIndex, 0, 100)
      modelDays.push({
        date: weatherDay.date,
        isForecast: weatherDay.hours.some(x => x.isForecast),
        value: accumulatedIndex,
        modelId: model.id,
      })
    }

    return modelDays
  }

}

function calcDayIndex(define: PowderyMildewModelDefine, weatherDay: WeatherDayDecrypted) {
  /*
   Gubler-Thomas Powdery Mildew Model
  */
  let consecutiveHoursInRiskZone = 0
  let consecutiveRiskHoursMet = false
  let excessiveTempMet = false

  const hours = weatherDay.hours as WeatherHour[]
  for (const { temperature } of hours) {
    if (temperature > define.excessiveTempThreshold) {
      excessiveTempMet = true
    }

    if (
      temperature >= define.lowerTempRiskThreshold
      && temperature <= define.upperTempRiskThreshold
    ) {
      consecutiveHoursInRiskZone += 1
      if (consecutiveHoursInRiskZone >= define.consecutiveRiskHoursRequired) {
        consecutiveRiskHoursMet = true
      }
    } else {
      consecutiveHoursInRiskZone = 0
    }
  }

  if (consecutiveRiskHoursMet) {
    if (excessiveTempMet) {
      return 10
    }

    return 20
  }

  return -10
}

export const powderyMildewModelCommon: Pick<PowderyMildewModelDefine, 'group' | 'graphLayout'> = {
  group: 'powderyMildew',
  graphLayout: {
    yaxis: {
      range: [0, 100],
      dtick: 10,
      autotick: false,
      autorange: false,
      automargin: false,
    },
  },
}

export type PowderyMildewModelDefine = ModelDefineBase<PowderyMildewModelGroupDefine, PowderyMildewModelStage> & {
  lowerTempRiskThreshold: number
  upperTempRiskThreshold: number
  excessiveTempThreshold: number
  consecutiveRiskHoursRequired: number
}

export type PowderyMildewModelStage = ModelStageBase & {
  sprayLevel: SprayLevel
}

export const sprayLevels = [
  'low',
  'medium',
  'high',
] as const
export type SprayLevel = typeof sprayLevels[number]

export const powderyMildewModelGroupDefine: PowderyMildewModelGroupDefine = {
  name: 'powderyMildew',
  currentStageHeader: 'Current Risk Level',
  stagesHeader: 'Risk Levels',
  modelUnits: 'index',
  modelUnitsName: ModelUnitsName.universal('Index'),
  requiresBiofixDate: false,
  requiresBiofixStage: false,
}

export type PowderyMildewModelGroupDefine = ModelGroupDefineBase<'powderyMildew'>
