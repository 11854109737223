<ion-header>
  <ion-toolbar>
    <ion-title>Sign Up</ion-title>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="login"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form (submit)="createAccount()">
    <div class="section-header">
      <ion-icon name="mail-outline"></ion-icon>
      <span>Email & Password</span>
    </div>

    <ion-list>
      <ion-item>
        <ion-input
          type="email"
          name="email"
          label="Email *"
          label-placement="stacked"
          mode="ios"
          placeholder="What's your email address?"
          [(ngModel)]="inputs.email"
          maxlength="255"
          required
          fcEnterFocusNext
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          type="password"
          name="password"
          label="Password *"
          label-placement="stacked"
          mode="ios"
          placeholder="Choose a password (6 characters or more)"
          [(ngModel)]="inputs.password"
          maxlength="255"
          required
          fcEnterFocusNext
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          type="password"
          name="password-repeat"
          label="Repeat Password *"
          label-placement="stacked"
          mode="ios"
          placeholder="Repeat your password"
          [(ngModel)]="inputs.passwordRepeat"
          maxlength="255"
          required
          fcEnterFocusNext
        ></ion-input>
      </ion-item>
    </ion-list>

    <div class="section-header">
      <ion-icon name="person-outline"></ion-icon>
      <span>User Info</span>
    </div>

    <ion-list>
      <ion-item>
        <ion-input
          type="text"
          name="name"
          label="Full Name"
          label-placement="stacked"
          mode="ios"
          placeholder="What's your full name?"
          [(ngModel)]="inputs.name"
          maxlength="255"
          [autocapitalize]="true"
          fcEnterFocusNext
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          type="text"
          name="company"
          label="Company"
          label-placement="stacked"
          mode="ios"
          placeholder="What company do you work for?"
          [(ngModel)]="inputs.company"
          maxlength="255"
          [autocapitalize]="true"
          fcEnterFocusNext
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          type="text"
          name="job-title"
          label="Job Title"
          label-placement="stacked"
          mode="ios"
          placeholder="What's your job title?"
          [(ngModel)]="inputs.jobTitle"
          maxlength="255"
          [autocapitalize]="true"
          fcEnterFocusNext
        ></ion-input>
      </ion-item>
    </ion-list>

    <div class="section-header">
      <ion-icon name="phone-portrait-outline"></ion-icon>
      <span>Display</span>
    </div>

    <ion-list>
      <ion-item>
        <ion-label color="primary" position="stacked">Units *</ion-label>
        <ion-select
          [(ngModel)]="inputs.measurementUnits"
          name="units"
        >
          <ion-select-option
            *ngFor="let unitsName of measurementUnits"
            [value]="unitsName"
          >
            {{ measurementUnitsText[unitsName] }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label color="primary" position="stacked">Date Format *</ion-label>
        <ion-select
          [(ngModel)]="inputs.dateFormat"
          name="dateFormat"
          aria-labelledby="dateFormatLabel"
        >
          <ion-select-option
            *ngFor="let dateFormatName of dateFormats"
            [value]="dateFormatName"
          >
            {{ dateFormatsText[dateFormatName] }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <div class="section-header">
      <ion-icon name="help-circle-outline"></ion-icon>
      <span>Other Info</span>
    </div>

    <ion-list>
      <ion-item>
        <ion-label color="primary" position="stacked">Where did you hear about Pest Prophet?</ion-label>
        <ion-select
          [(ngModel)]="inputs.heardAboutUsFromSelected"
          name="heardAboutUsFrom"
        >
          <ion-select-option
            *ngFor="let heardAboutUsFromOption of heardAboutUsFromOptions"
            [value]="heardAboutUsFromOption"
          >
            {{ heardAboutUsFromOption }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item *ngIf="inputs.heardAboutUsFromSelected == 'Other'">
        <ion-input
          type="text"
          name="heardAboutUsFromOther"
          label="Other"
          label-placement="stacked"
          mode="ios"
          placeholder="Please specify"
          [(ngModel)]="inputs.heardAboutUsFromOtherValue"
          maxlength="255"
          [autocapitalize]="true"
          fcEnterFocusNext
        ></ion-input>
      </ion-item>
    </ion-list>

    <div class="mh-20 mv-40" layout="row">
      <div class="mr-10" layout="row" layout-align="center">
        <ion-checkbox name="agreeToTerms" [(ngModel)]="inputs.agreeToTerms"></ion-checkbox>
      </div>
      <div class="agreement-text">
        I agree to Pest Prophet's <a (click)="goToTerms()">Terms and Conditions</a> and
        <a (click)="goToPrivacyPolicy()">Privacy Policy</a>.
      </div>
    </div>

    <div class="m-20" layout="row" layout-align="center start">
      <ion-button type="submit" color="secondary" [disabled]="!inputs.agreeToTerms">Create Account</ion-button>
    </div>
  </form>
</ion-content>
