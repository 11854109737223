<ion-header>
  <ion-toolbar>
    <ion-title>Forgot Password</ion-title>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="login"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <page-info>
    Request a link to reset your password.
  </page-info>

  <ion-list>
    <ion-item>
      <ion-input
        type="email"
        name="email"
        label="Email"
        label-placement="stacked"
        mode="ios"
        placeholder="Enter email..."
        maxlength="256"
        [(ngModel)]="inputs.email"
        required
        fcEnterBlur
      ></ion-input>
    </ion-item>
  </ion-list>

  <div layout="row" layout-align="center start">
    <ion-button (click)="handleSendClick()" [disabled]="emailSent">
      Send
    </ion-button>
  </div>
</ion-content>
