import { GddModelDefine } from '../gdd'

export const gddMexicanBeanBeetle: GddModelDefine = {
  lowerThreshold: 11.5,
  upperThreshold: 50,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddMexicanBeanBeetle',
  name: 'Mexican Bean Beetle',
  fullName: 'Mexican Bean Beetle (Epilachna varivestis) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 68,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs are present but have not fully hatched.',
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 69,
        max: 269,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged.',
        },
      ],
    },
    {
      name: 'Pupae',
      range: {
        min: 270,
        max: 359,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Larvae have entered pupae stage.',
        },
      ],
    },
    {
      name: 'Adult',
      range: {
        min: 360,
        max: 460,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Mexican Bean Beetle Degree Day Model is used to predict development of the Mexican Bean Beetle (Epilachna varivestis), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adults or eggs are first observed. The emergence of larvae, pupae and adults can then be predicted..',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-mexican-bean-beetle-growing-degree-day-model/',
  citations: [
    `Cardenas, S. A., H. B. Mojica and R. R. Robles. 1978. Estudios bioecologicos de Epilachna varivestis Mulsant, bajo condiciones de laboratorio y campo. Agrocienca 34: 133-145.`,
  ],
}
