import {
  LocationJson,
  Model,
  ModelJson,
} from '@pest-prophet/shared'
import _ from 'lodash'
import { Me } from './me'
import { environment } from '../../environments/environment'

export class Locations {

  static getImgSrc(location: LocationJson) {
    const latLon = `${location.latitude},${location.longitude}`
    const { googleMapsApiKey } = environment
    return `https://maps.googleapis.com/maps/api/staticmap?center=${latLon}&zoom=17&size=600x300&maptype=satellite&markers=color:blue%7Clabel:%7C${latLon}&key=${googleMapsApiKey}`
  }

  static isLocationLocked(
    locations: LocationJson[],
    locationId: LocationJson['id'],
  ) {
    return _.chain(locations)
      .sortBy(location => location.createdAt)
      .findIndex(location => location.id === locationId)
      .gt(Me.getMaxLocations() - 1)
      .value()
  }

  static isModelLocked(models: ModelJson[], modelId: Model['id']) {
    switch (Me.getSubscriptionDefine().type) {
      case 'free':
        return _.chain(models)
          .sortBy(model => model.createdAt)
          .findIndex(model => model.id === modelId)
          .gt(0)
          .value()
      default:
        return false
    }
  }

  private static getWeatherUpdateErrorText(location: LocationJson) {
    switch (location.autoWeatherUpdateError) {
      case 'weatherServiceUnreachable':
      case 'httpDeadlineExceeded':
      case 'requestError':
        return `
          Weather data failed to download completely. The weather service may be experiencing a temporary outage.
          Sorry for the inconvenience.
        `
      case 'missingData':
        return `
          Not enough weather data was available in this location for an accurate risk assessment. Please try
          creating a location in a different location. Sorry for the inconvenience.
        `
      case 'unknownError':
        return `
          An error occurred. Please try again. If the error continues to occur, please report the issue to
          support@pestprophet.com and we'll get it fixed as soon as possible. Sorry for the inconvenience.
        `
      default:
        return ''
    }
  }

  static getLocationStatusText(location: LocationJson) {
    switch (location.status) {
      case 'error':
        return Locations.getWeatherUpdateErrorText(location)
      default:
        return ''
    }
  }

}
