import { GddModelDefine } from '../gdd'

export const gddTomatoMonica: GddModelDefine = {
  lowerThreshold: 45,
  upperThreshold: 92,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddTomatoMonica',
  name: 'Tomato (Monica)',
  fullName: 'Tomato (Monica) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Transplanting',
      range: {
        min: 0,
        max: 600,
      },
      infos: [
        {
          name: 'Transplanting',
          value: 'After planting, fruit set has not yet occurred.',
        },
      ],
    },
    {
      name: 'First Flower on Most Plants',
      range: {
        min: 601,
        max: 1091,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'First flower on most plants.',
        },
      ],
    },
    {
      name: 'Second Week Fruit Expansion',
      range: {
        min: 1092,
        max: 1976,
      },
      infos: [
        {
          name: 'Fruit',
          value: 'Fruit in 2nd week of expansion.',
        },
      ],
    },
    {
      name: 'Early Harvest',
      range: {
        min: 1977,
        max: 2200,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Harvest has begun (median plant with 4 ripe fruit).',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Tomato Degree Day Model is used to predict harvest time of tomato based on the weather in a given season.',
    'For best results, a biofix date should be set at time of transplanting. The growing degree day model can then be used to predict harvest time.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-tomato-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Stoven, et al. 2019`,
  ],
}
