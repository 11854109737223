import {
  UserJson,
} from '@pest-prophet/shared'

export class UserUtils {

  static getDefault(): UserJson {
    return {
      createdAt: '',
      updatedAt: '',
      id: -1,
      firebaseUid: '',
      email: '',
      name: null,
      company: null,
      jobTitle: null,
      measurementUnits: 'metric',
      dateFormat: 'usa',
      analyticsEnabled: false,
      emailEnabledDailyReport: false,
      emailEnabledWeeklyReport: true,
      emailEnabledMonthlyReport: true,
      emailEnabledNewsletter: true,
      stripeCustomerId: null,
      stripeSubscription: null,
      iapSubscription: null,
      heardAboutUsFrom: null,
    }
  }

}
