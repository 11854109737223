import { GddModelDefine } from '../gdd'

export const gddSpottedTentiformLeafminer: GddModelDefine = {
  lowerThreshold: 44.1,
  upperThreshold: 86,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddSpottedTentiformLeafminer',
  name: 'Spotted Tentiform Leafminer',
  fullName: 'Spotted Tentiform Leafminer (Phyllonorycter blancardella) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'First Adult Emergence',
      range: {
        min: 0,
        max: 760,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have emerged.',
        },
      ],
    },
    {
      name: '2nd Generation Adult Emergence',
      range: {
        min: 761,
        max: 1247,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have emerged.',
        },
      ],
    },
    {
      name: '3rd Generation Adult Emergence',
      range: {
        min: 1248,
        max: 1400,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Spotted Tentiform Leafminer Degree Day Model is used to predict development of life-cycle stages of Spotted Tentiform Leafminer (Phyllonorycter blancardella), in order to improve pest management decisions',
    'For best results, a biofix date should be set when first adults are caught in traps, or observed. The growing degree day model can then be used to predict emergence of next generation of adults. For best results, the biofix should be re-set for each subsequent generation.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-spotted-tentiform-leafminer-growing-degree-day-model/',
  citations: [
    'Rock, G. C., R. E. Stinner, J. E. Bacheler, L. A. Hull, and H. W. Hogmire, Jr. 1993. Predicting geographical and within-season variation in male flights of four fruit pests. Environ. Entomol. 22: 716-725.',
  ],
}
