import { GddModelDefine } from '../gdd'

export const gddTaxusMealybug: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddTaxusMealybug',
  name: 'Taxus Mealybug GDD',
  fullName: 'Taxus Mealybug (Dysmicoccus wistariae) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Before Dormant Treatment',
      range: {
        min: 0,
        max: 7,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Overwintered nymphs not yet ready for treatment.',
        },
      ],
    },
    {
      name: 'Dormant Treatment',
      range: {
        min: 8,
        max: 91,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Overwintered nymphs may be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Dormant Treatment',
      range: {
        min: 92,
        max: 246,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'Overwintered Nymph Emergence',
      range: {
        min: 247,
        max: 363,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Overwintered nymphs have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Nymph Emergence',
      range: {
        min: 364,
        max: 500,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Overwintered nymphs have fully emerged and treatment window has closed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Taxus Mealybug Growing Degree Day Model is used to predict development of life-cycle stages of Taxus Mealybug (Dysmicoccus wistariae), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to time dormant treatment and predict emergence of overwintered nymphs.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-taxus-mealy-bug-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
