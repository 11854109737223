import { GddModelDefine } from '../gdd'

export const gddArtichokePlumeMoth: GddModelDefine = {
  lowerThreshold: 44.6,
  upperThreshold: 77,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddArtichokePlumeMoth',
  name: 'Artichoke Plume Moth',
  fullName: 'Artichoke Plume Moth (Platyptilia carduidactyla) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adult Moth Flight',
      range: {
        min: 0,
        max: 142,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'First Generation Larvae',
      range: {
        min: 143,
        max: 624,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Peak of first generation larvae.`,
        },
      ],
    },
    {
      name: 'First Generation Pupae',
      range: {
        min: 625,
        max: 857,
      },
      infos: [
        {
          name: 'Pupae',
          value: `Peak of first generation pupae.`,
        },
      ],
    },
    {
      name: 'First Generation Adult Moth Flight',
      range: {
        min: 858,
        max: 980,
      },
      infos: [
        {
          name: 'Adults',
          value: `First generation adult moths in flight.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Artichoke Plume Moth Growing Degree Day Model is used to predict life-stage developments of the Artichoke Plume Moth (Platyptilia carduidactyla) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Bari, M. A., and W. H. Lange. 1980. Influence of temperature on the development, fecundity and longevity of the artichoke plume moth. Environ. Entomol. 9: 673-676.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-artchoke-plume-moth-growing-degree-day-model',
}
