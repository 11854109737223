import { GddModelDefine } from '../gdd'

export const gddRhododendronGallMidge: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddRhododendronGallMidge',
  name: 'Rhododendron Gall Midge GDD',
  fullName: 'Rhododendron Gall Midge (Clinodiplosis rhododendri) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Larvae Emergence',
      range: {
        min: 0,
        max: 192,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Larve Emergence',
      range: {
        min: 193,
        max: 363,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First larvae have begun to emerge and may be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Larvae Emergence',
      range: {
        min: 364,
        max: 400,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have fully emerged and the window for treatment has closed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Rhododendron Gall Midge Growing Degree Day Model is used to predict development of life-cycle stages of Rhododendron Gall Midge (Clinodiplosis rhododendri), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-rhododendron-gall-midge-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
