import { GddModelDefine } from '../gdd'

export const gddSerpentineFruitFlyChicozapote: GddModelDefine = {
  lowerThreshold: 10.2,
  upperThreshold: 30,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddSerpentineFruitFlyChicozapote',
  name: 'Serpentine Fruit Fly (Chicozapote)',
  fullName: 'Serpentine Fruit Fly (Anastrepha serpentina) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 67,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 68,
        max: 278,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged from eggs.',
        },
      ],
    },
    {
      name: 'Pupae',
      range: {
        min: 279,
        max: 534,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Flies are in pupae stage, adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 535,
        max: 635,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Serpentine Fruit Fly Degree Day Model is used to predict development of life-cycle stages of the Serpentine Fruit Fly (Anastrepha serpentina), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adult flies are first observed. The growing degree day model can then be used to predict emergence of next generation of adults.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-serpentine-fruit-fly-growing-degree-day-model/',
  citations: [
    `Shaw, J. G., and D. F. Starr. 1946. Development of the immature stages of Anastrepha serpentina in relation to temperature. Journal of Agricultural Research 72: 265-276.`,
  ],
}
