import { GddModelDefine } from '../gdd'

export const gddPepperKingArthur: GddModelDefine = {
  lowerThreshold: 52,
  upperThreshold: 100,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddPepperKingArthur',
  name: 'Pepper (King Arthur)',
  fullName: 'Pepper (King Arthur) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Transplanting',
      range: {
        min: 0,
        max: 608,
      },
      infos: [
        {
          name: 'Transplanting',
          value: 'After planting, fruit set has not yet occurred.',
        },
      ],
    },
    {
      name: 'Fruit Set',
      range: {
        min: 609,
        max: 1321,
      },
      infos: [
        {
          name: 'Fruit',
          value: 'Fruit set has just occurred.',
        },
      ],
    },
    {
      name: 'First Green Harvest',
      range: {
        min: 1322,
        max: 1767,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'First green harvest.',
        },
      ],
    },
    {
      name: 'First Ripe Harvest',
      range: {
        min: 1768,
        max: 2000,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Harvest has begun (median plant with 4 ripe color peppers).',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pepper Degree Day Model is used to predict harvest time of pepper based on the weather in a given season.',
    'For best results, a biofix date should be set at time of transplanting. The growing degree day model can then be used to predict harvest time.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-pepper-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Stoven, et al. 2017`,
  ],
}
