import { GddModelDefine } from '../gdd'

export const gddBirchLeafminer: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddBirchLeafminer',
  name: 'Birch Leafminer',
  fullName: 'Birch Leafminer (Fenusa pusilla) Growing Degree Day Model',
  stages: [
    {
      name: 'No Adults Present',
      range: {
        min: 0,
        max: 175,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adult moths have not yet emerged.',
        },
      ],
    },
    {
      name: 'Overwintered Adults Emerge',
      range: {
        min: 176,
        max: 275,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adult moths may be present.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has not yet begun.',
        },
        {
          name: 'Larvae',
          value: 'Leaf mining larvae are not yet present.',
        },
      ],
    },
    {
      name: 'Egg Laying',
      range: {
        min: 276,
        max: 375,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adult moths may be present.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
        {
          name: 'Larvae',
          value: 'Leaf mining larvae are not yet present.',
        },
      ],
    },
    {
      name: 'First Generation Larvae',
      range: {
        min: 376,
        max: 600,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding in leaf mines.',
        },
      ],
    },
    {
      name: 'First Generation Adults',
      range: {
        min: 601,
        max: 700,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths may be present.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
        {
          name: 'Larvae',
          value: 'Second generation Leaf mining larvae are not yet present.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Birch Leafminer Degree Day Model is used to predict life-stage developments of the Birch Leafminer (Fenusa pusilla), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict egg hatch and emergence of larvae which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-birch-leafminer-growing-degree-day-model/',
  citations: [
    'Sadof, C.S. 1994. Recommendations for managing insects and mites on shade trees and shrubs. Purdue University, Dept. of Entomology publication E-41. West Lafayette, IN. 117 p.',
  ],
}
