import { GddModelDefine } from '../gdd'

export const gddLocustBorer: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddLocustBorer',
  name: 'Locust Borer',
  fullName: 'Locust Borer (Magacyllene robiniae) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adults have not yet Emerged',
      range: {
        min: 0,
        max: 2271,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged from overwintered larvae.',
        },
      ],
    },
    {
      name: 'Beginning of Adult Emergence',
      range: {
        min: 2272,
        max: 2805,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have begun to emerge from overwintered larvae.',
        },
      ],
    },
    {
      name: 'End of Adult Emergence',
      range: {
        min: 2806,
        max: 3000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged from overwintered larvae.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Locust Borer Degree Day Model is used to predict development of life-cycle stages of the Locust Borer (Magacyllene robiniae), in order to improve pest management decisions',
    'For best results, a biofix date should be set as January 1st for the Northern Hemisphere to predict overwintering emergence.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-locust-borer-growing-degree-day-model/',
  citations: [
    'Kowalsick, Thomas, and Scott Clark. 2006. Using Growing Degree Days for Insect Pest Management. Cornell Cooperative Extension. 2006.',
  ],
}
