import { GddModelDefine } from '../gdd'

export const gddConvergentLadyBeetle: GddModelDefine = {
  lowerThreshold: 48.2,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddConvergentLadyBeetle',
  name: 'Convergent Lady Beetle',
  fullName: 'Convergent Lady Beetle (Hippodamia convergens) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 83,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have been laid but have not yet hatched.',
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 84,
        max: 459,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Eggs have begun to hatch and larvae have emerged.',
        },
      ],
    },
    {
      name: 'Pre-Pupae',
      range: {
        min: 460,
        max: 496,
      },
      infos: [
        {
          name: 'Pre-pupae',
          value: 'Larvae have entered pre-pupae stage.',
        },
      ],
    },
    {
      name: 'Pupae',
      range: {
        min: 497,
        max: 621,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Larvae have entered pupae stage.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 622,
        max: 700,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Convergent Lady Beetle Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Convergent Lady Beetle (Hippodamia convergens) in order to improve pest management practices.',
    'For best results, a biofix date should be set to when first eggs/ adults are observed or released. The growing degree day model can then be used to predict emergence of larvae, pupae and adults.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-convergent-lady-beetle-growing-degree-day-model/',
  citations: [
    'Butler, G. D., Jr. and W. A. Dickerson. 1972. Life cycle of the convergent lady beetle in relation to temperature. J. Econ. Entomol. 65: 1508-1509.',
  ],
}
