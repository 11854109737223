import { GddModelDefine } from '../gdd'

export const gddRedbandedLeafroller: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddRedbandedLeafroller',
  name: 'Redbanded Leafroller GDD',
  fullName: 'Redbanded Leafroller (Argyrotaenia velutinana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence',
      range: {
        min: 0,
        max: 298,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Generation Adult Emergence',
      range: {
        min: 299,
        max: 618,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have begun to emerge from overwintered pupae.',
        },
      ],
    },
    {
      name: 'End of First Generation Adult Emergence',
      range: {
        min: 619,
        max: 800,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Redbanded Leafroller Growing Degree Day Model is used to predict development of life-cycle stages of Redbanded Leafroller (Argyrotaenia velutinana), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered larvae which can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-redbanded-leafroller-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
