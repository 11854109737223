import { tomCastModelCommon, TomCastModelDefine } from './tom-cast'

export const tomCastPotatoEarlyBlight: TomCastModelDefine = {
  ...tomCastModelCommon,
  type: 'tomCastPotatoEarlyBlight',
  name: 'Modified TomCast Potato Early Blight',
  fullName: 'Modified TomCast Potato Early Blight Model',
  stages: [
    {
      name: 'Treatment Not Recommended',
      range: {
        min: 0,
        max: 9,
      },
      infos: [
        {
          name: 'Potato Early Blight',
          value: `Risk of infection is low and treatment is not yet recommended.`,
        },
      ],
    },
    {
      name: 'Treatment Recommended',
      range: {
        min: 10,
      },
      infos: [
        {
          name: 'Potato Early Blight',
          value: `Risk of infection is high and preventative treatment is recommended.`,
        },
      ],
    },
  ],
  notes: [
    'Plant disease risk models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'Potato Early Blight is caused by the fungus Alternaria solani, and it can be very difficult to control once the disease has been established. Preventative spray treatments are recommend to prevent infection.',
    'The Potato Early Blight risk model, modified from the Tomcast model, is used to determine interval length between spray treatments. The model calculates daily DSV (Disease Severity Value) based on temperature and leaf wetness duration. When 15-20 DSVs have accumulated, treatment is recommended.',
    'Model should be first used immediately following planting. First spray should be after 10-15 DSVs have accumulated, ( or 25 DSV if planted before May 20), or June 15 if DSV threshold has not been met (Dates for northen hemisphere).',
    'The model should be reset after each spray, in order to time the subsequent treatment. ',
  ],
  citations: [
    'Pitblado, R. E. 1992. The development and implementation of TOM-CAST: A weather-timed fungicide spray program for field tomatoes. Ministry of Agriculture and Food, Ontario, Canada.',
    'Madden L., Pennypacker, S. P., and McNab, A. A. 1978. FAST, a forecast system for Alternaria solani on tomato. Phytopathology 68:1354-1358.',
    'Meto, et al. 2020. Modification of the TOMCAST Model with Aerobiological Data for Management of Potato Early Blight. Agronomy 2020, 10(12), 1872',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-potato-early-blight-risk-model/',
  outputMatrix: {
    axisRanges: {
      x: { min: 10, max: 29 }, // Mean Temperature
      y: { min: 0, max: 23 }, // Leaf Wetness Hours
    },
    outputs: [
      /*
       10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 */
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 0
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 1
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 2
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0], // 3
      [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], // 4
      [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], // 5
      [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 2, 2, 2, 2, 2, 1, 1, 1, 1], // 6
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 1, 1, 1, 1], // 7
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 1, 1, 1, 1], // 8
      [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2], // 9
      [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2], // 10
      [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2], // 11
      [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2], // 12
      [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3, 3, 2, 2, 2, 2], // 13
      [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3, 3, 2, 2, 2, 2], // 14
      [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3, 3, 2, 2, 2, 2], // 15
      [1, 1, 1, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], // 16
      [1, 1, 1, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], // 17
      [1, 1, 1, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], // 18
      [1, 1, 1, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], // 19
      [1, 1, 1, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], // 20
      [1, 1, 2, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 3, 3, 3, 3], // 21
      [1, 1, 2, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 3, 3, 3, 3], // 22
      [1, 1, 2, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4], // 23
    ],
  },
}
