export class StringUtils {

  static formatNumber(number: number, fractionDigits = 0) {
    const re = `\\d(?=(\\d{3})+${fractionDigits > 0 ? '\\.' : '$'})`
    // eslint-disable-next-line no-bitwise
    return number.toFixed(Math.max(0, ~~fractionDigits)).replace(new RegExp(re, 'g'), '$&,')
  }

  static formatCurrency(value: number, trimZeroCents: boolean = false) {
    if (trimZeroCents) {
      const isInt = (value % 1) === 0
      if (isInt) {
        return `$${this.formatNumber(value)}`
      }
    }

    return `$${this.formatNumber(value, 2)}`
  }

}
