import { GddModelDefine } from '../gdd'

export const gddAmericanSerpentineLeafminerChrysanthemums: GddModelDefine = {
  lowerThreshold: 10.6,
  upperThreshold: 50,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddAmericanSerpentineLeafminerChrysanthemums',
  name: 'American Serpentine Leafminer (Chrysanthemums)',
  fullName:
    'American Serpentine Leafminer (Liriomyza trifolii) on ChrysanthemumsGrowing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Adults / Eggs  Observed',
      range: {
        min: 0,
        max: 140,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have been observed and eggs are present.',
        },
        {
          name: 'Larvae',
          value: 'Eggs may have hatched and larvae may be present.',
        },
      ],
    },
    {
      name: 'Pupation',
      range: {
        min: 141,
        max: 282,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Pupation has begun.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 283,
        max: 350,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The American Serpentine Leafminer Degree Day Model is used to predict development of life-cycle stages of American Serpentine Leafminer (Liriomyza trifolii), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adults or eggs are first observed in the field. The growing degree day model can then be used to predict emergence of larvae from leaves, pupation and next generation of adults.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-american-serpentine-leafminer-growing-degree-day-model/',
  citations: [
    'Miller, G. W., and M. B. Isger. 1985. Effects of temperature on the development of Liriomyza trifolii (Burgess) (Diptera: Agromyzidae). Bull. Ent. Res. 75: 321-328.',
  ],
}
