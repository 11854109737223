import { powderyMildewModelCommon, PowderyMildewModelDefine } from './powdery-mildew'

export const powderyMildewHops: PowderyMildewModelDefine = {
  ...powderyMildewModelCommon,
  lowerTempRiskThreshold: 16,
  upperTempRiskThreshold: 27,
  excessiveTempThreshold: 30,
  consecutiveRiskHoursRequired: 6,
  type: 'powderyMildewHops',
  name: 'Hops Powdery Mildew',
  fullName: 'Adapted Gubler-Thomas Hops Powdery Mildew Risk Index Model',
  stages: [
    {
      name: 'Low',
      range: {
        min: 0,
        max: 30,
      },
      sprayLevel: 'low',
      infos: [
        {
          name: 'Biologicals',
          value: `See Label **`,
        },
        {
          name: 'Copper, Sulfur',
          value: `14 Days **`,
        },
        {
          name: 'Biocarbonates',
          value: `10 Days **`,
        },
        {
          name: 'DMI Fungicides *',
          value: `18 Days **`,
        },
        {
          name: 'Oils',
          value: `14 Days **`,
        },
        {
          name: 'Strobilurins',
          value: `14 Days`,
        },
      ],
    },
    {
      name: 'Moderate',
      range: {
        min: 40,
        max: 60,
      },
      sprayLevel: 'medium',
      infos: [
        {
          name: 'Biologicals',
          value: `See Label`,
        },
        {
          name: 'Copper, Sulfur',
          value: `10 Days`,
        },
        {
          name: 'Biocarbonates',
          value: `8 Days`,
        },
        {
          name: 'DMI Fungicides *',
          value: `10 Days`,
        },
        {
          name: 'Oils',
          value: `10 Days`,
        },
        {
          name: 'Strobilurins',
          value: `14 Days`,
        },
      ],
    },
    {
      name: 'High',
      range: {
        min: 70,
        max: 100,
      },
      sprayLevel: 'high',
      infos: [
        {
          name: 'Biologicals',
          value: `See Label ***`,
        },
        {
          name: 'Copper, Sulfur',
          value: `7 Days ***`,
        },
        {
          name: 'Biocarbonates',
          value: `7 Days ***`,
        },
        {
          name: 'DMI Fungicides *',
          value: `10 Days ***`,
        },
        {
          name: 'Oils',
          value: `7 Days ***`,
        },
        {
          name: 'Strobilurins',
          value: `7 Days ***`,
        },
      ],
    },
  ],
  notes: [
    `* Demethylation inhibitors such as tebuconazole or myclobutanil.`,
    `** or labeled maximum`,
    `*** or labeled minimum`,
    `Legal uses of pesticides are constantly changing, therefore always obtain and review a label prior to any using any product.`,
  ],
  description: [
    `This model calculates a daily (0-100) risk index for Hops Powdery Mildew (Podosphaera macularis) infection based on optimum temperatures for reproduction at the conidial stage. The model starts for the season either when 15 to 30 cm of growth from 50% of the plants is observed at bud break or when 15 to 30 cm of regrowth is observed after spring pruning.`,
  ],
  citations: [
    `Mahaffee, W. F., Thomas, C. S., Turechek, W. W., Ocamb, C. M., Nelson, M. E., Fox, A. Gubler, W. D. 2003. Responding to an introduced pathogen: Podosphaera macularis (hop powdery mildew) in the Pacific Northwest. Online. Plant Health Progress doi:10.1094/PHP-2003-1113-07-RV.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-powdery-mildew-risk-index-model-on-hops',
}
