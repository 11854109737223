import { GddModelDefine } from '../gdd'

export const gddPearScab: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddPearScab',
  name: 'Pear Scab',
  fullName: 'Pear Scab (Venturia pirina) Ascospore Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Bud Swell Stage',
      range: {
        min: 0,
        max: 10,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: '0% Ascospore Maturation.',
        },
      ],
    },
    {
      name: 'Ascospore Maturation - Beginning of Pear Scab Infection',
      range: {
        min: 11,
        max: 800,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: 'Ascospore Maturation has begun.',
        },
      ],
    },
    {
      name: 'Approximately 50% Ascospore Maturation',
      range: {
        min: 801,
        max: 1620,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: 'Approximately 50% Ascospore Maturation.',
        },
      ],
    },
    {
      name: '100% Ascospore Maturation',
      range: {
        min: 1621,
        max: 1680,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: '100% Ascospore Maturation.',
        },
      ],
    },
    {
      name: 'End of infection season.',
      range: {
        min: 1680,
        max: 1800,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: 'Ascopores have discharged remaing spores.',
        },
      ],
    },
  ],
  notes: [
    'Disease maturation stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pear Scab Degree Day Model is used to predict the ascopore maturation of the Apple Scab fungus, in order to improve pest management decisions.',
    'For best results, a biofix date should be set at bud swell stage . The growing degree day model can then be used to predict ascospore maturation.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-pear-scab-growing-degree-day-model/',
  citations: [
    `Spotts, R. A., Cervantes, L. A., and Niederholzer, F. J. A. 2000. Pear scab: Components of potential ascospore dose and validation of an ascospore maturity model. Plant Dis. 84:681-683.`,
  ],
}
