import { GddModelDefine } from '../gdd'

export const gddDownyBrome: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddDownyBrome',
  name: 'Downy Brome',
  fullName: 'Downy Brome (Bromus tectorum) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence',
      range: {
        min: 0,
        max: 1440,
      },
      infos: [
        {
          name: 'Seedhead',
          value: 'Panicle (seedhead) has not yet emerged.',
        },
      ],
    },
    {
      name: 'Seedhead emergence.',
      range: {
        min: 1441,
        max: 1800,
      },
      infos: [
        {
          name: 'Seedhead',
          value: 'Panicle (seedhead) has emerged.',
        },
      ],
    },
    {
      name: 'Beginning of seed production.',
      range: {
        min: 1801,
        max: 2430,
      },
      infos: [
        {
          name: 'Seeds.',
          value: 'Seed production has begun.',
        },
      ],
    },
    {
      name: 'Physiological Maturity',
      range: {
        min: 2431,
        max: 2700,
      },
      infos: [
        {
          name: 'Physiological Maturity.',
          value: 'Plant has reached physiological maturity.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Downy Brome Degree Day Model is used to predict emergence, seed production, and other growth stages of Downy Brome based on the weather in a given season.',
    'For best results, a biofix date should be set at January 1st for the Northern Hemispehere. For best results the biofix should be reset when later stages have been observed, in order to more accurately predict the next stage.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-downy-brome-growing-degree-day-model/',
  citations: [
    `Ball, et al. Predicting timing of downy brome (Bromus tectorum) seed production using growing degree days.Weed Science 52, July–August 2004Weed Science, 52:518–524. 2004`,
  ],
}
