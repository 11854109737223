import { GddModelDefine } from '../gdd'

export const gddBalsamTwigAphid: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddBalsamTwigAphid',
  name: 'Balsam Twig Aphid',
  fullName: 'Balsam Twig Aphid (Mindarus abietinus) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Egg hatch has not occured.',
      range: {
        min: 0,
        max: 60,
      },
      infos: [
        {
          name: 'Adults',
          value: 'No adult stem mothers present.',
        },
        {
          name: 'Eggs',
          value: 'Eggs have not yet hatched.',
        },
      ],
    },
    {
      name: 'Egg Hatch',
      range: {
        min: 61,
        max: 100,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult stem mothers have begun to emerge.',
        },
        {
          name: 'Eggs',
          value: 'Egg-hatching has begun.',
        },
      ],
    },
    {
      name: 'Stem Mothers Present',
      range: {
        min: 101,
        max: 140,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Aphid stem mothers are present.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Balsam Twig Aphid Degree Day Model is used to predict life-stage developments of the Balsam Twig Aphid (Mindarus abietinus), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of aphid stem mothers which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-balsam-twig-aphid-growing-degree-day-model/',
  citations: [
    'Fondren, K. and D.G. McCullough. 2002. Biology and management of balsam twig aphid. MSU Extension bulletin E-2813. 4 p.',
  ],
}
