import { GddModelDefine } from '../gdd'

export const gddOrientalFruitMoth: GddModelDefine = {
  lowerThreshold: 45,
  upperThreshold: 90,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddOrientalFruitMoth',
  name: 'Oriental Fruit Moth',
  fullName: 'Oriental Fruit Moth (Grapholita molesta) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adult Moth Flight',
      range: {
        min: 0,
        max: 28,
      },
      infos: [
        {
          name: 'Adults',
          value: `Peak of overwintered adult moths in flight.`,
        },
      ],
    },
    {
      name: 'First Generation Egg Laying',
      range: {
        min: 29,
        max: 200,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Peak of first generation egg laying.`,
        },
      ],
    },
    {
      name: 'First Generation Egg Hatch',
      range: {
        min: 201,
        max: 395,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Peak of first generation egg hatch.`,
        },
      ],
    },
    {
      name: 'First Generation Larvae',
      range: {
        min: 396,
        max: 587,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Peak of first generation larvae.`,
        },
      ],
    },
    {
      name: 'First Generation Pupae',
      range: {
        min: 588,
        max: 965,
      },
      infos: [
        {
          name: 'Pupae',
          value: `Peak of first generation pupae.`,
        },
      ],
    },
    {
      name: 'First Generation Adult Moth Flight',
      range: {
        min: 966,
        max: 993,
      },
      infos: [
        {
          name: 'Adults',
          value: `First generation adult moths in flight.`,
        },
      ],
    },
    {
      name: 'Second Generation Egg Laying',
      range: {
        min: 994,
        max: 1165,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Peak of second generation egg laying.`,
        },
      ],
    },
    {
      name: 'Second Generation Egg Hatch',
      range: {
        min: 1166,
        max: 1360,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Peak of second generation egg hatch.`,
        },
      ],
    },
    {
      name: 'Second Generation Larvae',
      range: {
        min: 1361,
        max: 1660,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Peak of second generation larvae.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Oriental Fruit Moth Growing Degree Day Model is used to predict life-stage developments of the Oriental Fruit Moth (Grapholita molesta) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Croft, B. A., M. F. Michels, and R. E. Rice. 1980. Validation of a PETE timing model for the oriental fruit moth in Michigan and central California (Lepidoptera: Olethreutidae). Great Lakes Entomol. 13: 211-217.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-oriental-fruit-moth-growing-degree-day-model',
}
