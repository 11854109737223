import { GddModelDefine } from '../gdd'

export const gddPineShootBeetle: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddPineShootBeetle',
  name: 'Pine Shoot Beetle',
  fullName: 'Pine Shoot Beetle (Tomicus piniperda) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Adults have not yet emerged.',
      range: {
        min: 0,
        max: 450,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult are not yet present.',
        },
      ],
    },
    {
      name: 'Optimum Control Window.',
      range: {
        min: 451,
        max: 500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult are just beginning to emerge.',
        },
      ],
    },
    {
      name: 'Adults Active',
      range: {
        min: 501,
        max: 550,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are present and shoot feeding.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pine Shoot Beetle Degree Day Model is used to predict life-stage developments of the Pine Shoot Beetle (Tomicus piniperda), in order to improve pest management decisions.',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-pine-shoot-beetle-growing-degree-day-model/',
  citations: [
    'McCullough, D.G. and C.S. Sadof. 1998. Evaluation of an integrated management and compliance program for Tomicus piniperda (Coleoptera: Scolytidae) in pine Christmas tree fields. Forum Article, Journal of Economic Entomology 91:785-795.',
  ],
}
