import { GddModelDefine } from '../gdd'

export const gddObliquebandedLeafroller: GddModelDefine = {
  lowerThreshold: 43,
  upperThreshold: 85,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddObliquebandedLeafroller',
  name: 'Obliquebanded Leafroller',
  fullName: 'Obliquebanded Leafroller (Choristoneura rosaceana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adult Flight Stage from Overwintered Larvae',
      range: {
        min: 0,
        max: 140,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are in flight and may be observed in pheromone traps.`,
        },
        {
          name: 'Eggs',
          value: `Adults have begun to lay eggs.`,
        },
      ],
    },
    {
      name: 'Beginning of Egg Hatch (First Generation)',
      range: {
        min: 141,
        max: 440,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are in flight and may be observed in pheromone traps.`,
        },
        {
          name: 'Eggs',
          value: `Eggs have begun to hatch.`,
        },
      ],
    },
    {
      name: 'Early Egg Hatch (First Generation)',
      range: {
        min: 441,
        max: 585,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are in flight and may be observed in pheromone traps.`,
        },
        {
          name: 'Eggs',
          value: `Eggs are continuing to hatch.`,
        },
      ],
    },
    {
      name: 'Peak Egg Hatch (First Generation)',
      range: {
        min: 586,
        max: 780,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are in flight and may be observed in pheromone traps.`,
        },
        {
          name: 'Eggs',
          value: `Peak egg hatch.`,
        },
      ],
    },
    {
      name: 'Small Larvae (First Generation)',
      range: {
        min: 781,
        max: 900,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Mostly small larvae present.`,
        },
      ],
    },
    {
      name: 'Large Larvae (First Generation)',
      range: {
        min: 901,
        max: 1200,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Mostly large larvae present.`,
        },
      ],
    },
    {
      name: 'Adult Flight Stage (First Generation)',
      range: {
        min: 1201,
        max: 1480,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are in flight and may be observed in pheromone traps.`,
        },
        {
          name: 'Eggs',
          value: `Adults have begun to lay eggs.`,
        },
      ],
    },
    {
      name: 'Beginning of Egg Hatch (Second Generation)',
      range: {
        min: 1481,
        max: 1890,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are in flight and may be observed in pheromone traps.`,
        },
        {
          name: 'Eggs',
          value: `Eggs have begun to hatch.`,
        },
      ],
    },
    {
      name: 'Early Egg Hatch (Second Generation)',
      range: {
        min: 1891,
        max: 2170,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are in flight and may be observed in pheromone traps.`,
        },
        {
          name: 'Eggs',
          value: `Eggs are continuing to hatch.`,
        },
      ],
    },
    {
      name: 'Peak Egg Hatch (Second Generation)',
      range: {
        min: 2171,
        max: 2530,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are in flight and may be observed in pheromone traps.`,
        },
        {
          name: 'Eggs',
          value: `Peak egg hatch.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Obliquebanded Leafroller Growing Degree Day Model is used to predict life-stage developments of the Obliquebanded Leafroller (Choristoneura rosaceana) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Brunner et al. 1997. Leafroller models: predicting development and timing controls. Washington State University Cooperative Extension. AREAWIDE IPM UPDATE. Vol. 2, No. 7 - June 1, 1997`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-obliquebanded-leafroller-growing-degree-day-model',
}
