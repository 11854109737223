import { Component } from '@angular/core'
import { StatusBar } from '@capacitor/status-bar'
import {
  AlertController,
  LoadingController,
  NavController,
} from '@ionic/angular'
import {
  DateFormat,
  dateFormats,
  MeasurementUnits,
  measurementUnitsText,
  dateFormatsText,
  measurementUnits,
} from '@pest-prophet/shared'
import { Analytics } from '../../services/analytics'
import { Tools } from '../../services/tools'
import { Me } from '../../services/me'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
})
export class SignupPage {

  measurementUnits = measurementUnits
  measurementUnitsText = measurementUnitsText
  dateFormats = dateFormats
  dateFormatsText = dateFormatsText
  heardAboutUsFromOptions = [
    'Google',
    'Bing',
    'Facebook',
    'Twitter',
    'iOS App Store',
    'Android Play Store',
    'Colleague / Friend',
    'Other',
  ]

  inputs = {
    name: '',
    email: '',
    company: '',
    jobTitle: '',
    password: '',
    passwordRepeat: '',
    measurementUnits: 'metric' as MeasurementUnits,
    dateFormat: 'usa' as DateFormat,
    agreeToTerms: false,
    heardAboutUsFromSelected: '',
    heardAboutUsFromOtherValue: '',
  }

  constructor(
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
  ) {}

  ionViewWillEnter() {
    if (Tools.isNative()) {
      void StatusBar.setBackgroundColor({ color: '#ffffff' })
    }
  }

  ionViewDidEnter() {
    Analytics.screenView('Signup')
  }

  async createAccount() {
    // Error check.
    let errorMsg
    if (!this.inputs.email.length) {
      errorMsg = 'Email address is required.'
    } else if (!this.inputs.password.length) {
      errorMsg = 'Password is required.'
    } else if (this.inputs.password.length < 6) {
      errorMsg = 'Password must be at least 6 characters long.'
    } else if (this.inputs.password !== this.inputs.passwordRepeat) {
      errorMsg = 'Passwords do not match.'
    }

    if (errorMsg) {
      const alert = await this.alertCtrl.create({
        header: errorMsg,
        buttons: ['Close'],
        backdropDismiss: false,
      })
      await alert.present()
    }

    const loading = await this.loadingCtrl.create({ message: 'Creating account...' })
    await loading.present()

    try {
      await Me.createAccount({
        settings: {
          email: this.inputs.email,
          name: this.inputs.name,
          company: this.inputs.company,
          jobTitle: this.inputs.jobTitle,
          measurementUnits: this.inputs.measurementUnits,
          dateFormat: this.inputs.dateFormat,
          heardAboutUsFrom: this.inputs.heardAboutUsFromSelected === 'Other'
            ? (this.inputs.heardAboutUsFromOtherValue || 'Other')
            : this.inputs.heardAboutUsFromSelected,
        },
        password: this.inputs.password,
      })
    } catch (err) {
      console.error(err.message)
      const alert = await this.alertCtrl.create({
        header: 'Error',
        message: err.message,
        buttons: ['Close'],
        backdropDismiss: false,
      })
      await alert.present()
      throw err
    } finally {
      await loading.dismiss()
    }

    const alert = await this.alertCtrl.create({
      header: 'Welcome to Pest Prophet!',
      message: `You should receive a verification email shortly. If you don't find one, it may be in your spam, or you may have accidentally entered the wrong email address.`,
      buttons: [
        {
          text: 'Close',
        },
      ],
    })
    await alert.present()

    Analytics.signup('email')

    await this.navCtrl.navigateRoot('/home', {
      animated: true,
      animationDirection: 'forward',
    })
  }

  async goToTerms() {
    await this.navCtrl.navigateForward('/terms-and-conditions')
  }

  async goToPrivacyPolicy() {
    await this.navCtrl.navigateForward('/privacy-policy')
  }

}
