import { GddModelDefine } from '../gdd'

export const gddIndianmealMothAlmonds: GddModelDefine = {
  lowerThreshold: 17.5,
  upperThreshold: 35,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddIndianmealMothAlmonds',
  name: 'Indianmeal Moth (Almonds)',
  fullName: 'Indianmeal Moth (Plodia interpunctella) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: '2nd Generation Adults Not Yet Emerged',
      range: {
        min: 0,
        max: 344,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs maybe present and may have begun hatching.',
        },
        {
          name: 'Larvae',
          value: 'Larvae may be present and can be targeted for treatment.',
        },
        {
          name: 'Adults',
          value: '2nd Generation adult moths have not yet emerged.',
        },
      ],
    },
    {
      name: 'Adult Emergence',
      range: {
        min: 345,
        max: 688,
      },
      infos: [
        {
          name: 'Adults',
          value:
            '2nd Generation adults have emerged and may be targeted for trapping and/ or treatment..',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Indianmeal Moth Degree Day Model is used to predict development of life-cycle stages of the Indianmeal moth (Plodia interpunctella), in order to improve pest management decisions',
    'For best results, a biofix date should be set adult moths are first observed or trapped. The growing degree day model can then be used to predict emergence of next generation of adults.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-indianmeal-moth-growing-degree-day-model/',
  citations: [
    `Johnson, J. A., P. L. Wofford, and L. C. Whitehand. 1992. Effect of diet and temperature on development rates, survival, and reproduction of the Indianmeal moth (Lepidoptera: Pyralidae). J. Econ. Entomol. 85: 561-566.`,
  ],
}
