import { Injectable } from '@angular/core'
import {
  Router,
} from '@angular/router'
import {
  AlertController,
  NavController,
} from '@ionic/angular'
import { NavigationOptions } from '@ionic/angular/providers/nav-controller'

@Injectable({
  providedIn: 'root',
})
export class PageService {

  constructor(
    private navCtrl: NavController,
    private router: Router,
    private alertCtrl: AlertController,
  ) {}

  abort(message: string) {
    console.error(`Aborting: ${message}`)
    return this.navCtrl.navigateRoot('/home')
  }

  async error(err: Error) {
    console.error(err.message)
    const errorAlert = await this.alertCtrl.create({
      header: 'Error',
      message: err.message,
      buttons: ['Close'],
      backdropDismiss: false,
    })
    await errorAlert.present()
  }

  failedToInitialize() {
    return this.abort('Failed to initialize, probably due to invalid route data.')
  }

  getRouteData<TRouteData extends RouteData>(
    required = true,
  ): Required<TRouteData> {
    const navigation = this.router.getCurrentNavigation()
    if (!navigation) {
      if (required) {
        void this.abort('Required route data is missing')
      }
      return {
        state: {},
        queryParams: {},
      } as Required<TRouteData>
    }

    return {
      state: (navigation.extras?.state ?? {}),
      queryParams: (navigation.extras?.queryParams ?? {}),
    } as Required<TRouteData>
  }

  getState<TRouteData extends RouteData>(
    required = true,
  ): Required<TRouteData>['state'] {
    return this.getRouteData(required).state
  }

  getQueryParams<TRouteData extends RouteData>(
    required = true,
  ): Required<TRouteData>['queryParams'] {
    return this.getRouteData(required).queryParams
  }

  makeRouteData<TRouteData extends RouteData>(
    data: TRouteData,
  ): Partial<NavigationOptions> {
    return data
  }

}

export type RouteData = {
  state?: object
  queryParams?: object
}
