import { GddModelDefine } from '../gdd'

export const gddCitrusRedMite: GddModelDefine = {
  lowerThreshold: 46.2,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCitrusRedMite',
  name: 'Citrus Red Mite',
  fullName: 'Citrus Red Mite (Panonychus citri) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'First Generation Eggs',
    range: {
      min: 0,
      max: 217,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have begun to lay eggs.`,
    }],
  }, {
    name: 'First Generation Larvae - Nymphs',
    range: {
      min: 218,
      max: 627,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae are emerging.`,
    }],
  }, {
    name: 'Second Generation Eggs',
    range: {
      min: 628,
      max: 843,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have developed from deutonymphs and have begun to lay eggs.`,
    }],
  }, {
    name: 'Second Generation Larvae - Nymphs',
    range: {
      min: 844,
      max: 1253,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae are emerging.`,
    }],
  }, {
    name: 'Third Generation Eggs',
    range: {
      min: 1254,
      max: 1469,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have developed from deutonymphs and have begun to lay eggs.`,
    }],
  }, {
    name: 'Third Generation Larvae - Nymphs',
    range: {
      min: 1470,
      max: 1878,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae are emerging.`,
    }],
  }, {
    name: 'Multiple Generations Overlapping',
    range: {
      min: 1879,
      max: 2500,
    },
    infos: [{
      name: 'Multiple Generations',
      value: `Multiple generations have likely begun to overlap, making phenological stage estimates inaccurate.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Citrus Red Mite Growing Degree Day model can be used to predict phenological stages of the Citrus Red Mite (Panonychus citri). Different types of miticides will affect mite life stages differently.`,
  ],
  citations: [
    `Yasuda, M. 1982. Influence of temperature on some of the life cycle parameters of the citrus red mite, Panonychus citri (McGregor) (Acarina: Tetranychidae). Jap. J. Appl. Ent. Zool. 26: 52-57.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-citrus-red-mite-growing-degree-day-models',
}
