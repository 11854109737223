import { GddModelDefine } from '../gdd'

export const gddCabbageMaggotEmergence: GddModelDefine = {
  lowerThreshold: 43.0,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCabbageMaggotEmergence',
  name: 'Cabbage Maggot Emergence',
  fullName: 'Cabbage Maggot (Delia radicum) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Pre-Emergence',
      range: {
        min: 0,
        max: 300,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults have not yet emerged.`,
        },
      ],
    },
    {
      name: 'Adult Emergence',
      range: {
        min: 301,
        max: 1176,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults have begun to emerge.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Cabbage Maggot Emergence Growing Degree Day Model is used to predict the spring emergence of Cabbage Maggot (Delia radicum) in order to prepare for setting sticky traps or beginning a spray program.`,
  ],
  citations: [
    `Eckenrode, C. J., and R. K. Chapman. 1972. Seasonal adult cabbage maggot populations in the field in relation to thermal-unit accumulations. Ann. Entomol. Soc. Amer. 65: 151-156.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-cabbage-maggot-emergence-growing-degree-day-model',
}
