<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="presentActionSheet()">
        <ion-icon slot="icon-only" name="cog-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>
      <img src="../../../assets/img/pest-prophet-logo-512.png" alt="pest prophet" />
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div
    *ngIf="isLoading"
    layout="row" layout-align="center center"
    style="height: 100%"
  >
    <ion-spinner
      name="crescent"
      color="primary"
      style="transform: scale(3) translateY(-100%);"
    ></ion-spinner>
  </div>

  <div *ngIf="!isLoading">
    <ion-card class="p-10"
      tappable
      *ngFor="let location of locations"
      (click)="handleLocationClick(location)"
    >
      <div class="location-card-header" layout="row" layout-align="space-between start">
        <div class="location-card-name">{{ location.name }}</div>
        <ion-icon class="location-card-lock" name="lock-closed-outline" *ngIf="Locations.isLocationLocked(locations, location.id)"></ion-icon>
      </div>
      <img src="{{ Locations.getImgSrc(location) }}" width="{{ locationImgWidth }}" height="{{ locationImgHeight }}" alt="location" />
    </ion-card>

    <div
      class="text-center font-weight-semibold mt-50 ion-padding"
      style="font-size: 2.8rem; line-height: 1.4;"
      *ngIf="!locations.length"
    >
      <div>You don't have any locations...</div>
      <div class="pv-40"></div>
      <div>Tap the button in the bottom right to create one.</div>
    </div>

    <upgrade-card
      *ngIf="!Me.canCreateLocation(locations.length)"
      cardId="homePageLocations"
      title="Add More Locations"
    >
      Upgrade your subscription plan to add more locations.
    </upgrade-card>
  </div>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="secondary" (click)="handleCreateLocationClick()">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
