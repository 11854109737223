import { GddModelDefine } from '../gdd'

export const gddWalnutCaterpillar: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddWalnutCaterpillar',
  name: 'Walnut Caterpillar',
  fullName: 'Walnut Caterpillar (Datana integerrima) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Larvae have not emerged',
      range: {
        min: 0,
        max: 1600,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'Egg Hatch - First Larvae',
      range: {
        min: 1601,
        max: 1700,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged and have begun to feed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Walnut Caterpillar Degree Day Model is used to predict life-stage developments of the Water Caterpillar (Datana integerrima), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by insecticedes.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-walnut-caterpillar-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
