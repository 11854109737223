import { GddModelDefine } from '../gdd'

export const gddLilacBorerEmergence: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddLilacBorerEmergence',
  name: 'Lilac Borer Emergence',
  fullName: 'Lilac Borer (Podosesia syringae) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'No Adult Activity',
    range: {
      min: 0,
      max: 304,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintered moths have not yet emerged. Recommended to set biofix to Jan. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'First Adult Emergence',
    range: {
      min: 305,
      max: 507,
    },
    infos: [{
      name: 'Adults',
      value: `First emergence of adult moths predicted.`,
    }],
  }, {
    name: '10% Adult Emergence',
    range: {
      min: 508,
      max: 929,
    },
    infos: [{
      name: 'Adults',
      value: `10% emergence of adult moths predicted.`,
    }],
  }, {
    name: '50% Adult Emergence',
    range: {
      min: 930,
      max: 1369,
    },
    infos: [{
      name: 'Adults',
      value: `50% emergence of adult moths predicted.`,
    }],
  }, {
    name: '90% Adult Emergence',
    range: {
      min: 1370,
      max: 1500,
    },
    infos: [{
      name: 'Adults',
      value: `90% emergence of adult moths predicted.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Lilac Borer Emergence Growing Degree Day Model is used to predict emergence of the Lilac Borer (Podosesia syringae) in order to improve pest management decisions. Trunk sprays are recommended at 10% estimated adult moth activity.`,
  ],
  citations: [
    `Potter, D. A. and G. M. Timmons. 1983. Forecasting emergence and flight of the lilac borer (Lepidoptera: Sessiidae) based on pheromone trapping and degree-day accumulations. Environ. Entomol. 12: 400-403.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-lilac-borer-growing-degree-day-model',
}
