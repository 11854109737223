import { GddModelDefine } from '../gdd'

export const gddSpruceSpiderMite: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddSpruceSpiderMite',
  name: 'Spruce Spider Mite',
  fullName: 'Spruce Spider Mite (Oligonychus ununguis) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Egg laying has not begun.',
      range: {
        min: 0,
        max: 150,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult scales may be present.',
        },
        {
          name: 'Eggs',
          value: 'Eggs are not yet present.',
        },
      ],
    },
    {
      name: 'Egg Hatching',
      range: {
        min: 151,
        max: 175,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to hatch.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Spruce Spider Mite Degree Day Model is used to predict life-stage developments of the Spruce Spider Mite (Oligonychus ununguis), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict egg hatching which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-spruce-spider-mite-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
