import { GddModelDefine } from '../gdd'

export const gddBlackNightshade: GddModelDefine = {
  lowerThreshold: 6,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddBlackNightshade',
  name: 'Black Nightshade',
  fullName: 'Black Nightshade (Solanum nigrum) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Plant Emergence',
      range: {
        min: 0,
        max: 600,
      },
      infos: [
        {
          name: 'Plant',
          value: 'Plant has emerged/ been observed.',
        },
      ],
    },
    {
      name: 'Flowering',
      range: {
        min: 601,
        max: 900,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'Flowers have emerged.',
        },
      ],
    },
    {
      name: 'Rapid Plant Growth',
      range: {
        min: 901,
        max: 1000,
      },
      infos: [
        {
          name: 'Plant',
          value: 'Plant is in rapid growth stage.',
        },
      ],
    },
    {
      name: 'Berry and Seed Maturity',
      range: {
        min: 1001,
        max: 1200,
      },
      infos: [
        {
          name: 'Berry and Seeds',
          value: 'Berry and Seeds have reached maturity.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Black Nightshade Degree Day Model is used to predict seed production timing of Black Nightshade (Solanum nigrum) based on the weather in a given season.',
    'For best results, a biofix date should be set when the plant has first emerged / been observed. The growing degree day model can then be used to predict seed and berry maturity.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-black-nightshade-growing-degree-day-model/',
  citations: [
    `McGiffen, M. E., Jr. and J. B. Masiunas. 1992. Prediction of black and eastern black nightshade (Solanum nigrum and S. ptycanthum) growth using degree-days. Weed Science 40: 86-89.`,
  ],
}
