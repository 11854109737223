import { GddModelDefine } from '../gdd'

export const gddDogwoodSawfly: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddDogwoodSawfly',
  name: 'Dogwood Sawfly',
  fullName: 'Dogwood Sawfly (Macremphytus tarsatus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 1151,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered pupae have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence from Overwintered Pupae',
      range: {
        min: 1152,
        max: 1500,
      },
      infos: [
        {
          name: 'Adult',
          value: 'First adults from overwintered pupae have begun to emerge.',
        },
      ],
    },
    {
      name: 'End Adult Emergence',
      range: {
        min: 1500,
        max: 1600,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Dogwood Sawfly Growing Degree Day Model is used to predict development of life-cycle stages of Dogwood Sawfly (Macremphytus tarsatus), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered pupae which can be targeted by traps.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-dogwood-sawfly-growing-degree-day-model/',
  citations: [
    'Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.',
    'Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.',
  ],
}
