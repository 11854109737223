import { GddModelDefine } from '../gdd'

export const gddEuropeanPineShootMoth: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddEuropeanPineShootMoth',
  name: 'European Pine Shoot Moth',
  fullName: 'European Pine Shoot Moth (Rhyacionia buoliana) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Larvae Present',
      range: {
        min: 0,
        max: 50,
      },
      infos: [
        {
          name: 'Larvae',
          value: ' Larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Larvae Present',
      range: {
        min: 51,
        max: 220,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and can be targeted for control',
        },
      ],
    },
    {
      name: 'Pupation',
      range: {
        min: 221,
        max: 700,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Most larvae have begun to pupate',
        },
        {
          name: 'Adults',
          value: 'Some adult moths may be present',
        },
      ],
    },
    {
      name: 'First Adults Active',
      range: {
        min: 701,
        max: 800,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are present and may be laying eggs',
        },
      ],
    },
    {
      name: 'Egg Laying',
      range: {
        min: 801,
        max: 900,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are present and laying eggs',
        },
      ],
    },
    {
      name: 'Egg Hatch',
      range: {
        min: 901,
        max: 1000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are present and may be laying eggs',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The European Pine Shoot Moth Degree Day Model is used to predict life-stage developments of the European Pine Shoot Moth (Rhyacionia buoliana), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by insecticide treatments.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-european-pine-shoot-moth-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
