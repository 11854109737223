import { GddModelDefine } from '../gdd'

export const gddDiambondbackMoth: GddModelDefine = {
  lowerThreshold: 45.1,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddDiambondbackMoth',
  name: 'Diamondback Moth',
  fullName: 'Diamondback Moth (Plutella xylostella) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 509,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths may be present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'First Generation Adults',
      range: {
        min: 510,
        max: 1018,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths are present.`,
        },
        {
          name: 'Eggs',
          value: `Next generation egg laying has begun.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Diamondback Moth Growing Degree Day Model is used to predict life-stage developments of the Diamondback Moth (Plutella xylostella) in order to improve pest management decisions.`,
    `For best results, traps should be placed early in the season.The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Harcourt, D. G. 1954. The biology and ecology of the diamondback moth, Plutella maculipennis (Curtis), in eastern Ontario. Ph.D. thesis, Cornell University, Ithaca, N.Y. 107p.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-diamondback-moth-growing-degree-day-model',
}
