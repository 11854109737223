import { GddModelDefine } from '../gdd'

export const gddThreecorneredAlfalfaHopper: GddModelDefine = {
  lowerThreshold: 52,
  upperThreshold: 100,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddThreecorneredAlfalfaHopper',
  name: 'Threecornered Alfalfa Hopper',
  fullName: 'Threecornered Alfalfa Hopper (Spissistilus festinus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Overwintered Adults have not Begun Egg-laying',
      range: {
        min: 0,
        max: 450,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults may be present, but have not yet begun egg-laying.',
        },
      ],
    },
    {
      name: 'Overwintered Adults Begin Egg-Laying',
      range: {
        min: 451,
        max: 780,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have begun laying eggs.',
        },
      ],
    },
    {
      name: 'Peak Egg-Laying - Overwintered Adults',
      range: {
        min: 781,
        max: 1180,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have reached peak egg-laying.',
        },
      ],
    },
    {
      name: 'Emergence of Late Instar Nymphs - First Generation ',
      range: {
        min: 1181,
        max: 1430,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'First generation of late instar nymphs have emergened.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - First Generation',
      range: {
        min: 1431,
        max: 2180,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from next generation nymphs have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - First Generation',
      range: {
        min: 2181,
        max: 2580,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from first generation nymphs have emerged and are in peak flight.',
        },
        {
          name: 'Nymphs',
          value: 'Second generation nymphs have begun to emerge.',
        },
      ],
    },
    {
      name: 'Emergence of Late Instar Nymphs - Second Generation ',
      range: {
        min: 2581,
        max: 2830,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Second generation of late instar nymphs have emergened.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Second Generation',
      range: {
        min: 2831,
        max: 3580,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from next generation nymphs have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - Second Generation',
      range: {
        min: 3581,
        max: 4230,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from second generation nymphs have emerged and are in peak flight.',
        },
        {
          name: 'Nymphs',
          value: 'Third generation nymphs have begun to emerge.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Third Generation',
      range: {
        min: 4231,
        max: 4400,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from next generation nymphs have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Threecornered Alfalfa Hopper Degree Day Model is used to predict development of life-cycle stages of Threecornered Alfalfa Hopper (Spissistilus festinus), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict first egg-laying from overwintered adults and development of subsequent generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-threecornered-alfalfa-hopper-growing-degree-day-model/',
  citations: [
    'Wistrom, C., M.S. Sisterson, M.P. Pryor, J.M. Hashim-Buckey, and K.M. Daane. 2010. Distribution of glassy-winged sharpshooter and threecornered alfalfa hopper on Plant hosts in the San Joaquin Valley, California. J. Econ. Entomol. 103: 1051-1059.',
  ],
}
