import { GddModelDefine } from '../gdd'

export const gddTomatoPinworm: GddModelDefine = {
  lowerThreshold: 10.2,
  upperThreshold: 35,
  cutoffMethod: 'vertical',

  group: 'gdd',
  type: 'gddTomatoPinworm',
  name: 'Tomato Pinworm',
  fullName: 'Tomato Pinworm (Keiferia lycopersicella) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Adult Moth Activity and Egg Laying',
      range: {
        min: 0,
        max: 91,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'First Generation Small Larvae',
      range: {
        min: 92,
        max: 201,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Peak of first generation small first and second instar larvae.`,
        },
      ],
    },
    {
      name: 'First Generation Large Larvae',
      range: {
        min: 202,
        max: 321,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Peak of first generation large third and fourth instar larvae.`,
        },
      ],
    },
    {
      name: 'First Generation Pupae',
      range: {
        min: 322,
        max: 454,
      },
      infos: [
        {
          name: 'Pupae',
          value: `Peak of first generation pupae.`,
        },
      ],
    },
    {
      name: 'First Generation Adult Moths Pre Egg Laying',
      range: {
        min: 455,
        max: 545,
      },
      infos: [
        {
          name: 'Adults',
          value: `First generation adult moths present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has not yet begun.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Tomato Pinworm Growing Degree Day Model is used to predict life-stage developments of the Tomato Pinworm (Keiferia lycopersicella) in order to improve pest management decisions.`,
    `For best results, traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Lin, S. Y. H., and J. T. Trumble. 1985. Influence of temperature and tomato maturity on development and survival of Keiferia lycopersicella (Lepidoptera: Gelechiidae). Environ. Entomol. 14: 855-858.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-tomato-pinworm-growing-degree-day-model',
}
