import { Component } from '@angular/core'
import { Analytics } from '../../services/analytics'

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.page.html',
  styleUrls: ['./terms-and-conditions.page.scss'],
})
export class TermsAndConditionsPage {

  ionViewDidEnter() {
    Analytics.screenView('Terms & Conditions')
  }

}
