import { GddModelDefine } from '../gdd'

export const gddWesternSpruceBudworm: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddWesternSpruceBudworm',
  name: 'Western Spruce Budworm',
  fullName: 'Western Spruce Budworm (Choristoneura occidentalis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Larvae have not yet Emerged',
      range: {
        min: 0,
        max: 200,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'Beginning of Larvae Emergence',
      range: {
        min: 201,
        max: 300,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered larvae have begun to emerge.',
        },
      ],
    },
    {
      name: 'End of Larvae Emergence',
      range: {
        min: 301,
        max: 400,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Ovwerwintered larvae have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Western Spruce Budworm Degree Day Model is used to predict development of life-cycle stages of the Western Spruce Budworm (Choristoneura occidentalis), in order to improve pest management decisions',
    'For best results, a biofix date should be set as January 1st for the Northern Hemisphere to predict overwintering emergence.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-western-spruce-budworm-growing-degree-day-model/',
  citations: [
    'Kowalsick, Thomas, and Scott Clark. 2006. Using Growing Degree Days for Insect Pest Management. Cornell Cooperative Extension. 2006.',
  ],
}
