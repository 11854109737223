import { GddModelDefine } from '../gdd'

export const gddDiorhabdaCarinulata: GddModelDefine = {
  lowerThreshold: 52,
  upperThreshold: 98,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddDiorhabdaCarinulata',
  name: 'Diorhabda Carinulata',
  fullName: 'Diorhabda Carinulata Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Pre-Emergence',
      range: {
        min: 0,
        max: 230,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'Overwintered Adult Emergence',
      range: {
        min: 231,
        max: 446,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have begun to emerge.',
        },
      ],
    },
    {
      name: 'First Egg Hatch',
      range: {
        min: 447,
        max: 502,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'First generation egg hatch has begun.',
        },
      ],
    },
    {
      name: 'Peak Egg Hatch',
      range: {
        min: 503,
        max: 670,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'First generation egg hatch has peaked.',
        },
      ],
    },

    {
      name: 'Peak First Generation Larvae',
      range: {
        min: 671,
        max: 1128,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have peaked.',
        },
      ],
    },

    {
      name: 'First Generation Adults Sensitive to Photoperiod',
      range: {
        min: 1129,
        max: 1661,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults have emerged and are sensitive to photoperiod.',
        },
      ],
    },

    {
      name: 'Peak Second Generation Larvae',
      range: {
        min: 1662,
        max: 2119,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Second generation larvae have peaked.',
        },
      ],
    },
    {
      name: 'Second Generation Adults Sensitive to Photoperiod',
      range: {
        min: 2120,
        max: 2652,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have emerged and are sensitive to photoperiod.',
        },
      ],
    },
    {
      name: 'Peak Third Generation Larvae',
      range: {
        min: 2653,
        max: 3110,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Third generation larvae have peaked.',
        },
      ],
    },
    {
      name: 'Third Generation Adults Sensitive to Photoperiod',
      range: {
        min: 3111,
        max: 3643,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adults have emerged and are sensitive to photoperiod.',
        },
      ],
    },
    {
      name: 'Peak Fourth Generation Larvae',
      range: {
        min: 3644,
        max: 4104,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Fourth generation larvae have peaked.',
        },
      ],
    },
    {
      name: 'Fourth Generation Adults Sensitive to Photoperiod',
      range: {
        min: 4105,
        max: 4300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fourth generation adults have emerged and are sensitive to photoperiod.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Diorhabda Carinulata Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Diorhabda Carinulata, in order to improve pest management practices.',
    'For best results, a biofix date should be set to January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of overwintered adults and egg-hatch, larvae and adult emergence of subsequent generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-diorhabda-carinulata-growing-degree-day-model/',
  citations: [
    'Herrera, A.M., D.D. Dahlsten, N. Tomic-Carruthers, and R.I. Carruthers. 2005. Estimating Temperature-Dependent Developmental Rates of Diorhabda elongata (Col: Chrysomelidae), a Biological Contral Agent of Saltcedar (Tamarix spp.). Env. Entomol. 34:775-784.,',
    'Lewis, P.A., C.J. DeLoach, A.E. Knutson, J.L. Tracy, and T.O. Robbins. 2003. Biology of Diorhabda elongata deserticula (Col: Chrysomelidae) an Asian leaf beetle for the biological control of saltcedars (Tamarix spp.) in the United States. Biol. Control. 27:101-116',
  ],
}
