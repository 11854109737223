import { Injectable } from '@angular/core'
import { LocalStorageService } from './local-storage.service'

@Injectable({
  providedIn: 'root',
})
export class DebugService {

  constructor(
    private localStorage: LocalStorageService,
  ) {}

  init() {
    this.listenForKeyboardCommands()
  }

  private listenForKeyboardCommands() {
    window.addEventListener('keypress', (e: KeyboardEvent) => {
      switch (e.key) {
        case '~':
          void this.localStorage.clear()
          console.info('Local storage cleared!')
          break
        default:
          break
      }
    })
  }

}
