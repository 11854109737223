import { GddModelDefine } from '../gdd'

export const gddCubanLaurelThrips: GddModelDefine = {
  lowerThreshold: 53.6,
  upperThreshold: 95,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCubanLaurelThrips',
  name: 'Cuban Laurel Thrips',
  fullName: 'Cuban Laurel Thrips (Gynaikothrips ficorum) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 441,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths may be present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'First Generation Adults',
      range: {
        min: 442,
        max: 882,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths are present.`,
        },
        {
          name: 'Eggs',
          value: `Next generation egg laying has begun.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Cuban Laurel Thrips Growing Degree Day Model is used to predict life-stage developments of the Cuban Laurel Thrips (Gynaikothrips ficorum) in order to improve pest management decisions.`,
    `For best results, traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult thrips have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of the next generation, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Paine, T. D. 1992. Cuban laurel thrips (Thysanoptera: Phalaeothripidae) biology in southern California: seasonal abundance, temperature dependent development, leaf suitability, and predation. Ann. Entomol. Soc. Am. 85: 164-172.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-cuban-laurel-thrips-growing-degree-day-model',
}
