import { GddModelDefine } from '../gdd'

export const gddCanolaBNapus: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddCanolaBNapus',
  name: 'Canola (B. Napus)',
  fullName: 'Canola (B. Napus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Seed Planted',
      range: {
        min: 0,
        max: 270,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has not broken through soil.',
        },
      ],
    },
    {
      name: 'Shoot Break through soil.',
      range: {
        min: 271,
        max: 330,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has broken through the soil.',
        },
      ],
    },
    {
      name: 'First true leaf unfolds.',
      range: {
        min: 331,
        max: 410,
      },
      infos: [
        {
          name: 'First leaf.',
          value: 'First true leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Fourth true leaf unfolds.',
      range: {
        min: 411,
        max: 920,
      },
      infos: [
        {
          name: 'Fourth leaf.',
          value: 'Fourth true leaf has unfolded.',
        },
      ],
    },
    {
      name: 'First Inter-Node Detectable',
      range: {
        min: 921,
        max: 1510,
      },
      infos: [
        {
          name: 'Node',
          value: 'First visible inter-node has become detectable.',
        },
      ],
    },
    {
      name: 'Flower bud just visible (green bud).',
      range: {
        min: 1511,
        max: 1750,
      },
      infos: [
        {
          name: 'Flower bud',
          value: 'Green flower bud is just visible.',
        },
      ],
    },
    {
      name: 'First flower bud yellow.',
      range: {
        min: 1751,
        max: 1810,
      },
      infos: [
        {
          name: 'Flower bud',
          value: 'First flower bud is yellow but still closed.',
        },
      ],
    },
    {
      name: '10% Flowers Open / 10% Bloom.',
      range: {
        min: 1811,
        max: 2100,
      },
      infos: [
        {
          name: 'Flowers',
          value: '10% of flower buds open or 10% bloom.',
        },
      ],
    },
    {
      name: '10% of pods reach final size.',
      range: {
        min: 2101,
        max: 2400,
      },
      infos: [
        {
          name: 'Pods',
          value: '10% of pods have reached final size.',
        },
      ],
    },
    {
      name: '10% of seeds changed color.',
      range: {
        min: 2401,
        max: 2490,
      },
      infos: [
        {
          name: 'Seeds',
          value: '10% of seeds have changed color (any brown detectable).',
        },
      ],
    },
    {
      name: '40% of seeds changed color.',
      range: {
        min: 2491,
        max: 2640,
      },
      infos: [
        {
          name: 'Seeds',
          value: '40% of seeds have changed color.',
        },
      ],
    },
    {
      name: 'Nearly all seeds changed color.',
      range: {
        min: 2641,
        max: 2870,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Nearly all seeds have changed color.',
        },
        {
          name: 'Pods',
          value: 'Pods are ripe and dry.',
        },
      ],
    },
    {
      name: 'Harvest',
      range: {
        min: 2871,
        max: 3200,
      },
      infos: [
        {
          name: 'Plants',
          value: 'Plants are dead and dry, ready for harvest.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Canola Degree Day Model is used to predict harvest time and other growth stages of canola based on the weather in a given season.',
    'For best results, a biofix date should be set at planting. The growing degree day model can then be used to predict harvest time. For best results the biofix should be reset when later stages have been observed, in order to more accurately predict the next stage.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-canola-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Stu Brandt, Scott, SK 1993-97 and Perry Miller, Swift Current, SK 1995-98`,
  ],
}
