import { GddModelDefine } from '../gdd'

export const gddOmnivorousLeaftier: GddModelDefine = {
  lowerThreshold: 36,
  upperThreshold: 100,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddOmnivorousLeaftier',
  name: 'Omnivorous Leaftier',
  fullName: 'Omnivorous Leaftier (Cnephasia longana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence from Overwintering',
      range: {
        min: 0,
        max: 436,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered larvae have not emerged.',
        },
      ],
    },
    {
      name: 'Initial 1st Instar Larvae Emergence',
      range: {
        min: 437,
        max: 617,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Initial 1st Instar Larve from overwintered hibernacula.',
        },
      ],
    },
    {
      name: 'Peak 1st Instar Larvae Emergence',
      range: {
        min: 618,
        max: 775,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Peak 1st Instar Larvae from overwintered hibernacula.',
        },
      ],
    },
    {
      name: 'Initial 3rd Instar Larvae Emergence',
      range: {
        min: 776,
        max: 1210,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Initial 3rd Instar Larvae exit leaf mines and begin tying leaves.',
        },
      ],
    },
    {
      name: 'Peak 3rd Instar Larvae Emergence',
      range: {
        min: 1211,
        max: 1559,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Peak 3rd Instar Larvae exit leaf mines and begin tying leaves.',
        },
      ],
    },
    {
      name: 'Last 3rd Instar Larvae Emergence',
      range: {
        min: 1560,
        max: 1698,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Last 3rd Instar Larvae exit leaf mines and begin tying leaves.',
        },
      ],
    },
    {
      name: 'First Larvae enter Pupation',
      range: {
        min: 1699,
        max: 2045,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First larvae finish development and enter pupation.',
        },
      ],
    },
    {
      name: 'First Moths Emerge',
      range: {
        min: 2046,
        max: 2265,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adult moths have emerged.',
        },
      ],
    },
    {
      name: 'First Egg-laying - 1st Generation',
      range: {
        min: 2266,
        max: 2542,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active and have begun laying eggs.',
        },
      ],
    },
    {
      name: 'Last Larvae Finish Feeding',
      range: {
        min: 2543,
        max: 2765,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active and have begun laying eggs.',
        },
        {
          name: 'Larvae',
          value: 'Last larvae finish feeding.',
        },
      ],
    },
    {
      name: 'First Egg Hatch- 1st Generation',
      range: {
        min: 2766,
        max: 3673,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First next generation larvae have emerged and will seek hibernation sites.',
        },
      ],
    },
    {
      name: 'Last Egg Hatch- 1st Generation',
      range: {
        min: 3674,
        max: 3800,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Last next generation larvae have emerged and will seek hibernation sites.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Omnivorous Leaftier Degree Day Model is used to predict development of life-cycle stages of Omnivorous Leaftier (Cnephasia longana), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-omnivorous-leaftier-growing-degree-day-model/',
  citations: [
    'Coop, Len. 2012. Oregon State University. Omnivorous Leaftier Phenology (degree-day) Model. data from 1930-42, 46-51.',
  ],
}
