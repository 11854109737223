import { GddModelDefine } from '../gdd'

export const gddCruciferFleaBeetle: GddModelDefine = {
  lowerThreshold: 51.8,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCruciferFleaBeetle',
  name: 'Crucifer Flea Beetle',
  fullName: 'Crucifer Flea Beetle (Phyllotreta cruciferae) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adult / Eggs',
      range: {
        min: 0,
        max: 146,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are active.`,
        },
        {
          name: 'Eggs',
          value: `Adult have begun to lay eggs.`,
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 147,
        max: 524,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Eggs have begun to hatch and larvae have emerged.`,
        },
      ],
    },
    {
      name: 'Prepupae',
      range: {
        min: 525,
        max: 606,
      },
      infos: [
        {
          name: 'Prepupae',
          value: `Larvae have developed into the prepupae stage.`,
        },
      ],
    },
    {
      name: 'Pupae',
      range: {
        min: 607,
        max: 820,
      },
      infos: [
        {
          name: 'Pupae',
          value: `Prepupae have developed into the pupae stage.`,
        },
      ],
    },
    {
      name: 'Next Generation Adult / Eggs',
      range: {
        min: 821,
        max: 966,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are active.`,
        },
        {
          name: 'Eggs',
          value: `Adult have begun to lay eggs.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Crucifer Flea Beetle Growing Degree Day Model is used to predict life-stage developments of the Crucifer Flea Beetle (Phyllotreta cruciferae) in order to improve pest management decisions.`,
  ],
  citations: [
    `G. B. Kinoshita, H. J. Svec, C. R. Harris, F. L. McEwen. 1979. Biology of the crucifer flea beetle, Phyllotreta cruciferae (Coleoptera: Chrysomelidae), in southwestern Ontario. Can. Ent. 111: 1395-1407.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-crucifer-flea-beetle-growing-degree-day-model',
}
