import { Injectable } from '@angular/core'
import { Storage } from '@capacitor/storage'
import { parseBool } from '@pest-prophet/shared'
import { Me } from './me'

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  async isUpgradeCardHidden(cardId: string) {
    const key = this.makeUpgradeCardHiddenKey(cardId)
    const { value } = await Storage.get({ key })
    return value === 'true'
  }

  async setUpgradeCardHidden(cardId: string, hidden: boolean) {
    const key = this.makeUpgradeCardHiddenKey(cardId)
    const value = `${hidden}`
    await Storage.set({ key, value })
  }

  async getPwaInstallInfoHidden() {
    const key = `${this.getUserPrefix()}_pwaInstallInfoHidden`
    const { value } = await Storage.get({ key })
    return parseBool(value)
  }

  async setPwaInstallInfoHidden(hidden: boolean) {
    const key = `${this.getUserPrefix()}_pwaInstallInfoHidden`
    const value = `${hidden}`
    await Storage.set({ key, value })
  }

  async clear() {
    await Storage.clear()
  }

  private getUserPrefix() {
    return `user${Me.getFirebaseId()}`
  }

  private makeUpgradeCardHiddenKey(cardId: string): string {
    return `${this.getUserPrefix()}_upgradeCardHidden_${cardId}`
  }

}
