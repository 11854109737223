import { GddModelDefine } from '../gdd'

export const gddRedrootPigweed: GddModelDefine = {
  lowerThreshold: 46,
  upperThreshold: 89,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddRedrootPigweed',
  name: 'Redroot Pigweed',
  fullName: 'Redroot Pigweed (Amaranthus retroflexus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Cotyledon Present',
      range: {
        min: 0,
        max: 121,
      },
      infos: [
        {
          name: 'Cotyledon',
          value: 'Cotyledon present.',
        },
      ],
    },
    {
      name: '2 Leaves Present',
      range: {
        min: 122,
        max: 189,
      },
      infos: [
        {
          name: 'Leaves',
          value: '2 leaves present.',
        },
      ],
    },
    {
      name: '4-5 Leaves Present',
      range: {
        min: 190,
        max: 277,
      },
      infos: [
        {
          name: 'Leaves',
          value: '4-5 leaves present.',
        },
      ],
    },
    {
      name: '6-7 Leaves Present',
      range: {
        min: 278,
        max: 537,
      },
      infos: [
        {
          name: 'Leaves',
          value: '6-7 leaves present.',
        },
      ],
    },
    {
      name: 'First Flowering',
      range: {
        min: 538,
        max: 1004,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'First flowers are present.',
        },
      ],
    },
    {
      name: 'Lower 95% Confidence Interval - First Viable Seed',
      range: {
        min: 1005,
        max: 1078,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Lower 95% confidence interval - first viable seed.',
        },
      ],
    },
    {
      name: 'Average - First Viable Seed',
      range: {
        min: 1079,
        max: 1152,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Average - first viable seed.',
        },
      ],
    },
    {
      name: 'Upper 95% Confidence Interval - First Viable Seed',
      range: {
        min: 1153,
        max: 1400,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Upper 95% confidence interval - first viable seed.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Redroot Pigweed Degree Day Model is used to predict seed production of Redroot Pigweed (Amaranthus retroflexus) based on the weather in a given season.',
    'For best results, a biofix date should be set when the cotyledon is first emerged / observed. The growing degree day model can then be used to predict seed production.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-redroot-pigweed-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
  ],
}
