import { GddModelDefine } from '../gdd'

export const gddHoneydewMoth: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 95,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddHoneydewMoth',
  name: 'Honeydew Moth',
  fullName: 'Honeydew Moth (Cryptoblabes gnidiella) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 221,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have not begun flight.',
        },
      ],
    },
    {
      name: 'First Flight Adults from Overwintered Larvae',
      range: {
        min: 222,
        max: 344,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have begun flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - Adults from Overwintered Larvae',
      range: {
        min: 345,
        max: 696,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are in peak flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak Egghatch- 1st Generation',
      range: {
        min: 697,
        max: 1300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are in flight and egg-laying is in peak.',
        },
        {
          name: 'Larvae',
          value: 'First generation larvae have emerged.',
        },
      ],
    },

    {
      name: 'Peak Flight - 1st Generation Adults',
      range: {
        min: 1301,
        max: 2255,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults are in peak flight and egg-laying has begun.',
        },
      ],
    },

    {
      name: 'Peak Flight - 2nd Generation',
      range: {
        min: 2256,
        max: 3211,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults are in peak flight.',
        },
      ],
    },

    {
      name: 'Peak Flight - 3rd Generation',
      range: {
        min: 3212,
        max: 4167,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 4th Generation',
      range: {
        min: 4168,
        max: 5123,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fourth generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 5th Generation',
      range: {
        min: 5124,
        max: 6078,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fifth generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 6th Generation',
      range: {
        min: 6079,
        max: 6300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Sixth generation adults are in peak flight.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Honeydew Moth Degree Day Model is used to predict development of life-cycle stages of Honeydew Moth (Cryptoblabes gnidiella), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-honeydew-moth-growing-degree-day-model/',
  citations: [
    'Avidov, Z. and S. Gothilf. 1960. Observations on the honeydew moth (Cryptoblabes gnidiella Milliere) in Israel. I. Biology, phenology and economic importance. Ktavim. 10:109-124.',
  ],
}
