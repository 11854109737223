import { GddModelDefine } from '../gdd'

export const gddBeetLeafhopper: GddModelDefine = {
  lowerThreshold: 58,
  upperThreshold: 95,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddBeetLeafhopper',
  name: 'Beet Leafhopper',
  fullName: 'Beet Leafhopper (Circulifer tenellus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 200,
      },
      infos: [
        {
          name: 'Eggs',
          value: `Most leafhoppers are in the egg stage.`,
        },
      ],
    },
    {
      name: 'Nymphs',
      range: {
        min: 201,
        max: 650,
      },
      infos: [
        {
          name: 'Nymphs',
          value: `Most leafhoppers are in the nymph stage.`,
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 651,
        max: 850,
      },
      infos: [
        {
          name: 'Adults',
          value: `Most leafhoppers are adults. Biofix should be reset for next generation`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Beet Leafhopper Growing Degree Day Model is used to predict life-stage developments of the Beet Leafhopper (Circulifer tenellus) in order to improve pest management decisions.`,
  ],
  citations: [
    `Harries, F. H., and J. R. Douglass. 1948. Bionomic studies on the beet leafhopper. Ecological Monographs 18: 47-79.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-beet-leafhopper-growing-degree-day-model',
}
