import { assertExists } from '../../utils/assert.utils'
import {
  Model,
  ModelDayCreateInput,
  WeatherDayDecrypted,
} from '../../utils/entity.utils'
import { ModelOutputMatrix, ModelUtilsShared } from '../../utils/model.utils'
import { ModelUnitsName } from '../../utils/units.utils'
import { WeatherDayUtilsShared } from '../../utils/weather-day.utils'
import {
  ModelDefineBase,
  ModelGroupDefineBase,
  ModelStageBase,
} from '../model-base-types'

export class TomCast {

  static calcModelDays(model: Model, define: TomCastModelDefine, weatherDaysSorted: WeatherDayDecrypted[]): ModelDayCreateInput[] {
    assertExists(model.biofixDate, `Unable to calculate TomCast model days: biofix date missing.`)

    let dsv = 0
    const modelDays: ModelDayCreateInput[] = []

    for (const weatherDay of weatherDaysSorted) {
      if (weatherDay.date <= model.biofixDate) {
        continue
      }

      dsv += calcDayDsv(define, weatherDay)

      modelDays.push({
        date: weatherDay.date,
        isForecast: weatherDay.hours.some(x => x.isForecast),
        value: dsv,
        modelId: model.id,
      })
    }

    return modelDays
  }

}

function calcDayDsv(define: TomCastModelDefine, weatherDay: WeatherDayDecrypted): TomCastOutput {
  return ModelUtilsShared.getOutputMatrixValue(
    define,
    WeatherDayUtilsShared.getMeanTemperature(weatherDay),
    WeatherDayUtilsShared.getLeafWetnessHours(weatherDay),
  )
}

export const tomCastModelCommon: Pick<TomCastModelDefine, 'group' | 'outputMatrix' | 'graphLayout'> = {
  group: 'tomCast',
  outputMatrix: {
    axisRanges: {
      x: { min: 13, max: 29 }, // Mean Temperature
      y: { min: 0, max: 23 }, // Leaf Wetness Hours
    },
    outputs: [ /*
       13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 */
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 0
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 1
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 2
      [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0], // 3
      [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], // 4
      [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], // 5
      [0, 0, 0, 0, 0, 1, 1, 1, 2, 2, 2, 2, 2, 1, 1, 1, 1], // 6
      [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 1, 1, 1, 1], // 7
      [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 1, 1, 1, 1], // 8
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2], // 9
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2], // 10
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2], // 11
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2], // 12
      [1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3, 3, 2, 2, 2, 2], // 13
      [1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3, 3, 2, 2, 2, 2], // 14
      [1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3, 3, 2, 2, 2, 2], // 15
      [2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], // 16
      [2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], // 17
      [2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], // 18
      [2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], // 19
      [2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3], // 20
      [3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 3, 3, 3, 3], // 21
      [3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 3, 3, 3, 3], // 22
      [3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4], // 23
    ],
  },
  graphLayout: {
    yaxis: {
      range: [0, 30],
      dtick: 1,
    },
  },
}

export type TomCastModelDefine = ModelDefineBase<TomCastModelGroupDefine, TomCastModelStage> & {
  outputMatrix: ModelOutputMatrix<TomCastOutput>
}

export type TomCastModelStage = ModelStageBase

export const tomCastModelGroupDefine: TomCastModelGroupDefine = {
  name: 'tomCast',
  currentStageHeader: 'Current Disease Severity',
  stagesHeader: 'Stages',
  modelUnits: 'dsv',
  modelUnitsName: ModelUnitsName.universal('DSV'),
  requiresBiofixDate: true,
  requiresBiofixStage: false,
}

export type TomCastModelGroupDefine = ModelGroupDefineBase<'tomCast'>

export type TomCastOutput = 0 | 1 | 2 | 3 | 4
