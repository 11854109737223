import { GddModelDefine } from '../gdd'

export const gddBeanSal: GddModelDefine = {
  lowerThreshold: 43.5,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddBeanSal',
  name: 'Bean (Sal)',
  fullName: 'Bean (Sal) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: '50% Seedling Emergence.',
      range: {
        min: 0,
        max: 1108,
      },
      infos: [
        {
          name: 'Seedling',
          value: '50% seedling emergence.',
        },
      ],
    },
    {
      name: '50% Anthesis',
      range: {
        min: 1109,
        max: 1400,
      },
      infos: [
        {
          name: 'Flowers',
          value: '50% of flowering has begun.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Bean Degree Day Model is used to predict flowering of beans based on the weather in a given season.',
    'For best results, a biofix date should be set at 50% seedling emergence. The growing degree day model can then be used to predict time of flowering.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-bean-growing-degree-day-model/',
  citations: [
    `Scully, B., and J. G. Waines 1988. Ontogeny and yield response of common and tepary beans to temperature. Agron. J. 80: 921-925.`,
  ],
}
