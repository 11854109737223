import { GddModelDefine } from '../gdd'

export const gddLarchCasebearer: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddLarchCasebearer',
  name: 'Larch Casebearer',
  fullName: 'Larch Casebearer (Coleophora laricella) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Egg hatching has not begun.',
      range: {
        min: 0,
        max: 120,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths may be present.',
        },
        {
          name: 'Eggs',
          value: 'Eggs are not yet present.',
        },
      ],
    },
    {
      name: 'Egg Hatching',
      range: {
        min: 121,
        max: 150,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to hatch.',
        },
        {
          name: 'Larvae',
          value: 'Larvae have emerged from eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Larch Casebearer Degree Day Model is used to predict life-stage developments of the Larch Casebearer (Coleophora laricella), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-larch-casebearer-growing',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
