import { SubscriptionType } from '@pest-prophet/shared'
import { Tools } from './tools'
import { Me } from './me'

type AnalyticsWindow = Window & {
  dataLayer?: any[]
}

export class Analytics {

  private static initialized = false
  private static initializedUser = false
  private static initializedUserId?: string

  static init() {
    if (this.initialized) {
      return
    }

    if (Tools.isDev()) {
      return
    }

    (window as AnalyticsWindow).dataLayer = (window as AnalyticsWindow).dataLayer || []
    gtag('js', new Date())
    gtag('config', 'UA-109087819-1')

    this.initialized = true
    console.log('analytics initialized')
  }

  static setUser(userId: string) {
    if (this.initializedUser && this.initializedUserId === userId) {
      return
    }

    if (Tools.isDev()) {
      return
    }

    if (!Me.getUserData().analyticsEnabled) {
      console.warn('User has disabled analytics')
      return
    }

    gtag('set', { user_id: userId })
    gtag('config', 'UA-109087819-6')

    this.initializedUser = true
    this.initializedUserId = userId
    console.log('analytics user set')
  }

  static event(args: {
    action: string
    category?: AnalyticsCategory
    label?: string
    value?: number
  }) {
    const {
      action,
      category = 'engagement',
      label,
      value,
    } = args

    try {
      gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
      })
    } catch (err) {}
  }

  static screenView(screenName: string) {
    defaultEvent('screen_view', { screen_name: screenName })
  }

  static login(method: string) {
    defaultEvent('login', { method })
  }

  static signup(method: string) {
    defaultEvent('sign_up', { method })
  }

  static error(err: Error, fatal: string | boolean) {
    defaultEvent('exception', {
      description: err.message,
      fatal,
    })
  }

  static beginCheckout(type: SubscriptionType) {
    defaultEvent('begin_checkout', { event_label: type })
  }

  static purchase(type: SubscriptionType) {
    defaultEvent('purchase', { event_label: type })
  }

}

function defaultEvent(name: string, params?: { [name: string]: string | boolean }) {
  try {
    gtag('event', name, params)
  } catch (err) {}
}

function gtag(...args: any[]) {
  const dataLayer = (window as AnalyticsWindow).dataLayer
  if (!dataLayer) {
    throw new Error('Analytics has not been initialized yet!')
  }

  dataLayer.push(args)
}

export const analyticsCategories = [
  'engagement',
  'ecommerce',
  'location',
  'model',
  'forgot_password',
  'upgrade_alert',
] as const
export type AnalyticsCategory = typeof analyticsCategories[number]
