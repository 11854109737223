import { WeatherHour } from '../../utils'
import { assertExists } from '../../utils/assert.utils'
import {
  Model,
  ModelDayCreateInput,
  WeatherDayDecrypted,
} from '../../utils/entity.utils'
import { MathUtils } from '../../utils/math.utils'
import { ModelUnitsName } from '../../utils/units.utils'
import {
  ModelDefineBase,
  ModelGroupDefineBase,
  ModelStageBase,
} from '../model-base-types'

export class Gdd {

  static calcModelDays(model: Model, define: GddModelDefine, weatherDaysSorted: WeatherDayDecrypted[]): ModelDayCreateInput[] {
    assertExists(model.biofixDate, `Unable to calculate GDD model days: biofix date missing.`)
    assertExists(model.biofixStage, `Unable to calculate GDD model days: biofix stage missing.`)

    const biofixModelStage = define.stages[model.biofixStage]
    assertExists(biofixModelStage, `Unable to calculate GDD model days: biofix model stage could not be found.`)
    assertExists(biofixModelStage.range.min, `Unable to calculate GDD model days: biofix model stage does not contain a range min.`)

    // Get the max GDD value to cap at.
    const highestRange = define.stages[define.stages.length - 1].range
    const gddMax = highestRange.max ?? highestRange.min
    assertExists(gddMax, `Unable to calculate GDD model days: gdd max could not be found.`)

    const modelDays: ModelDayCreateInput[] = []

    // Set the starting GDD value based on the biofix stage.
    let gdd = biofixModelStage.range.min
    modelDays.push({
      date: model.biofixDate,
      isForecast: false,
      value: gdd,
      modelId: model.id,
    })

    for (const weatherDay of weatherDaysSorted) {
      if (weatherDay.date <= model.biofixDate) {
        continue
      }

      gdd += calcGrowingDegreeDay(define, weatherDay, gdd)
      gdd = Math.min(gdd, gddMax) // Don't go beyond the highest threshold value.

      modelDays.push({
        date: weatherDay.date,
        isForecast: weatherDay.hours.some(x => x.isForecast),
        value: gdd,
        modelId: model.id,
      })
    }

    return modelDays
  }

}

function calcGrowingDegreeDay(define: GddModelDefine, weatherDay: WeatherDayDecrypted, gdd: number): number {
  let growingDegreeHours = 0
  const hours = weatherDay.hours as WeatherHour[]
  for (const { temperature } of hours) {
    growingDegreeHours += calcGrowingDegreeHour(define, temperature, gdd)
  }

  return growingDegreeHours / hours.length
}

function calcGrowingDegreeHour(modelDefine: GddModelDefine, temp: number, gdd: number): number {
  // Make sure the temp is in consistent units.
  if (modelDefine.measurementUnits === 'imperial') {
    temp = MathUtils.celsiusToFahrenheight(temp)
  }

  // Get the model stage at this gdd value in case it has custom thresholds.
  // Use defined thresholds unles model stage has individual thresholds defined.
  const {
    lowerThreshold = modelDefine.lowerThreshold,
    upperThreshold = modelDefine.upperThreshold,
  } = getModelStageAtGdd(modelDefine, gdd)

  assertExists(lowerThreshold, `Undefined lower threshold! (model = ${modelDefine.type}, gdd = ${gdd})`)
  assertExists(upperThreshold, `Undefined upper threshold! (model = ${modelDefine.type}, gdd = ${gdd})`)

  let gddHour: number
  if (temp <= upperThreshold) {
    // Below upper threshold. Return the difference between the temp and lower threshold,
    // with a minimum value of 0.
    gddHour = temp - lowerThreshold
  } else {
    // Above upper threshold. Handle based on the cutoff method.
    switch (modelDefine.cutoffMethod) {
      case 'horizontal':
        gddHour = upperThreshold - lowerThreshold
        break
      case 'intermediate':
        gddHour = upperThreshold - lowerThreshold - (temp - upperThreshold)
        break
      case 'vertical':
        gddHour = 0
        break
      case 'none':
        gddHour = temp - lowerThreshold
        break
      default:
        throw new Error(`Unknown cutoff method: '${modelDefine.cutoffMethod}'`)
    }
  }

  return Math.max(0, gddHour)
}

function getModelStageAtGdd(modelDefine: GddModelDefine, gdd: number): GddModelStage {
  const gddFloored = Math.floor(gdd)

  for (const stage of modelDefine.stages) {
    const {
      min = Number.NEGATIVE_INFINITY,
      max = Number.POSITIVE_INFINITY,
    } = stage.range

    if (MathUtils.inRange(gddFloored, { min, max })) {
      return stage
    }
  }

  throw new Error(`Model stage could not be found! (model = ${modelDefine.type}, gdd = ${gdd})`)
}

export type GddModelVarietyDefine = Omit<GddModelDefine, 'group' | 'name' | 'fullName'>

export function createGddVarietyBuilder(
  args: {
    name: (varietyName: string) => string
    fullName: (varietyName: string) => string
  },
) {
  return {
    variety: (
      varietyName: string,
      modelVarietyDefine: GddModelVarietyDefine,
    ): GddModelDefine => ({
      group: 'gdd',
      name: args.name(varietyName),
      fullName: args.fullName(varietyName),
      varietyName,
      ...modelVarietyDefine,
    }),
  }
}

export type GddModelDefine = ModelDefineBase<GddModelGroupDefine, GddModelStage> & {
  lowerThreshold?: number
  upperThreshold?: number
  cutoffMethod: GddCutoffMethod
  biofixDefaultDate?: {
    month: number
    day: number
  }
}

export type GddModelStage = ModelStageBase & {
  lowerThreshold?: number
  upperThreshold?: number
}

export const gddCutoffMethods = [
  'horizontal',
  'intermediate',
  'vertical',
  'none',
]
export type GddCutoffMethod = typeof gddCutoffMethods[number]

export const gddModelGroupDefine: GddModelGroupDefine = {
  name: 'gdd',
  currentStageHeader: 'Current Growth Stage',
  stagesHeader: 'Growth Stages',
  modelUnits: 'temperature',
  modelUnitsName: ModelUnitsName.universal('GDD'),
  requiresBiofixDate: true,
  requiresBiofixStage: true,
  biofixDefaultDate: {
    month: 3,
    day: 1,
  },
}

export type GddModelGroupDefine = ModelGroupDefineBase<'gdd'>
