import { GddModelDefine } from '../gdd'

export const gddLacanobiaFruitworm: GddModelDefine = {
  lowerThreshold: 44,
  upperThreshold: 90,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddLacanobiaFruitworm',
  name: 'Lacanobia Fruitworm',
  fullName: 'Lacanobia Fruitworm (Lacanobia subjuncta) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Overwintering Moth Emergence',
      range: {
        min: 0,
        max: 390,
      },
      infos: [
        {
          name: 'Adults',
          value: `Over-wintered adult moths begin emergence.`,
        },
      ],
    },
    {
      name: 'Adult Moth Flight',
      range: {
        min: 391,
        max: 667,
      },
      infos: [
        {
          name: 'Adults',
          value: `First Generation Adult Moth Flight.`,
        },
      ],
    },
    {
      name: 'First Generation Egg Laying',
      range: {
        min: 668,
        max: 769,
      },
      infos: [
        {
          name: 'Adults',
          value: `First Generation Adult Moths in Flight`,
        },
        {
          name: 'Eggs',
          value: `First Generation Egg Laying`,
        },
      ],
    },
    {
      name: 'First Generation Egg Hatch',
      range: {
        min: 770,
        max: 1044,
      },
      infos: [
        {
          name: 'Adults',
          value: `First Generation Adult Moths in Flight`,
        },
        {
          name: 'Eggs',
          value: `First Generation Egg Hatch`,
        },
        {
          name: 'Larvae',
          value: `First Generation Larvae emergence has begun`,
        },
      ],
    },
    {
      name: '80% First Generation Egg Hatch',
      range: {
        min: 1045,
        max: 2191,
      },
      infos: [
        {
          name: 'Adults',
          value: `First Generation Adult Moths in Flight`,
        },
        {
          name: 'Eggs',
          value: `80% First Generation Egg Hatch`,
        },
        {
          name: 'Larvae',
          value: `First Generation Larvae emergence has begun`,
        },
      ],
    },
    {
      name: 'Second Generation Adult Moth Flight',
      range: {
        min: 2192,
        max: 2465,
      },
      infos: [
        {
          name: 'Adults',
          value: `Second Generation Adult Moths in Flight`,
        },
      ],
    },
    {
      name: 'Second Generation Egg Laying',
      range: {
        min: 2466,
        max: 2565,
      },
      infos: [
        {
          name: 'Adults',
          value: `Second Generation Adult Moths in Flight`,
        },
        {
          name: 'Eggs',
          value: `Second Generation Egg Laying`,
        },
      ],
    },
    {
      name: 'Second Generation Egg Hatch',
      range: {
        min: 2566,
        max: 3042,
      },
      infos: [
        {
          name: 'Adults',
          value: `Second Generation Adult Moths in Flight`,
        },
        {
          name: 'Eggs',
          value: `Second Generation Egg Hatch`,
        },
        {
          name: 'Larvae',
          value: `Second Generation Larvae emergence has begun`,
        },
      ],
    },
    {
      name: '80% Second Generation Egg Hatch',
      range: {
        min: 3043,
        max: 4190,
      },
      infos: [
        {
          name: 'Adults',
          value: `Second Generation Adult Moths in Flight`,
        },
        {
          name: 'Eggs',
          value: `80% Second Generation Egg Hatch`,
        },
        {
          name: 'Larvae',
          value: `Second Generation Larvae emergence has begun`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Lacanobia Fruitworm Growing Degree Day Model is used to predict life-stage developments of the Lacanobia Fruitworm (Lacanobia subjuncta) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Doerr, Michael D., Brunner, Jay F., and Jones, Vincent P. 2002. Temperature-Dependent Development of Lacanobia subjuncta (Lepidoptera: Noctuidae). Environ. Entomol. 31(6): 995-999`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-lacanobia-fruitworm-growing-degree-day-model',
}
