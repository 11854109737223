import { GddModelDefine } from '../gdd'

export const gddBagworm: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddBagworm',
  name: 'Bagworm',
  fullName: 'Bagworm (Thyridopteryx ephemeraeformis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Larvae Emergence',
      range: {
        min: 0,
        max: 600,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered eggs are bags attached to twigs and larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Larvae Emergence from Overwintered Eggs',
      range: {
        min: 601,
        max: 900,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First larvae from overwintered eggs have begun to emerge.',
        },
      ],
    },
    {
      name: 'End Larvae Emergence from Overwintered Eggs',
      range: {
        min: 901,
        max: 1000,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae from overwintered eggs have all emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Bagworm Degree Day Model is used to predict development of life-cycle stages of Bagworm (Thyridopteryx ephemeraeformis), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae from overwintered eggs. Larvae can be targeted for treatment with insecticides.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-bagworm-growing-degree-day-model/',
  citations: [
    'Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.',
    'Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.',
  ],
}
