import { GddModelDefine } from '../gdd'

export const gddWesternTentCaterpillar: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddWesternTentCaterpillar',
  name: 'Western Tent Caterpillar',
  fullName: 'Western Tent Caterpillar (Malacosoma californicum) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Larvae have not yet Emerged',
      range: {
        min: 0,
        max: 100,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have not yet emerged from overwintered eggs.',
        },
      ],
    },
    {
      name: 'Beginning of Larvae Emergence',
      range: {
        min: 101,
        max: 500,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have begun to emerge from overwintered eggs.',
        },
      ],
    },
    {
      name: 'End of Larvae Emergence',
      range: {
        min: 501,
        max: 600,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged from overwintered eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Western Tent Caterpillar Degree Day Model is used to predict development of life-cycle stages of the Western Tent Caterpilllar (Malacosoma californicum), in order to improve pest management decisions',
    'For best results, a biofix date should be set as January 1st for the Northern Hemisphere to predict overwintering emergence.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-western-tent-caterpillar-growing-degree-day-model/',
  citations: [
    'Kowalsick, Thomas, and Scott Clark. 2006. Using Growing Degree Days for Insect Pest Management. Cornell Cooperative Extension. 2006.',
  ],
}
