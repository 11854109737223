import { Component } from '@angular/core'
import { Analytics } from '../../services/analytics'

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage {

  ionViewDidEnter() {
    Analytics.screenView('Privacy Policy')
  }

}
