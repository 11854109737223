import { GddModelDefine } from '../gdd'

export const gddSunnPest: GddModelDefine = {
  lowerThreshold: 54,
  upperThreshold: 97,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddSunnPest',
  name: 'Sunn Pest',
  fullName: 'Sunn Pest (Eurygaster integriceps) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Overwintered Adults have not Arrived',
      range: {
        min: 0,
        max: 180,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have not arrived.',
        },
      ],
    },
    {
      name: 'Arrival of Overwintered Adults',
      range: {
        min: 181,
        max: 378,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have arrived and have begun laying eggs.',
        },
      ],
    },
    {
      name: 'Peak Egg-Laying',
      range: {
        min: 379,
        max: 891,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have arrived and have reached peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak Nymphs',
      range: {
        min: 892,
        max: 1062,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'First generation nymphs have reached peak emergence.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - First Generation',
      range: {
        min: 1063,
        max: 1260,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from next generation nymphs have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - First Generation',
      range: {
        min: 1261,
        max: 1400,
      },
      infos: [
        {
          name: 'Adults',
          value:
            'Adults from first generation nymphs have emerged and are in peak flight. They will typically leave to overwinter elsewhere.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Sunn Pest Degree Day Model is used to predict development of life-cycle stages of Sunn Pest (Eurygaster integriceps), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict arrival of adults and development of subsequent generations.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-sunn-pest-growing-degree-day-model/',
  citations: [
    'Kivan, M. 2008. Development rate and lower temperature threshold in the eggs of Eurygaster integriceps (Heteroptera: Scutelleridae). Insect Science 15:163-166.',
  ],
}
