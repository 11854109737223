import { GddModelDefine } from '../gdd'

export const gddCankerworm: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCankerworm',
  name: 'Cankerworm',
  fullName: 'Cankerworm (Paleacrita vernata) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Caterpillars Present',
      range: {
        min: 0,
        max: 100,
      },
      infos: [
        {
          name: 'Adults',
          value: ' Adult moths may be present.',
        },
        {
          name: 'Larvae',
          value: ' Larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'Young Caterpillars',
      range: {
        min: 101,
        max: 200,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Young caterpillars are present and can be targeted for control',
        },
      ],
    },
    {
      name: 'Mature Caterpillars and Adults',
      range: {
        min: 201,
        max: 400,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have matured.',
        },
        {
          name: 'Adults',
          value: ' Adult moths may be present.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Cankerworm Degree Day Model is used to predict life-stage developments of the Spring Cankerwom ( Paleacrita vernata), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of young caterpillars which can be targeted by insecticide treatments.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-cankerworm-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
