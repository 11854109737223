import { GddModelDefine } from '../gdd'

export const gddPecanNutCasebearer: GddModelDefine = {
  lowerThreshold: 38,
  upperThreshold: 120,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPecanNutCasebearer',
  name: 'Pecan Nut Casebearer',
  fullName: 'Pecan Nut Casebearer (Acrobasis nuxvorella) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Pupation has not begun',
      range: {
        min: 0,
        max: 956,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Some overwintered larvae may have emerged, but pupation has not begun.',
        },
      ],
    },
    {
      name: '10% Pupation',
      range: {
        min: 957,
        max: 1183,
      },
      infos: [
        {
          name: 'Larvae',
          value: '10% of overwintered larvae have begun pupation.',
        },
      ],
    },
    {
      name: '50% Pupation',
      range: {
        min: 1184,
        max: 1413,
      },
      infos: [
        {
          name: 'Larvae',
          value: '50% of overwintered larvae have begun pupation.',
        },
      ],
    },
    {
      name: '90% Pupation',
      range: {
        min: 1414,
        max: 1521,
      },
      infos: [
        {
          name: 'Larvae',
          value: '90% of overwintered larvae have begun pupation.',
        },
      ],
    },
    {
      name: '10% Adult Emergence',
      range: {
        min: 1522,
        max: 1740,
      },
      infos: [
        {
          name: 'Adults',
          value: '10% of adults from overwintered larvae have emerged.',
        },
      ],
    },
    {
      name: '50% Adult Emergence',
      range: {
        min: 1741,
        max: 1823,
      },
      infos: [
        {
          name: 'Adults',
          value: '50% of adults from overwintered larvae have emerged.',
        },
        {
          name: 'Nut Clusters',
          value: 'Nut clusters should be inspected for eggs.',
        },
      ],
    },
    {
      name: '10% Egg-laying',
      range: {
        min: 1824,
        max: 1936,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Over 50% of adults from overwintered larvae have emerged.',
        },
        {
          name: 'Egg-Laying',
          value: '10% of adults have begun laying eggs.',
        },
        {
          name: 'Egg Hatch',
          value: 'First egg hatch has begun.',
        },
        {
          name: 'Nut Clusters',
          value: 'Nut clusters should be inspected for eggs.',
        },
      ],
    },
    {
      name: '90% Adult Emergence',
      range: {
        min: 1937,
        max: 2100,
      },
      infos: [
        {
          name: 'Adults',
          value: '90% of adults from overwintered larvae have emerged.',
        },
        {
          name: 'Egg-Laying',
          value: '50% of adults have begun laying eggs.',
        },
        {
          name: 'Egg Hatch',
          value: '16% of egg hatch has begun.',
        },
        {
          name: 'Nut Clusters',
          value: '12% entry to nut clusters.',
        },
      ],
    },
    {
      name: '84% Egg-laying',
      range: {
        min: 2101,
        max: 2300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Over 90% of adults from overwintered larvae have emerged.',
        },
        {
          name: 'Egg-Laying',
          value: '84% of adults have begun laying eggs.',
        },
        {
          name: 'Egg Hatch',
          value: '52% of egg hatch has begun.',
        },
        {
          name: 'Nut Clusters',
          value: '25% entry to nut clusters.',
        },
      ],
    },
    {
      name: '80% Egg-hatch',
      range: {
        min: 2301,
        max: 2610,
      },
      infos: [
        {
          name: 'Egg Hatch',
          value: '80% of egg hatch has begun.',
        },
        {
          name: 'Nut Clusters',
          value: '70% entry to nut clusters.',
        },
      ],
    },
    {
      name: '90% Nut Entry',
      range: {
        min: 2611,
        max: 2700,
      },
      infos: [
        {
          name: 'Nut Clusters',
          value: '90% entry to nut clusters.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pecan Nut Casebearer Degree Day Model is used to predict development of life-cycle stages of Pecan Nut Casebearer (Acrobasis nuxvorella), in order to improve pest management decisions',
    'For best results, a biofix date should be set to 10 days before estimated 50% budbreak, or March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-pecan-nut-casebearer-growing-degree-day-model/',
  citations: [
    'Harris, M. K., J. A.Jackman, and D. R.Ring. 1988. Sound management vital to minimize impact of pecan nut casebearer. Pecan South. 22: 4–6.',
  ],
}
