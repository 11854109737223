import { GddModelDefine } from '../gdd'

export const gddAzaleaLaceBug: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddAzaleaLaceBug',
  name: 'Azalea Lace Bug GDD',
  fullName: 'Azalea Lace Bug (Stephanitis pyrioides) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Larvae Emergence',
      range: {
        min: 0,
        max: 206,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered eggs have not yet hatched.',
        },
      ],
    },
    {
      name: 'First Larvae Emergence from Overwintered Eggs',
      range: {
        min: 207,
        max: 300,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae from overwintered pupae have begun to emerge.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Azalea Lace Bug Degree Day Model is used to predict development of life-cycle stages of Azalea Lace Bug (Stephanitis pyrioides), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larve from overwintered eggs.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-azalea-lace-bug-growing-degree-day-model/',
  citations: [
    `Herms, Daniel. Using Degree-Days and Plant Pathology to Predict Pest Activity. Tactics and Tools for IPM. Chapter 11. 49-59.`,
  ],
}
