import { GddModelDefine } from '../gdd'

export const gddMummyBerry: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 80,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddMummyBerry',
  name: 'Mummy Berry Infection Season',
  fullName: 'Mummy Berry (Monilinia vaccinii-corymbosi) Infection Season Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Ascopores Not Yet Released',
      range: {
        min: 0,
        max: 855,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: 'Ascopores have not been released / observed.',
        },
      ],
    },
    {
      name: 'First Observed Ascopore Release',
      range: {
        min: 856,
        max: 1020,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: 'First observed release of ascopores from apothecia.',
        },
        {
          name: 'Bud-break',
          value: 'Approximate timing of bud break for Berkeley variety.',
        },
      ],
    },
    {
      name: 'Average First Ascopore Release',
      range: {
        min: 1021,
        max: 1304,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: 'Average first observed release of ascopores from apothecia.',
        },
      ],
    },
    {
      name: 'Average Last Ascopore Release',
      range: {
        min: 1305,
        max: 1498,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: 'Average first observed release of ascopores from apothecia.',
        },
      ],
    },
    {
      name: 'Last Observed Ascopore Release',
      range: {
        min: 1499,
        max: 1600,
      },
      infos: [
        {
          name: 'Fungus Ascospore',
          value: 'Last observed release of ascopores from apothecia.',
        },
      ],
    },
  ],
  notes: [
    'Disease maturation based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Mummy Berry Infection Season Degree Day Model is used to predict the ascopore maturation and relase of the Mummy Berry fungus, in order to improve pest management decisions.',
    'For best results, a biofix date should be set at January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict ascospore release.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-mummy-berry-growing-degree-day-model/',
  citations: [
    `Hildebrand, P.D. and P.G. Braun. 1991. Factors affecting infection of lowbush blueberry by ascospores of Monilinia vaccinii-corymbosi. Can. J. Plant Pathol. 13:232-240.`,
    `Ramsdell, D.C., J.W. Nelson and R. Myers. 1974. Phytopathology 64:222-228 An Epidemiological Study of Mummy Berry Disease of Highbush Blueberry`,
  ],
}
