import { GddModelDefine } from '../gdd'

export const gddTaxusBudMite: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddTaxusBudMite',
  name: 'Taxus Bud Mite GDD',
  fullName: 'Taxus Bud Mite (Cedidophyopsis psilaspis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 148,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence',
      range: {
        min: 149,
        max: 448,
      },
      infos: [
        {
          name: 'Adults',
          value:
            'Adults from overwintered eggs have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Adult Emergence',
      range: {
        min: 449,
        max: 707,
      },
      infos: [
        {
          name: 'Adult',
          value:
            'Adults from overwintered eggs have fully emerged and treatment window has closed.',
        },
      ],
    },

    {
      name: 'Second Generation Adult Emergence',
      range: {
        min: 708,
        max: 912,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Second Generation Adult Emergence',
      range: {
        min: 913,
        max: 1000,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Second Generation adults have fully emerged and treatment window has closed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Taxus Bud Mite Growing Degree Day Model is used to predict development of life-cycle stages of Taxus Bud Mite (Cedidophyopsis psilaspis), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults and nymphs from overwintered eggs as well as the second generation.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-taxus-bud-mite-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
