import { Me } from '../services/me'

export class Utility {

  static getBiofixDateFormat() {
    if (Me.getUserData().dateFormat === 'europe') {
      return 'd MMM yyyy'
    }
    return 'MMM d yyyy'
  }

  static getElementFullSize(element: HTMLElement) {
    const style = window.getComputedStyle(element)
    return {
      width: element.offsetWidth
        + parseFloat(style.borderLeft || '0')
        + parseFloat(style.borderRight || '0')
        + parseFloat(style.marginLeft || '0')
        + parseFloat(style.marginRight || '0')
        + parseFloat(style.paddingLeft || '0')
        + parseFloat(style.paddingRight || '0'),
      height: element.offsetHeight
        + parseFloat(style.borderTop || '0')
        + parseFloat(style.borderBottom || '0')
        + parseFloat(style.marginTop || '0')
        + parseFloat(style.marginBottom || '0')
        + parseFloat(style.paddingTop || '0')
        + parseFloat(style.paddingBottom || '0'),
    }
  }

  static async wait(ms?: number) {
    await new Promise<void>(resolve => {
      setTimeout(resolve, ms)
    })
  }

}
