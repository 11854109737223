export const stripeSubscriptionTypes = [
  'stripeProMonthly',
  'stripeProYearly',
  'stripeProApiMonthly',
  'stripeProApiFreeTrial',
  'stripeProApiMeteredPerLocation',
  'stripeProApiMeteredPerExternalUser',
] as const
export type StripeSubscriptionType = typeof stripeSubscriptionTypes[number]

export const stripeProductIds = [
  'prod_DRR8cRiZ5RGbJk', // Professional
  'prod_DdrNjrDt9ITys6', // Professional (test)
] as const
export type StripeProductId = typeof stripeProductIds[number]

export interface StripeCouponDefine {
  idLive: string
  idTest: string
  percentOff: number
}

export type StripeCouponName =
  | 'newYear2019Sale'

export const stripeCoupons: {
  [K in StripeCouponName]: StripeCouponDefine
} = {
  newYear2019Sale: {
    idLive: 'FHiWHA3L',
    idTest: '6t6Do3ih',
    percentOff: 50,
  },
} as const
