import { GddModelDefine } from '../gdd'

export const gddSpottedLanternfly: GddModelDefine = {
  lowerThreshold: 7.36,
  upperThreshold: 50,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddSpottedLanternfly',
  name: 'Spotted Lanternfly',
  fullName: 'Spotted Lanternfly (Lycorma delicatula) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Diapause Stage',
      range: {
        min: 0,
        max: 171,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Egg masses are present but in diapause stage.',
        },
      ],
    },
    {
      name: 'Development Stage',
      range: {
        min: 172,
        max: 618,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Egg masses are present and in development stage.',
        },
      ],
    },
    {
      name: 'Nymph Emergence',
      range: {
        min: 619,
        max: 1000,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Egg masses have fully developed.',
        },
        {
          name: 'Nymphs',
          value: 'Nymphs have begun to emerge.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Spotted Lanternfly Degree Day Model is used to predict life-stage developments of the Spotted Lanternfly (Lycorma delicatula), in order to improve pest management decisions.',
    'For best results, the biofix date should be set to the first date that egg masses are discovered, or alternately, September 15 for the Northern Hemisphere. The growing degree day model can then be used to predict timing when egg masses switch from diapause to development stage and then to predict nymph emergence.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-spotted-lanternfly-growing-degree-day-model/',
  citations: [
    'Keena, M.A. and Nielsen, A.L. 2021. Comparison of the Hatch of Newly Laid Lycorma delicatula (Hemiptera: Fulgoridae) Eggs From the United States After Exposure to Different Temperatures and Durations of Low Temperature. Environ. Entomol. 50: 410-417. doi: 10.1093/ee/nvaa177.',
    'Kreitman, D., Keena, M.A., Nielsen, A.L., and Hamilton, G. 2021. Effects of Temperature on Development and Survival of Nymphal Lycorma delicatula (Hemiptera: Fulgoridae). Environ. Entomol. 50: 183-191. doi: 10.1093/ee/nvaa155.',
  ],
}
