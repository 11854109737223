import { GddModelDefine } from '../gdd'

export const gddBroccoliGreenMagic: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 70,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddBroccoliGreenMagic',
  name: 'Broccoli (Green Magic)',
  fullName: 'Broccoli (Green Magic) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Transplanted 2-4 leaves',
      range: {
        min: 0,
        max: 1458,
      },
      infos: [
        {
          name: 'Head Iniation',
          value: 'Not yet to 50% Head Iniation.',
        },
      ],
    },
    {
      name: '50% Head Iniation',
      range: {
        min: 1459,
        max: 2103,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Harvest has not yet begun.',
        },
      ],
    },
    {
      name: 'First Harvest',
      range: {
        min: 2104,
        max: 2456,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Harvest has begun.',
        },
      ],
    },
    {
      name: 'Early Flowering',
      range: {
        min: 2457,
        max: 3000,
      },
      infos: [
        {
          name: 'Flowering',
          value: 'Flowering has begun.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Broccoli Degree Day Model is used to predict harvest time of broccoli based on the weather in a given season.',
    'For best results, a biofix date should be set at transplant when there are 2-4 leaves. The growing degree day model can then be used to predict harvest time.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-broccoli-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
  ],
}
