import { GddModelDefine } from '../gdd'

export const gddPandemisLeafroller: GddModelDefine = {
  lowerThreshold: 41,
  upperThreshold: 85,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPandemisLeafroller',
  name: 'Pandemis Leafroller',
  fullName: 'Pandemis Leafroller (Pandemis pyrusana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence from Overwintering',
      range: {
        min: 0,
        max: 930,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have not emerged.',
        },
      ],
    },
    {
      name: 'First Moth Catch',
      range: {
        min: 931,
        max: 1160,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adult moths have emerged.',
        },
      ],
    },
    {
      name: '50% Moth Emergence',
      range: {
        min: 1161,
        max: 1280,
      },
      infos: [
        {
          name: 'Adults',
          value: '50% of adult moths have emerged.',
        },
      ],
    },
    {
      name: 'First Egg Hatch',
      range: {
        min: 1281,
        max: 1345,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active.',
        },
        {
          name: 'Eggs',
          value: 'Egg hatch has begun.',
        },
      ],
    },
    {
      name: '50% Egg Hatch',
      range: {
        min: 1346,
        max: 1430,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active.',
        },
        {
          name: 'Eggs',
          value: '50% of Egg hatch has occurred.',
        },
      ],
    },
    {
      name: '95% Moth Emergence',
      range: {
        min: 1431,
        max: 2250,
      },
      infos: [
        {
          name: 'Adults',
          value: '95% of adult moths have emerged.',
        },
      ],
    },
    {
      name: 'First Moth Catch - 2nd Flight',
      range: {
        min: 2251,
        max: 2750,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from first generation (2nd flight) have emerged.',
        },
      ],
    },
    {
      name: '50% Moth Emergence - 2nd Flight',
      range: {
        min: 2751,
        max: 2870,
      },
      infos: [
        {
          name: 'Adults',
          value: '50% of adults from first generation (2nd flight) have emerged.',
        },
      ],
    },
    {
      name: 'First Egg Hatch - 2nd Generation',
      range: {
        min: 2871,
        max: 3025,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active.',
        },
        {
          name: 'Eggs',
          value: '2nd generation Egg hatch has begun.',
        },
      ],
    },
    {
      name: '50% Egg Hatch - 2nd Generation',
      range: {
        min: 3026,
        max: 3300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active.',
        },
        {
          name: 'Eggs',
          value: '50% of second generation egg hatch has occurred.',
        },
      ],
    },
    {
      name: '95% Moth Emergence - 2nd Flight',
      range: {
        min: 3301,
        max: 3500,
      },
      infos: [
        {
          name: 'Adults',
          value: '95% of 1st generation (2nd flight) adult moths have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pandemis Leafroller Degree Day Model is used to predict development of life-cycle stages of Pandemis Leafroller (Pandemis pyrusana), in order to improve pest management decisions',
    'For best results, a biofix date should be set for March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-pandemis-leafroller-growing-degree-day-model/',
  citations: [
    'Brunner J. 1991. Leafroller pests of fruit crops in Washington State. In New Directions in Tree Fruit Pest Management. Good Fruit Grower. pp 185-198.',
  ],
}
