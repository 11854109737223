import { GddModelDefine } from '../gdd'

export const gddAmericanPlumBorer: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddAmericanPlumBorer',
  name: 'American Plum Borer',
  fullName: 'American Plum Borer (Euzophera semifuneralis) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Adults Present',
      range: {
        min: 0,
        max: 245,
      },
      infos: [
        {
          name: 'Adults',
          value: 'No adult moths present.',
        },
      ],
    },
    {
      name: 'Adult Flight and Egg-Laying',
      range: {
        min: 246,
        max: 440,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths in flight.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Second Generation Larvae',
      range: {
        min: 441,
        max: 1375,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding.',
        },
        {
          name: 'Adults',
          value: 'Second generation adult moths have not yet emerged.',
        },
      ],
    },
    {
      name: 'Second Generation Adult Moth Flight',
      range: {
        min: 1376,
        max: 1500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adult moths in flight.',
        },
      ],
    },
    {
      name: 'Next Generation Larvae',
      range: {
        min: 1501,
        max: 2000,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are feeding and preparing to overwinter.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The American Plum Borer Degree Day Model is used to predict life-stage developments of the American Plum Borer (Euzophera semifuneralis), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict egg hatch and emergence of larvae which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-american-plum-borer-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
