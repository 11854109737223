export const iapSubscriptionTypes = [
  'iapProOneFieldMonthly',
  'iapProOneFieldYearly',
  'iapProTwoFieldsMonthly',
  'iapProTwoFieldsYearly',
  'iapProThreeFieldsMonthly',
  'iapProThreeFieldsYearly',
  'iapProFourFieldsMonthly',
  'iapProFourFieldsYearly',
  'iapProFiveFieldsMonthly',
  'iapProFiveFieldsYearly',
] as const
export type IapSubscriptionType = typeof iapSubscriptionTypes[number]

export const iapMaxLocationsOptions = [1, 2, 3, 4, 5] as const
export type IapMaxLocationsOption = typeof iapMaxLocationsOptions[number]

