import { GddModelDefine } from '../gdd'

export const gddPacificSpiderMite: GddModelDefine = {
  lowerThreshold: 52,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddPacificSpiderMite',
  name: 'Pacific Spider Mite',
  fullName: 'Pacific Spider Mite (Tetranychus pacificus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'First Generation Eggs',
    range: {
      min: 0,
      max: 94,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have begun to lay eggs.`,
    }],
  }, {
    name: 'First Generation Larvae',
    range: {
      min: 95,
      max: 139,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae are emerging.`,
    }],
  }, {
    name: 'First Generation Protonymphs',
    range: {
      min: 140,
      max: 176,
    },
    infos: [{
      name: 'Protonymphs',
      value: `Larvae have developed into protonymphs.`,
    }],
  }, {
    name: 'First Generation Deutonymphs',
    range: {
      min: 177,
      max: 218,
    },
    infos: [{
      name: 'Deutonymphs',
      value: `Protonymphs have developed into deutonymphs.`,
    }],
  }, {
    name: 'Second Generation Eggs',
    range: {
      min: 219,
      max: 312,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have developed from deutonymphs and have begun to lay eggs.`,
    }],
  }, {
    name: 'Second Generation Larvae',
    range: {
      min: 313,
      max: 357,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae are emerging.`,
    }],
  }, {
    name: 'Second Generation Protonymphs',
    range: {
      min: 358,
      max: 394,
    },
    infos: [{
      name: 'Protonymphs',
      value: `Larvae have developed into protonymphs.`,
    }],
  }, {
    name: 'Second Generation Deutonymphs',
    range: {
      min: 395,
      max: 436,
    },
    infos: [{
      name: 'Deutonymphs',
      value: `Protonymphs have developed into deutonymphs.`,
    }],
  }, {
    name: 'Third Generation Eggs',
    range: {
      min: 437,
      max: 530,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have developed from deutonymphs and have begun to lay eggs.`,
    }],
  }, {
    name: 'Third Generation Larvae',
    range: {
      min: 531,
      max: 575,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae are emerging.`,
    }],
  }, {
    name: 'Third Generation Protonymphs',
    range: {
      min: 576,
      max: 612,
    },
    infos: [{
      name: 'Protonymphs',
      value: `Larvae have developed into protonymphs.`,
    }],
  }, {
    name: 'Third Generation Deutonymphs',
    range: {
      min: 613,
      max: 654,
    },
    infos: [{
      name: 'Deutonymphs',
      value: `Protonymphs have developed into deutonymphs.`,
    }],
  }, {
    name: 'Multiple Generations Overlapping',
    range: {
      min: 655,
      max: 1000,
    },
    infos: [{
      name: 'Multiple Generations',
      value: `Multiple generations have likely begun to overlap, making phenological stage estimates inaccurate.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Pacific Spider Mite Growing Degree Day model can be used to predict phenological stages of Pacific Spider Mites (Tetranychus pacificus). Different types of miticides will affect mite life stages differently.`,
  ],
  citations: [
    `Carey, J. R., and J. W. Bradley. 1982. Developmental rates, vital schedules, sex ratios, and life tables for Tetranychus urticae, T. turkestani and T. pacificus(Acarina: Tetranychidae) on cotton. Acarologia 23: 333-345.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-spider-mite-growing-degree-day-models',
}
