import {
  Component,
  NgZone,
  OnInit,
} from '@angular/core'
import { Router } from '@angular/router'
import { StatusBar } from '@capacitor/status-bar'
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  NavController,
} from '@ionic/angular'
import {
  LocationJson,
} from '@pest-prophet/shared'
import { Api } from '../../services/api'
import { Locations } from '../../services/locations'
import { PageService } from '../../utility/page.service'
import {
  UpgradeAlertController,
  UpgradeAlertReason,
} from '../../utility/upgrade-alert.controller'
import { Analytics } from '../../services/analytics'
import { StoreService } from '../../services/store.service'
import { Tools } from '../../services/tools'
import { Me } from '../../services/me'
import { LocationRouteData } from '../location/location.page'
import { LoginRouteData } from '../login/login.page'

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {

  Me = Me
  Locations = Locations

  locations: LocationJson[] = []
  isLoading = false
  locationImgWidth = 0
  locationImgHeight = 0
  logs: string[] = []

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private actionSheetCtrl: ActionSheetController,
    private upgradeAlertCtrl: UpgradeAlertController,
    private iap: StoreService,
    private zone: NgZone,
    private page: PageService,
  ) {}

  ngOnInit() {
    this.locationImgWidth = document.querySelector('ion-app')!.clientWidth
    this.locationImgHeight = this.locationImgWidth / 2
  }

  ionViewWillEnter() {
    if (Tools.isNative()) {
      void StatusBar.setBackgroundColor({ color: '#ffffff' })
    }

    void this.fetchLocations()
  }

  ionViewDidEnter() {
    Analytics.screenView('Home')
  }

  async fetchLocations() {
    this.isLoading = true

    try {
      this.locations = await Api.get('/locations/')
    } finally {
      this.isLoading = false
    }
  }

  async handleLocationClick(location: LocationJson) {
    if (Locations.isLocationLocked(this.locations, location.id)) {
      const alert = await this.alertCtrl.create({
        header: 'Location Locked',
        message: 'This location currently exceeds your subscription plan. You can unlock it by upgrading your plan.',
        buttons: [
          {
            text: 'Upgrade',
            cssClass: 'alert-upgrade-plan-button',
            handler: async () => {
              if (Tools.currentPaymentPlatform === 'stripe') {
                await this.navCtrl.navigateForward('/account-settings/subscription-stripe/change')
              } else {
                await this.navCtrl.navigateForward('/account-settings/subscription-iap/change')
              }
            },
          },
          {
            text: 'Delete Location',
            cssClass: 'danger',
            handler: () => {
              void (async () => {
                await alert.dismiss()
                void this.handleDeleteLocationClick(location)
              })()
            },
          },
          {
            text: 'Cancel',
            cssClass: 'alert-close-button',
          },
        ],
        backdropDismiss: false,
      })
      await alert.present()
      return
    }

    const loading = await this.loadingCtrl.create({ message: 'Loading...' })
    await loading.present()

    await this.navCtrl.navigateForward(
      '/location',
      this.page.makeRouteData<LocationRouteData>({
        state: {
          location,
        },
      }),
    )

    await loading.dismiss()
  }

  async handleDeleteLocationClick(location: LocationJson) {
    const alert = await this.alertCtrl.create({
      header: 'Delete Location',
      message: 'Are you sure?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            void (async () => {
              await alert.dismiss()

              const loading = await this.loadingCtrl.create({ message: 'Deleting location...' })
              await loading.present()

              try {
                await Api.delete(`/locations/${location.id}`)
              } catch (err) {
                await this.page.error(err)
                throw err
              } finally {
                await loading.dismiss()
              }
            })()
          },
        },
      ],
      backdropDismiss: false,
    })
    await alert.present()
  }

  async handleCreateLocationClick() {
    if (Me.canCreateLocation(this.locations.length)) {
      await this.navCtrl.navigateForward('/create-location')
    } else {
      const alert = await this.upgradeAlertCtrl.create({
        reason: UpgradeAlertReason.LocationLimitReached,
        navCtrl: this.navCtrl,
      })
      await alert.present()
    }
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: 'Account Settings',
          handler: () => {
            void this.navCtrl.navigateForward('/account-settings')
          },
        },
        {
          text: 'Logout',
          role: 'destructive',
          handler: () => {
            void (async () => {
              await actionSheet.dismiss()
              const confirmAlert = await this.alertCtrl.create({
                header: 'Logout',
                message: 'Are you sure?',
                buttons: [
                  {
                    text: 'Cancel',
                  },
                  {
                    text: 'Logout',
                    cssClass: 'danger',
                    handler: () => {
                      void (async () => {
                        await confirmAlert.dismiss()

                        const loading = await this.loadingCtrl.create({ message: 'Logging out...' })
                        await loading.present()

                        await Me.logout()

                        void this.navCtrl.navigateRoot('/login', {
                          ...this.page.makeRouteData<LoginRouteData>({
                            state: {
                              startupComplete: true,
                            },
                          }),
                          animated: true,
                          animationDirection: 'back',
                        })

                        await loading.dismiss()
                      })()

                      return false
                    },
                  },
                ],
                backdropDismiss: false,
              })

              await confirmAlert.present()
            })()

            return false
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    })
    await actionSheet.present()
  }

}
