import * as prisma from '@prisma/client'
import { Prisma } from '@prisma/client'
import { keysTyped } from './object.utils'
import { WeatherHour } from './typebox.utils'
import {
  Override,
  PartialBy,
} from './typescript.utils'

type PrismaInclude<TInclude> = Omit<TInclude, '_count'>

export const includeLocationsOrdered = {
  locations: {
    orderBy: { id: 'asc' },
  },
} as const

export const includeModelsOrdered = {
  models: {
    orderBy: { id: 'asc' },
  },
} as const

export const includeModelDaysOrdered = {
  modelDays: {
    orderBy: { date: 'asc' },
  },
} as const

export const includeWeatherDaysOrdered = {
  weatherDays: {
    orderBy: { date: 'asc' },
  },
} as const

// User
export const userIncludeAll = {
  ...includeLocationsOrdered,
  iapSubscription: true,
  stripeSubscription: true,
  userActivity: {
    orderBy: {
      id: 'asc',
    },
  },
  userDailyMetrics: {
    orderBy: {
      date: 'asc',
    },
  },
} as const

export const userIncludeMe = {
  ...includeLocationsOrdered,
  iapSubscription: true,
  stripeSubscription: true,
} as const

const userWithAll = Prisma.validator<Prisma.UserArgs>()({
  include: userIncludeAll,
})

export type UserCreateInput = PartialBy<Prisma.UserCreateInput, keyof typeof userIncludeAll>
export type User = prisma.User
export type UserWithAll = Prisma.UserGetPayload<typeof userWithAll>
export type UserWithAny = User & Partial<UserWithAll>
export type UserInclude = Prisma.UserInclude
export type UserRelations = PrismaInclude<UserInclude>
export type UserWith<T extends keyof UserRelations> =
  User & Pick<UserWithAll, T>

// Location
export const locationIncludeAll = {
  owner: {
    include: {
      iapSubscription: true,
      stripeSubscription: true,
    },
  },
  ...includeModelsOrdered,
  ...includeWeatherDaysOrdered,
} as const

export const locationIncludeForWeatherUpdate = {
  ...includeModelsOrdered,
  owner: {
    include: {
      iapSubscription: true,
      stripeSubscription: true,
    },
  },
} as const

export const locationIncludeForModelsUpdate = {
  owner: {
    include: {
      iapSubscription: true,
      stripeSubscription: true,
    },
  },
  ...includeModelsOrdered,
  ...includeWeatherDaysOrdered,
} as const

export const locationIncludeModelDays = {
  models: {
    orderBy: {
      id: 'asc',
    },
    include: {
      ...includeModelDaysOrdered,
    },
  },
} as const

const locationWithAll = Prisma.validator<Prisma.LocationArgs>()({
  include: locationIncludeAll,
})

export type LocationCreateInput = PartialBy<Prisma.LocationCreateInput, keyof typeof locationIncludeAll>
export type Location = prisma.Location
export type LocationDecrypted<TLocation extends Location> =
  & TLocation
  & {
    weatherDays: WeatherDayDecrypted[]
  }
export type LocationWithAll = Prisma.LocationGetPayload<typeof locationWithAll>
export type LocationWithAny = Location & Partial<LocationWithAll>
export type LocationInclude = Prisma.LocationInclude
export type LocationFindManyArgs = Prisma.LocationFindManyArgs
export type LocationRelations = PrismaInclude<LocationInclude>
export type LocationWith<T extends keyof LocationRelations>
  = Location & Pick<LocationWithAll, T>

export type LocationForWeatherUpdate =
  & Location
  & { owner: UserWith<'iapSubscription' | 'stripeSubscription'> }
  & { models: Array<Model> }

export type LocationWithModelDays =
  & Location
  & {
    models: ModelWith<'modelDays'>[]
  }

// WeatherDay
export const weatherDayIncludeAll = {
  location: true,
} as const

const weatherDayWithAll = Prisma.validator<Prisma.WeatherDayArgs>()({
  include: weatherDayIncludeAll,
})

export type WeatherDayCreateInput = PartialBy<Prisma.WeatherDayCreateInput, keyof typeof weatherDayIncludeAll>
export type WeatherDayCreateManyInput = Prisma.WeatherDayCreateManyInput
export type WeatherDayUncheckedCreateInput = Prisma.WeatherDayUncheckedCreateInput
export type WeatherDay = prisma.WeatherDay
export type WeatherDayDecrypted = Override<WeatherDay, {
  hours: WeatherHour[]
}>
export type WeatherDayWithAll = Prisma.WeatherDayGetPayload<typeof weatherDayWithAll>
export type WeatherDayWithAny = WeatherDay & Partial<WeatherDayWithAll>
export type WeatherDayRelations = PrismaInclude<Prisma.WeatherDayInclude>
export type WeatherDayWith<T extends keyof WeatherDayRelations> =
  WeatherDay & Pick<WeatherDayWithAll, T>

// Model
export const modelIncludeAll = {
  location: true,
  ...includeModelDaysOrdered,
} as const

const modelWithAll = Prisma.validator<Prisma.ModelArgs>()({
  include: modelIncludeAll,
})

export type ModelCreateInput = PartialBy<Prisma.ModelCreateInput, keyof typeof modelIncludeAll>
export type ModelCreateManyInput = Prisma.ModelCreateManyInput
export type Model = prisma.Model
export type ModelWithAll = Prisma.ModelGetPayload<typeof modelWithAll>
export type ModelWithAny = Model & Partial<ModelWithAll>
export type ModelInclude = Prisma.ModelInclude
export type ModelRelations = PrismaInclude<ModelInclude>
export type ModelWith<T extends keyof ModelRelations> =
  Model & Pick<ModelWithAll, T>

// ModelDay
export const modelDayIncludeAll = {
  model: true,
} as const

const modelDayWithAll = Prisma.validator<Prisma.ModelDayArgs>()({
  include: modelDayIncludeAll,
})

export type ModelDayCreateInput = Override<
  Prisma.ModelDayUncheckedCreateInput,
  {
    date: Date
  }
>
export type ModelDay = prisma.ModelDay
export type ModelDayWithAll = Prisma.ModelDayGetPayload<typeof modelDayWithAll>
export type ModelDayWithAny = ModelDay & Partial<ModelDayWithAll>
export type ModelDayRelations = PrismaInclude<Prisma.ModelDayInclude>
export type ModelDayWith<T extends keyof ModelDayRelations> =
  ModelDay & Pick<ModelDayWithAll, T>

// IapSubscription
export const iapSubscriptionIncludeAll = {
  user: true,
} as const

const iapSubscriptionWithAll = Prisma.validator<Prisma.IapSubscriptionArgs>()({
  include: iapSubscriptionIncludeAll,
})

export type IapSubscriptionCreateInput =
  | IapSubscriptionIosCreateInput
  | IapSubscriptionAndroidCreateInput

export type IapSubscriptionIosCreateInput = Override<PartialBy<Prisma.IapSubscriptionCreateInput, keyof typeof iapSubscriptionIncludeAll>, {
  platform: 'iosAppstore'
}>

export type IapSubscriptionAndroidCreateInput = Override<PartialBy<Prisma.IapSubscriptionCreateInput, keyof typeof iapSubscriptionIncludeAll>, {
  platform: 'androidPlaystore'
}>

export type IapSubscriptionWithAll = Prisma.IapSubscriptionGetPayload<typeof iapSubscriptionWithAll>
export type IapSubscriptionWithAny = IapSubscription & Partial<IapSubscriptionWithAll>
export type IapSubscriptionInclude = Prisma.IapSubscriptionInclude
export type IapSubscriptionRelations = PrismaInclude<IapSubscriptionInclude>
export type IapSubscriptionWith<T extends keyof IapSubscriptionRelations> =
  IapSubscription & Pick<IapSubscriptionWithAll, T>

export type IapSubscription =
  | IapIosSubscription
  | IapAndroidSubscription

export type IapIosSubscription = Override<Omit<prisma.IapSubscription, 'createdAt' | 'updatedAt' | 'userId'>, {
  platform: 'iosAppstore'
}>

export type IapAndroidSubscription = Override<Omit<prisma.IapSubscription, 'createdAt' | 'updatedAt' | 'userId'>, {
  platform: 'androidPlaystore'
}>

// StripeSubscription
export const stripeSubscriptionIncludeAll = {
  user: true,
  discount: true,
} as const

const stripeSubscriptionWithAll = Prisma.validator<Prisma.StripeSubscriptionArgs>()({
  include: stripeSubscriptionIncludeAll,
})

export type StripeSubscriptionCreateInput = PartialBy<Prisma.StripeSubscriptionCreateInput, keyof typeof stripeSubscriptionIncludeAll>
export type StripeSubscription = prisma.StripeSubscription
export type StripeSubscriptionWithAll = Prisma.StripeSubscriptionGetPayload<typeof stripeSubscriptionWithAll>
export type StripeSubscriptionWithAny = StripeSubscription & Partial<StripeSubscriptionWithAll>
export type StripeSubscriptionInclude = Prisma.StripeSubscriptionInclude
export type StripeSubscriptionRelations = PrismaInclude<StripeSubscriptionInclude>
export type StripeSubscriptionWith<T extends keyof StripeSubscriptionRelations> =
  StripeSubscription & Pick<StripeSubscriptionWithAll, T>

// StripeDiscount
export const stripeDiscountIncludeAll = {
  stripeSubscription: true,
} as const

const stripeDiscountWithAll = Prisma.validator<Prisma.StripeDiscountArgs>()({
  include: stripeDiscountIncludeAll,
})

export type StripeDiscountCreateInput = PartialBy<Prisma.StripeDiscountCreateInput, keyof typeof stripeDiscountIncludeAll>
export type StripeDiscount = prisma.StripeDiscount
export type StripeDiscountWithAll = Prisma.StripeDiscountGetPayload<typeof stripeDiscountWithAll>
export type StripeDiscountWithAny = StripeDiscount & Partial<StripeDiscountWithAll>
export type StripeDiscountRelations = PrismaInclude<Prisma.StripeDiscountInclude>
export type StripeDiscountWith<T extends keyof StripeDiscountRelations> =
  StripeDiscount & Pick<StripeDiscountWithAll, T>

// UserActivity
export type UserActivity = prisma.UserActivity

// UserDailyMetrics
export type UserDailyMetrics = prisma.UserDailyMetrics

//
// Enums
//
export const locationStatuses = keysTyped(prisma.LocationStatus)
export type LocationStatus = prisma.LocationStatus

export const autoWeatherUpdateErrors = keysTyped(prisma.AutoWeatherUpdateError)
export type AutoWeatherUpdateError = prisma.AutoWeatherUpdateError

export const modelTypes = keysTyped(prisma.ModelType)
export type ModelType = prisma.ModelType

export const paymentPlatforms = keysTyped(prisma.PaymentPlatform)
export type PaymentPlatform = prisma.PaymentPlatform

export const stripeSubscriptionPriceIds = keysTyped(prisma.StripeSubscriptionPriceId)
export type StripeSubscriptionPriceId = prisma.StripeSubscriptionPriceId

export const stripeSubscriptionStatuses = keysTyped(prisma.StripeSubscriptionStatus)
export type StripeSubscriptionStatus = prisma.StripeSubscriptionStatus

export const iapSubscriptionProductIds = keysTyped(prisma.IapSubscriptionProductId)
export type IapSubscriptionProductId = prisma.IapSubscriptionProductId

export const subscriptionProductIds = [
  ...stripeSubscriptionPriceIds,
  ...iapSubscriptionProductIds,
]
export type SubscriptionProductId =
  | StripeSubscriptionPriceId
  | IapSubscriptionProductId

export const userActivityTypes = keysTyped(prisma.UserActivityType)
export type UserActivityType = prisma.UserActivityType

export const dateFormats = keysTyped(prisma.DateFormat)
export type DateFormat = prisma.DateFormat

export const measurementUnits = keysTyped(prisma.MeasurementUnits)
export type MeasurementUnits = prisma.MeasurementUnits
