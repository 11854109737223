import { GddModelDefine } from '../gdd'

export const gddFruittreeLeafroller: GddModelDefine = {
  lowerThreshold: 41,
  upperThreshold: 82,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddFruittreeLeafroller',
  name: 'Fruittree Leafroller',
  fullName: 'Fruittree Leafroller (Archips argyrospila) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adult Flight Stage from Overwintered Larvae',
      range: {
        min: 0,
        max: 277,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are in flight and may be observed in pheromone traps.`,
        },
        {
          name: 'Eggs',
          value: `Adults have begun to lay eggs.`,
        },
      ],
    },
    {
      name: 'Peak Egg Hatch (First Generation)',
      range: {
        min: 278,
        max: 500,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are in flight and may be observed in pheromone traps.`,
        },
        {
          name: 'Eggs',
          value: `Peak egg hatch.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Fruittree Leafroller Growing Degree Day Model is used to predict life-stage developments of the Fruittree Leafroller (Archips argyrospila) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict emergence of larvae (egg hatch), which can be targeted by insecticide sprays. The biofix needs to be reset for each subsequent generation after adult moth catch.`,
  ],
  citations: [
    `Judd, G. J. R., M. G. T. Gardner, and D. R. Thomson. 1993. Temperature-dependent development and prediction of hatch of overwintered eggs of the fruittree leafroller, Archips argyrospilus(Walker) (Lepidoptera: Tortricidae). Can. Entomol. 125: 945-956.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-fruittree-leafroller-growing-degree-day-model',
}
