import { GddModelDefine } from '../gdd'

export const gddSouthernRedMite: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddSouthernRedMite',
  name: 'Southern Red Mite GDD',
  fullName: 'Southern Red Mite (Oligonychus ilicis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Before Dormant Treatment',
      range: {
        min: 0,
        max: 7,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Overwintered eggs not yet ready for treatment.',
        },
      ],
    },
    {
      name: 'Dormant Treatment',
      range: {
        min: 8,
        max: 91,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Overwintered eggs may be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Dormant Treatment',
      range: {
        min: 92,
        max: 246,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Generation Adult Emergence',
      range: {
        min: 247,
        max: 363,
      },
      infos: [
        {
          name: 'Adults',
          value:
            'Adults from overwintered eggs have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Adult Emergence',
      range: {
        min: 364,
        max: 618,
      },
      infos: [
        {
          name: 'Adult',
          value:
            'Adults from overwintered eggs have fully emerged and treatment window has closed.',
        },
      ],
    },

    {
      name: 'Second Generation Adult Emergence',
      range: {
        min: 619,
        max: 802,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Second Generation Adult Emergence',
      range: {
        min: 803,
        max: 2500,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Second Generation adults have fully emerged and treatment window has closed.',
        },
      ],
    },

    {
      name: 'Third Generation Adult Emergence',
      range: {
        min: 2501,
        max: 2700,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Third Generation Adult Emergence',
      range: {
        min: 2701,
        max: 2800,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Third Generation adults have fully emerged and treatment window has closed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Southern Red Mite Growing Degree Day Model is used to predict development of life-cycle stages of Southern Red Mite (Oligonychus ilicis), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to time dormant treatment and predict emergence of adults and nymphs for the subsequent generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-southern-red-mite-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
