import { GddModelDefine } from '../gdd'

export const gddEuropeanGrapevineMoth: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 86,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddEuropeanGrapevineMoth',
  name: 'European Grapevine Moth',
  fullName: 'European Grapevine Moth (Lobesia botrana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Overwintering Moth Emergence',
      range: {
        min: 0,
        max: 270,
      },
      infos: [
        {
          name: 'Adults',
          value: `Overwintered adult moths begin emergence.`,
        },
      ],
    },
    {
      name: 'Adult Moth Flight',
      range: {
        min: 271,
        max: 450,
      },
      infos: [
        {
          name: 'Adults',
          value: `Overwintered adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Eggs have not yet been laid.`,
        },
      ],
    },
    {
      name: 'First Generation Egg Laying',
      range: {
        min: 451,
        max: 540,
      },
      infos: [
        {
          name: 'Adults',
          value: `Overwintered adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'First Generation Egg Hatch',
      range: {
        min: 541,
        max: 720,
      },
      infos: [
        {
          name: 'Adults',
          value: `Overwintered adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Egg Hatch has begun.`,
        },
      ],
    },
    {
      name: 'Peak Generation Egg Hatch',
      range: {
        min: 721,
        max: 960,
      },
      infos: [
        {
          name: 'Adults',
          value: `95% overwintered adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Egg Hatch near peak.`,
        },
      ],
    },
    {
      name: 'First Generation Adult Moth Flight',
      range: {
        min: 961,
        max: 1275,
      },
      infos: [
        {
          name: 'Adults',
          value: `First Generation adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Eggs have not yet been laid.`,
        },
      ],
    },
    {
      name: 'Second Generation Egg Laying',
      range: {
        min: 1276,
        max: 1395,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'Second Generation Egg Hatch',
      range: {
        min: 1396,
        max: 1595,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Egg Hatch has begun.`,
        },
      ],
    },
    {
      name: 'Peak Second Generation Egg Hatch',
      range: {
        min: 1596,
        max: 1905,
      },
      infos: [
        {
          name: 'Adults',
          value: `95% adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Egg Hatch near peak.`,
        },
      ],
    },
    {
      name: 'Second Generation Adult Moth Flight',
      range: {
        min: 1906,
        max: 2220,
      },
      infos: [
        {
          name: 'Adults',
          value: `Second Generation adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Eggs have not yet been laid.`,
        },
      ],
    },
    {
      name: 'Third Generation Egg Laying',
      range: {
        min: 2221,
        max: 2340,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'Third Generation Egg Hatch',
      range: {
        min: 2341,
        max: 2590,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Egg Hatch has begun.`,
        },
      ],
    },
    {
      name: 'Peak Third Generation Egg Hatch',
      range: {
        min: 2591,
        max: 2900,
      },
      infos: [
        {
          name: 'Adults',
          value: `95% adult moths in flight.`,
        },
        {
          name: 'Eggs',
          value: `Egg Hatch near peak.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The European Grapevine Moth Growing Degree Day Model is used to predict life-stage developments of the European Grapvine Moth (Lobesia botrana) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Cooper M, Varela L, Smith R, Whitmer D, Simmons G, Lucchi A, Broadway R, Steinhauer R. 2014. MANAGING NEWLY ESTABLISHED PESTS: Growers, scientists and regulators collaborate on European grapevine moth program. Calif Agr 68(4):125-133.`,
    `Caffarelli V, Vita G. Heat accumulation for timing grapevine moth control measures. Bulletin SROP. 1988. 11:24-6.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-european-grapevine-moth-growing-degree-day-model',
}
