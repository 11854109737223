import { GddModelDefine } from '../gdd'

export const gddSnowballAphid: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddSnowballAphid',
  name: 'Snowball Aphid GDD',
  fullName: 'Snowball Aphid (Neoceruraphis viburnicola) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 148,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered eggs have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence',
      range: {
        min: 149,
        max: 298,
      },
      infos: [
        {
          name: 'Adults',
          value:
            'Adults from overwintered eggs have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Adult Emergence',
      range: {
        min: 299,
        max: 400,
      },
      infos: [
        {
          name: 'Adult',
          value:
            'Adults from overwintered eggs have fully emerged and treatment window has closed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Snowball Aphid Growing Degree Day Model is used to predict development of life-cycle stages of Snowball Aphid (Neoceruraphis viburnicola), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered eggs, which can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-snowball-aphid-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
