import { GddModelDefine } from '../gdd'

export const gddTurpentineBeetle: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddTurpentineBeetle',
  name: 'Turpentine Beetle',
  fullName: 'Turpentine Beetle (Dendroctonus terebrans) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Overwintered Beetles have not emerged',
      range: {
        min: 0,
        max: 300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult beetles have not yet emerged.',
        },
      ],
    },
    {
      name: 'Parent Beetles Colonizing Brood Material',
      range: {
        min: 301,
        max: 350,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult parent beetles have begun to colonize brood material.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Turpentine Beetle Degree Day Model is used to predict life-stage developments of the Turpentine Beetle (Dendroctonus terebrans), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adult beetles which can be targeted by insecticedes.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-turpentine-beetle-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
