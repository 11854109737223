import { GddModelDefine } from '../gdd'

export const gddTussockMoth: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddTussockMoth',
  name: 'Tussock Moth GDD',
  fullName: 'Tussock Moth (Orgyia leucostigma) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Larvae Emergence',
      range: {
        min: 0,
        max: 192,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have not yet emerged from overwintered eggs.',
        },
      ],
    },
    {
      name: 'First Larvae Emergence',
      range: {
        min: 193,
        max: 298,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First larvae have begun to emerge and may be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Larvae Emergence',
      range: {
        min: 299,
        max: 2145,
      },
      infos: [
        {
          name: 'Larvae',
          value:
            'Larvae from overwintered eggs have fully emerged and the window for treatment has closed.',
        },
      ],
    },
    {
      name: 'Second generation Larvae Emergence',
      range: {
        min: 2146,
        max: 2516,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Second generation larvae have begun to emerge and may be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Second Generation Larvae Emergence',
      range: {
        min: 2517,
        max: 2800,
      },
      infos: [
        {
          name: 'Larvae',
          value:
            'Second generation larvae have fully emerged and the window for treatment has closed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Tussock Moth Growing Degree Day Model is used to predict development of life-cycle stages of Tussock Moth (Orgyia leucostigma), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae from overwintered eggs, as well as the second generation of larvae, which can be targeted for treatment.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-tussock-moth-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
