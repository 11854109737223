import { powderyMildewModelCommon, PowderyMildewModelDefine } from './powdery-mildew'

export const powderyMildewCherry: PowderyMildewModelDefine = {
  ...powderyMildewModelCommon,
  lowerTempRiskThreshold: 20,
  upperTempRiskThreshold: 30,
  excessiveTempThreshold: 35,
  consecutiveRiskHoursRequired: 6,
  type: 'powderyMildewCherry',
  name: 'Cherry Powdery Mildew',
  fullName: 'Adapted Gubler-Thomas Cherry Powdery Mildew Risk Index Model',
  stages: [
    {
      name: 'Low',
      range: {
        min: 0,
        max: 30,
      },
      sprayLevel: 'low',
      infos: [
        {
          name: 'Biologicals',
          value: `See Label **`,
        },
        {
          name: 'Copper, Sulfur',
          value: `14 Days **`,
        },
        {
          name: 'Bicarbonates',
          value: `10 Days **`,
        },
        {
          name: 'DMI Fungicides*',
          value: `18 Days **`,
        },
        {
          name: 'Oils',
          value: `14 Days **`,
        },
        {
          name: 'Strobilurins',
          value: `14 Days`,
        },
      ],
    },
    {
      name: 'Moderate',
      range: {
        min: 40,
        max: 60,
      },
      sprayLevel: 'medium',
      infos: [
        {
          name: 'Biologicals',
          value: `See Label`,
        },
        {
          name: 'Copper, Sulfur',
          value: `10 Days`,
        },
        {
          name: 'Bicarbonates',
          value: `8 Days`,
        },
        {
          name: 'DMI Fungicides *',
          value: `10 Days`,
        },
        {
          name: 'Oils',
          value: `10 Days`,
        },
        {
          name: 'Strobilurins',
          value: `14 Days`,
        },
      ],
    },
    {
      name: 'High',
      range: {
        min: 70,
        max: 100,
      },
      sprayLevel: 'high',
      infos: [
        {
          name: 'Biologicals',
          value: `See Label ***`,
        },
        {
          name: 'Copper, Sulfur',
          value: `7 Days ***`,
        },
        {
          name: 'Bicarbonates',
          value: `7 Days ***`,
        },
        {
          name: 'DMI Fungicides*',
          value: `10 Days ***`,
        },
        {
          name: 'Oils',
          value: `7 Days ***`,
        },
        {
          name: 'Strobilurins',
          value: `7 Days ***`,
        },
      ],
    },
  ],
  notes: [
    `* Demethylation inhibitors such as tebuconazole or myclobutanil.`,
    `** Or label maximum`,
    `*** Or label minimum`,
  ],
  description: [
    `This model calculates a daily (0-100) risk index for Cherry Powdery Mildew (Podosphaera clandestina) infection based on optimum temperatures for reproduction at the conidial stage. Use of the model should begin at the first visible signs of powdery mildew infection, or 2 weeks after the first full bloom.`,
    `Rotating sprays with different active ingredients is recommended to reduce risk of resistance. Always consult product label for necessary environmental conditions before spraying, as well as proper handling/safety precautions.`,
  ],
  citations: [
    `Grove, Gary G., Robin J. Boal, and L. H. Bennett. 2000. Managing powdery mildew of cherry in Washington orchards and nurseries with spray oils. Plant Health Progress Online.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-cherry-powdery-mildew-risk-index-model',
}
