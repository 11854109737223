import {
  ComponentFactoryResolver,
  Injectable,
  Type,
  ViewContainerRef,
} from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class ComponentCreatorService {

  rootViewContainerRef!: ViewContainerRef

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  init(rootViewContainerRef: ViewContainerRef) {
    this.rootViewContainerRef = rootViewContainerRef
  }

  create<T>(componentType: Type<T>) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(componentType)
    const component = factory.create(this.rootViewContainerRef.parentInjector)
    this.rootViewContainerRef.insert(component.hostView)
  }

}
