import { Injectable } from '@angular/core'
import {
  Resolve,
} from '@angular/router'
import { NavController } from '@ionic/angular'
import { Me } from '../services/me'
import { StartupService } from '../services/startup.service'

@Injectable({
  providedIn: 'root',
})
export class MeResolver implements Resolve<Me | undefined> {

  constructor(
    private startup: StartupService,
    private navCtrl: NavController,
  ) {}

  async resolve() {
    await this.startup.waitForComplete()

    try {
      await Me.autoLogin()
      return Me
    } catch (err) {
      console.error(err)
      await this.navCtrl.navigateRoot('/login')
      return undefined
    }
  }

}
