import {
  Directive,
  ElementRef,
  HostListener,
} from '@angular/core'
import { Tools } from '../services/tools'

@Directive({
  selector: '[fcEnterBlur]',
})
export class EnterBlurDirective {

  constructor(
    private elementRef: ElementRef,
  ) {}

  @HostListener('keydown', ['$event'])
  handleInputChange(e: KeyboardEvent) {
    if (Tools.isDesktop()) {
      return
    }

    const code = e.keyCode || e.which
    if (code === 13) {
      e.preventDefault()
      const input = this.elementRef.nativeElement.querySelector('input')
      input.blur()
    }
  }

}
