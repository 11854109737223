import { Type } from '@sinclair/typebox'
import {
  TypeBasic,
  TypeBody,
  TypeData,
  TypeResponse,
} from '../utils/typebox.utils'
import { apiSchemaInternal } from './api-schema.internal'
import { apiTags } from './api-tags'

export const apiSchemaV1 = {

  '/info': {
    '/models': {
      get: {
        summary: 'Get list of models',
        tags: [apiTags.info],
        response: {
          200: TypeBasic.Array(TypeData.ModelInfoMinimal()),
        },
      }
    },

    '/models/:type': {
      get: {
        summary: 'Get model details',
        tags: [apiTags.info],
        params: Type.Object({
          type: TypeData.ModelType(),
        }),
        response: {
          200: TypeData.ModelInfo(),
        },
      },
    },
  },

  '/locations': {
    '/': {
      post: apiSchemaInternal['/locations']['/'].post,
      get: apiSchemaInternal['/locations']['/'].get,
    },

    '/:locationId': {
      get: apiSchemaInternal['/locations']['/:locationId'].get,
      patch: apiSchemaInternal['/locations']['/:locationId'].patch,
      delete: apiSchemaInternal['/locations']['/:locationId'].delete,
    },

    '/:locationId/models': {
      get: apiSchemaInternal['/locations']['/:locationId/models'].get,
      post: apiSchemaInternal['/locations']['/:locationId/models'].post,
      delete: apiSchemaInternal['/locations']['/:locationId/models'].delete,
    },

    '/:locationId/models/:modelId': {
      get: apiSchemaInternal['/locations']['/:locationId/models/:modelId'].get,
      patch: apiSchemaInternal['/locations']['/:locationId/models/:modelId'].patch,
      delete: apiSchemaInternal['/locations']['/:locationId/models/:modelId'].delete,
    },

    '/:locationId/weather-days': {
      get: apiSchemaInternal['/locations']['/:locationId/weather-days'].get,
      patch: apiSchemaInternal['/locations']['/:locationId/weather-days'].patch,
      delete: apiSchemaInternal['/locations']['/:locationId/weather-days'].delete,
    },

    '/:locationId/retry-weather-update': {
      post: apiSchemaInternal['/locations']['/:locationId/retry-weather-update'].post,
    },

    '/bulk/weather-days': {
      patch: {
        summary: 'Add/update multiple weather days in the location',
        tags: [apiTags.locationWeatherDay],
        body: Type.Record(
          Type.Number({ description: 'locationId' }),
          Type.Array(TypeBody.WeatherDayCreate({
            additionalProperties: false,
          })),
        ),
        response: {
          200: TypeResponse.Ok(),
        },
      },

      delete: {
        summary: 'Delete multiple weather days in the location',
        tags: [apiTags.locationWeatherDay],
        body: Type.Record(
          Type.Number(),
          TypeData.ModelDayDateRange({
            additionalProperties: false,
          }),
        ),
        response: {
          200: TypeResponse.Ok(),
        },
      },
    },
  },

  '/users': {
    '/me': {
      get: apiSchemaInternal['/users']['/me'].get,
      patch: apiSchemaInternal['/users']['/me'].patch,
    },

    '/me/api-key': {
      post: apiSchemaInternal['/users']['/me/api-key'].post,
    },

    '/me/enable-encryption': {
      post: apiSchemaInternal['/users']['/me/enable-encryption'].post,
    },

    '/me/disable-encryption': {
      post: apiSchemaInternal['/users']['/me/disable-encryption'].post,
    },
  },

}
