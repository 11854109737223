import {
  Static,
  TSchema,
} from '@sinclair/typebox'
import {
  AxiosError,
} from 'axios'
import { apiSchemaInternal } from '../schemas/api-schema.internal'
import { apiSchemaV1 } from '../schemas/api-schema.v1'
import { ApiErrorType } from './error.utils'
import {
  OmitPropertiesOfType,
  RequiredKeys,
  ValueOr,
} from './typescript.utils'

export type RequestMethod =
  | 'get'
  | 'post'
  | 'put'
  | 'patch'
  | 'delete'

export const apiHeaders = {
  adminKey: 'x-admin-key',
  apiKey: 'x-api-key',
  appEngineCron: 'x-appengine-cron',
  firebaseIdToken: 'x-firebase-id-token',
  firebaseUid: 'x-firebase-uid',
  stripeSignature: 'stripe-signature', // Header sent from Stripe events.
} as const

export const ok = {
  ok: true,
} as const

export const apiSchemas = {
  internal: apiSchemaInternal,
  v1: apiSchemaV1,
} as const

export type ApiSchemas = typeof apiSchemas
export type ApiVersion = keyof ApiSchemas

export const apiVersionPaths = {
  internal: '/internal',
  v1: '/v1',
} as const

export type ApiFullPaths<
  TApiVersion extends ApiVersion,
> = keyof {
  [K in keyof ApiSchemas[TApiVersion] as `${K & string}${keyof ApiSchemas[TApiVersion][K] & string}`]: ApiSchemas[TApiVersion][K]
}

export type ApiFullTypedPaths = TypedPath<ApiFullPaths<'internal'>>
export type ApiV1FullTypedPaths = TypedPath<ApiFullPaths<'v1'>>

export type ApiFullPathToRoute<
  TApiVersion extends ApiVersion,
  TFullPath extends ApiFullPaths<TApiVersion>,
  TApiSchema extends ApiSchemas[TApiVersion] = ApiSchemas[TApiVersion],
  TBasePaths extends keyof TApiSchema = keyof TApiSchema,
> =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TFullPath extends `${infer TSlash}${infer TBasePath}/${infer TSubPath}`
    ? TApiSchema[`/${TBasePath}` & TBasePaths][`/${TSubPath}` & keyof TApiSchema[`/${TBasePath}` & TBasePaths]]
    : void

export type ApiSchemaTypedPaths<TApiVersion extends ApiVersion> = {
  [TBasePath in keyof ApiSchemas[TApiVersion]]: {
    [TPath in keyof ApiSchemas[TApiVersion][TBasePath] as TypedPath<TPath>]: ApiSchemas[TApiVersion][TBasePath][TPath]
  }
}

export type ApiSchemaFullTypedPaths<TApiVersion extends ApiVersion> = {
  [TFullPath in ApiFullPaths<TApiVersion> as TypedPath<TFullPath>]: ApiFullPathToRoute<TApiVersion, TFullPath>
}

export type ApiV1SchemaFullTypedPaths = {
  [TFullPath in ApiFullPaths<'v1'> as TypedPath<TFullPath>]: ApiFullPathToRoute<'v1', TFullPath>
}

export type TypedPath<T> =
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends `${infer L}:${infer TParam}/${infer R}`
    ? TypedPath<`${L}${number}/${R}`>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    : T extends `${infer L}:${infer TParam}`
      ? TypedPath<`${L}${number}`>
      : T

export type StaticOrVoid<T> =
  T extends TSchema
    ? Static<T> extends never
      ? void
      : Static<T>
    : void

export type RouteOptionsUnknown = {
  body?: unknown
  query?: unknown
  params?: unknown
  headers?: unknown
  response?: unknown
}

// The schema requires that response data be nested under the status code, so we need one extra step
// to get the actual response data.
export type ResponseData<TResponse> =
  TResponse extends Record<string | number, infer TData>
    ? TData
    : void

export type RouteResponse<
  TApiVersion extends ApiVersion,
  TFullTypedPath extends keyof ApiSchemaFullTypedPaths<TApiVersion>,
  TMethod extends keyof ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath] & RequestMethod,
  TRouteMethodSchema extends ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath][TMethod] = ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath][TMethod],
> = StaticOrVoid<ResponseData<ValueOr<TRouteMethodSchema, 'response', void>>>

// Gets type of params, query, body, or response for the given route.
export type RouteData<
  TApiVersion extends ApiVersion,
  TFullTypedPath extends keyof ApiSchemaFullTypedPaths<TApiVersion>,
  TMethod extends keyof ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath] & RequestMethod,
  TDataType extends keyof RouteOptionsUnknown,
  TRouteMethodSchema extends ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath][TMethod] = ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath][TMethod],
> =
  TDataType extends 'response'
    ? StaticOrVoid<ResponseData<ValueOr<TRouteMethodSchema, TDataType, void>>>
    : StaticOrVoid<ValueOr<TRouteMethodSchema, TDataType, void>>

// For creating conditional options parameter in api requests.
export type ApiRequestOptions<
  TApiVersion extends ApiVersion,
  TFullTypedPath extends keyof ApiSchemaFullTypedPaths<TApiVersion>,
  TMethod extends keyof ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath] & RequestMethod,
  TRouteSchema = ApiSchemaFullTypedPaths<TApiVersion>[TFullTypedPath][TMethod],
  TOptions = OmitPropertiesOfType<{
    body: TRouteSchema extends { body: infer TBody }
      ? TBody extends TSchema
        ? Static<TBody>
        : void
      : void

    query?: TRouteSchema extends { query: infer TQuery }
      ? TQuery extends TSchema
        ? RequiredKeys<Static<TQuery>> extends never
          ? Static<TQuery> | undefined
          : Static<TQuery>
        : void
      : void

    retries?: number
  }, void>,
> =
  Required<TOptions> extends { query: unknown } | { body: unknown }
    ? RequiredKeys<TOptions> extends never
      ? [TOptions?]
      : [TOptions]
    : [TOptions?]

// Final error type that gets sent to client.
export type ApiErrorJson = {
  kind: 'ApiError'
  message: string
  type: ApiErrorType
  sourceError?: any
}

export type ApiErrors = {
  kind: 'ApiErrors'
  errors: ApiErrorJson[]
}

export function isAxiosError(err: any): err is AxiosError {
  return err.response?.data != null
}

export function isAxiosApiError(err: any): err is AxiosError<ApiErrors> {
  return err.response?.data?.kind === 'ApiErrors'
}

export function isApiError(err: any): err is ApiErrorJson {
  return err.kind === 'ApiError'
}
