import { GddModelDefine } from '../gdd'

export const gddHemlockScale: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddHemlockScale',
  name: 'Hemlock Scale GDD',
  fullName: 'Hemlock Scale (Hemiberlesia ithacae) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Pre-Dormant Treatment',
      range: {
        min: 0,
        max: 35,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Overwintered nymphs not yet ready for treatment.',
        },
      ],
    },
    {
      name: 'Dormant Treatment',
      range: {
        min: 36,
        max: 121,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Overwintered nymphs may be targeted for treatment.',
        },
      ],
    },

    {
      name: 'No Crawler Emergence',
      range: {
        min: 122,
        max: 1388,
      },
      infos: [
        {
          name: 'Crawlers',
          value: 'Crawlers from overwintered nymphs have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Crawler Emergence - First Generation',
      range: {
        min: 1389,
        max: 2154,
      },
      infos: [
        {
          name: 'Crawlers',
          value: 'First crawlers from overwintered nymphs have begun to emerge.',
        },
      ],
    },
    {
      name: 'End of First Generation Crawler Emergence',
      range: {
        min: 2155,
        max: 2300,
      },
      infos: [
        {
          name: 'Crawlers',
          value: 'Crawlers have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Hemlock Scale Growing Degree Day Model is used to predict development of life-cycle stages of Hemlock Scale (Hemiberlesia ithacae), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of crawlers as well as the treatment timing for overwintered nymphs.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-hemlock-scale-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
