import { GddModelDefine } from '../gdd'

export const gddSunflowerMoth: GddModelDefine = {
  lowerThreshold: 13.3,
  upperThreshold: 50,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddSunflowerMoth',
  name: 'Sunflower Moth',
  fullName: 'Sunflower Moth (Homoeosoma electellum) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 1114,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults may be present, but have not reached peak flight',
        },
      ],
    },
    {
      name: 'Peak Flight - 1st Generation',
      range: {
        min: 1115,
        max: 2530,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are peak flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak Flight - 2nd Generation',
      range: {
        min: 2531,
        max: 3000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation adults are in peak flight.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Sunflower Moth Degree Day Model is used to predict development of life-cycle stages of the Sunflower Moth (Homoeosoma electellum), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-sunflower-moth-growing-degree-day-model/',
  citations: [
    `Carlson, E. C., R. L. Witt and J. C. Miller. 1978. Tracing the flight activity of the sunflower moth. Calif. Agric. 32: 8-9.`,
  ],
}
