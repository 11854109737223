import { GddModelDefine } from '../gdd'

export const gddVoriaRuralis: GddModelDefine = {
  lowerThreshold: 45.5,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddVoriaRuralis',
  name: 'Voria Ruralis',
  fullName: 'Voria Ruralis Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs and larvae.',
      range: {
        min: 0,
        max: 227,
      },
      infos: [
        {
          name: 'Eggs and Larvae',
          value: 'Eggs and larvae are present, pupation has not yet occurred',
        },
      ],
    },
    {
      name: 'Pupation',
      range: {
        min: 228,
        max: 424,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Pupation has begun, adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 425,
        max: 500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have emerged and begun laying next generation eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Voria Ruralis Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Voria Ruralis, in order to improve pest management practices.',
    'For best results, a biofix date should be set when Voria Ruralis are first observed or introduced to field. The growing degree day model can then be used to predict emergence of adults and next generation eggs.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-voria-ruralis-growing-degree-day-model/',
  citations: [
    'Jackson, C. G., G. D. Butler, Jr., and D. E. Bryan. 1969. Time required for development of Voria ruralis and its host, the cabbage looper, at different temperatures. J. Econ. Entomol. 62: 69-70.',
  ],
}
