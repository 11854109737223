import { GddModelDefine } from '../gdd'

export const gddBeetArmyworm: GddModelDefine = {
  lowerThreshold: 54,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddBeetArmyworm',
  name: 'Beet Armyworm',
  fullName: 'Beet Armyworm (Spodoptera exigua) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adult Moth Activity / Eggs',
      range: {
        min: 0,
        max: 94,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'First Generation Larvae',
      range: {
        min: 95,
        max: 564,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Peak of first generation larvae.`,
        },
      ],
    },
    {
      name: 'First Generation Pupae',
      range: {
        min: 565,
        max: 882,
      },
      infos: [
        {
          name: 'Pupae',
          value: `Peak of first generation pupae.`,
        },
      ],
    },
    {
      name: 'First Generation Adult Moths',
      range: {
        min: 883,
        max: 976,
      },
      infos: [
        {
          name: 'Adults',
          value: `First generation adult moths present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Beet Armyworm Growing Degree Day Model is used to predict life-stage developments of the Beet Armyworm (Spodoptera exigua) in order to improve pest management decisions.`,
    `For best results, traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Hogg, D. B., and A. P. Gutierrez. 1980. A model of the flight phenology of the beet armyworm, Spodoptera exigua (Lepidoptera: Noctuidae), in central California. Hilgardia: 48: 1-36.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-beet-armyworm-growing-degree-day-model',
}
