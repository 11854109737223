import { GddModelDefine } from '../gdd'

export const gddOakBlotchLeafminer: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddOakBlotchLeafminer',
  name: 'Oak Blotch Leafminer GDD',
  fullName: 'Oak Blotch Leafminer (Cameraria spp.) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Larvae Emergence',
      range: {
        min: 0,
        max: 533,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Generation Larvae Emergence',
      range: {
        min: 534,
        max: 912,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have begun to emerge.',
        },
      ],
    },
    {
      name: 'End of First Generation Larvae Emergence',
      range: {
        min: 299,
        max: 1029,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have all emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Oak Blotch Leafminer Degree Day Model is used to predict development of life-cycle stages of Oak Blotch Leafminer (Cameraria spp.), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted for treatment with insecticides.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-oak-blotch-leafminer-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
