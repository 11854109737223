import { GddModelDefine } from '../gdd'

export const gddWhitePineAphid: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddWhitePineAphid',
  name: 'White Pine Aphid GDD',
  fullName: 'White Pine Aphid (Cinara strobi) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 7,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs from overwintered eggs have not yet emerged.',
        },
      ],
    },

    {
      name: 'First Generation Nymph Emergence',
      range: {
        min: 8,
        max: 121,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs from overwintered eggs have begun to emerge.',
        },
        {
          name: 'Adults',
          value: 'Adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Generation Adult Emergence',
      range: {
        min: 122,
        max: 246,
      },
      infos: [
        {
          name: 'Adults',
          value:
            'Adults from overwintered eggs have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End First Generation Adult Emergence',
      range: {
        min: 247,
        max: 1917,
      },
      infos: [
        {
          name: 'Adult',
          value:
            'Adults from overwintered eggs have fully emerged and treatment window has closed.',
        },
      ],
    },
    {
      name: 'Second Generation Adult Emergence',
      range: {
        min: 1918,
        max: 2271,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have begun to emerge and can be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Second Generation Adult Emergence',
      range: {
        min: 2272,
        max: 2500,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Second generation adults have fully emerged and treatment window has closed.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The White Pine Aphid Growing Degree Day Model is used to predict development of life-cycle stages of White Pine Aphid (Cinara strobi), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of nymphs and adults from overwintered eggs, as well as the next generations of adults, which can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-white-pine-aphid-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
