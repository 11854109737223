import { GddModelDefine } from '../gdd'

export const gddGalerucellaCalmariensis: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 100,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddGalerucellaCalmariensis',
  name: 'Galerucella Calmariensis',
  fullName: 'Galerucella Calmariensis Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Pre-Overwintered Adult Egg-Laying',
      range: {
        min: 0,
        max: 180,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have not yet emerged and begun egg-laying.',
        },
      ],
    },
    {
      name: 'First Overwintered Adult Egg-Laying',
      range: {
        min: 181,
        max: 233,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have begun egg-laying.',
        },
      ],
    },
    {
      name: '10% Overwintered Adult Egg-Laying',
      range: {
        min: 234,
        max: 461,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have reached 10% egg-laying.',
        },
      ],
    },
    {
      name: 'Peak Overwintered Adult Egg-Laying',
      range: {
        min: 462,
        max: 755,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have reached peaked egg-laying.',
        },
      ],
    },
    {
      name: 'Peak First Generation Larvae',
      range: {
        min: 756,
        max: 895,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have peaked.',
        },
      ],
    },
    {
      name: '10% First Generation Teneral Adults Sensitive to Photoperiod',
      range: {
        min: 896,
        max: 1616,
      },
      infos: [
        {
          name: 'Adults',
          value:
            '10% of first generation teneral adults have emerged and are sensitive to photoperiod.',
        },
      ],
    },
    {
      name: 'Peak First Generation Adult Egg-Laying',
      range: {
        min: 1617,
        max: 1836,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults have reached peaked egg-laying.',
        },
      ],
    },
    {
      name: 'Peak Second Generation Larvae',
      range: {
        min: 1837,
        max: 1910,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Second generation larvae have peaked.',
        },
      ],
    },
    {
      name: '10% Second Generation Teneral Adults Sensitive to Photoperiod',
      range: {
        min: 1911,
        max: 2771,
      },
      infos: [
        {
          name: 'Adults',
          value:
            '10% of second generation teneral adults have emerged and are sensitive to photoperiod.',
        },
      ],
    },
    {
      name: 'Peak Second Generation Adult Egg-Laying',
      range: {
        min: 2772,
        max: 2778,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have reached peaked egg-laying.',
        },
      ],
    },
    {
      name: '10% Third Generation Teneral Adults Sensitive to Photoperiod',
      range: {
        min: 2779,
        max: 3000,
      },
      infos: [
        {
          name: 'Adults',
          value:
            '10% of third generation teneral adults have emerged and are sensitive to photoperiod.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Galerucella Calmariensis Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Galerucella Calmariensis, in order to improve pest management practices.',
    'For best results, a biofix date should be set to January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of overwintered adults and egg-hatch, larvae and adult emergence of subsequent generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-galerucella-calmariensis-growing-degree-day-model/',
  citations: [
    'McAvoy, T.J. and L.T. Kok. 2004. Temperature dependent development and survival of two sympatric species, Galerucella calmariensis and G. pusilla, on purple loosestrife. BioControl 49:467-480.,',
    'McAvoy, T.J., L.T. Kok, and W.T. Mays. 1997. Phenology of an Established Population of Galerucella calmariensis (L.) and G. pusilla (Duft.) (Coleoptera: Chrysomelidae) on Purple Loosestrife, Lythrum salicaria L. (Lythraceae), in Southwest Virginia. Biological Control 9:106-111.],',
  ],
}
