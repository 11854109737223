import { gddAlmondNavelOrangeworm } from './gdd-almond-navel-orangeworm'

export const gddAlmondNavelOrangewormNewCrop = gddAlmondNavelOrangeworm.variety('New Crop', {
  lowerThreshold: 55.0,
  upperThreshold: 93.9,
  cutoffMethod: 'vertical',

  type: 'gddAlmondNavelOrangewormNewCrop',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adult Flight / Eggs',
      range: {
        min: 0,
        max: 738,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths may be present.`,
        },
        {
          name: 'Eggs',
          value: `Egg laying has begun.`,
        },
      ],
    },
    {
      name: 'Next Generation Adults',
      range: {
        min: 739,
        max: 1478,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adult moths are present.`,
        },
        {
          name: 'Eggs',
          value: `Next generation egg laying has begun.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Almond Navel Orangeworm Growing Degree Day Model is used to predict life-stage developments of the Almond Navel Orangeworm (Amyelois transitella) on almonds in order to improve pest management decisions.`,
    `For best results, traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict the emergence of the next generation of adults.`,
  ],
  citations: [
    `Sanderson, J. P., M. M. Barnes, and W. S. Seaman. 1989. Synthesis and validation of a degree-day model for navel orangeworm (Lepidoptera: Pyralidae) development in California almond orchards. Environ. Ent. 18: 612-617.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-navel-orangeworm-growing-degree-day-model',
})
