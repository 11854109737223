import { GddModelDefine } from '../gdd'

export const gddSafflower: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddSafflower',
  name: 'Safflower',
  fullName: 'Safflower (Saffire) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Seed Planted',
      range: {
        min: 0,
        max: 340,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has not broken through soil.',
        },
      ],
    },
    {
      name: 'Shoot Break through soil.',
      range: {
        min: 341,
        max: 410,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has broken through the soil.',
        },
      ],
    },
    {
      name: 'First true leaf unfolds.',
      range: {
        min: 411,
        max: 1160,
      },
      infos: [
        {
          name: 'First leaf.',
          value: 'First true leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Rosette Stage.',
      range: {
        min: 1161,
        max: 1900,
      },
      infos: [
        {
          name: 'Rosette Stage.',
          value: 'Immature primary bud increases to max diameter.',
        },
      ],
    },
    {
      name: 'Bud Initiation',
      range: {
        min: 1901,
        max: 2270,
      },
      infos: [
        {
          name: 'Bud Initiation',
          value: 'Miniature floral head about .25 inches diameter.',
        },
      ],
    },
    {
      name: 'Secondary Branches Begin to Form.',
      range: {
        min: 2271,
        max: 2650,
      },
      infos: [
        {
          name: 'Secondary Branches',
          value: 'Secondary branches beginning to form from top down.',
        },
      ],
    },
    {
      name: 'Onset of Anthesis.',
      range: {
        min: 2651,
        max: 2950,
      },
      infos: [
        {
          name: 'Flowers',
          value: '1-10% Flowering.',
        },
      ],
    },
    {
      name: 'Anthesis complete when florets have wilted.',
      range: {
        min: 2951,
        max: 3320,
      },
      infos: [
        {
          name: 'Anthesis',
          value: 'Anthesis complete when all florets have wilted.',
        },
      ],
    },
    {
      name: 'Seeds Mature.',
      range: {
        min: 3321,
        max: 3431,
      },
      infos: [
        {
          name: 'Seeds',
          value:
            'Seeds are mature when the seed hull loses opaqueness. Little green color on leaves and heads.',
        },
      ],
    },
    {
      name: 'Direct Harvest',
      range: {
        min: 3432,
        max: 3600,
      },
      infos: [
        {
          name: 'Harvest',
          value:
            'Most of leaves brown with hint of green. Plants dry not brittle, seeds rub easily from heads.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Safflower Degree Day Model is used to predict harvest time and other growth stages of early Safflower (Saffire) based on the weather in a given season.',
    'For best results, a biofix date should be set at planting. The growing degree day model can then be used to predict harvest time. For best results the biofix should be reset when later stages have been observed, in order to more accurately predict the next stage.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-safflower-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Stu Brandt, Scott, SK 1993-97 and Perry Miller, Swift Current, SK 1995-98`,
  ],
}
