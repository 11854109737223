import { GddModelDefine } from '../gdd'

export const gddAlfalfaWeevil: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 87.8,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddAlfalfaWeevil',
  name: 'Alfalfa Weevil',
  fullName: 'Alfalfa Weevil (Hypera postica) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adult / Eggs',
      range: {
        min: 0,
        max: 196,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are active.`,
        },
        {
          name: 'Eggs',
          value: `Adults have begun to lay eggs.`,
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 197,
        max: 504,
      },
      infos: [
        {
          name: 'Larvae',
          value: `Eggs have begun to hatch and larvae have emerged.`,
        },
      ],
    },
    {
      name: 'Prepupae',
      range: {
        min: 505,
        max: 565,
      },
      infos: [
        {
          name: 'Prepupae',
          value: `Larvae have developed into the prepupae stage.`,
        },
      ],
    },
    {
      name: 'Pupae',
      range: {
        min: 566,
        max: 701,
      },
      infos: [
        {
          name: 'Pupae',
          value: `Prepupae have developed into the pupae stage.`,
        },
      ],
    },
    {
      name: 'Next Generation Adult / Eggs',
      range: {
        min: 702,
        max: 797,
      },
      infos: [
        {
          name: 'Adults',
          value: `Adults are active.`,
        },
        {
          name: 'Eggs',
          value: `Adult have begun to lay eggs.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Alfalfa Weevil Growing Degree Day Model is used to predict life-stage developments of the Alfalfa Weevil (Hypera postica) in order to improve pest management decisions.`,
    `For best results, sweep nets should be used early in the season to monitor for insect presence. The biofix should be adjusted to represent the first date that adult weevils have been caught consistently. The growing degree day model can then be used to predict emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Guppy, J. C., and M. K. Mukerji. 1974. Effects of temperature on developmental rate of the immature stages of the alfalfa weevil, Hypera postica (Coleoptera: Curculionidae). Can. Ent. 106: 93-100.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-alfalfa-weevil-growing-degree-day-model',
}
