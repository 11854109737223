import { powderyMildewCherry } from './powdery-mildew-cherry'
import { powderyMildewGrape } from './powdery-mildew-grape'
import { powderyMildewHops } from './powdery-mildew-hops'

export * from './powdery-mildew'

export const powderyMildewModelDefines = {
  powderyMildewCherry,
  powderyMildewGrape,
  powderyMildewHops,
} as const
