import { GddModelDefine } from '../gdd'

export const gddPineEriophyidMite: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPineEriophyidMite',
  name: 'Pine Eriophyid Mite GDD',
  fullName: 'Pine Eriophyid Mite (Eriophyidae) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence',
      range: {
        min: 0,
        max: 298,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Emergence of Overwintered Adults',
      range: {
        min: 299,
        max: 533,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have begun to emerge.',
        },
      ],
    },
    {
      name: 'End of Overwintered Adult Emergence',
      range: {
        min: 534,
        max: 600,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pine Eriophyid Mite Growing Degree Day Model is used to predict development of life-cycle stages of Pine Eriophyid Mite (Eriophyidae), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of overwintered adults which can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-pine-eriophyid-mite-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
