import { GddModelDefine } from '../gdd'

export const gddRoundheadedAppleTreeBorer: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddRoundheadedAppleTreeBorer',
  name: 'Roundheaded Apple Tree Borer GDD',
  fullName: 'Roundheaded Apple Tree Borer (Saperda candida) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence',
      range: {
        min: 0,
        max: 802,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Generation Adult Emergence',
      range: {
        min: 803,
        max: 1029,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have begun to emerge from overwintered larvae.',
        },
      ],
    },
    {
      name: 'End of First Generation Adult Emergence',
      range: {
        min: 804,
        max: 1514,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have fully emerged.',
        },
      ],
    },
    {
      name: 'First Emergence of Second Generation Adults',
      range: {
        min: 1515,
        max: 1798,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First next generation adults have begun to emerge.',
        },
      ],
    },
    {
      name: 'End of Second Generation Adult Emergence',
      range: {
        min: 1799,
        max: 2000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next Generation adults have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Roundheaded Apple Tree Borer Growing Degree Day Model is used to predict development of life-cycle stages of Roundheaded Apple Tree Borer (Saperda candida), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered larvae which can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-roundheaded-apple-tree-borer-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
