import { GddModelDefine } from '../gdd'

export const gddSodWebworm: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddSodWebworm',
  name: 'Sod Webworm',
  fullName: 'Sod Webworm (Pediasia trisecta) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 380,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'Begin Adult Emergence',
      range: {
        min: 381,
        max: 488,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have started to emerge and are in peak flight.',
        },
      ],
    },
    {
      name: 'End Peak Flight',
      range: {
        min: 489,
        max: 974,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged. Peak flight is ending.',
        },
      ],
    },
    {
      name: 'Begin Second Generation Adult Emergence',
      range: {
        min: 975,
        max: 1098,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have started to emerge and are in peak flight.',
        },
      ],
    },
    {
      name: 'End Peak Flight - Second Generation',
      range: {
        min: 1099,
        max: 1314,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have emerged. Peak flight is ending.',
        },
      ],
    },
    {
      name: 'Begin Third Generation Adult Emergence',
      range: {
        min: 1315,
        max: 1343,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adults have started to emerge and are in peak flight.',
        },
      ],
    },
    {
      name: 'End Peak Flight - Third Generation',
      range: {
        min: 1344,
        max: 1400,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have emerged. Peak flight is ending.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Sod Webworm Degree Day Model is used to predict development of life-cycle stages of Sod Webworm (Pediasia trisecta), in order to improve pest management decisions',
    'For best results, a biofix date should be set to January 1st, for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of next generation of adults. For best results, the biofix should be re-set for each subsequent generation.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-sod-webworm-growing-degree-day-model/',
  citations: [
    'Tolley, M. P., and W. H. Robinson. 1986. Seasonal abundance and degree-day prediction of sod webworm (Lepidoptera: Pyralidae) adult emergence in Virginia. J. Econ. Entomol. 79: 400-404.',
  ],
}
