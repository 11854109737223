import { assertExists } from '../../utils/assert.utils'
import {
  Model,
  ModelDayCreateInput,
  WeatherDayDecrypted,
} from '../../utils/entity.utils'
import { ModelOutputMatrix, ModelUtilsShared } from '../../utils/model.utils'
import { ModelUnitsName } from '../../utils/units.utils'
import { WeatherDayUtilsShared } from '../../utils/weather-day.utils'
import {
  ModelDefineBase,
  ModelGroupDefineBase,
  ModelStageBase,
} from '../model-base-types'

export class MelCast {

  static calcModelDays(model: Model, define: MelCastModelDefine, weatherDaysSorted: WeatherDayDecrypted[]): ModelDayCreateInput[] {
    assertExists(model.biofixDate, `Unable to calculate MelCast model days: biofix date missing.`)

    let efi = 0
    const modelDays: ModelDayCreateInput[] = []

    for (const weatherDay of weatherDaysSorted) {
      if (weatherDay.date <= model.biofixDate) {
        continue
      }

      efi += calcDayEfi(define, weatherDay)

      modelDays.push({
        date: weatherDay.date,
        isForecast: weatherDay.hours.some(x => x.isForecast),
        value: efi,
        modelId: model.id,
      })
    }

    return modelDays
  }

}

function calcDayEfi(define: MelCastModelDefine, weatherDay: WeatherDayDecrypted): MelCastOutput {
  return ModelUtilsShared.getOutputMatrixValue(
    define,
    WeatherDayUtilsShared.getMeanTemperature(weatherDay),
    WeatherDayUtilsShared.getLeafWetnessHours(weatherDay),
  )
}

export const melCastModelCommon: Pick<MelCastModelDefine, 'group'> = {
  group: 'melCast',
}

export type MelCastModelDefine = ModelDefineBase<MelCastModelGroupDefine, MelCastModelStage> & {
  outputMatrix: ModelOutputMatrix<MelCastOutput>
}

export type MelCastModelStage = ModelStageBase

export const melCastModelGroupDefine: MelCastModelGroupDefine = {
  name: 'melCast',
  currentStageHeader: 'Current Disease Severity',
  stagesHeader: 'Stages',
  modelUnits: 'efi',
  modelUnitsName: ModelUnitsName.universal('EFI'),
  requiresBiofixDate: true,
  requiresBiofixStage: false,
}

export type MelCastModelGroupDefine = ModelGroupDefineBase<'melCast'>

export type MelCastOutput = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
