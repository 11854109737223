import { melCastMuskmelonAlternariaLeafBlight } from './mel-cast-muskmelon-alternaria-leaf-blight'
import { melCastMuskmelonAnthracnose } from './mel-cast-muskmelon-anthracnose'
import { melCastMuskmelonGummyStemBlight } from './mel-cast-muskmelon-gummy-stem-blight'
import { melCastWatermelonAlternariaLeafBlight } from './mel-cast-watermelon-alternaria-leaf-blight'
import { melCastWatermelonAnthracnose } from './mel-cast-watermelon-anthracnose'
import { melCastWatermelonGummyStemBlight } from './mel-cast-watermelon-gummy-stem-blight'

export * from './mel-cast'

export const melCastModelDefines = {
  melCastWatermelonAnthracnose,
  melCastWatermelonGummyStemBlight,
  melCastWatermelonAlternariaLeafBlight,
  melCastMuskmelonAnthracnose,
  melCastMuskmelonGummyStemBlight,
  melCastMuskmelonAlternariaLeafBlight,
} as const
