import { GddModelDefine } from '../gdd'

export const gddCalicoScale: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddCalicoScale',
  name: 'Calico Scale GDD',
  fullName: 'Calico Scale (Eulecanium cerasorum) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Crawlers Have Not Yet Emerged',
      range: {
        min: 0,
        max: 748,
      },
      infos: [
        {
          name: 'Crawlers',
          value: 'Crawlers have not yet emerged.',
        },
      ],
    },
    {
      name: 'Crawler Emergence',
      range: {
        min: 749,
        max: 1000,
      },
      infos: [
        {
          name: 'Crawlers',
          value: 'Eggs have begun to hatch and crawlers have begun to emerge.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Calico Scale Degree Day Model is used to predict development of life-cycle stages of Calico Scale (Eulecanium cerasorum), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of crawlers which can be targeted for treatment.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-calico-scale-growing-degree-day-model/',
  citations: [
    `Herms, Daniel. Using Degree-Days and Plant Pathology to Predict Pest Activity. Tactics and Tools for IPM. Chapter 11. 49-59.`,
  ],
}
