export const assertExhaustive = (
  value: never,
  message: string = 'Reached unexpected case in exhaustive switch',
): never => {
  throw new Error(message)
}

export function assert(
  condition: boolean,
  message: string = 'Assertion failed',
): asserts condition {
  if (!condition) {
    throw new Error(message)
  }
}

export function assertExists<T>(
  value: T,
  message: string = 'Value is null or undefined',
): asserts value is NonNullable<T> {
  if (value == null) {
    throw new Error(message)
  }
}

export function thruAssert(
  condition: boolean,
  message: string,
) {
  if (!condition) {
    console.error(message)
  }
}
