import { GddModelDefine } from '../gdd'

export const gddOat: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddOat',
  name: 'Oat',
  fullName: 'Oat Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Seed Planted',
      range: {
        min: 0,
        max: 280,
      },
      infos: [
        {
          name: 'Coleoptyle',
          value: 'Coleoptyle has not broken through soil.',
        },
      ],
    },
    {
      name: 'Germination: Coleoptyle has broken through soil.',
      range: {
        min: 281,
        max: 340,
      },
      infos: [
        {
          name: 'Coleoptyle',
          value: 'Coleoptyle has broken through the soil.',
        },
      ],
    },
    {
      name: 'First leaf unfolds.',
      range: {
        min: 341,
        max: 650,
      },
      infos: [
        {
          name: 'First leaf.',
          value: 'First leaf has unfolded.',
        },
      ],
    },
    {
      name: 'First Tiller Visible',
      range: {
        min: 651,
        max: 960,
      },
      infos: [
        {
          name: 'Tiller',
          value: 'First tiller has become visible.',
        },
      ],
    },
    {
      name: 'First Node Detectable',
      range: {
        min: 961,
        max: 1280,
      },
      infos: [
        {
          name: 'Node',
          value: 'First node has become detectable.',
        },
      ],
    },
    {
      name: 'Flag leaf sheath extended.',
      range: {
        min: 1281,
        max: 1590,
      },
      infos: [
        {
          name: 'Flag leaf sheath',
          value: 'Flag leaf sheath has been extended.',
        },
      ],
    },
    {
      name: 'Tip of inflorescence emerged.',
      range: {
        min: 1591,
        max: 1900,
      },
      infos: [
        {
          name: 'Inflorescence',
          value: 'Tip of inflorescence emerged. First spikelet has become visible.',
        },
      ],
    },
    {
      name: 'First anthers of flowers visible.',
      range: {
        min: 1901,
        max: 2210,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'First anthers of flowers have become visible.',
        },
      ],
    },
    {
      name: 'First grains reach half of final size.',
      range: {
        min: 2210,
        max: 2650,
      },
      infos: [
        {
          name: 'Grains',
          value: 'First grains reach half of final size.',
        },
      ],
    },
    {
      name: 'Soft dough stage grain development.',
      range: {
        min: 2651,
        max: 2840,
      },
      infos: [
        {
          name: 'Grains',
          value: 'Soft dough stage grain development.',
        },
      ],
    },
    {
      name: 'Grains ripe and hard.',
      range: {
        min: 2841,
        max: 3200,
      },
      infos: [
        {
          name: 'Grains',
          value: 'Grains ripe and hard.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Oat Degree Day Model is used to predict harvest time and other growth stages of oat based on the weather in a given season.',
    'For best results, a biofix date should be set at planting. The growing degree day model can then be used to predict harvest time. For best results the biofix should be reset when later stages have been observed, in order to more accurately predict the next stage.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-oat-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Perry Miller, Swift Current, SK 1996-98`,
  ],
}
