import { GddModelDefine } from '../gdd'

export const gddEmeraldAshBorerEmergence: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 100,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddEmeraldAshBorerEmergence',
  name: 'Emerald Ash Borer Emergence',
  fullName: 'Emerald Ash Borer (Agrilus planipennis) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'No Adult Activity',
    range: {
      min: 0,
      max: 550,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintered adult beetles have not yet emerged. Recommended to set biofix to Jan. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'First Adult Emergence',
    range: {
      min: 551,
      max: 653,
    },
    infos: [{
      name: 'Adults',
      value: `First emergence of adult beetles predicted.`,
    }],
  }, {
    name: '10% Adult Emergence',
    range: {
      min: 654,
      max: 855,
    },
    infos: [{
      name: 'Adults',
      value: `10% emergence of adult beetles predicted.`,
    }],
  }, {
    name: '50% Adult Emergence',
    range: {
      min: 856,
      max: 1491,
    },
    infos: [{
      name: 'Adults',
      value: `50% emergence of adult beetles predicted.`,
    }],
  }, {
    name: '95% Adult Emergence',
    range: {
      min: 1492,
      max: 2500,
    },
    infos: [{
      name: 'Adults',
      value: `95% emergence of adult beetles predicted.`,
    }],
  }, {
    name: 'End Adult Emergence',
    range: {
      min: 2501,
      max: 2700,
    },
    infos: [{
      name: 'Adults',
      value: `Approximate end of adult beetle emergence`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Emerald Ash Borer Emergence Growing Degree Day Model is used to predict emergence of the Emerald Ash Borer (Agrilus planipennis) in order to improve pest management decisions. Traps should be placed before first sign of adult beetle activity.`,
  ],
  citations: [
    `This model was developed and tested in Oregon by Len Coop at Integrated Plant Protection Center, Oregon State University with the following primary reference:`,
    `Duarte, Samuel Andres Discua, 2013. Characterizing Prepual Diapause and Adult Emergence Phenology of Emerald Ash Borer. The Ohio State University.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-emerald-ash-borer-growing-degree-day-model',
}
