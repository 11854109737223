import { GddModelDefine } from '../gdd'

export const gddHopVineBorerEmergence: GddModelDefine = {
  lowerThreshold: 41.5,
  upperThreshold: 75,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddHopVineBorerEmergence',
  name: 'Hop Vine Borer Emergence',
  fullName: 'Hop Vine Borer (Hydraecia immanis) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'No Adult Activity',
    range: {
      min: 0,
      max: 2384,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintered moths have not yet emerged. Recommended to set biofix to Jan. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'First Adult Emergence',
    range: {
      min: 2385,
      max: 2753,
    },
    infos: [{
      name: 'Adults',
      value: `First emergence of adult moths predicted.`,
    }],
  }, {
    name: '10% Adult Emergence',
    range: {
      min: 2754,
      max: 3171,
    },
    infos: [{
      name: 'Adults',
      value: `10% emergence of adult moths predicted.`,
    }],
  }, {
    name: '50% Adult Emergence',
    range: {
      min: 3172,
      max: 3561,
    },
    infos: [{
      name: 'Adults',
      value: `50% emergence of adult moths predicted.`,
    }],
  }, {
    name: '90% Adult Emergence',
    range: {
      min: 3562,
      max: 3800,
    },
    infos: [{
      name: 'Adults',
      value: `90% emergence of adult moths predicted.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Hop Vine Borer Emergence Growing Degree Day Model is used to predict emergence of the Hop Vine Borer (Hydraecia immanis) in order to improve pest management decisions. Light traps should be placed before first sign of adult moth activity.`,
  ],
  citations: [
    `Levine, E. 1989. Forecasting Hydraecia immanis (Lepidoptera: Noctuidae) moth phenology based on light trap catches and degree-day accumulations. J. Econ. Entomol. 82: 433-438.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-hop-vine-borer-growing-degree-day-model',
}
