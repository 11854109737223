import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class StartupService {

  private _isComplete = false

  async waitForComplete() {
    if (this._isComplete) {
      return
    }

    await new Promise<void>(resolve => {
      const intervalId = setInterval(() => {
        if (this._isComplete) {
          clearInterval(intervalId)
          resolve()
        }
      })
    })
  }

  setComplete() {
    this._isComplete = true
  }

}
