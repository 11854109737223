import { GddModelDefine } from '../gdd'

export const gddWesternCherryFruitFly: GddModelDefine = {
  lowerThreshold: 41,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddWesternCherryFruitFly',
  name: 'Western Cherry Fruit Fly',
  fullName: 'Western Cherry Fruit Fly (Rhagoletis cingulata) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adults Have Not Yet Emerged',
      range: {
        min: 0,
        max: 832,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged from overwintered pupae.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Overwintering Generation',
      range: {
        min: 833,
        max: 889,
      },
      infos: [
        {
          name: 'Adults',
          value:
            'Adults from overwintered pupae have emerged but have not yet reached peak egg-laying.',
        },
      ],
    },
    {
      name: '10% Adult Emergence',
      range: {
        min: 890,
        max: 974,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have reached 10% emergence.',
        },
      ],
    },
    {
      name: 'First Egg-laying on Fruit',
      range: {
        min: 975,
        max: 1069,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have emerged and have begun laying eggs.',
        },
      ],
    },
    {
      name: 'First Larvae  / Egg Hatch',
      range: {
        min: 1070,
        max: 1136,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have begun to emerge.',
        },
      ],
    },
    {
      name: '50% Adult Emergence',
      range: {
        min: 1137,
        max: 1233,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have reached 50% emergence.',
        },
      ],
    },
    {
      name: 'Peak Egg Laying',
      range: {
        min: 1234,
        max: 1324,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have reached peak egg laying.',
        },
      ],
    },
    {
      name: '100% Adult Emergence',
      range: {
        min: 1325,
        max: 1431,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have reached 100% emergence.',
        },
      ],
    },
    {
      name: 'First Pupation',
      range: {
        min: 1432,
        max: 1500,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'First pupation has begun.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Western Cherry Fruit Fly Degree Day Model is used to predict development of life-cycle stages of Western Cherry Fruit Fly (Rhagoletis cingulata), in order to improve pest management decisions',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered pupae as well as following generations.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-western-cherry-fruit-fly-growing-degree-day-model/',
  citations: [
    'AliNiazee, M. T.  1979. A computerized phenology model for predicting biological events of Rhagolet isindifferens  (Diptera: Tephritidae).  Can. Ent. 111: 1101-1109.',
  ],
}
