import { GddModelDefine } from '../gdd'

export const gddPearRustMite: GddModelDefine = {
  lowerThreshold: 6,
  upperThreshold: 50,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPearRustMite',
  name: 'Pear Rust Mite',
  fullName: 'Pear Rust Mite (Epitrimerus pyri) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Emergence / Early Stages of Emergence from Overwintering',
      range: {
        min: 0,
        max: 60,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults are not yet present.',
        },
      ],
    },
    {
      name: 'Median Emergence from Overwintering',
      range: {
        min: 61,
        max: 100,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pear Rust Mite Degree Day Model is used to predict development of life-cycle stages of the Pear Rust Mite (Epitrimerus pyri), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict median mite emergence.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-pear-rust-mite-growing-degree-day-model/',
  citations: [
    `Bergh, J.C., and G.J.R. Judd. 1993. Degree-day model for predicting emergence of pear rust mite (Acari: Eriophyidae) Deutogynes from overwintering sites. Environ. Entomol. 22:1325-1332.`,
  ],
}
