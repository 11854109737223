import _ from 'lodash'
import { DateTime } from 'luxon'
import {
  allModelDefines,
  modelGroupDefines,
  ModelDefine,
  ModelStage,
} from '../models'
import {
  ModelDefineBase,
  ModelStageBase,
} from '../models/model-base-types'
import { thruAssert } from './assert.utils'
import {
  DateTimeIso,
} from './date-time.utils'
import {
  MeasurementUnits,
  Model,
  ModelDay,
  ModelType,
  ModelWith,
} from './entity.utils'
import {
  MathUtils,
  Range,
} from './math.utils'

export class ModelUtilsShared {

  static biofixDefaults = {
    daysAgo: 10,
  } as const

  static getModelDefine(type: ModelType) {
    const modelDefine = allModelDefines[type]

    if (!modelDefine) {
      throw new Error(`Unable to find model define with type '${type}'`)
    }

    return modelDefine
  }

  static getModelUnitsName(define: ModelDefine, units: MeasurementUnits) {
    const group = ModelUtilsShared.getModelGroupDefine(define)
    switch (units) {
      case 'metric':
        return group.modelUnitsName.metric
      case 'imperial':
        return group.modelUnitsName.imperial
      default:
        throw new Error(`Unknown units type '${units}'`)
    }
  }

  static getModelGroupDefine(define: ModelDefine) {
    return modelGroupDefines[define.group]
  }

  static getDefaultBiofixDate(modelType: ModelType): DateTimeIso | null {
    const modelDefine = ModelUtilsShared.getModelDefine(modelType)
    const modelGroupDefine = ModelUtilsShared.getModelGroupDefine(modelDefine)
    if (!modelGroupDefine.biofixDefaultDate) {
      return null
    }

    let date = DateTime.fromObject(modelGroupDefine.biofixDefaultDate, { zone: 'utc' })
    if (date > DateTime.now()) {
      date = date.minus({ year: 1 })
    }

    return date.toISO()
  }

  static getDefaultBiofixStage(modelType: ModelType): number | null {
    return ModelUtilsShared.requiresBiofixStage(modelType)
      ? 0
      : null
  }

  static requiresBiofix(modelType: ModelType) {
    const modelDefine = ModelUtilsShared.getModelDefine(modelType)
    const modelGroup = ModelUtilsShared.getModelGroupDefine(modelDefine)
    return modelGroup.requiresBiofixDate || modelGroup.requiresBiofixStage
  }

  static requiresBiofixDate(modelType: ModelType) {
    const modelDefine = ModelUtilsShared.getModelDefine(modelType)
    const modelGroup = ModelUtilsShared.getModelGroupDefine(modelDefine)
    return modelGroup.requiresBiofixDate
  }

  static requiresBiofixStage(modelType: ModelType) {
    const modelDefine = ModelUtilsShared.getModelDefine(modelType)
    const modelGroup = ModelUtilsShared.getModelGroupDefine(modelDefine)
    return modelGroup.requiresBiofixStage
  }

  static requiresSettings(modelType: ModelType) {
    return ModelUtilsShared.requiresBiofix(modelType)
  }

  static getModelCurrentStages(model: ModelWith<'modelDays'>): ModelStage[] {
    const lastModelDay = _.last(model.modelDays)

    if (!lastModelDay) {
      const modelDefine = ModelUtilsShared.getModelDefine(model.type)
      return [modelDefine.stages[0]]
    }

    return ModelUtilsShared.getModelDayStages(model, lastModelDay)
  }

  static getModelDayStages(model: Model, modelDay: ModelDay): ModelStage[] {
    const modelDefine = ModelUtilsShared.getModelDefine(model.type)
    return (modelDefine.stages as ModelStageBase[])
      .filter(stage => ModelUtilsShared.isModelDayAtStage(stage, modelDay))
  }

  static isModelDayAtStage(stage: ModelStage, modelDay: ModelDay): boolean {
    let min = stage.range.min
    if (min == null) {
      min = Number.NEGATIVE_INFINITY
    }

    let max = stage.range.max
    if (max == null) {
      max = Number.POSITIVE_INFINITY
    }

    const modelDayValueFloored = Math.floor(modelDay.value)

    return (modelDayValueFloored >= min && modelDayValueFloored <= max)
  }

  static getOutputMatrixValue<TOutputMatrix extends ModelOutputMatrix<any>>(
    define: ModelDefineBase<any, any> & { outputMatrix: TOutputMatrix },
    xValue: number,
    yValue: number,
  ): TOutputMatrix['outputs'][0][0] {
    const { x: xRange, y: yRange } = define.outputMatrix.axisRanges

    const xIndex = MathUtils.clamp(xValue, xRange.min, xRange.max) - xRange.min
    const yIndex = MathUtils.clamp(yValue, yRange.min, yRange.max) - yRange.min

    const value = define.outputMatrix.outputs[yIndex][xIndex]
    thruAssert(value != null, `Undefined model output! Model = ${define.type}, Values = (${xValue}, ${yValue}), Indices = (${xIndex}, ${yIndex})`)

    return value ?? 0
  }

}

export type ModelOutputMatrix<TOutputValue extends number> = {
  axisRanges: {
    x: Required<Range>
    y: Required<Range>
  }
  outputs: ModelOutputMatrixOutputs<TOutputValue>
}

export type ModelOutputMatrixOutputs<TOutputValue extends number> = Array<Array<TOutputValue>>
