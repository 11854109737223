import { GddModelDefine } from '../gdd'

export const gddPearPsylla: GddModelDefine = {
  lowerThreshold: 5,
  upperThreshold: 50,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPearPsylla',
  name: 'Pear Psylla',
  fullName: 'Pear Psylla (Cacopsylla pyricola) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 80,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults are not yet present.',
        },
      ],
    },
    {
      name: 'Peak Spring Counts',
      range: {
        min: 81,
        max: 1000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pear Psylla Degree Day Model is used to predict development of life-cycle stages of the Pear Psylla (Cacopsylla pyricola), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict peak spring catch.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-pear-psylla-growing-degree-day-model/',
  citations: [
    `Horton, D. R., B. S. Higbee, T. R. Unruh, and P. H. Westigard. 1992. Spatial characteristics and effects of fall density and weather on overwintering loss of pear psylla (Homoptera: Psyllidae). Environ. Entomol. 21: 1319-1332.`,
  ],
}
