import { assertExhaustive } from './assert.utils'
import {
  IapSubscription,
  IapSubscriptionProductId,
  StripeSubscription,
  StripeSubscriptionPriceId,
} from './entity.utils'
import { Environment } from './env.utils'
import { StringUtils } from './string.utils'
import {
  IapSubscriptionType,
  iapSubscriptionTypes,
} from './subscription-iap.utils'
import {
  StripeProductId,
  StripeSubscriptionType,
  stripeSubscriptionTypes,
} from './subscription-stripe.utils'

export type IapSubscriptionMinimal = Pick<IapSubscription, 'platform' | 'productId'>
export type StripeSubscriptionMinimal = Pick<StripeSubscription, 'platform' | 'priceId'>

export type SubscriptionMinimal =
  | IapSubscriptionMinimal
  | StripeSubscriptionMinimal

export class SubscriptionUtils {

  static getDefine(subscription: SubscriptionMinimal, env: Environment): SubscriptionDefine {
    if (SubscriptionUtils.isIapSubscription(subscription)) {
      return SubscriptionUtils.getIapSubscriptionDefine(subscription, env)
    }

    if (SubscriptionUtils.isStripeSubscription(subscription)) {
      return SubscriptionUtils.getStripeSubscriptionDefine(subscription, env)
    }

    throw new Error('Unknown subscription define')
  }

  static getType(subscription: SubscriptionMinimal): SubscriptionType {
    if (SubscriptionUtils.isIapSubscription(subscription)) {
      return iapProductIdToType(subscription.productId)
    }

    if (SubscriptionUtils.isStripeSubscription(subscription)) {
      return stripePriceIdToType(subscription.priceId)
    }

    throw new Error('Unknown subscription type')
  }

  static isStripeSubscription(subscription: SubscriptionMinimal): subscription is StripeSubscription {
    switch (subscription.platform) {
      case 'stripe':
        return true
      default:
        return false
    }
  }

  static isIapSubscription(subscription: SubscriptionMinimal): subscription is IapSubscription {
    switch (subscription.platform) {
      case 'iosAppstore':
      case 'androidPlaystore':
        return true
      default:
        return false
    }
  }

  static validateStripeSubscriptionType(type: string) {
    return stripeSubscriptionTypes.includes(type as any)
  }

  static getSubscriptionDefineByType(type: SubscriptionType, env: Environment): SubscriptionDefine {
    if (SubscriptionUtils.isIapSubscriptionType(type)) {
      return SubscriptionUtils.iapTypeToDefine(type, env)
    }

    if (SubscriptionUtils.isStripeSubscriptionType(type)) {
      return SubscriptionUtils.getStripeSubscriptionDefineByType(type, env)
    }

    if (type === 'free') {
      return SubscriptionUtils.getFreeSubscriptionDefine(env)
    }

    throw new Error(`Unable to find define for unknown subscription type '${type}'`)
  }

  static isIapSubscriptionType(type: SubscriptionType): type is IapSubscriptionType {
    return iapSubscriptionTypes.includes(type as IapSubscriptionType)
  }

  static isStripeSubscriptionType(type: SubscriptionType): type is StripeSubscriptionType {
    return stripeSubscriptionTypes.includes(type as StripeSubscriptionType)
  }

  static isIapSubscriptionDefine(define: SubscriptionDefineBase): define is IapSubscriptionDefine {
    return SubscriptionUtils.isIapSubscriptionType(define.type)
  }

  static isStripeSubscriptionDefine(define: SubscriptionDefineBase): define is StripeSubscriptionDefine {
    return SubscriptionUtils.isStripeSubscriptionType(define.type)
  }

  static getFreeSubscriptionDefine(env: Environment): SubscriptionDefine {
    return {
      type: 'free',
      name: 'Free',
      fullName: 'Free',
      maxLocations: 1,
      maxModels: 1,
      minHistoricalWeatherDays: getMinHistoricalWeatherDays(env),
      maxHistoricalWeatherDays: getMaxHistoricalWeatherDays(env),
      maxForecastWeatherDays: 0,
      weatherUpdateIntervalHours: 24, // For free plan this is only for reference and not used in weather update.
      tier: 0,
      interval: 'monthly',
      pricePerLocationPerMonth: 0,
    }
  }

  static getIapSubscriptionDefine(subscription: IapSubscription, env: Environment): IapSubscriptionDefine {
    const type = iapProductIdToType(subscription.productId)
    return SubscriptionUtils.iapTypeToDefine(type, env)
  }

  static iapTypeToDefine(type: IapSubscriptionType, env: Environment): IapSubscriptionDefine {
    return buildProDefine({
      type,
      productId: iapTypeToProductId(type),
      maxLocations: iapTypeToMaxLocations(type),
      interval: iapTypeToInterval(type),
    }, env) as IapSubscriptionDefine
  }

  static getStripeSubscriptionDefine(subscription: StripeSubscription, env: Environment): StripeSubscriptionDefine {
    const type = stripePriceIdToType(subscription.priceId)
    return SubscriptionUtils.getStripeSubscriptionDefineByType(type, env)
  }

  static getStripeSubscriptionDefineByType(type: StripeSubscriptionType, env: Environment): StripeSubscriptionDefine {
    return buildProDefine({
      type,
      maxLocations: stripeTypeToMaxLocations(type),
      interval: stripeTypeToInterval(type),
      productId: stripeTypeToProductId(type, env),
      priceId: stripeTypeToPriceId(type, env),
    }, env) as StripeSubscriptionDefine
  }

  static getPrice(type: SubscriptionType, locationCount: number, env: Environment): number {
    const define = SubscriptionUtils.getSubscriptionDefineByType(type, env)
    switch (define.interval) {
      case 'monthly':
        return define.pricePerLocationPerMonth * locationCount
      case 'yearly':
        return define.pricePerLocationPerMonth * 12 * locationCount
      default:
        throw new Error(`Unexpected interval: ${define.interval}`)
    }
  }

  static getPriceText(type: SubscriptionType, locationCount: number, ownedSubscriptionType: SubscriptionType | undefined, env: Environment): string {
    const price = SubscriptionUtils.getPrice(type, locationCount, env)
    const define = SubscriptionUtils.getSubscriptionDefineByType(type, env)

    const proratedText = (ownedSubscriptionType !== 'free')
      ? ' (prorated)'
      : ''

    switch (define.interval) {
      case 'monthly':
        return `$${StringUtils.formatNumber(price, 2)} / month${proratedText}`
      case 'yearly':
        return `$${StringUtils.formatNumber(price, 2)} / year${proratedText}`
      default:
        throw new Error(`Unexpected interval: ${define.interval}`)
    }
  }

  static getProductId(define: SubscriptionDefineBase) {
    if (SubscriptionUtils.isIapSubscriptionDefine(define)) {
      return define.productId
    }

    if (SubscriptionUtils.isStripeSubscriptionDefine(define)) {
      return define.priceId
    }

    throw new Error(`Unexpected subscription define type`)
  }

  static isMeteredPerExternalUser(priceId: StripeSubscriptionPriceId) {
    const type = stripePriceIdToType(priceId)
    return type === 'stripeProApiMeteredPerExternalUser'
  }

  static getMaxLocationsPerExternalUser(priceId: StripeSubscriptionPriceId) {
    switch (priceId) {
      // API Metered (Rooted)
      case 'price_1Q1wnSG3YjyYmcxBwq4kjpLT':
      case 'price_1Q1wsNG3YjyYmcxBMlvPQStz':
        return 20
      default:
        return 0
    }
  }

}

//
// Helpers
//

function buildProDefine(options: BuildSubscriptionOptions, env: Environment): SubscriptionDefine {
  return {
    name: 'Professional',
    fullName: 'Professional',
    maxModels: 10000,
    minHistoricalWeatherDays: getMinHistoricalWeatherDays(env),
    maxHistoricalWeatherDays: getMaxHistoricalWeatherDays(env),
    maxForecastWeatherDays: env.isDeployed ? 6 : 2,
    weatherUpdateIntervalHours: 1,
    tier: 1,
    pricePerLocationPerMonth: options.interval === 'monthly' ? 19.99 : 9.99,
    ...options,
  }
}

function iapProductIdToType(productId: IapSubscriptionProductId): IapSubscriptionType {
  switch (productId) {
    case 'pro_one_field_monthly': return 'iapProOneFieldMonthly'
    case 'pro_one_field_yearly': return 'iapProOneFieldYearly'
    case 'pro_two_fields_monthly': return 'iapProTwoFieldsMonthly'
    case 'pro_two_fields_yearly': return 'iapProTwoFieldsYearly'
    case 'pro_three_fields_monthly': return 'iapProThreeFieldsMonthly'
    case 'pro_three_fields_yearly': return 'iapProThreeFieldsYearly'
    case 'pro_four_fields_monthly': return 'iapProFourFieldsMonthly'
    case 'pro_four_fields_yearly': return 'iapProFourFieldsYearly'
    case 'pro_five_fields_monthly': return 'iapProFiveFieldsMonthly'
    case 'pro_five_fields_yearly': return 'iapProFiveFieldsYearly'
    default:
      return assertExhaustive(productId)
  }
}

function iapTypeToProductId(type: IapSubscriptionType): IapSubscriptionProductId {
  switch (type) {
    case 'iapProOneFieldMonthly': return 'pro_one_field_monthly'
    case 'iapProOneFieldYearly': return 'pro_one_field_yearly'
    case 'iapProTwoFieldsMonthly': return 'pro_two_fields_monthly'
    case 'iapProTwoFieldsYearly': return 'pro_two_fields_yearly'
    case 'iapProThreeFieldsMonthly': return 'pro_three_fields_monthly'
    case 'iapProThreeFieldsYearly': return 'pro_three_fields_yearly'
    case 'iapProFourFieldsMonthly': return 'pro_four_fields_monthly'
    case 'iapProFourFieldsYearly': return 'pro_four_fields_yearly'
    case 'iapProFiveFieldsMonthly': return 'pro_five_fields_monthly'
    case 'iapProFiveFieldsYearly': return 'pro_five_fields_yearly'
    default:
      return assertExhaustive(type)
  }
}

function iapTypeToMaxLocations(type: IapSubscriptionType): number {
  switch (type) {
    case 'iapProOneFieldMonthly':
    case 'iapProOneFieldYearly':
      return 1
    case 'iapProTwoFieldsMonthly':
    case 'iapProTwoFieldsYearly':
      return 2
    case 'iapProThreeFieldsMonthly':
    case 'iapProThreeFieldsYearly':
      return 3
    case 'iapProFourFieldsMonthly':
    case 'iapProFourFieldsYearly':
      return 4
    case 'iapProFiveFieldsMonthly':
    case 'iapProFiveFieldsYearly':
      return 5
    default:
      return assertExhaustive(type)
  }
}

function iapTypeToInterval(type: IapSubscriptionType): SubscriptionInterval {
  switch (type) {
    case 'iapProOneFieldMonthly':
    case 'iapProTwoFieldsMonthly':
    case 'iapProThreeFieldsMonthly':
    case 'iapProFourFieldsMonthly':
    case 'iapProFiveFieldsMonthly':
      return 'monthly'
    case 'iapProOneFieldYearly':
    case 'iapProTwoFieldsYearly':
    case 'iapProThreeFieldsYearly':
    case 'iapProFourFieldsYearly':
    case 'iapProFiveFieldsYearly':
      return 'yearly'
    default:
      return assertExhaustive(type)
  }
}

function stripePriceIdToType(priceId: StripeSubscriptionPriceId): StripeSubscriptionType {
  switch (priceId) {
    case 'plan_DUHPayhDgdOwAI':
    case 'plan_DdrOTX5qKTHA1C':
      return 'stripeProMonthly'
    case 'plan_Dr8Ty2ZsLqO3eI':
    case 'plan_DdrOMftIY1N9TY':
      return 'stripeProYearly'
    case 'price_1KzVpVG3YjyYmcxBo7SIZpk6':
    case 'price_1KzSQHG3YjyYmcxBQzJ5Gxkb':
      return 'stripeProApiMonthly'
    case 'price_1L3bMkG3YjyYmcxBICxVumKQ':
    case 'price_1L3bBOG3YjyYmcxBCUSaQ9Mn':
      return 'stripeProApiFreeTrial'
    case 'price_1MggWAG3YjyYmcxBzaW4HP1Z':
    case 'price_1MghiBG3YjyYmcxBJ0zXOYxv':
      return 'stripeProApiMeteredPerLocation'
    case 'price_1Q1wnSG3YjyYmcxBwq4kjpLT':
    case 'price_1Q1wsNG3YjyYmcxBMlvPQStz':
      return 'stripeProApiMeteredPerExternalUser'
    default:
      return assertExhaustive(priceId)
  }
}

function stripeTypeToMaxLocations(type: StripeSubscriptionType): number {
  // These are just fallback values. The actual max locations is determined by the quantity set in Stripe.
  switch (type) {
    case 'stripeProMonthly':
    case 'stripeProYearly':
    case 'stripeProApiMonthly':
      return 100
    case 'stripeProApiFreeTrial':
      return 5
    case 'stripeProApiMeteredPerLocation':
    case 'stripeProApiMeteredPerExternalUser':
      return 1000000
    default:
      return assertExhaustive(type)
  }
}

function stripeTypeToInterval(type: StripeSubscriptionType): SubscriptionInterval {
  switch (type) {
    case 'stripeProMonthly':
    case 'stripeProApiMonthly':
    case 'stripeProApiFreeTrial':
    case 'stripeProApiMeteredPerLocation':
    case 'stripeProApiMeteredPerExternalUser':
      return 'monthly'
    case 'stripeProYearly':
      return 'yearly'
    default:
      return assertExhaustive(type)
  }
}

function stripeTypeToProductId(type: StripeSubscriptionType, env: Environment): StripeProductId {
  const { isProd } = env

  switch (type) {
    case 'stripeProMonthly':
    case 'stripeProYearly':
    case 'stripeProApiMonthly':
    case 'stripeProApiMeteredPerLocation':
    case 'stripeProApiMeteredPerExternalUser':
    case 'stripeProApiFreeTrial':
      return isProd ? 'prod_DRR8cRiZ5RGbJk' : 'prod_DdrNjrDt9ITys6'
    default:
      return assertExhaustive(type)
  }
}

function stripeTypeToPriceId(type: StripeSubscriptionType, env: Environment): StripeSubscriptionPriceId {
  const { isProd } = env

  switch (type) {
    case 'stripeProMonthly':
      return isProd ? 'plan_DUHPayhDgdOwAI' : 'plan_DdrOTX5qKTHA1C'
    case 'stripeProYearly':
      return isProd ? 'plan_Dr8Ty2ZsLqO3eI' : 'plan_DdrOMftIY1N9TY'
    case 'stripeProApiMonthly':
      return isProd ? 'price_1KzVpVG3YjyYmcxBo7SIZpk6' : 'price_1KzSQHG3YjyYmcxBQzJ5Gxkb'
    case 'stripeProApiMeteredPerLocation':
      return isProd ? 'price_1MghiBG3YjyYmcxBJ0zXOYxv' : 'price_1MggWAG3YjyYmcxBzaW4HP1Z'
    case 'stripeProApiMeteredPerExternalUser':
      return isProd ? 'price_1Q1wsNG3YjyYmcxBMlvPQStz' : 'price_1Q1wnSG3YjyYmcxBwq4kjpLT'
    case 'stripeProApiFreeTrial':
      return isProd ? 'price_1L3bMkG3YjyYmcxBICxVumKQ' : 'price_1L3bBOG3YjyYmcxBCUSaQ9Mn'
    default:
      return assertExhaustive(type)
  }
}

function getMinHistoricalWeatherDays({ isDeployed }: Environment) {
  return isDeployed ? 90 : 14
}

function getMaxHistoricalWeatherDays({ isDeployed }: Environment) {
  return isDeployed ? 365 : 30
}

type BuildSubscriptionOptions =
  | BuildIapSubscriptionOptions
  | BuildStripeSubscriptionOptions

type BuildIapSubscriptionOptions = Pick<IapSubscriptionDefine, 'maxLocations' | 'type' | 'productId' | 'interval'>
type BuildStripeSubscriptionOptions = Pick<StripeSubscriptionDefine, 'maxLocations' | 'type' | 'productId' | 'priceId' | 'interval'>

export const subscriptionTypes = [
  'free',
  ...iapSubscriptionTypes,
  ...stripeSubscriptionTypes,
] as const
export type SubscriptionType = typeof subscriptionTypes[number]

export const subscriptionIntervals = [
  'monthly',
  'yearly',
] as const
export type SubscriptionInterval = typeof subscriptionIntervals[number]

export type SubscriptionDefine =
  | IapSubscriptionDefine
  | StripeSubscriptionDefine
  | FreeSubscriptionDefine

export type IapSubscriptionDefine = SubscriptionDefineBase & {
  type: IapSubscriptionType
  productId: IapSubscriptionProductId
}

export type StripeSubscriptionDefine = SubscriptionDefineBase & {
  type: StripeSubscriptionType
  productId: StripeProductId
  priceId: StripeSubscriptionPriceId
}

export type FreeSubscriptionDefine = SubscriptionDefineBase & {
  type: 'free'
}

export type SubscriptionDefineBase = {
  type: SubscriptionType
  name: string
  fullName: string
  maxLocations: number
  maxModels: number
  minHistoricalWeatherDays: number
  maxHistoricalWeatherDays: number
  maxForecastWeatherDays: number
  weatherUpdateIntervalHours: number
  tier: number
  interval: SubscriptionInterval
  pricePerLocationPerMonth: number
  coupon?: CouponDefine
}

export interface CouponDefine {
  id: string
  percentOff: number
}
