import { GddModelDefine } from '../gdd'

export const gddAlfalfaEarlySummer: GddModelDefine = {
  lowerThreshold: 45.5,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddAlfalfaEarlySummer',
  name: 'Alfalfa (Early Summer)',
  fullName: 'Alfalfa (Early Summer) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Not Harvest-Ready',
      range: {
        min: 0,
        max: 765,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'No flowers have developed.',
        },
      ],
    },
    {
      name: 'Harvest',
      range: {
        min: 766,
        max: 1000,
      },
      infos: [
        {
          name: 'Flowers',
          value: '10% of flowers. Plants ready for harvest.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Alfalfa Degree Day Model is used to predict flowering and harvest time of Alfalfa in the three-cutting managament system, based on the weather in a given season.',
    'For summer Alfalfa, the biofix date should be set to the previous harvest date. The growing degree day model can then be used to predict time of harvest.',
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-alfalfa-growing-degree-day-model/',
  citations: [
    'Sanderson, M.A., T.P. Karnezos, and A.G. Matches. 1994. Morphological development of alfalfa as a function of growing degree days. J. Prod. Agric. 7:239-242.',
  ],
}
