import { GddModelDefine } from '../gdd'

export const gddSunflowerBeetleEmergence: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 90,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddSunflowerBeetleEmergence',
  name: 'Sunflower Beetle Emergence',
  fullName: 'Sunflower Beetle (Zygogramma exclamationis) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'No Adult Activity',
    range: {
      min: 0,
      max: 418,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintered adult beetles have not yet emerged. Recommended to set biofix to Mar. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'First Adult Emergence',
    range: {
      min: 419,
      max: 711,
    },
    infos: [{
      name: 'Adults',
      value: `First emergence of adult beetles predicted.`,
    }],
  }, {
    name: 'Peak Adult Emergence',
    range: {
      min: 712,
      max: 1000,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of adult beetle emergence.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Sunflower Beetle Emergence Growing Degree Day Model is used to predict emergence of the Sunflower Beetle (Zygogramma exclamationis) in order to improve pest management decisions. Traps should be placed before the first sign of adult beetle activity.`,
  ],
  citations: [
    `Charlet, L. D. 1991. Overwintering survival and emergence pattern for the sunflower beetle (Coleoptera: Chrysomelidae) in the northern great plains. J. Econ. Entomol. 84: 132-135.`,
  ],
  moreInfoUrl: 'https://blog.pestprophet.com/how-to-use-the-sunflower-beetle-growing-degree-day-model',
}
