import { GddModelDefine } from '../gdd'

export const gddTwobandedJapaneseWeevil: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddTwobandedJapaneseWeevil',
  name: 'Twobanded Japanese Weevil GDD',
  fullName: 'Twobanded Japanese Weevil (Callirhopalus bifasciatus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence',
      range: {
        min: 0,
        max: 1644,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Generation Adult Emergence',
      range: {
        min: 1645,
        max: 2271,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have begun to emerge from overwintered larvae.',
        },
      ],
    },
    {
      name: 'End of First Generation Adult Emergence',
      range: {
        min: 2272,
        max: 2500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Twobanded Japanese Weevil Growing Degree Day Model is used to predict development of life-cycle stages of Twobanded Japanese Weevil (Callirhopalus bifasciatus), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered larvae which can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-twobanded-japanese-weevil-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
