import { AngularFireAuth } from '@angular/fire/compat/auth'
import { App } from '@capacitor/app'
import { Geolocation } from '@capacitor/geolocation'
import {
  IapSubscriptionDefine,
  PaymentPlatform,
  SubscriptionProductId,
  StripeSubscriptionType,
  SubscriptionDefineBase,
  SubscriptionUtils,
  SubscriptionType,
  UserSettingsBody,
  UserCreateBody,
  UserJson,
  ModelJson,
  isApiError,
  SubscriptionMinimal,
} from '@pest-prophet/shared'
import firebase from 'firebase/compat/app'
import { DateTime } from 'luxon'
import { environment } from '../../environments/environment'
import { LatLon } from '../entities/lat-lon'
import { UserUtils } from '../entities/user-utils'
import { Analytics } from './analytics'
import { Api } from './api'
import { Config } from './config'
import { Tools } from './tools'

export class Me {

  private static _user?: UserJson
  private static _ranAutoLogin: boolean
  private static _geoLocation: LatLon
  private static firebaseAuth: AngularFireAuth

  static init(
    firebaseAuth: AngularFireAuth,
  ) {
    this.firebaseAuth = firebaseAuth
  }

  static async autoLogin() {
    if (this._ranAutoLogin) {
      return
    }

    this._ranAutoLogin = true

    await this.refresh()
  }

  static async refresh() {
    const firebaseUser = await Me.waitForFirebaseAuthentication()
    if (firebaseUser == null) {
      throw new Error('Failed to refresh user')
    }

    await Config.refresh()
    await this.fetch()
    Analytics.setUser(this.getFirebaseId())

    console.log('user', this._user)
  }

  static getUserData(): UserJson {
    if (this._user == null) {
      throw new Error('User not logged in')
    }

    return this._user
  }

  static getFirebaseId(): string {
    return this._user?.firebaseUid ?? ''
  }

  static isCurrentSubscriptionType(subscriptionType: SubscriptionType) {
    return (subscriptionType === this.getSubscriptionDefine().type)
  }

  static isCurrentRenewingSubscription(subscriptionDefine: SubscriptionDefineBase, maxLocations: number) {
    return (
      this.isCurrentSubscription(subscriptionDefine, maxLocations)
      && this.isSubscriptionAutoRenewing()
    )
  }

  static isCurrentSubscription(subscriptionDefine: SubscriptionDefineBase, maxLocations: number): boolean {
    return (
      this.isCurrentSubscriptionType(subscriptionDefine.type)
      && this.getMaxLocations() === maxLocations
    )
  }

  static getSubscriptionData() {
    return this._user?.iapSubscription ?? this._user?.stripeSubscription ?? undefined
  }

  static getSubscriptionType(): SubscriptionType {
    const subscription = this.getSubscriptionData()
    if (!subscription) {
      return 'free'
    }

    return SubscriptionUtils.getType(subscription as SubscriptionMinimal)
  }

  static getSubscriptionDefine(): SubscriptionDefineBase {
    return SubscriptionUtils.getSubscriptionDefineByType(this.getSubscriptionType(), environment)
  }

  static isSubscribed(): boolean {
    return this.getSubscriptionType() !== 'free'
  }

  static getSubscriptionPaymentPlatform(): PaymentPlatform | undefined {
    if (this._user?.iapSubscription) {
      return this._user.iapSubscription.platform
    }

    if (this._user?.stripeSubscription) {
      return 'stripe'
    }

    return undefined
  }

  static isSubscribedOnDifferentPlatform(): boolean {
    if (this.getSubscriptionPaymentPlatform() == null) {
      return false
    }

    return (this.getSubscriptionPaymentPlatform() !== Tools.currentPaymentPlatform)
  }

  static getBiofixRange(): { min: DateTime; max: DateTime } {
    return {
      min: DateTime.now()
        .startOf('day')
        .minus({ days: this.getSubscriptionDefine().maxHistoricalWeatherDays }),
      max: DateTime.now()
        .startOf('day'),
    }
  }

  static getMaxLocations(): number {
    if (this._user?.iapSubscription) {
      const define = this.getSubscriptionDefine() as IapSubscriptionDefine
      return define.maxLocations
    }

    return this._user?.stripeSubscription?.quantity ?? 1
  }

  static getMaxModels(): number {
    return this.getSubscriptionDefine().maxModels
  }

  static canCreateLocation(locationCount: number): boolean {
    return locationCount < this.getMaxLocations()
  }

  static shouldShowSubscriptionSales(): boolean {
    // Only show sales for users who aren't subscribed.
    return (this.getSubscriptionDefine().type === 'free')
  }

  static isSubscriptionAutoRenewing(): boolean {
    return (
      this._user?.iapSubscription?.autoRenewing === true
      || this._user?.stripeSubscription?.cancelAtPeriodEnd === false
    )
  }

  static getSubscriptionExpiresAt(): DateTime | undefined {
    if (this._user?.iapSubscription?.autoRenewing === false) {
      return DateTime.fromISO(this._user.iapSubscription.expiresAt)
    }

    if (this._user?.stripeSubscription?.cancelAtPeriodEnd === true) {
      return DateTime.fromISO(this._user.stripeSubscription.currentPeriodEnd)
    }

    return undefined
  }

  static getSubscriptionExpiresAtText(): string {
    const expiresAt = this.getSubscriptionExpiresAt()
    if (expiresAt == null) {
      return 'n/a'
    }

    switch (this._user?.dateFormat) {
      case 'europe':
        return expiresAt.toFormat('d/M/yy')
      case 'usa':
        return expiresAt.toFormat('M/d/yy')
      default:
        return expiresAt.toISO()
    }
  }

  static getPaymentPlatformText(): string {
    if (this.getSubscriptionPaymentPlatform() == null) {
      return ''
    }

    switch (this.getSubscriptionPaymentPlatform()) {
      case 'iosAppstore':
        return 'iOS'
      case 'androidPlaystore':
        return 'Android'
      case 'stripe':
      default:
        return 'the Web App'
    }
  }

  static getSubscriptionProductId(): SubscriptionProductId | undefined {
    if (this._user?.iapSubscription) {
      return this._user.iapSubscription.productId
    }

    if (this._user?.stripeSubscription) {
      return this._user.stripeSubscription.priceId
    }

    return undefined
  }

  static isCurrentlySubscribedTo(productId: SubscriptionProductId): boolean {
    return (this.getSubscriptionProductId() === productId)
  }

  static canAddModel(models: ModelJson[]): boolean {
    switch (this.getSubscriptionDefine().type) {
      case 'free':
        return (models.length < 1)
      default:
        return true
    }
  }

  //
  // Login / Logout / Create / Destroy
  //

  static async login(
    args: {
      email: string
      password: string
    },
  ): Promise<void> {
    const { email, password } = args

    let firebaseUser: firebase.User | null
    try {
      const userCredential = await this.firebaseAuth.signInWithEmailAndPassword(email, password)
      firebaseUser = userCredential.user
    } catch (err) {
      if (err.code === 'auth/invalid-email') {
        throw new Error('Email address is not properly formated.')
      } else if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
        throw new Error('Email and/or password are incorrect.')
      } else {
        throw new Error(err.message)
      }
    }

    try {
      await this.refresh()
    } catch (err: unknown) {
      if (isApiError(err)) {
        // If we successfully logged into Firebase but the user wasn't found in our database,
        // try to recreate their account in our database.
        console.warn('User exists in Firebase but not in database! Attempting to recreate...')
        if (err.type === 'UserNotFound') {
          await this.createMe({
            email,
            name: firebaseUser?.displayName ?? '',
            company: '',
            jobTitle: '',
            dateFormat: 'usa',
            measurementUnits: 'metric',
            heardAboutUsFrom: '',
          })
          return
        }
      }

      throw err
    }
  }

  static async logout(): Promise<void> {
    await this.firebaseAuth.signOut()
    this.clear()
  }

  static getFirebaseUser(): Promise<firebase.User | null> {
    return this.firebaseAuth.currentUser
  }

  static async getFirebaseIdToken(): Promise<string> {
    const firebaseUser = await this.getFirebaseUser()
    return await firebaseUser?.getIdToken() ?? ''
  }

  static async changeEmail(args: {
    newEmail: string
    password: string
  }): Promise<void> {
    const { newEmail, password } = args

    const firebaseUser = await this.getFirebaseUser()
    if (firebaseUser == null) {
      throw new Error('Not authenticated!')
    }

    await this.reauthenticate(password)

    // TODO: Put this server-side in a transaction while saving user settings to avoid sync issues.
    await firebaseUser.updateEmail(newEmail)

    await this.saveUserSettings({ email: newEmail })
  }

  static async changePassword(args: { newPassword: string; currentPassword: string }): Promise<void> {
    await this.reauthenticate(args.currentPassword)

    const firebaseUser = await this.getFirebaseUser()
    if (firebaseUser == null) {
      throw new Error('Not authenticated!')
    }

    await firebaseUser.updatePassword(args.newPassword)
  }

  static async forgotPassword(args: { email: string }): Promise<void> {
    try {
      await this.firebaseAuth.sendPasswordResetEmail(args.email)
    } catch (err) {
      console.error(err.message)
      if (err.code === 'auth/user-not-found') {
        throw new Error('Account with that email could not be found.')
      } else if (err.code === 'auth/invalid-email') {
        throw new Error('Email address is not properly formated.')
      } else {
        throw new Error(err.message)
      }
    }
  }

  static async createFirebaseUser(
    args: {
      email: string
      password: string
    },
  ) {
    const { email, password } = args

    try {
      await this.firebaseAuth.createUserWithEmailAndPassword(email, password)
    } catch (err) {
      if (err.code === 'auth/email-already-in-use') {
        throw new Error('That email address is already is in use by another account.')
      } else if (err.code === 'auth/invalid-email') {
        throw new Error('Email address is not properly formated.')
      } else {
        throw new Error(err.message)
      }
    }
  }

  private static async createMe(settings: UserCreateBody) {
    this._user = await Api.post('/users/me', {
      body: settings,
    })
  }

  private static async deleteMe() {
    await Api.delete('/users/me')
  }

  static async createAccount(
    args: {
      settings: UserCreateBody
      password: string
    },
  ): Promise<void> {
    const { settings, password } = args

    // Create Firebase user and wait for authentication.
    await this.createFirebaseUser({
      email: settings.email,
      password,
    })

    const firebaseUser = await this.waitForFirebaseAuthentication()
    if (!firebaseUser) {
      throw new Error('Failed to authenticate with Firebase!')
    }

    // Create user in our database.
    try {
      await this.createMe(settings)
    } catch (err) {
      await firebaseUser.delete()
      await this.deleteMe()
      throw err
    }

    void this.sendEmailVerification(firebaseUser)

    await this.autoLogin()
  }

  static async deleteAccount(args: {
    password: string
  }): Promise<void> {
    const { password } = args

    await this.reauthenticate(password)
    const firebaseUser = await this.waitForFirebaseAuthentication()
    if (firebaseUser == null) {
      throw new Error('Not authenticated!')
    }

    await this.deleteMe()
    await firebaseUser.delete()

    this.clear()
  }

  static async saveUserSettings(userSettingsPatch: UserSettingsBody): Promise<void> {
    if (this._user == null) {
      throw new Error('User is not logged in')
    }

    this._user = {
      ...this._user,
      ...userSettingsPatch,
    }

    await Api.patch('/users/me', {
      body: this._user,
    })
  }

  //
  // Subscription (Stripe)
  //

  static async stripeCreateCustomer(args: {
    sourceTokenId: string
  }): Promise<void> {
    const { sourceTokenId } = args

    // NOTE: Creating a customer will automatically create a source as well.
    await Api.post('/stripe/customer', {
      body: {
        sourceTokenId,
      },
    })

    await Me.refresh()
  }

  static async stripeUpdateSource(args: {
    sourceTokenId: string
  }): Promise<void> {
    const { sourceTokenId } = args

    await Api.put('/stripe/source', {
      body: {
        sourceTokenId,
      },
    })

    await Me.refresh()
  }

  static async stripeChangeSubscription(args: {
    subscriptionType: StripeSubscriptionType
    maxLocations: number
  }): Promise<void> {
    const { subscriptionType, maxLocations } = args

    await Api.put('/stripe/change-subscription', {
      body: {
        subscriptionType,
        maxLocations,
      },
    })

    await Me.refresh()
  }

  static async stripeCancelSubscription(): Promise<void> {
    await Api.patch('/stripe/cancel-subscription')
    await Me.refresh()
  }

  static async stripeUndoCancelSubscription(): Promise<void> {
    await Api.patch('/stripe/undo-cancel-subscription')
    await Me.refresh()
  }

  //
  // Subscriptiom (IAP)
  //

  private static getManageSubscriptionUrl(): string | undefined {
    const paymentPlatform = Me.getSubscriptionPaymentPlatform()
    switch (paymentPlatform) {
      case 'iosAppstore':
        return 'https://apps.apple.com/account/subscriptions'
      case 'androidPlaystore': {
        if (this._user?.iapSubscription) {
          const productId = this._user.iapSubscription.productId
          return `https://play.google.com/store/account/subscriptions?sku=${productId}&package=${Tools.packageId}`
        }

        return 'http://play.google.com/store/account/subscriptions'
      }
      default:
        return undefined
    }
  }

  static manageSubscription(): void {
    const manageSubscriptionUrl = this.getManageSubscriptionUrl()
    if (manageSubscriptionUrl) {
      window.location.href = manageSubscriptionUrl
    }
  }

  //
  // Geolocation
  //

  private static getLocationBrowser(): Promise<LatLon> {
    return new Promise<LatLon>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(position => {
        this._geoLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }
        resolve(this._geoLocation)
        // eslint-disable-next-line promise/prefer-await-to-callbacks
      }, err => {
        const error = new Error(err.message)
        console.error(error)
        reject(error)
      })
    })
  }

  private static async getLocationNative(): Promise<LatLon> {
    try {
      const position = await Geolocation.getCurrentPosition()
      this._geoLocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }
      return this._geoLocation
    } catch (err) {
      const error = new Error(err.message)
      console.error(error)
      throw error
    }
  }

  static getLocation(forceRefresh = false): Promise<LatLon> {
    if (!forceRefresh && this._geoLocation) {
      return Promise.resolve(this._geoLocation)
    }

    if (Tools.isNative()) {
      return this.getLocationNative()
    }

    return this.getLocationBrowser()
  }

  //
  // Helpers
  //

  static async fetch() {
    this._user = await Api.get('/users/me')
  }

  private static clear() {
    this._user = UserUtils.getDefault()
    this._ranAutoLogin = false
  }

  private static async reauthenticate(password: string) {
    const firebaseUser = await this.getFirebaseUser()
    if (firebaseUser == null) {
      throw new Error('Not authenticated!')
    }

    try {
      await this.firebaseAuth.signInWithEmailAndPassword(
        firebaseUser.email!,
        password,
      )
    } catch (err) {
      switch (err.code) {
        case 'auth/wrong-password':
          throw new Error('Password is incorrect.')
        case 'auth/email-already-in-use':
          throw new Error('That email address is already is in use by another account.')
        case 'auth/invalid-email':
          throw new Error('Email address is not properly formated.')
        case 'auth/requires-recent-login':
          throw new Error('Please logout, login, then try again.')
        default:
          throw new Error(err.message)
      }
    }
  }

  private static async sendEmailVerification(firebaseUser: firebase.User) {
    let actionCodeSettings: firebase.auth.ActionCodeSettings
    if (Tools.isBrowser()) {
      actionCodeSettings = {
        url: window.location.origin,
      }
    } else {
      const appInfo = await App.getInfo()
      const launchUrl = await App.getLaunchUrl()
      actionCodeSettings = {
        url: launchUrl?.url ?? window.location.origin,
        iOS: {
          bundleId: appInfo.id,
        },
        android: {
          packageName: appInfo.id,
        },
      }
    }

    await firebaseUser.sendEmailVerification(actionCodeSettings)
  }

  static async waitForFirebaseAuthentication(): Promise<firebase.User | null> {
    return new Promise<firebase.User | null>((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error('Timed out waiting for Firebase authentication.'))
      }, 7000)

      void this.firebaseAuth.onAuthStateChanged(user => {
        clearTimeout(timeoutId)
        resolve(user)
      })
    })
  }

}
