import { Injectable } from '@angular/core'
import {
  AlertController,
  NavController,
} from '@ionic/angular'
import { Analytics } from '../services/analytics'
import { Tools } from '../services/tools'
import { Me } from '../services/me'

@Injectable({
  providedIn: 'root',
})
export class UpgradeAlertController {

  constructor(
    private alertCtrl: AlertController,
  ) {}

  getMessage(reason: UpgradeAlertReason) {
    switch (reason) {
      case UpgradeAlertReason.LocationLimitReached:
        return 'You have reached the location limit for your current subscription plan. You can add more locations by upgrading your plan.'
      case UpgradeAlertReason.LocationModelLimitReached:
        return 'You have reached this location\'s model limit for your current subscription plan. You can add more models by upgrading your plan.'
      default:
        throw new Error(`Unrecognized reason type: ${reason}`)
    }
  }

  create(args: {
    reason: UpgradeAlertReason
    navCtrl: NavController
  }) {
    const { reason, navCtrl } = args

    Analytics.event({ category: 'upgrade_alert', action: 'show', label: reason })

    return this.alertCtrl.create({
      header: 'Upgrade Required',
      message: this.getMessage(reason),
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            Analytics.event({ category: 'upgrade_alert', action: 'close', label: reason })
          },
        },
        {
          text: 'Upgrade',
          handler: async () => {
            Analytics.event({ category: 'upgrade_alert', action: 'upgrade', label: reason })
            if (Tools.isNative()) {
              if ((Tools.getPlatform() === 'ios' && Me.isSubscribed()) || Me.isSubscribedOnDifferentPlatform()) {
                await navCtrl.navigateForward('/account-settings/subscription-iap')
              } else {
                await navCtrl.navigateForward('/account-settings/subscription-iap/change')
              }
            } else if (Me.isSubscribedOnDifferentPlatform()) {
              await navCtrl.navigateForward('/account-settings/subscription-stripe')
            } else {
              await navCtrl.navigateForward('/account-settings/subscription-stripe/change')
            }
          },
        },
      ],
      backdropDismiss: false,
    })
  }

}

export enum UpgradeAlertReason {
  LocationLimitReached = 'locationLimitReached',
  LocationModelLimitReached = 'locationModelLimitReached',
}
