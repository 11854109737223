import { GddModelDefine } from '../gdd'

export const gddHemlockLooper: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddHemlockLooper',
  name: 'Hemlock Looper GDD',
  fullName: 'Hemlock Looper (Lambdina fiscellaria) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Larvae Emergence',
      range: {
        min: 0,
        max: 447,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae from overwintered eggs have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Emergence of Larvae from Overwintered Eggs',
      range: {
        min: 448,
        max: 707,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First larvae from overwintered eggs have begun to emerge.',
        },
      ],
    },
    {
      name: 'End of Larvae Emergence',
      range: {
        min: 708,
        max: 800,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Eggs from overwintered larvae have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Hemlock Looper Growing Degree Day Model is used to predict development of life-cycle stages of Hemlock Looper (Lambdina fiscellaria), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae from overwintered eggs which can be targeted for treatment.',
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-hemlock-looper-growing-degree-day-model/',
  citations: [
    `Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.`,
    `Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.`,
  ],
}
