import { GddModelDefine } from '../gdd'

export const gddObscureScaleEmergence: GddModelDefine = {
  lowerThreshold: 40,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddObscureScaleEmergence',
  name: 'Obscure Scale Emergence',
  fullName: 'Obscure Scale (Melanaspis obscura)) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Pre-Emergence of Adults / Crawlers',
      range: {
        min: 0,
        max: 1092,
      },
      infos: [
        {
          name: 'Crawlers',
          value: `Crawlers have not yet emerged. Recommended to set biofix to Jan. 1 for Northern Hemisphere.`,
        },
      ],
    },
    {
      name: 'Adult Male Emergence',
      range: {
        min: 1093,
        max: 2737,
      },
      infos: [
        {
          name: 'Adult Males',
          value: `Adult males have begun to emerge.`,
        },
      ],
    },
    {
      name: 'Crawler Emergence',
      range: {
        min: 2738,
        max: 3500,
      },
      infos: [
        {
          name: 'Crawlers',
          value: `Crawlers have begun to emerge.`,
        },
      ],
    },
  ],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Obscure Scale Growing Degree Day Model is used to predict the spring emergence of Obscure Scale (Melanaspis obscura) in order to prepare for setting sticky traps or beginning a spray program.`,
  ],
  citations: [
    `Potter, D. A., M. P. Jensen, and F. C. Gordon. 1989. Phenology and degree-day relationship of the obscure scale (Homoptera: Diaspididae) and associated parasites on Pin Oak in Kentucky. J. Econ. Entomol. 82: 551-555.`,
  ],
  moreInfoUrl:
    'https://blog.pestprophet.com/how-to-use-the-obscure-scale-emergence-growing-degree-day-model',
}
